import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { Panel } from '../../../components';
import {
  genderGraph,
  defaultPieChart,
  defaultBarChart
} from '../../../components/Graphs';

import { COLOR_SCHEME } from '../heatmap/mapConsts';
import {
  getCurrentUser,
  getAppSettings,
  getFilteredIncidents,
  getIncidentForm
} from '../../../selectors';

const GRAPH_DEFS = {
  genderChart: {
    style: {
      barColor: COLOR_SCHEME
    }
  },
  defaultBarChart: {
    style: {
      fill: COLOR_SCHEME[0]
    }
  },
  defaultPieChart: {
    style: {
      pieColor: COLOR_SCHEME
    }
  }
};

class IncidentStats extends React.Component {
  computeGenderGraph = index => {
    const { incidents, t, form } = this.props;
    const { barColor } = GRAPH_DEFS.genderChart.style;
    const gender = [];
    form.data[index].options.forEach(({ label }) => {
      gender.push({
        name: t(label),
        value: incidents.filter(
          p => p.data[index] && p.data[index].label === label
        ).length
      });
    });

    return genderGraph(
      gender,
      form.data[index].title,
      barColor,
      t,
      `incident-genderChart-${index}`
    );
  };

  computePieChart = index => {
    const { pieColor } = GRAPH_DEFS.defaultPieChart.style;
    const { incidents, t, form } = this.props;
    const data = [];
    form.data[index].options.forEach(({ label }) => {
      data.push({
        name: t(label),
        value: incidents.filter(
          p => p.data[index] && p.data[index].label === label
        ).length
      });
    });

    return defaultPieChart(
      data,
      form.data[index].title,
      pieColor,
      `incident-pieChart-${index}`
    );
  };

  computeBarChart = index => {
    const {
      incidents,
      t,
      form,
      appSettings: { styles }
    } = this.props;
    let { fill } = GRAPH_DEFS.defaultBarChart.style;
    if (styles) {
      fill = styles.menuBackgroundColor;
    }
    const data = [];
    form.data[index].options.forEach(({ label }) => {
      data.push({
        name: t(label),
        value: incidents.filter(
          p => p.data[index] && p.data[index].label === label
        ).length
      });
    });

    return defaultBarChart(
      data,
      form.data[index].title,
      fill,
      t,
      `incident-barChart-${index}`
    );
  };
  // eslint-disable-next-line
  getGraphs = () => {
    const { form } = this.props;

    if (!Array.isArray(form.data)) {
      return null;
    }

    return form.data.reduce((graphs, item, idx) => {
      // default
      let graphDefs = Array.isArray(item.options) ? ['defaultBarChart'] : [];

      // customized
      if (form.analyticsOptions !== undefined) {
        graphDefs = Array.isArray(form.analyticsOptions[idx])
          ? form.analyticsOptions[idx]
          : [];
      }

      graphDefs.forEach(graphDef => {
        if (graphDef === 'genderChart') {
          graphs.push(this.computeGenderGraph(idx));
        } else if (graphDef === 'defaultBarChart') {
          graphs.push(this.computeBarChart(idx));
        } else if (graphDef === 'defaultPieChart') {
          graphs.push(this.computePieChart(idx));
        }
      });

      return graphs;
    }, []);
  };

  render() {
    const { t, onClose } = this.props;
    return (
      <Panel
        title={t('Ereignis Statistik')}
        id="incidentStats"
        onClose={onClose}
      >
        <div
          style={{
            height: 'calc(100% - 40px)',
            overflowY: 'auto',
            padding: 16
          }}
        >
          {this.getGraphs()}
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    appSettings: getAppSettings(state),
    currentUser: getCurrentUser(state),
    incidents: getFilteredIncidents(state),
    form: getIncidentForm(state)
  };
}

export default connect(mapStateToProps)(
  translate('translations')(IncidentStats)
);
