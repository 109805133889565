// @flow
import { combineEpics } from 'redux-observable';
import axios from 'axios'; // TODO use ajax Observable
import { Observable } from 'rxjs/Observable';
import i18n from 'i18next';

import * as t from './actionTypes';
import { API_URL } from '../config';

const loginEpic = action$ =>
  action$
    .ofType(t.LOGIN)
    .filter(action => action.payload.name && action.payload.password)
    .mergeMap(action =>
      Observable.concat(
        Observable.of({ ...action, type: t.LOGIN_PENDING }),
        Observable.fromPromise(
          axios.post(`${API_URL}auth`, action.payload, {
            withCredentials: true
          })
        )
          .map(response => {
            if (response.data.userCtx) {
              const session = {
                ...response.data,
                cookie: response.headers['set-cookie']
              };
              return { type: t.LOGIN_SUCCESS, session };
            }

            return { type: t.LOGIN_REQUIRES_2FA };
          })
          .catch(() =>
            Observable.of({
              type: t.LOGIN_ERROR,
              error: i18n.t('Falsches Password/Email')
            })
          )
      )
    );

const logoutEpic = action$ =>
  action$.ofType(t.LOGOUT).mergeMap(action =>
    Observable.concat(
      Observable.of({ ...action, type: t.LOGOUT_PENDING }),
      Observable.fromPromise(
        axios.delete(`${API_URL}db/_session`, { withCredentials: true })
      )
        .map(() => ({ type: t.LOGOUT_SUCCESS }))
        .catch(error => Observable.of({ type: t.LOGOUT_ERROR, error }))
    )
  );

const refreshTokenEpic = action$ =>
  action$.ofType(t.REFRESH_TOKEN).mergeMap(action =>
    Observable.concat(
      Observable.of({ ...action, type: t.REFRESH_TOKEN_PENDING }),
      Observable.fromPromise(
        axios.get(`${API_URL}db/_session`, { withCredentials: true })
      )
        .map(response => {
          if (response.data.userCtx.name != null) {
            const session = {
              ...response.data,
              cookie: response.headers['set-cookie']
            };
            return { type: t.LOGIN_SUCCESS, session };
          }
          return { type: t.REFRESH_TOKEN_SUCCESS };
        })
        .catch(error => Observable.of({ type: t.REFRESH_TOKEN_ERROR, error }))
    )
  );

export default combineEpics(loginEpic, logoutEpic, refreshTokenEpic);
