// CHAT action
export const CHAT_LOCATION_HOVER_IN = 'CHAT_LOCATION_HOVER_IN';
export const CHAT_LOCATION_HOVER_OUT = 'CHAT_LOCATION_HOVER_OUT';
export const CHAT_LOCATION_INIT = 'CHAT_LOCATION_INIT';
export const CHAT_LOCATION_SET = 'CHAT_LOCATION_SET';
export const CHAT_LOCATION_DONE = 'CHAT_LOCATION_DONE';
export const CHAT_LOAD_MORE_MESSAGES = 'CHAT_LOAD_MORE_MESSAGES';

// BASE actions
export const BASE_ZOOM_INIT = 'BASE_ZOOM_INIT';
export const BASE_ZOOM_DONE = 'BASE_ZOOM_DONE';

// MAP actions
export const MAP_VIEWPORT_CHANGE = 'MAP_VIEWPORT_CHANGE';
export const MAP_LAYER_CHANGE = 'MAP_LAYER_CHANGE';

// SIDE MENU actions
export const SIDEMENU_TOGGLED = 'SIDEMENU_TOGGLED';

// chat
export const LAST_SEEN_MESSAGES = 'LAST_SEEN_MESSAGES';

export const SET_PRIVATE_KEY = 'SET_PRIVATE_KEY';
