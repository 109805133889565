import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import * as Fuse from 'fuse.js';
import _ from 'lodash';

import {
  REMOVE,
  EDIT,
  NEW,
  VIEW,
  getInitialDoc,
  FILTER,
  FILTER_BY_FINISHED,
  FILTER_BY_IMAGE,
  FILTER_BY_MESSAGE
} from '../../../constants';
import {
  Panel,
  ListHeader,
  List,
  QuestionDialog,
  DynDialog,
  EditDialog,
  ProcessDialog
} from '../../../components';
import { deleteDoc, updateDoc } from '../../../Couchbase';
import { timeConverter, wait } from '../../../utils';
import {
  incidentsDialogStyle,
  incidentsDialogStyleFilter,
  incidentsDialogStyleEdit,
  questionDialogStyles
} from '../../../ModalStyles';

import {
  getFilteredBasesCanLogIn,
  getFilteredIncidents,
  getCurrentUser,
  getAppSettings,
  getCurrentEvent,
  getIncidentForm,
  getPermissions,
  getEncryptionDetails
} from '../../../selectors';

import withEncryption from '../../../encryption/withEncryption';

import {
  exportCSV,
  transformItem,
  getTitle,
  exportAsPDF
} from '../exportHelper';

import FilterIncidentDialog from './FilterIncidentDialog';

const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'doc.baseName',
    'doc.eventName',
    'doc.userName',
    'doc.data.label',
    'doc.data.value',
    'doc.data.extra'
  ]
};

class Incidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchActive: false,
      functionType: undefined,
      incidentDoc: undefined,
      filterType: [],
      sortBy: {
        label: props.t('Erstellungsdatum'),
        value: 'newestEvent'
      },
      showExtendedInfo: false
    };
  }

  sendNotification = notification =>
    this.notificationSystem.addNotification(notification);
  componentDidUpdate(prevProps) {
    const { t, incidents, currentUserId } = this.props;

    const { currentEventId } = this.state;
    const newIncident = incidents[incidents.length - 1];

    if (
      incidents.length > prevProps.incidents.length &&
      prevProps.incidents.length > 0 &&
      newIncident.userId !== currentUserId
    ) {
      this.sendNotification({
        title: t('Neues Ereignis'),
        message: 'von ' + newIncident.userName,
        position: 'tc',
        autoDismiss: 120,
        level: 'warning'
      });
    }
  }

  componentWillReceiveProps({ incidents, bases }) {
    // the edit dialog is open, so we need to update the incident doc to have the correct _rev nummer and
    // not create conflicts
    if (this.state.incidentDoc) {
      const incidentDoc = incidents.find(
        i => i._id === this.state.incidentDoc._id
      );
      this.setState({ incidentDoc });
    }
    this.setState({ count: bases.length });
  }

  setUpIncidentData = () => {
    const rowData = [];
    const { t, form } = this.props;
    let incidents = [...this.props.incidents];

    // filtering
    if (this.state.filterType.length > 0) {
      if (this.state.filterType.includes(FILTER_BY_FINISHED)) {
        incidents = incidents.filter(i => !i.completed);
      }
      if (this.state.filterType.includes(FILTER_BY_IMAGE)) {
        incidents = incidents.filter(
          i => !(i.image === undefined || i.image === '')
        );
      }
      if (this.state.filterType.includes(FILTER_BY_MESSAGE)) {
        incidents = incidents.filter(
          i =>
            !(i.messages === undefined || Object.keys(i.messages).length === 0)
        );
      }
    }
    // sorting
    switch (this.state.sortBy.value) {
      case 'newestActivity':
        incidents.sort((p1, p2) => {
          let sortByP1 = p1.ts;
          let sortByP2 = p2.ts;
          if (p1.activity) {
            const activityP1 = p1.activity.map(a => a.ts);
            sortByP1 = Math.max(activityP1);
          }

          if (p2.activity) {
            const activityP2 = p2.activity.map(a => a.ts);
            sortByP2 = Math.max(activityP2);
          }

          return sortByP2 - sortByP1;
        });
        break;
      case 'newestMessage':
        incidents.sort((p1, p2) => {
          let sortByP1 = p1.ts;
          const sortByP2 = p2.ts;
          if (p1.messages) {
            sortByP1 = Math.max(
              Object.values(p1.messages).map(a => {
                const d = new Date(a.createdAt);
                return parseInt(d.getTime() / 1000, 10);
              })
            );
          }

          if (p2.messages) {
            sortByP1 = Math.max(
              Object.values(p2.messages).map(a => {
                const d = new Date(a.createdAt);
                return parseInt(d.getTime() / 1000, 10);
              })
            );
          }

          return sortByP2 - sortByP1;
        });
        break;
      default:
        // default by newest event
        incidents.sort((p1, p2) => p2.ts - p1.ts);
    }

    incidents.forEach(p => {
      const title = getTitle(p, form);
      const tsString = timeConverter(p.ts);
      let details = '';

      if (this.state.showExtendedInfo) {
        // add more details
        p.data.forEach((item, idx) => {
          let d = transformItem(item, form && form.data && form.data[idx]);

          if (d !== undefined) {
            d = Array.isArray(d) ? d : [d];

            d.forEach(dd => {
              details += `${dd.title}: ${dd.value}`;
              if (dd.extra) {
                details += ` - ${dd.extra}`;
              }
              details += '\n';
            });
          }
        });

        // // add the messages
        // if (p.messages) {
        //   const msgkeys = Object.keys(p.messages);
        //   msgkeys.forEach((k) => {
        //     details += p.messages[k].text;
        //     details += p.messages[k].image ? ' (img)' : '';
        //     details += p.messages[k].location ? ` (${latLngToDgreeString([p.messages[k].location.latitude, p.messages[k].location.longitude])})` : '';
        //     details += '\n';
        //   });
        // }
      }

      // empty line
      if (details.length > 0) {
        details += '\n';
      }

      details += p.case ? `[${p.case}] ` : '';
      details += `${t('Gemeldet am')} ${tsString}\n`;
      details += `${t('von')} ${p.userName}, ${t('Posten')}: ${p.baseName}`;

      const baseDoc = this.props.bases.find(b => p.baseId === b._id); // p.baseId == b._id.split(':')[3]

      const highlighted = _.some(
        p.data,
        item =>
          item &&
          ((Array.isArray(item.value) && _.some(item.value, 'highlight')) ||
            (item.value && item.value.highlight) ||
            (item.component === 'ButtonGroup' &&
              p.className === 'PatientTreatment' &&
              item.extra))
      );

      const data = {
        highlighted,
        title,
        _id: p._id,
        details,
        rowAction: () => {
          this.openModal(p, baseDoc, VIEW);
        },
        handleDrop: payload => {
          this.handleMsgDrop(p._id, payload);
        },
        leftIcon: {
          icon: 'bell'
        },
        rightIcons: [],
        doc: p
      };
      if (this.props.currentUser && this.props.currentUser.admin) {
        data.rightIcons.push({
          icon: 'trash',
          action: () => {
            this.openModal(p, undefined, REMOVE);
          }
        });
        data.rightIcons.push({
          icon: 'pen',
          action: () => {
            this.openModal(p, baseDoc, EDIT);
          }
        });
      }

      // add state indicator
      if (
        (p.activity && p.activity.length > 0) ||
        (p.messages && Object.keys(p.messages).length > 0)
      ) {
        data.rightIcons.push({
          icon: 'comments',
          action: () => {}
        });
      }
      if (p.completed) {
        data.rightIcons.push({
          icon: 'check',
          action: () => {}
        });
      }

      rowData.push(data);
    });
    return rowData;
  };

  handleMsgDrop = (id, payload) => {
    const incidentDoc = this.props.incidents.find(i => i._id === id);
    if (incidentDoc) {
      // init the message property if it does not exist
      if (!incidentDoc.messages) {
        incidentDoc.messages = {};
      }

      // add the message to the incident doc
      incidentDoc.messages[payload._id] = payload;

      // send new version to db
      updateDoc(incidentDoc, status => {
        if (!status) {
          this.notificationSystem.addNotification({
            message: this.props.t('Nachricht konnte nicht anhangen werden.'),
            level: 'error'
          });
        } else {
          this.notificationSystem.addNotification({
            message: this.props.t('Nachricht erfolgreich angehangen.'),
            level: 'success'
          });
        }
      });
    }
  };

  openModal = (incidentDoc, baseDoc, functionType) => {
    this.setState({
      modalIsOpen: true,
      functionType,
      baseDoc,
      incidentDoc
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  closeModalSucces = incidentDoc => {
    this.setState({ modalIsOpen: false });

    switch (this.state.functionType) {
      case REMOVE:
        if (this.state.incidentDoc) {
          deleteDoc(this.state.incidentDoc, status => {
            if (status) {
              this.setState({ incidentDoc: undefined });
              this.notificationSystem.addNotification({
                message: this.props.t('Ereignis erfolgreich gelöscht'),
                level: 'success'
              });
            }
          });
        }
        break;
      case NEW:
        updateDoc(incidentDoc, status => {
          if (!status) {
            this.notificationSystem.addNotification({
              message: this.props.t('Ereignis konnte nicht erstellt werden.'),
              level: 'error'
            });
          } else {
            this.notificationSystem.addNotification({
              message: this.props.t('Ereignis erfolgreich erstellt.'),
              level: 'success'
            });
          }
        });
        break;
      case EDIT:
        updateDoc(incidentDoc, status => {
          if (!status) {
            this.notificationSystem.addNotification({
              message: this.props.t('Ereignis konnte nicht bearbeitet werden.'),
              level: 'error'
            });
          } else {
            this.notificationSystem.addNotification({
              message: this.props.t('Ereignis erfolgreich bearbeitet.'),
              level: 'success'
            });
          }
        });
        break;
      default: // create and edit is the same code
    }
  };

  getInitalDoc = () => {
    const {
      appSettings: { appId },
      currentEvent: { _id: eventId, name: eventName },
      currentUser: { _id: userId, name: userName },
      form
    } = this.props;
    const initalDoc = getInitialDoc('Incident');
    if (form && form.length > 0) {
      form.data.forEach(d => {
        if (d.options && d.options.length > 0) {
          initalDoc.data.push(d.options[0]);
        }
      });
    }
    return {
      ...initalDoc,
      appId,
      eventId,
      eventName,
      userId,
      userName,
      ts: Math.floor(Date.now() / 1000)
    };
  };

  export = () => {
    const { incidents, form, t } = this.props;
    exportCSV(incidents, form, t('Ereignisse') + '.csv');
  };

  showPrintDialog = () => this.setState({ exporting: true });

  hidePrintDialog = () => this.setState({ exporting: false });

  print = async options => {
    const { incidents, form, t, decryptItem, currentEvent } = this.props;

    this.setState({ generatingPDF: true });

    await wait(100);

    exportAsPDF(incidents, {
      form,
      decryptItem,
      ...options,
      translations: {
        docTitle: `${t('Ereignisse')}: ${currentEvent.name}`,
        title: t('Ereignis'),
        createdAt: t('Created at'),
        createdBy: t('Created by'),
        lastEditAt: t('Last edit at'),
        lastEditBy: t('Last edit by'),
        event: t('Event'),
        base: t('Posten'),
        protocol: t('Weitere Schritte'),
        messages: t('Nachrichten')
      },
      filename: `${t('Ereignisse')}.pdf`
    }).finally(() => {
      // hide process dialog
      this.setState({ exporting: false, generatingPDF: false });
    });
  };

  getFunctionButtons = (appSettings, currentUser) => {
    const res = [
      {
        action: () => {
          this.setState({ searchActive: !this.state.searchActive });
        },
        icon: 'search',
        active: this.state.searchActive,
        tooltip: this.props.t('Ereignisse durchsuchen')
      },
      {
        action: () => {
          this.openModal(undefined, undefined, FILTER);
        },
        icon: 'filter',
        active: this.state.filterType.length > 0,
        tooltip: this.props.t('Ereignisse filtern')
      },
      {
        action: () => {
          this.setState({ showExtendedInfo: !this.state.showExtendedInfo });
        },
        icon: this.state.showExtendedInfo
          ? 'caret-square-up'
          : 'caret-square-down',
        tooltip: this.props.t('Details anzeigen')
      },
      {
        action: () => {
          this.openModal(undefined, undefined, NEW);
        },
        url: appSettings.assets.addIconUrl,
        icon: 'plus-square',
        tooltip: this.props.t('Neues Ereignis')
      }
    ];

    if (currentUser.admin) {
      res.push({
        url: appSettings.assets.downloadIconUrl,
        action: () => this.export(),
        icon: 'file-download',
        tooltip: this.props.t('Ereignisse exportieren')
      });
      res.push({
        url: appSettings.assets.printIconUrl,
        icon: 'print',
        action: () => this.showPrintDialog()
      });
    }
    return res;
  };

  changeSortSettingsChange = ({ filterType, sortBy }) => {
    this.setState({ filterType, sortBy, modalIsOpen: false });
  };

  getModalStyle = () => {
    switch (this.state.functionType) {
      case VIEW:
        return incidentsDialogStyle;
      case EDIT:
        return incidentsDialogStyleEdit;
      case NEW:
        return incidentsDialogStyleEdit;
      case FILTER:
        return incidentsDialogStyleFilter;
      case REMOVE:
        return questionDialogStyles;
      default:
        if (this.state.functionType) {
          console.log(`FunctionType: ${this.state.functionType} not supported`);
        }
        return undefined;
    }
  };

  search = (searchTerm, dataList) => {
    if (searchTerm.length < 2) {
      return dataList;
    }
    const fuse = new Fuse(dataList, SEARCH_OPTIONS);
    return fuse.search(searchTerm);
  };

  handleSearch = searchValue => this.setState({ searchValue });

  render() {
    const {
      t,
      incidents,
      permissions,
      appSettings,
      currentUser,
      currentEvent,
      form,
      bases,
      onClose,
      encryption
    } = this.props;
    const {
      modalIsOpen,
      functionType,
      incidentDoc,
      baseDoc,
      sortBy,
      filterType,
      searchActive,
      searchValue,
      exporting,
      generatingPDF
    } = this.state;

    const rowData = this.setUpIncidentData();
    const currentRowData =
      searchActive && searchValue ? this.search(searchValue, rowData) : rowData;

    return (
      <Panel title={t('Ereignisse')} id="devices" onClose={onClose}>
        <ListHeader
          functionButtons={this.getFunctionButtons(appSettings, currentUser)}
          count={incidents.length}
          searchActive={searchActive}
          onSearch={this.handleSearch}
        />
        <div
          style={{
            height: searchActive ? 'calc(100% - 120px)' : 'calc(100% - 80px)'
          }}
        >
          <List rowData={currentRowData} draggable />
        </div>

        <ProcessDialog
          visible={exporting}
          title={t('Generating PDF file...')}
          onPress={this.print}
          onClose={this.hidePrintDialog}
          loading={generatingPDF}
          showDecryptOption={encryption.enabled}
          t={t}
        />

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={this.getModalStyle()}
          contentLabel="deleteAlert"
        >
          {functionType === REMOVE && (
            <QuestionDialog
              caption={t('Löschen')}
              content={t(
                'Sind sie sicher, dass sie den Eintrag löschen wollen?'
              )}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
            />
          )}
          {functionType === VIEW && (
            <EditDialog
              nextStepsActive={permissions.dashboard.features.nextSteps}
              doc={incidentDoc}
              baseDoc={baseDoc}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
              appSettings={appSettings}
              currentUser={currentUser}
            />
          )}
          {(functionType === EDIT || functionType === NEW) && (
            <DynDialog
              form={form}
              mainDoc={incidentDoc || this.getInitalDoc()}
              docType="Incident"
              baseDoc={baseDoc}
              bases={bases}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
              appSettings={appSettings}
              currentUser={currentUser}
              currentEvent={currentEvent}
              encryption={encryption}
            />
          )}
          {functionType === FILTER && (
            <FilterIncidentDialog
              caption={t('Ereignisse sortieren')}
              errorHandler={this.closeModal}
              succesHandler={this.changeSortSettingsChange}
              sortBy={sortBy}
              filterType={filterType}
            />
          )}
        </Modal>
        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  const currentEvent = getCurrentEvent(state);
  return {
    appSettings: getAppSettings(state),
    incidents: getFilteredIncidents(state),
    bases: getFilteredBasesCanLogIn(state),
    currentUserId: getCurrentUser(state)._id,
    currentUser: getCurrentUser(state),
    form: getIncidentForm(state),
    currentEvent,
    permissions: getPermissions(state),
    encryption: currentEvent
      ? getEncryptionDetails(state)[currentEvent._id]
      : {
          enabled: false
        }
  };
}

export default connect(mapStateToProps)(withEncryption(Incidents));
