import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-native';

class ScaledImage extends Component {
  componentWillMount() {
    const { source } = this.props;

    if (typeof source === 'string') {
      Image.getSize(source, this.setImageSize);
    } else if (typeof source === 'number') {
      const { width, height } = Image.resolveAssetSource(source);
      this.setImageSize(width, height);
    }
  }

  setImageSize = (w, h) => {
    const { scale, width, height } = this.props;
    if (scale) {
      this.setState({
        width: w * scale,
        height: h * scale
      });
    } else if (width && !height) {
      this.setState({
        width,
        height: h * (width / width)
      });
    } else if (!width && height) {
      this.setState({
        width: w * (height / height),
        height
      });
    } else {
      this.setState({
        width: w,
        height: h
      });
    }
  };

  getStyles = () => {
    const { style, width, height } = this.state;
    const styles = [
      {
        height,
        width
      }
    ];

    if (style) {
      styles.push(style);
    }

    return styles;
  };

  render() {
    const { source } = this.props;
    if (this.state) {
      return <Image source={source} style={this.getStyles()} />;
    }
    return null;
  }
}

ScaledImage.propTypes = {
  source: PropTypes.any.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  scale: PropTypes.number,
  style: PropTypes.any
};

export default ScaledImage;
