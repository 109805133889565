import { SET_PRIVATE_KEY } from '../actionTypes';
import { LOGOUT } from '../../../auth/actionTypes';

const initialState = {};

export default (state = initialState, action) => {
  if (action.type === SET_PRIVATE_KEY) {
    return { ...state, [action.eventId]: action.privateKey };
  }

  if (action.type === LOGOUT) {
    return initialState;
  }

  return state;
};
