export const PUT_DOC = 'db/PUT_DOC';
export const PUT_DOC_SUCCESS = 'db/PUT_DOC_SUCCESS';
export const PUT_DOC_PENDING = 'db/PUT_DOC_PENDING';
export const PUT_DOC_PENDING_SUCCESS = 'db/PUT_DOC_PENDING_SUCCESS';
export const PUT_DOC_PENDING_ERROR = 'db/PUT_DOC_PENDING_ERROR';
export const PUT_DOC_ERROR = 'db/PUT_DOC_ERROR';

export const REMOVE_DOC = 'db/REMOVE_DOC';
export const REMOVE_DOC_PENDING = 'db/REMOVE_DOC_PENDING';
export const REMOVE_DOC_SUCCESS = 'db/REMOVE_DOC_SUCCESS';
export const REMOVE_DOC_ERROR = 'db/REMOVE_DOC_ERROR';

export const BATCH_PUT_DOC = 'db/BATCH_PUT_DOC';
export const BATCH_PUT_DOC_SUCCESS = 'db/BATCH_PUT_DOC_SUCCESS';
export const BATCH_PUT_DOC_ERROR = 'db/BATCH_PUT_DOC_ERROR';

export const ENABLE_LIVE_UPDATE = 'db/ENABLE_LIVE_UPDATE';

export const RESET_DB = 'db/RESET_DB';
export const RESET_DB_SUCCESS = 'db/RESET_DB_SUCCESS';
export const RESET_DB_ERROR = 'db/RESET_DB_ERROR';

export const SYNC = 'db/SYNC';
export const SYNC_ACTIVE = 'db/SYNC_ACTIVE';
export const SYNC_PAUSED = 'db/SYNC_PAUSED';
export const SYNC_CHANGE = 'db/SYNC_CHANGE';
export const SYNC_ERROR = 'db/SYNC_ERROR';

export const LOAD_DB_PENDING = 'db/LOAD_DB_PENDING';
export const LOAD_DB_ERROR = 'db/LOAD_DB_ERROR';
export const LOAD_DB_SUCCESS = 'db/LOAD_DB_SUCCESS';

export const APP_SETTINGS_LOADED = 'db/APP_SETTINGS_LOADED';
export const SET_APP = 'db/SET_APP';
