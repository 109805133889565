import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-unresolved
import { Text, View, StyleSheet } from 'react-native';

// import DateTimePicker from '../Input/DateTimePicker';
import Picker from '../Input/Picker';
import DateTimeInput from '../Input/DateTimeInput';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#CCCCCC',
    margin: 3,
    marginBottom: 0,
    padding: 3,
    borderRadius: 3,
    zIndex: 3
  },
  text: {
    fontSize: 16,
    fontWeight: '400',
    color: '#8DA624',
    fontFamily: 'Helvetica'
  },
  label: {
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#33495D',
    fontFamily: 'Helvetica',
    paddingTop: 3
  },
  dateInputContainer: {
    marginTop: 0
  }
});

const FormHeader = ({
  translations,
  userName,
  selectedBase,
  selectableBases,
  eventName,
  timestamp,
  onChangeBase,
  onChangeTimestamp,
  isBaseDisabled
}) => (
  <View style={styles.container}>
    <Text style={styles.label}>
      {`${translations.by}: `}
      <Text style={styles.text}>{userName}</Text>
    </Text>

    <Text style={styles.label}>
      {`${translations.event}: `}
      <Text style={styles.text}>{eventName}</Text>
    </Text>

    <View style={{ paddingTop: 6, zIndex: 3 }}>
      <Text style={styles.label}>{`${translations.createdAt}: `}</Text>
      <DateTimeInput
        containerStyle={styles.dateInputContainer}
        mode="datetime"
        value={timestamp}
        onChange={onChangeTimestamp}
      />
    </View>

    <View style={{ paddingTop: 6, zIndex: 2 }}>
      <Text style={styles.label}>
        {`${translations.base}: `}
        <Picker
          options={selectableBases.map(b => ({ label: b.name, value: b._id }))}
          value={
            selectedBase && {
              label: selectedBase.name,
              value: selectedBase._id
            }
          }
          onChange={onChangeBase}
          isDisabled={isBaseDisabled}
        />
      </Text>
    </View>
  </View>
);

FormHeader.propTypes = {
  userName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  selectedBase: PropTypes.object,
  selectableBases: PropTypes.array.isRequired,
  timestamp: PropTypes.number.isRequired,
  onChangeTimestamp: PropTypes.func,
  onChangeBase: PropTypes.func,
  status: PropTypes.string,
  translations: PropTypes.object,
  isBaseDisabled: PropTypes.bool
};

FormHeader.defaultProps = {
  onChangeBase: () => {},
  onChangeTimestamp: () => {},
  status: undefined,
  selectedBase: null,
  translations: {
    createdAt: 'Created at',
    by: 'By',
    event: 'Event',
    base: 'Base'
  },
  isBaseDisabled: false
};

export default FormHeader;

// TODO translations
