/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
// import { DynamicSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import ListItem from './ListItem';
import ListItemDragable from './ListItemDragable';
import withLightbox from './withLightbox';

import Message from './Message';

class List extends React.Component {
  state = {
    selectionModeActive: false,
    selection: [],
    firstSelectionElement: undefined
  };

  componentDidUpdate({ scrollToTop }) {
    if (!scrollToTop && this.props.scrollToTop && this.list) {
      console.log('scrollToTop');
      this.list.scrollToItem(0);
    }
  }

  longPressHandler = row => {
    const { selectable } = this.props;
    if (selectable) {
      this.setState({
        selectionModeActive: true,
        selection: [row],
        firstSelectionElement: row
      });
    }
  };

  selectAll = () => {
    const { rowData } = this.props;
    this.setState({
      selection: rowData,
      selectionModeActive: true,
      firstSelectionElement: rowData[0] // first elem or undefined
    });
  };

  resetSelection = () => {
    this.setState({
      selection: [],
      selectionModeActive: false,
      firstSelectionElement: undefined
    });
  };

  selectionClickHandler = (row, event) => {
    let selection = [...this.state.selection];
    let { selectionModeActive, firstSelectionElement } = this.state;

    const { rowData } = this.props;

    if (event.ctrlKey) {
      console.log('crtl');
    } else if (event.shiftKey) {
      if (!firstSelectionElement) return; // should only happen when list is empty
      // clear the current selection
      selection = [];

      // compute the range to add to the selection
      let startIndex = rowData.findIndex(
        s => s._id === firstSelectionElement._id
      );
      let endIndex = rowData.findIndex(s => s._id === row._id);

      // set the correct order
      startIndex = Math.min(startIndex, endIndex);
      endIndex = Math.max(startIndex, endIndex);

      // add all items between start and stop index
      for (let i = startIndex; i <= endIndex; i += 1) {
        selection = [...selection, rowData[i]];
      }
    } else {
      // normal press event
      // check if the row is already selected, otherwise add to the selection
      const selectionIndex = selection.findIndex(s => s._id === row._id);

      if (selectionIndex > -1) {
        selection.splice(selectionIndex, 1);
      } else {
        selection = [...selection, row];
      }
    }

    // nothing selected anymore toggle selction mode
    if (selection.length === 0) {
      selectionModeActive = false;
      firstSelectionElement = undefined;
    }

    // update the state
    this.setState({ selection, selectionModeActive, firstSelectionElement });
  };

  Row = React.forwardRef(({ index, style }, forwardedRef) => {
    const { selectionModeActive } = this.state;

    const { draggable, rowData, selection, openGallery } = this.props;

    const e = rowData[index];

    const selected = selection.find(s => s._id === e._id);

    if (draggable) {
      return (
        <div
          ref={forwardedRef}
          style={{
            ...style,
            backgroundColor: index % 2 ? '#efefef' : '#f9f9f9',
            borderTopWidth: 1,
            borderTopColor: '#DDDDDD',
            borderTopStyle: 'solid'
          }}
        >
          <ListItemDragable
            value={e.title}
            _id={e._id}
            handleDrop={e.handleDrop}
            ts={e.ts}
            highlighted={e.highlighted}
            status={e.status}
            key={e._id}
            rowAction={e.rowAction}
            oneUserOnlineFlag={e.oneUserOnlineFlag}
            leftIcon={e.leftIcon}
            rightIcons={e.rightIcons}
            details={e.details}
            longPressHandler={() => this.longPressHandler(e)}
            selected={selected !== undefined}
            selectionModeActive={selectionModeActive}
            selectionClickHandler={event =>
              this.selectionClickHandler(e, event)
            }
          >
            {e.doc && e.doc.className === 'ChatMessage' && (
              <Message data={e.doc.data} openGallery={openGallery} />
            )}
          </ListItemDragable>
        </div>
      );
    }

    return (
      <div
        ref={forwardedRef}
        style={{
          ...style,
          backgroundColor: index % 2 ? '#efefef' : '#f9f9f9',
          borderTopWidth: 1,
          borderTopColor: '#DDDDDD',
          borderTopStyle: 'solid'
        }}
      >
        <ListItem
          value={e.title}
          _id={e._id}
          ts={e.ts}
          highlighted={e.highlighted}
          status={e.status}
          key={e._id}
          rowAction={e.rowAction}
          oneUserOnlineFlag={e.oneUserOnlineFlag}
          leftIcon={e.leftIcon}
          rightIcons={e.rightIcons}
          details={e.details}
          longPressHandler={() => this.longPressHandler(e)}
          selected={selected !== undefined}
          selectionModeActive={selectionModeActive}
          selectionClickHandler={event => this.selectionClickHandler(e, event)}
        >
          {e.doc && e.doc.className === 'ChatMessage' && (
            <Message data={e.doc.data} openGallery={openGallery} />
          )}
        </ListItem>
      </div>
    );
  });

  getItemKey = (index, data) => {
    // console.log(index, data);
    const { rowData } = this.props;
    const item = rowData[index];

    // const item = data[index];
    return item._id;
  };

  render() {
    const { rowData } = this.props;

    return (
      <AutoSizer>
        {({ width, height }) => (
          <div style={{ width, height, overflowY: 'auto' }}>
            {rowData.map((row, i) => (
              <this.Row index={i} key={row._id} />
            ))}
          </div>
        )}
      </AutoSizer>
    );
  }

  // render() {
  //   const { rowData } = this.props;

  //   return (
  //     <AutoSizer>
  //       {({ width, height }) => (
  //         <DynamicSizeList
  //           itemCount={rowData.length}
  //           itemData={rowData}
  //           itemKey={this.getItemKey}
  //           width={width - 2}
  //           height={height}
  //           ref={(list) => {
  //             this.list = list;
  //           }}
  //         >
  //           {this.Row}
  //         </DynamicSizeList>
  //       )}
  //     </AutoSizer>
  //   );
  // }
}

List.propTypes = {
  rowData: PropTypes.array,
  selectable: PropTypes.bool,
  draggable: PropTypes.bool,
  selection: PropTypes.array
};

List.defaultProps = {
  rowData: [],
  selection: [],
  selectable: false,
  draggable: false
};

export default withLightbox(List);
