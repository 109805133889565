import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import * as Fuse from 'fuse.js';
import axios from 'axios';
import moment from 'moment';

import { prepareCodePDF } from './preparePDF';

import { Panel, ListHeader, List, QuestionDialog } from '../../components';
import { updateDoc, deleteDoc } from '../../Couchbase';
import { questionDialogStyles, editQrStyles } from '../../ModalStyles';
import { NEW, EDIT, REMOVE, REMOVE_SELECTED } from '../../constants';
import EditQrDialog from './EditQrDialog';

import {
  getFilteredQrs,
  getAppAssets,
  getCurrentUser,
  getEvents,
  getBases,
  getAppSettings
} from '../../selectors';

import { timeConverter, generateId } from '../../utils';

const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'doc.properties.company',
    'doc.properties.description',
    'doc.properties.title'
  ]
};

const initNewQRCode = (appId, user) => {
  const now = moment();

  return {
    _id: `${appId}:QRCode:${generateId()}`,
    className: 'QRCode',
    appId,
    userName: user.name,
    userId: user._id,
    ts: now.unix(),
    properties: {
      company: user.company,
      validFrom: now.toISOString()
    }
  };
};

class QR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      functionType: undefined,
      qrDoc: undefined,
      searchActive: false
    };
  }

  setUpFunctionData = () => {
    const { searchActive } = this.state;
    const { assets, t } = this.props;

    return [
      {
        action: () => {
          this.setState({ searchActive: !searchActive });
        },
        icon: 'search',
        active: this.state.searchActive,
        tooltip: t('QR codes durchsuchen')
      },
      {
        action: () => this.printAllQrCodes(),
        icon: 'print',
        tooltip: t('Alle QR codes drucken')
      },
      {
        action: () => {
          this.openModal(undefined, NEW);
        },
        icon: 'plus-square',
        tooltip: t('Neuen QR erstellen')
      },
      {
        action: () => {
          if (this.list) {
            this.list.selectAll();
          }
          this.openModal(undefined, REMOVE_SELECTED);
        },
        icon: 'folder-minus',
        tooltip: t('Alle QR codes löschen')
      }
    ];
  };

  // checked
  setUpData = () => {
    const { qrCodes, t } = this.props;

    const getDetails = ({ validFrom, validUntil }) => {
      const now = moment();
      const start = moment(validFrom);
      const end = moment(validUntil);

      if (now < start) {
        return `${t('Gültig ab')} ${timeConverter(start.unix())}`;
      }

      if (now < end) {
        return `${t('Gültig bis')} ${timeConverter(end.unix())}`;
      }

      return `${t('Ungültig')}`;
    };

    const rowData = [];
    qrCodes.forEach(e =>
      rowData.push({
        title: e.properties.title,
        _id: e._id,
        details: getDetails(e.properties),
        leftIcon: {
          icon: 'qrcode'
        },
        rightIcons: [
          {
            icon: 'trash',
            action: () => {
              this.openModal(e, REMOVE);
            }
          },
          {
            icon: 'pen',
            action: () => {
              this.openModal(e, EDIT);
            }
          }
        ],
        doc: e
      })
    );
    return rowData;
  };

  // checked
  openModal = (qrDoc, functionType) =>
    this.setState({ modalIsOpen: true, functionType, qrDoc });

  // checked
  closeModal = () => this.setState({ modalIsOpen: false });

  closeModalSucces = qrDoc => {
    this.setState({ modalIsOpen: false });
    const { t } = this.props;
    const { functionType, qrDoc: currentDoc } = this.state;

    console.log(qrDoc, functionType);

    switch (functionType) {
      case REMOVE:
        if (currentDoc) {
          deleteDoc(currentDoc, status => {
            if (status) {
              this.setState({ qrDoc: undefined });
              this.notificationSystem.addNotification({
                message: t('QR code erfolgreich gelöscht'),
                level: 'success'
              });
            }
          });
        }
        break;
      case REMOVE_SELECTED:
        if (this.list && this.list.state.selection.length > 0) {
          // get only the user in the selection
          const promisesEdit = [];
          this.list.state.selection.forEach(qr =>
            promisesEdit.push(deleteDoc(qr.doc))
          );

          axios
            .all(promisesEdit)
            .then(() => {
              this.notificationSystem.addNotification({
                message: t('Alle Qr codes erfolgreich gelöscht.'),
                level: 'success'
              });
            })
            .catch((error, response) => {
              this.notificationSystem.addNotification({
                message:
                  response && response.message
                    ? response.message
                    : t('Qr code löschen fehlgeschlagen.'),
                level: 'error'
              });
              console.error(error, response);
            });
        }
        break;
      case NEW:
        if (qrDoc) {
          updateDoc(qrDoc, status => {
            if (status) {
              this.notificationSystem.addNotification({
                message: t('QR code erfolgreich gespeichert.'),
                level: 'success'
              });
            }
          });
        }
        break;
      case EDIT:
        updateDoc(qrDoc, status => {
          if (!status) {
            this.notificationSystem.addNotification({
              message: t('QR code konnte nicht bearbeitet werden.'),
              level: 'error'
            });
          } else {
            this.notificationSystem.addNotification({
              message: t('QR code erfolgreich bearbeitet.'),
              level: 'success'
            });
          }
        });
        break;
      default: {
        // create and edit is the same code
        console.error('No type specified');
      }
    }
  };

  renderDialog = () => {
    const { t, currentUser, appId } = this.props;
    const { functionType, qrDoc } = this.state;

    const initialValues = qrDoc || initNewQRCode(appId, currentUser);

    switch (functionType) {
      case REMOVE:
        return (
          <QuestionDialog
            caption={t('Löschen')}
            content={t('Sind sie sicher, dass sie den Eintrag löschen wollen?')}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      case REMOVE_SELECTED:
        return (
          <QuestionDialog
            caption={t('Qr Code löschen')}
            content={t(
              'Sind sie sicher, dass sie die ausgewählten Einträge löschen wollen?'
            )}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      case NEW:
        return (
          <EditQrDialog
            caption={t('QR code erstellen')}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
            initialValues={initialValues}
          />
        );
      case EDIT:
        return (
          <EditQrDialog
            caption={t('QR code bearbeiten')}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
            initialValues={initialValues}
          />
        );
      default: {
        return null;
      }
    }
  };

  printAllQrCodes = () => {
    const { qrCodes, t, bases, events } = this.props;
    const { searchActive } = this.state;

    const now = moment();
    // generate codes
    let usedQrCodes = qrCodes;
    if (searchActive) {
      usedQrCodes = this.search(this.list.state.searchValue, this.setUpData());
    }
    prepareCodePDF(usedQrCodes, t, bases, events);
  };

  // checked
  search = (searchTerm, dataList) => {
    if (searchTerm.length < 2) {
      return dataList;
    }
    const fuse = new Fuse(dataList, SEARCH_OPTIONS);
    return fuse.search(searchTerm);
  };

  render() {
    const { modalIsOpen, functionType, searchActive, searchValue } = this.state;
    const { notDraggable, t, qrCodes } = this.props;

    const rowData = this.setUpData();
    const currentRowData =
      searchActive && searchValue ? this.search(searchValue, rowData) : rowData;

    return (
      <Panel title={t('QR codes')} id="qr">
        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />

        <ListHeader
          functionButtons={this.setUpFunctionData()}
          count={qrCodes.length}
          searchActive={searchActive}
          onSearch={value => this.setState({ searchValue: value })}
        />
        <div
          style={{
            height: searchActive ? 'calc(100% - 120px)' : 'calc(100% - 80px)'
          }}
        >
          <List rowData={currentRowData} />
        </div>
        <Modal
          isOpen={modalIsOpen}
          style={
            functionType === NEW || functionType === EDIT
              ? editQrStyles
              : questionDialogStyles
          }
          shouldCloseOnOverlayClick={false}
          onRequestClose={event => {
            // Ignore react-modal esc-close handling
            if (event.type === 'keydown' && event.keyCode === 27) {
              return;
            }

            this.closeModal();
          }}
          contentLabel="deleteAlert"
        >
          {this.renderDialog()}
        </Modal>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    qrCodes: getFilteredQrs(state),
    assets: getAppAssets(state),
    currentUser: getCurrentUser(state),
    events: getEvents(state),
    bases: getBases(state),
    appId: getAppSettings(state).appId
  };
}

export default connect(mapStateToProps)(QR);

// TODO create QR Code for current app, not of user, otherwise superyser create qr codes for app system
