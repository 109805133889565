import TextInput from './TextInput';
import DateTimeInput from './DateTimeInput';
import PickerInput from './Picker';

export default name => {
  switch (name) {
    case 'DateTimeInput':
      return DateTimeInput;
    case 'PickerInput':
      return PickerInput;
    case 'TextInput':
      return TextInput;
    default:
      console.warn(`Could not find Component for "${name}".`);
      return null;
  }
};
