import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import { translate } from 'react-i18next';

import { DropTarget } from 'react-dnd';

import { updateDoc } from '../../../Couchbase';
import { generateId } from '../../../utils';

import { ItemTypes } from '../../../constants';

import {
  getAppSettings,
  getCurrentUser,
  getCurrentEvent
} from '../../../selectors';

import { locationDone, locationInit } from '../actions';

class ChatInput extends Component {
  state = {
    chatInput: '',
    withLocation: false
  };

  cancleLocation = () => {
    this.notificationSystem.addNotification({
      message: this.props.t('Ort hinzufügen wurde abgebrochen.'),
      level: 'success'
    });
    this.setState({ withLocation: false });
    this.props.locationDone();
  };

  addLocation = () => {
    this.notificationSystem.addNotification({
      message: this.props.t(
        'Bitte auf die Karte klicken um den Ort zu setzen.'
      ),
      level: 'info'
    });
    this.setState({ withLocation: true });
    this.props.locationInit();
  };

  textChangeHandler = event => {
    this.setState({ chatInput: event.target.value });
  };

  submitHandler = event => {
    // Stop the form from refreshing the page on submit
    event.preventDefault();

    const d = new Date();
    const {
      currentUser: { _id, name, color },
      appSettings: { appId },
      addLocation,
      selectedEvent,
      group
    } = this.props;
    const eventId = selectedEvent._id;

    // Call the onSend callback with the chatInput message
    const chatMsg = {
      appId,
      baseId: '0',
      baseName: 'Zentrale',
      className: 'ChatMessage',
      data: {
        _id: generateId(), // UUID needed by the app
        createdAt: d.toISOString(),
        text: this.state.chatInput,
        group,
        to: group, // TODO add @ to address user directly
        user: {
          _id,
          name,
          color
        }
      },
      eventId,
      eventName: selectedEvent.name,
      ts: Math.floor(d.getTime() / 1000),
      uploaded: false,
      userId: _id,
      userName: name,
      _id: `${appId}:ChatMessage:${eventId
        .split(':')
        .slice(-1)
        .pop()}:0:${Math.floor(d.getTime() / 1000).toString()}` // TODO where does this come from
    };
    if (this.state.withLocation) {
      if (addLocation.payload.location) {
        // location is set
        chatMsg.data.location = {
          latitude: addLocation.payload.location.latitude,
          longitude: addLocation.payload.location.longitude
        };
      }
    }
    // Clear the input box
    this.setState({ chatInput: '', withLocation: false });
    this.props.locationDone();
    updateDoc(chatMsg, status => {
      if (!status) {
        console.error('An error occured during the message sending.');
        this.notificationSystem.addNotification({
          title: this.props.t('Nachricht konnte nicht versendet werden.'),
          message: this.props.t(
            'Sie scheinen offline zu sein? Bitte prüfen Sie ihre Internetverbindung.'
          ),
          level: 'error'
        });
      }
    });
  };

  render() {
    const { withLocation, chatInput } = this.state;
    const { t, connectDropTarget, isOver, canDrop } = this.props;
    return connectDropTarget(
      <div>
        <NotificationSystem
          ref={c => {
            this.notificationSystem = c;
          }}
        />
        <form className="chat-input">
          <div className="input-group">
            <div className="input-group-addon">
              {withLocation ? (
                <span
                  className="input-group-text"
                  onClick={this.cancleLocation}
                  title={t('Ort hinzufügen abbrechen.')}
                  style={{ cursor: 'pointer', color: 'red' }}
                >
                  <i className="glyphicon glyphicon-map-marker fa-1x" />
                </span>
              ) : (
                <span
                  className="input-group-text"
                  onClick={this.addLocation}
                  title={t('Ort zur Nachricht hinzufügen.')}
                  style={{ cursor: 'pointer' }}
                >
                  <i className="glyphicon glyphicon-map-marker fa-1x" />
                </span>
              )}
            </div>

            <textarea
              // type="text"
              onChange={this.textChangeHandler}
              value={chatInput}
              placeholder={`${t('Nachricht eingeben')} ...`}
              required
              className={`form-control ${isOver ? 'dropTarget-is-over' : ''} ${
                canDrop ? 'dropTarget' : ''
              }`}
              style={{ height: 80 }}
              id="message"
            />

            <div className="input-group-addon">
              <span
                className="input-group-text"
                onClick={this.submitHandler}
                style={{ cursor: 'pointer' }}
              >
                <i className="glyphicon glyphicon-send fa-1x" />
              </span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const itemTarget = {
  drop({ group, onChange }, monitor, comp) {
    const item = monitor.getItem();

    // handle the dropped item
    console.log('TODO handle drop', item);

    comp.setState({ chatInput: `${item.title}\n\n${item.details}` });
    // change group
    // onChange(group);

    // post item in group;
    // TODO
  },
  canDrop(props, monitor) {
    return true;
  }
};

const collectTrg = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
});

const DropChatInput = DropTarget(
  ItemTypes.ITEM,
  itemTarget,
  collectTrg
)(ChatInput);

function mapStateToProps(state) {
  const { addLocation } = state.dashboard; // TODO

  return {
    addLocation,
    appSettings: getAppSettings(state),
    currentUser: getCurrentUser(state),
    selectedEvent: getCurrentEvent(state)
  };
}

export default connect(mapStateToProps, { locationDone, locationInit })(
  translate('translations')(DropChatInput)
);
