import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import MobileDetect from 'mobile-detect';
import axios from 'axios';
import { translate } from 'react-i18next';
import i18n from 'i18next';
import Loading from 'react-loading-animation';
import Helmet from 'react-helmet';

import { renderField } from '../../components';
import auth from '../../auth';
import { DB_URL } from '../../config';

import { DEFAULT_HOST_SETTINGS } from '../../constants';

const lower = value => value && value.toLowerCase();

class Login extends Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);
    this.state = {
      isPhone: md.phone() !== null,
      settings: DEFAULT_HOST_SETTINGS
    };
  }

  componentDidMount() {
    const host = window.location.hostname;
    this._mounted = true;

    // TODO refactor into state?
    axios
      .get(`${DB_URL}HostSettings:${host}`)
      .then(res => res.data)
      .then(settings => {
        if (this._mounted) {
          this.setState({
            settings: { ...DEFAULT_HOST_SETTINGS, ...settings }
          });
        }
      })
      .catch(() => {
        if (this._mounted) {
          this.setState({ settings: DEFAULT_HOST_SETTINGS });
        }
      });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleSubmit = ({ email, password, token }) => {
    const {
      login,
      auth: { requiresTwoFactor }
    } = this.props;
    if (requiresTwoFactor) {
      login(email, password, token);
    } else {
      login(email, password);
    }
  };

  render() {
    const {
      auth: { isLoggedIn, isLoggingIn, requiresTwoFactor, error },
      handleSubmit,
      t
    } = this.props;

    if (isLoggedIn) {
      return <Redirect to="/dashboard" />;
    }

    const {
      isPhone,
      settings: {
        appName,
        customerName,
        companyIconUrl,
        antaviIconUrl,
        appIconUrl,
        background,
        appLandingPage,
        styles,
        htmlTitle
      }
    } = this.state;

    const backgroundColor =
      styles && styles.footerColor ? styles.footerColor : '#34495E';
    return (
      <React.Fragment>
        <Helmet>
          <title>{htmlTitle}</title>
        </Helmet>
        <div
          className="login-wrapper"
          style={{
            background: `url('${background}') no-repeat center center fixed`
          }}
        >
          <div className={isPhone ? 'login-div-top-mobile' : 'login-div-top'}>
            <div
              style={{
                marginLeft: '14px',
                marginRight: '14px',
                marginTop: '28px'
              }}
            >
              <form onSubmit={handleSubmit(this.handleSubmit)}>
                <h3>{t('Anmelden')}</h3>
                <Field
                  key="email"
                  id="email"
                  name="email"
                  type="email"
                  component={renderField}
                  label={t('Email')}
                  normalize={lower}
                />
                <Field
                  key="password"
                  id="password"
                  name="password"
                  type="password"
                  component={renderField}
                  label={t('Password')}
                />
                {requiresTwoFactor && (
                  <Field
                    key="token"
                    id="token"
                    name="token"
                    type="number"
                    component={renderField}
                    label={t('Zugangscode')}
                  />
                )}

                {isLoggingIn && <Loading />}
                {typeof error === 'string' && (
                  <p style={{ color: '#FF0000' }}>{error}</p>
                )}
                <button
                  type="submit"
                  className="btn btn-default pull-right"
                  style={{ marginTop: '5%' }}
                  disabled={isLoggingIn}
                >
                  {t('Anmelden')}
                </button>
              </form>
            </div>
          </div>
          {!isPhone && (
            <div className="login-div-bottom">
              <div className="pull-right text-center">
                <img src={appIconUrl} alt="App icon" className="login-logo" />
                <br />
                <span style={{ color: 'white' }}>{appName}</span>
              </div>
            </div>
          )}
          <footer className="footer" style={{ backgroundColor }}>
            <div>
              <a href="http://www.antavi.ch/">
                <img src={antaviIconUrl} height="40px" alt="antavi icon" />
              </a>
              <div className="pull-right">
                <a href={appLandingPage || 'http://ops.antavi.ch'}>
                  <span style={{ color: 'white', marginRight: '20px' }}>
                    {customerName}
                  </span>
                </a>
                {companyIconUrl && (
                  <img src={companyIconUrl} height="40px" alt="company icon" />
                )}
              </div>
            </div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

const validate = ({ email, password }) => {
  const errors = {};
  if (!email) {
    errors.email = i18n.t('Enter an email');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = i18n.t('Invalid email address');
  }
  if (!password) {
    errors.password = i18n.t('Enter a password');
  }

  return errors;
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

const withForm = reduxForm({
  form: 'loginForm',
  validate
})(translate('translations')(Login));

export default connect(mapStateToProps, { login: auth.login })(withForm);
