import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import axios from 'axios';
import * as Fuse from 'fuse.js';

import { Panel, ListHeader, List, QuestionDialog } from '../../components';
import {
  questionDialogStyles,
  importUserStyles,
  editUserStyles
} from '../../ModalStyles';
import {
  NEW,
  EDIT,
  REMOVE,
  IMPORT,
  REMOVE_SELECTED,
  EDIT_SELECTED
} from '../../constants';
import { USER_URL } from '../../config';

import {
  getFilteredUsers,
  getAppSettings,
  getCurrentUser
} from '../../selectors';

import EditUserDialog from './EditUserDialog';
import ImportUserDialog from './ImportUserDialog';

const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['doc.company', 'doc.email', 'doc.name']
};

class Users extends Component {
  state = {
    deleteAlertOpen: false,
    searchActive: false,
    functionType: undefined,
    userDoc: undefined
  };

  setUpFunctionData = () => {
    const {
      appSettings: { assets },
      t
    } = this.props;

    const { searchActive } = this.state;
    return [
      {
        action: () => {
          this.setState(state => ({ searchActive: !state.searchActive }));
        },
        icon: 'search',
        active: this.state.searchActive,
        tooltip: t('Benutzer durchsuchen')
      },
      {
        action: () => {
          this.openModal(undefined, IMPORT);
        },
        icon: 'file-upload',
        tooltip: t('Benuter aus CSV-Datei importieren')
      },

      {
        action: () => {
          this.openModal(undefined, NEW);
        },
        icon: 'user-plus',
        tooltip: t('Neuen Benutzer anlegen')
      },
      {
        action: () => {
          // TODO fix multi edit dialog, currently needs to set all user properties

          if (this.list) {
            this.list.selectAll();
          }
          this.openModal(undefined, REMOVE_SELECTED);
        },
        icon: 'folder-minus',
        tooltip: t('Alle normalen Benutzer löschen (ohne Admins)')
      }

      // TODO fix multi edit dialog, currently needs to set all user properties

      // {
      //   action: () => { this.openModal(undefined, EDIT_SELECTED); },
      //   url: this.props.appSettings.assets.editSelectedIconUrl,
      // },

      // {
      //   action: () => {
      //     if(this.list) {
      //       this.list.selectAll();
      //     }
      //   },
      //   url: this.props.appSettings.assets.multiSelectIconUrl,
      //   tooltip: this.props.t("Alle Benutzer selektieren")
      // }
    ];
  };

  setUpData = () => {
    const rowData = [];
    const { users, appSettings, t } = this.props;
    users.forEach(e => {
      rowData.push({
        title: e.name,
        _id: e._id,
        details:
          e.company +
          (e.telephone ? ` (${e.telephone}) ` : '') +
          (e.admin ? ` (${t('Admin')})` : ''),
        leftIcon: {
          icon: 'user'
        },
        rightIcons: [
          {
            icon: 'trash',
            action: () => {
              this.openModal(e, REMOVE);
            }
          },
          {
            icon: 'pen',
            action: () => {
              this.openModal(e, EDIT);
            }
          }
        ],
        doc: e
      });
    });
    return rowData;
  };

  openModal = (userDoc, functionType) => {
    this.setState({
      modalIsOpen: true,
      searchActive: false,
      functionType,
      userDoc
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  closeModalSucces = userDoc => {
    this.setState({ modalIsOpen: false });
    const { t } = this.props;

    switch (this.state.functionType) {
      case REMOVE:
        if (this.state.userDoc) {
          axios({
            method: 'delete',
            url: `${USER_URL}${this.state.userDoc.email}`,
            data: {},
            withCredentials: true
          })
            .then(response => {
              this.setState({ userDoc: undefined });
              this.notificationSystem.addNotification({
                message: t('User erfolgreich gelöscht'),
                level: 'success'
              });
            })
            .catch(error => {
              console.error(error.response);
              this.notificationSystem.addNotification({
                message: t('User löschen fehlgeschlagen.'),
                level: 'error'
              });
            });
        }
        break;
      case REMOVE_SELECTED:
        const promises = [];

        if (this.list && this.list.state.selection.length > 0) {
          // get only the user in the selection
          const selectedUsers = this.props.users.filter(
            f =>
              this.list.state.selection.find(s => s._id === f._id) !== undefined
          );
          selectedUsers.forEach(e => {
            if (!e.admin) {
              promises.push(
                axios({
                  method: 'delete',
                  url: `${USER_URL}${e.email}`,
                  data: {},
                  withCredentials: true
                })
              );
            }
          });

          axios
            .all(promises)
            .then(response => {
              this.notificationSystem.addNotification({
                message: t('Alle user erfolgreich gelöscht.'),
                level: 'success'
              });
            })
            .catch((error, response) => {
              this.notificationSystem.addNotification({
                message:
                  response && response.message
                    ? response.message
                    : t('Das Löschen ist fehlgeschlagen.'),
                level: 'error'
              });
              console.error(error, response);
            });
          // reset all the selected values
          this.list.resetSelection();
        }
        break;
      case NEW:
        if (userDoc) {
          axios
            .post(USER_URL, userDoc, { withCredentials: true })
            .then(response => {
              this.notificationSystem.addNotification({
                message: t('User erfolgreich angelegt'),
                level: 'success'
              });
            })
            .catch(error => {
              // TODO move to server

              let message = t('User anlegen fehlgeschlagen.');

              if (error.response) {
                console.error(error.response);

                if (error.response.status === 409) {
                  message = t('User existiert bereits.');
                } else if (error.response.status === 550) {
                  message = t('Einladungsemail konnte nicht versendet werden.');
                }
              }

              this.notificationSystem.addNotification({
                message,
                level: 'error'
              });
            });
        }
        break;
      case EDIT:
        if (userDoc && this.state.userDoc) {
          axios
            .put(`${USER_URL}/${this.state.userDoc.email}`, userDoc, {
              withCredentials: true
            })
            .then(response => {
              this.setState({ userDoc });
              this.notificationSystem.addNotification({
                message: t('User erfolgreich editiert.'),
                level: 'success'
              });
            })
            .catch(error => {
              this.notificationSystem.addNotification({
                message: t('User editieren fehlgeschlagen.'),
                level: 'error'
              });
              console.error(error);
            });
        }

        break;
      case EDIT_SELECTED:
        const selectedUsers = this.props.users.filter(
          f =>
            this.list.state.selection.find(s => s._id === f._id) !== undefined
        );
        const promisesEdit = [];

        // do a update request for all selected users
        selectedUsers.forEach(user => {
          const data = { ...user, ...userDoc };
          // force mail update
          data.email = user.email;
          promisesEdit.push(
            axios.put(`${USER_URL}${data.email}`, data, {
              withCredentials: true
            })
          );
        });

        axios
          .all(promisesEdit)
          .then(() => {
            this.notificationSystem.addNotification({
              message: t('Alle user erfolgreich editiert.'),
              level: 'success'
            });
          })
          .catch((error, response) => {
            this.notificationSystem.addNotification({
              message:
                response && response.message
                  ? response.message
                  : t('User editieren fehlgeschlagen.'),
              level: 'error'
            });
            console.error(error, response);
          });
        // reset all the selected values
        this.list.resetSelection();
        break;
      case IMPORT:
        // userDoc is an array in this case
        if (userDoc && userDoc instanceof Array) {
          const promises = [];
          // make a request for each user doc
          userDoc.forEach((user, index) => {
            promises.push(
              axios.post(USER_URL, user, { withCredentials: true })
            );
          });

          axios
            .all(promises)
            .then(response => {
              this.notificationSystem.addNotification({
                message: t('User erfolgreich importiert.'),
                level: 'success'
              });
            })
            .catch(error => {
              let message = t('Importieren fehlgeschlagen.');

              // TODO move to server
              if (error.response) {
                console.error(error.response);

                if (error.response.status === 409) {
                  message = t('User existiert bereits.');
                } else if (error.response.status === 550) {
                  message = t('E-mail Addresse existiert nicht.');
                }
              }

              this.notificationSystem.addNotification({
                message,
                level: 'error'
              });
            });
        }
        break;
      default: {
        // create and edit is the same code
        console.error('No type specified');
      }
    }
  };

  renderDialog = ({ initialValues }) => {
    const {
      t,
      currentUser,
      appSettings: { appId }
    } = this.props;

    const { functionType, userDoc } = this.state;

    switch (functionType) {
      case REMOVE:
        return (
          <QuestionDialog
            caption={t('Löschen')}
            content={t('Sind sie sicher, dass sie den Eintrag löschen wollen?')}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      case REMOVE_SELECTED:
        return (
          <QuestionDialog
            caption={t('User löschen')}
            content={t(
              'Sind sie sicher, dass sie alle Benutzer (ohne Admins) löschen wollen?'
            )}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      case NEW:
        return (
          <EditUserDialog
            caption={
              functionType === NEW
                ? t('Neuer Benutzer')
                : t('Benutzer editieren')
            }
            userDoc={userDoc}
            errorHandler={this.closeModal}
            initialValues={{ appId, initialValues }}
            succesHandler={this.closeModalSucces}
          />
        );
      case EDIT:
        return (
          <EditUserDialog
            caption={
              functionType === NEW
                ? t('Neuer Benutzer')
                : t('Benutzer editieren')
            }
            userDoc={userDoc}
            errorHandler={this.closeModal}
            initialValues={initialValues}
            succesHandler={this.closeModalSucces}
          />
        );
      case EDIT_SELECTED:
        return (
          <EditUserDialog
            caption={t('Benutzer editieren')}
            userDoc={{}}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      case IMPORT:
        return (
          <ImportUserDialog
            caption={t('Benutzer importieren')}
            defaultCompany={currentUser.company}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      default:
        return null;
    }
  };

  search = (searchTerm, dataList) => {
    if (searchTerm.length < 2) {
      return dataList;
    }
    const fuse = new Fuse(dataList, SEARCH_OPTIONS);
    return fuse.search(searchTerm);
  };

  render() {
    let initialValues = {};
    const {
      userDoc,
      modalIsOpen,
      functionType,
      searchActive,
      searchValue
    } = this.state;
    const { t, users } = this.props;
    if (userDoc) {
      const {
        name,
        company,
        email,
        phone,
        role,
        admin,
        color,
        country,
        apps,
        events,
        bases,
        tags,
        appId,
        devices
      } = userDoc;
      initialValues = {
        initialValues: {
          name,
          company,
          email,
          phone,
          role,
          admin,
          color,
          country,
          apps,
          events,
          bases,
          tags,
          appId,
          devices
        }
      };
    }

    const rowData = this.setUpData();
    const currentRowData =
      searchActive && searchValue ? this.search(searchValue, rowData) : rowData;
    return (
      <Panel title={t('Benutzer')} id="users">
        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />

        <ListHeader
          functionButtons={this.setUpFunctionData()}
          count={users.length}
          searchActive={searchActive}
          onSearch={value => this.setState({ searchValue: value })}
        />
        <div
          style={{
            height: searchActive ? 'calc(100% - 120px)' : 'calc(100% - 80px)'
          }}
        >
          <List rowData={currentRowData} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          style={
            functionType === IMPORT
              ? importUserStyles
              : functionType === NEW ||
                functionType === EDIT ||
                functionType === EDIT_SELECTED
              ? editUserStyles
              : questionDialogStyles
          }
          shouldCloseOnOverlayClick={false}
          onRequestClose={event => {
            // Ignore react-modal esc-close handling
            if (event.type === 'keydown' && event.keyCode === 27) {
              return;
            }

            this.closeModal();
          }}
          contentLabel="deleteAlert"
        >
          {this.renderDialog(initialValues)}
        </Modal>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: getFilteredUsers(state),
    appSettings: getAppSettings(state),
    currentUser: getCurrentUser(state)
  };
}

export default connect(mapStateToProps)(Users);
