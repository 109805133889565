import _ from 'lodash';

import { Observable } from 'rxjs/Observable';
import { combineEpics } from 'redux-observable';

import * as t from './actionTypes';

import { LOGIN_SUCCESS, LOGOUT } from '../auth/actionTypes';

import dbActions from './actions';

import { getClient } from './SyncGatewayClient';
import {
  isAppConfigured,
  getCurrentAppId,
  getUserSettingsCurrentUser
} from '../selectors';
import { SET_LAYOUTS } from '../routes/organisation/ActionTypes';

const getUserChannels = (email, channels, appId) => {
  // check for special case super user
  if (Object.keys(channels).includes('*')) {
    return `admin-${appId},user-${email}`;
  }

  const filteredChannels = Object.keys(channels)
    .filter(c => c.includes(appId))
    .join(',');

  return `${filteredChannels},user-${email}`;
};

const createSyncObservable = (appId, auth) => {
  // init db
  const email = auth.session.userCtx.name;
  const { channels } = auth.session.userCtx;

  // const loadRemoteDocs = getClient()
  //   .allDocs({
  //     include_docs: true,
  //     update_seq: true,
  //     startkey: `${appId}:`,
  //     endkey: `${appId}:\ufff0`
  //   })
  //   .map(res => ({
  //     type: t.BATCH_PUT_DOC_SUCCESS,
  //     docs: res.rows.map(r => r.doc),
  //     seq: res.update_seq
  //   }));

  // const loadRemoteDocs = getClient()
  //   .getChangesViaPost({
  //     include_docs: true,
  //     active_only: true,
  //     timeout: 60000,
  //     filter: 'sync_gateway/bychannel',
  //     channels: getUserChannels(email, channels, appId)
  //   })
  //   .map((res) => {
  //     // NOTE: remove dupplicates, take most current rev
  //     const docs = _(res.results)
  //       .map('doc')
  //       .sortBy('_rev')
  //       .reverse()
  //       .sortedUniqBy('_id')
  //       .value();

  //     return {
  //       type: t.BATCH_PUT_DOC_SUCCESS,
  //       docs,
  //       seq: res.last_seq
  //     };
  //   });

  // let seq = 0;
  // const loadRemoteDocsInBatches = getClient()
  //   .getChanges({
  //     include_docs: true,
  //     active_only: true,
  //     limit: 1000,
  //     since: seq,
  //     timeout: 60000,
  //     filter: 'sync_gateway/bychannel',
  //     channels: getUserChannels(email, channels, appId)
  //   })
  //   .map((res) => {
  //     seq = res.last_seq;
  //     // console.log(seq, res);
  //     return {
  //       type: t.BATCH_PUT_DOC_SUCCESS,
  //       docs: res.results.map(r => r.doc),
  //       seq: res.last_seq
  //     };
  //   })
  //   .repeat()
  //   .takeWhile(action => action.docs.length > 0);

  let cnt = 0;
  let total = 1;

  const options = {
    include_docs: true,
    update_seq: true,
    startkey: `${appId}:`,
    limit: 400,
    endkey: `${appId}:\ufff0`
  };
  const loadRemoteDocsInBatches = Observable.defer(() =>
    getClient().allDocs(options)
  )
    .map(res => {
      // console.log(res);
      options.startkey = res.rows[res.rows.length - 1].id;
      cnt += res.rows.length;
      // console.log(cnt, cnt / total);
      return {
        type: t.BATCH_PUT_DOC_SUCCESS,
        docs: res.rows.map(r => r.doc),
        seq: res.update_seq,
        progress: Math.max(Math.round((cnt / total) * 100), 1)
      };
    })
    .repeat()
    .takeWhile(({ docs }) => docs.length !== 1);

  // const options = {
  //   include_docs: true,
  //   update_seq: true,
  //   startkey: appId,
  //   limit: 100,
  //   endkey: `${appId}\ufff0`
  // };

  const getTotal = getClient()
    .allDocs({
      startkey: `${appId}:`,
      endkey: `${appId}:\ufff0`
    })
    .do(res => {
      total = res.total_rows;
      // console.log('Total docs', total);
    });

  // const loadRemoteDocsInBatches = Observable.defer(() => {
  //   console.log(options);

  //   return getClient()
  //     .allDocs(options);
  // })
  //   .map((res) => {
  //     options.startkey = res.rows[res.rows.length - 1].id;

  //     cnt += res.rows.length;
  //     console.log(cnt, total);
  //     return {
  //       type: t.BATCH_PUT_DOC_SUCCESS,
  //       docs: res.rows.map(r => r.doc),
  //       seq: res.update_seq,
  //       progress: cnt / total
  //     };
  //   })
  //   .finally(() => console.log('finally'))
  //   .repeatWhen(notifier => notifier.flatMap((notification) => {
  //     console.log('repeatWhen', cnt, total);
  //     if (cnt < total) {
  //       return Observable.of(notification);
  //     }

  //     return Observable.empty();
  //   }))

  //   .finally(() => console.log('finally'));

  // .repeatWhen(src => src.flatMap(() => {
  //   console.log(cnt, total, cnt < total);
  //   return cnt < total ? Observable.of(null) : Observable.empty();
  // }))

  // .repeatWhen(() => Observable.of(null))

  // let c = 0;
  // Observable.from([1, 2, 3])
  // .do((i) => { c += i; })
  // .repeatWhen(notifier => notifier.flatMap((notification) => {
  //   if (c < 2) {
  //     return Observable.of(notification);
  //   }
  //   return Observable.empty();
  // }))
  // .do(console.log)
  // .finally(() => console.log('finally'))
  // .subscribe();

  const loadRemoteDocs = getTotal.flatMap(() => loadRemoteDocsInBatches);

  const loadPersonalDocs = getClient()
    .getChanges({
      include_docs: true,
      active_only: true,
      filter: 'sync_gateway/bychannel',
      channels: `user-${email}`
    })
    .map(res => ({
      type: t.BATCH_PUT_DOC_SUCCESS,
      docs: res.results.map(r => r.doc)
    }));

  return Observable.concat(
    Observable.of({ type: t.LOAD_DB_PENDING }),
    loadRemoteDocs,
    loadPersonalDocs,
    Observable.of({ type: t.LOAD_DB_SUCCESS })
  );
};

const createContinuousSyncObservable = (seq, appId, email, channels) =>
  getClient()
    .getChanges({
      include_docs: true,
      since: seq,
      timeout: 60000,
      heartbeat: 10000,
      feed: 'longpoll',
      filter: 'sync_gateway/bychannel',
      channels: getUserChannels(email, channels, appId)
    })

    // handle no connection errors
    .retryWhen(notifier => notifier.flatMap(() => getClient().online()))

    // listen to changes again when longpoll returns with first results
    .repeat()

    // update status
    .flatMap(res => {
      const actions = res.results.map(change => {
        // console.log(change);
        if (change.deleted || change.removed) {
          // TODO check how to handle access revoked docs
          return {
            type: t.REMOVE_DOC_SUCCESS,
            doc: change.doc || { _id: change.id },
            seq: change.seq
          };
        }
        return { type: t.PUT_DOC_SUCCESS, doc: change.doc, seq: change.seq };
      });

      return Observable.from(actions);
    });

// const inAppEvents = action$ => action$.ofType(APP_STATE_CHANGE).filter(action => action.appState === 'active');

// const outAppEvents = action$ => action$.ofType(APP_STATE_CHANGE).filter(action => action.appState === 'background');

const dbLoadedEvents = action$ => action$.ofType(t.LOAD_DB_SUCCESS);

const logoutEvents = action$ => action$.ofType(LOGOUT);

const inAndOut = (action$, state$) =>
  Observable.merge(
    Observable.combineLatest(
      dbLoadedEvents(action$),
      Observable.merge(
        // inAppEvents(action$),
        // action$.ofType(SET_USER_PROFILE),
        action$.ofType(t.SET_APP)
        // action$.ofType(SET_EVENT),
        // action$.ofType(SET_BASE)
      )
    )
      .filter(() => isAppConfigured(state$.value))
      .map(() => ({
        inApp: true
      })),
    logoutEvents(action$).map(() => ({
      inApp: false
    }))
  )
    .distinctUntilKeyChanged('inApp')
    .share();

const continuousSyncEpic = (action$, state$) =>
  inAndOut(action$, state$)
    .filter(({ inApp }) => inApp)
    .flatMap(() => {
      console.log('START CONTINOUS SYNC');
      return createContinuousSyncObservable(
        state$.value.docs.seq,
        getCurrentAppId(state$.value),
        state$.value.auth.session.userCtx.name,
        state$.value.auth.session.userCtx.channels
      )
        .takeUntil(inAndOut(action$, state$).filter(({ inApp }) => !inApp))
        .finally(() => {
          console.log('STOP CONTINOUS SYNC');
        });
    });

const loadAppSettings = () =>
  getClient()
    .allDocs({
      include_docs: true,
      startkey: 'AppSettings',
      endkey: 'AppSettings\ufff0'
    })
    .map(res => ({
      type: t.BATCH_PUT_DOC_SUCCESS,
      docs: res.rows.map(r => r.doc)
    }));

const autoSelectAppSettingsEpic = (action$, state$) =>
  action$.ofType(t.APP_SETTINGS_LOADED).flatMap(() => {
    const { docs } = state$.value;
    const appSettings = docs.AppSettings;

    return Array.isArray(appSettings) && appSettings.length === 1
      ? Observable.of(dbActions.setAppSettings(appSettings[0]))
      : Observable.empty();
  });

const loadAppSettingsEpic = action$ =>
  action$
    .ofType(LOGIN_SUCCESS)
    .flatMap(() =>
      Observable.concat(
        loadAppSettings(),
        Observable.of({ type: t.APP_SETTINGS_LOADED })
      )
    );

// on LOGIN_SUCCESS start sync until LOGOUT_SUCCESS
const syncEpic = (action$, state$) =>
  action$
    .ofType(t.SET_APP)
    .flatMap(action =>
      createSyncObservable(
        action.appDoc.appId,
        state$.value.auth,
        state$.value.docs.pending
      )
    );

const resetEpic = action$ => logoutEvents(action$).map(dbActions.resetDB);

const createPutObservable = (doc, onlineObservable) =>
  getClient()
    .putDoc(doc)
    .retryWhen(notifier =>
      notifier.flatMap(error => {
        if (error.status === 0) {
          console.log('offline, retry on next change');
          // network error, retry on reconnect
          // triggered via next live change listener // TODO use a "back online Observable"
          return onlineObservable;
        }

        console.log('putDoc', error);
        return Observable.throwError(error);
      })
    )
    .map(() => ({ type: t.PUT_DOC_PENDING_SUCCESS, doc }))
    .catch(error =>
      Observable.of({ type: t.PUT_DOC_PENDING_ERROR, error, doc })
    );

// on PUT_DOC save doc in db
const putEpic = action$ =>
  action$
    .ofType(t.PUT_DOC)
    .flatMap(action =>
      Observable.merge(
        Observable.of({ type: t.PUT_DOC_PENDING, doc: action.doc }),
        createPutObservable(action.doc, getClient().online())
      )
    );

// on REMOVE_DOC save doc in db
const removeEpic = action$ =>
  action$
    .ofType(t.REMOVE_DOC)
    .map(() => ({ type: 'remove epic not yet implemented' }));

// const createLiveObservable = (state$, updateInterval) => Observable.timer(0, updateInterval)
//   .map(i => ({ type: 'timer', i }))
//   .flatMap(() => {
//     const appId = getCurrentAppId(state$.value);
//     const eventId = getCurrentEventId(state$.value);
//     const { seq } = state$.value.docs;

//     return getClient()
//       .getChanges({
//         include_docs: true,
//         active_only: true,
//         filter: 'sync_gateway/bychannel',
//         channels: `${eventId}-live,${appId}-all-events-live`,
//         since: seq
//       })
//       .map(res => ({
//         type: t.BATCH_PUT_DOC_SUCCESS,
//         docs: res.results.map(r => r.doc),
//         seq: res.last_seq
//       }))
//       .catch(error => Observable.of({ type: t.BATCH_PUT_DOC_ERROR, error }));
//   });

// updates when in app
// const liveDeviceEpic = (action$, state$) => inAndOut(action$, state$)
//   .filter(({ inApp }) => inApp)
//   .flatMap(() => {
//     console.log('START POLLING');
//     return createLiveObservable(state$, 10 * 1000)
//       .takeUntil(inAndOut(action$, state$).filter(({ inApp }) => !inApp))
//       .finally(() => console.log('STOP POLLING'));
//   });

const layoutsEpic = (action$, state$) =>
  action$
    .ofType(SET_LAYOUTS)
    .debounceTime(500)
    .flatMap(() => {
      const userSettings = getUserSettingsCurrentUser(state$.value);
      const { layouts } = state$.value.organisation;

      if (_.isEqual(userSettings.layouts, layouts)) {
        return Observable.of({ type: 'USER_PROFILE_NO_NEED_TO_UPDATE_LAYOUT' });
      }

      const doc = _.omit({ ...userSettings, layouts }, '_rev');

      return getClient()
        .upsertDoc(doc)
        .map(() => ({ type: 'USER_PROFILE_UPDATED_LAYOUT' }))
        .catch(error =>
          Observable.of({ type: 'USER_PROFILE_UPDATE_ERROR', error })
        );
    });

const getAllDocs = action$ =>
  Observable.merge(
    action$.ofType(t.PUT_DOC_SUCCESS).map(action => action.doc),
    action$
      .ofType(t.BATCH_PUT_DOC_SUCCESS)
      .flatMap(action => Observable.from(action.docs))
  );

const setLayoutEpic = action$ =>
  getAllDocs(action$)
    .filter(doc => doc && doc.className === 'UserSettings' && doc.layouts)
    .map(({ layouts }) => ({ type: SET_LAYOUTS, layouts }));

export default combineEpics(
  putEpic,
  removeEpic,
  loadAppSettingsEpic,
  syncEpic,
  continuousSyncEpic,
  resetEpic,
  autoSelectAppSettingsEpic,
  layoutsEpic,
  setLayoutEpic
  // liveDeviceEpic
);
