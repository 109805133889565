export const ALL_EVENTS = 'ALL_EVENTS';
export const NEW_EVENT = 'NEW_EVENT';

// Data types
export const CLASSNAME_MESSAGE = 'ChatMessage';
export const CLASSNAME_INCIDENT = 'Incident';
export const CLASSNAME_PATIENT = 'PatientTreatment';
export const CLASSNAME_CROWDSPOTTER = 'CrowdSpotter';

// user
export const NEW = 'NEW';
export const EDIT = 'EDIT';
export const VIEW = 'VIEW';
export const REMOVE = 'REMOVE';
export const CANCEL = 'CANCEL';
export const UPDATE = 'UPDATE';
export const IMPORT = 'IMPORT';
export const REMOVE_ALL = 'REMOVE_ALL';
export const REMOVE_SELECTED = 'REMOVE_SELECTED';
export const EDIT_SELECTED = 'EDIT_SELECTED';
export const FILTER = 'FILTER';

// maps
// export const KEY = 'AIzaSyAS2DPKAKZSfLm4LfTGOQWTWj-e4f5U1eA';
export const KEY = 'AIzaSyCyyIfQWJnRJ9Q8eLaCIop5l-M-nCLxisU'; // antavi Ops
// export const KEY = 'AIzaSyAOQdngwz6R0Q0M9MnAc--hlqzx_ErdIvI'; // antavi webpage
export const TERRAIN = 'TERRAIN';
export const ROADMAP = 'ROADMAP';
export const SATELLITE = 'SATELLITE';
export const HYBRID = 'HYBRID';
export const DEFAULTLAT = 47.36;
export const DEFAULTLNG = 8.543011;
export const DEFAULTZOOM = 14;
export const TRAJECTORIES_VISIBLE_DURATION = 20 * 60 * 1000; // 20 min
export const DEFAULT_MAP_VIEWPORT = {
  center: [47.35, 8.55], // default zürich
  zoom: 12
};

// filter
export const FILTER_BY_FINISHED = 'FILTER_BY_FINISHED';
export const FILTER_BY_IMAGE = 'FILTER_BY_IMAGE';
export const FILTER_BY_MESSAGE = 'FILTER_BY_MESSAGE';
export const FILTER_CHAT = 'FILTER_CHAT';
export const FILTER_INCIDENT = 'FILTER_INCIDENT';
export const FILTER_PATIENT = 'FILTER_PATIENT';
export const FILTER_CROWD = 'FILTER_CROWD';

// Dragable types
export const ItemTypes = {
  CARD: 'card',
  MESSAGE: 'message',
  ITEM: 'item'
};

export const getInitialDoc = className => ({
  appId: '',
  _id: '',
  baseId: '',
  baseName: '',
  className,
  data: [],
  eventId: '',
  eventName: '',
  status: 'new',
  ts: 0,
  uploaded: false,
  userId: '',
  userName: ''
});

export const DEFAULT_PERMISSIONS = {
  dashboard: {
    features: {
      tactics: true,
      nextSteps: true,
      encryption: false
    },
    permissionGranted: true,
    requiredRoles: ['user', 'admin'],
    bases: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    devices: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    map: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    incidents: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    patients: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    materialOrders: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    chat: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    activities: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    }
  },
  events: {
    permissionGranted: true,
    requiredRoles: ['admin'],
    eventEditor: {
      permissionGranted: true,
      requiredRoles: ['admin']
    },
    events: {
      permissionGranted: true,
      requiredRoles: ['admin']
    },
    users: {
      permissionGranted: true,
      requiredRoles: ['admin']
    },
    qrCodes: {
      permissionGranted: true,
      requiredRoles: ['admin']
    }
  },
  briefings: {
    permissionGranted: true,
    requiredRoles: ['user', 'admin'],
    briefingEditor: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    briefings: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    }
  },
  analytics: {
    permissionGranted: true,
    requiredRoles: ['user', 'admin'],
    incidentStats: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    patientStats: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    incidentProcessingStats: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    },
    heatmap: {
      permissionGranted: true,
      requiredRoles: ['user', 'admin']
    }
  },
  settings: {
    permissionGranted: true,
    requiredRoles: ['admin'],
    email: {
      permissionGranted: true,
      requiredRoles: ['admin']
    },
    users: {
      permissionGranted: true,
      requiredRoles: ['admin']
    },
    qrCodes: {
      permissionGranted: true,
      requiredRoles: ['admin']
    },
    devices: {
      permissionGranted: true,
      requiredRoles: ['admin']
    }
  }
};

export const DEFAULT_ASSETS = {
  addIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/button_add.png',
  antaviIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/logo_antavi.png',
  appIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/logo_app.png',
  avatarIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/avatar.png',
  background:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/background.png',
  baseIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/base.png',
  briefingIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/briefing.png',
  calendarIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/calendar.png',
  cartIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/cart.png',
  companyIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/logo_customer.png',
  dashboardActiveIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_dashboard_active.png',
  dashboardBackground:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/background.png',
  dashboardIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_dashboard.png',
  deleteAllIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/delete.png',
  deleteIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/button_delete.png',
  deviceIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/device.png',
  downloadIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/button_download.png',
  editIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/button_edit.png',
  eventsActiveIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_events_active.png',
  eventsIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_events.png',
  importIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/import.png',
  indicatorBlueIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/indicator_blue.png',
  indicatorGreenIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/indicator_green.png',
  indicatorRedIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/indicator_red.png',
  locationIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/button_location.png',
  logoutActiveIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_logout_active.png',
  logoutIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_logout.png',
  menuBriefingActiveIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_briefing_active.png',
  menuBriefingIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_briefing.png',
  menuDatetimeIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_datetime.png',
  menuViewIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_views.png',
  notifySound:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/notify.mp3',
  patientIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/patient.png',
  photoIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/photo.png',
  pushLocate:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/locate.png',
  recenterIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/recenter.png',
  settingsActiveIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_settings_active.png',
  settingsIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_settings.png',
  trajTsNowIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/trajectory.png',
  userIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/user.png',
  incidentIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/incident.png',
  incidentCompletedIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/status/status_ok%402x.png',
  incidentWithMessagesIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/status/status_activity%402x.png',
  incidentNewIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/status/status_attention%402x.png',
  tagsIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menuicons/tag%402x.png',
  filterIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menuicons/filter%402x.png',
  dndPreviewIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menuicons/drag%402x.png',
  extendedIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menuicons/expand%402x.png',
  minimizeIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menuicons/collapse%402x.png',
  editSelectedIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/button_edit.png',
  multiSelectIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menuicons/multiselect%402x.png',
  searchIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/search.png',
  qrIconUrl: 'https://s3.eu-central-1.amazonaws.com/srzevents/assets/qr.png',
  printIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/print.png',
  menuAnalyticsIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_analytics.png',
  menuAnalyticsActiveIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/menu_briefing_active.png'
};

export const DEFAULT_MARKER_ICONS = [
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/operator%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/base.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/police%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/sanitaet%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/exit%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/exit-west%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/exit-south%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/exit-north%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/train%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/tram%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/bus%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/carpark%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/kindersammelstelle%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/sammelplatz%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/toilet%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/toilet_disabled%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/atm%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/warning%402x.png',
  'https://s3.eu-central-1.amazonaws.com/srzevents/assets/marker/info%402x.png'
];

export const DEFAULT_STYLES = {
  logoBackgroundColor: '#DBFF49',
  menuHighlightColor: '#253341',
  menuBackgroundColor: '#34495D',
  menuTextColor: '#FFFFFF',
  menuTextHighlightColor: '#DBFF49',
  iconColor: '#FFFFFF',
  iconHighlightColor: '#DBFF49',
  placeholderColor: '#b902d6'
};

export const DEFAULT_APP_SETTINGS = {
  appId: 'demoapp',
  appName: 'Debug Event',
  className: 'AppSettings',
  company: 'Debug INC',
  htmlTitle: 'OPS - Command & Control',
  styles: DEFAULT_STYLES,
  assets: DEFAULT_ASSETS,
  markerIcons: DEFAULT_MARKER_ICONS
};

export const DEFAULT_HOST_SETTINGS = {
  appName: 'OPS',
  customerName: 'OPS - Crowd Management',
  htmlTitle: 'OPS - Command & Control',
  companyIconUrl: '',
  antaviIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/logo_antavi.png',
  appIconUrl:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/logo_app.png',
  background:
    'https://s3.eu-central-1.amazonaws.com/srzevents/assets/background.png',
  appLandingPage: 'http://ops.antavi.ch',
  styles: {
    footerColor: '#34495D'
  }
};

// info cols per layout size
// lg: 13,
// md: 9,
// sm: 6,
// xs: 3
export const DEFAULT_DASHBOARD_LAYOUT = {
  lg: [
    {
      h: 2,
      i: 'bases',
      minH: 1,
      minW: 2,
      moved: false,
      static: false,
      w: 2,
      x: 0,
      y: 0
    },
    {
      h: 2,
      i: 'devices',
      minH: 1,
      minW: 2,
      moved: false,
      static: false,
      w: 2,
      x: 0,
      y: 2
    },
    {
      h: 2,
      i: 'map',
      minH: 2,
      minW: 4,
      moved: false,
      static: false,
      w: 5,
      x: 2,
      y: 0
    },
    {
      h: 2,
      i: 'incidents',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 7,
      y: 0
    },
    {
      h: 2,
      i: 'patients',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 10,
      y: 0
    },
    {
      h: 2,
      i: 'materialOrders',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 7,
      y: 2
    },
    {
      h: 2,
      i: 'chat',
      minH: 1,
      minW: 4,
      moved: false,
      static: false,
      w: 5,
      x: 2,
      y: 2
    },
    {
      h: 2,
      i: 'activities',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 10,
      y: 2
    }
  ],
  md: [
    {
      h: 2,
      i: 'bases',
      minH: 1,
      minW: 2,
      moved: false,
      static: false,
      w: 2,
      x: 0,
      y: 0
    },
    {
      h: 2,
      i: 'devices',
      minH: 1,
      minW: 2,
      moved: false,
      static: false,
      w: 2,
      x: 0,
      y: 2
    },
    {
      h: 2,
      i: 'map',
      minH: 2,
      minW: 4,
      moved: false,
      static: false,
      w: 4,
      x: 2,
      y: 0
    },
    {
      h: 2,
      i: 'incidents',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 6,
      y: 0
    },
    {
      h: 1,
      i: 'patients',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 6,
      y: 2
    },
    {
      h: 1,
      i: 'materialOrders',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 6,
      y: 3
    },
    {
      h: 2,
      i: 'chat',
      minH: 1,
      minW: 4,
      moved: false,
      static: false,
      w: 4,
      x: 2,
      y: 2
    },
    {
      h: 2,
      i: 'activities',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 6,
      y: 4
    }
  ],
  sm: [
    {
      h: 2,
      i: 'bases',
      moved: false,
      static: false,
      w: 3,
      x: 3,
      y: 2
    },
    {
      h: 2,
      i: 'devices',
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 8
    },
    {
      h: 2,
      i: 'map',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 6,
      x: 0,
      y: 0
    },
    {
      h: 2,
      i: 'incidents',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 4
    },
    {
      h: 2,
      i: 'patients',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 3,
      y: 4
    },
    {
      h: 2,
      i: 'materialOrders',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 6
    },
    {
      h: 2,
      i: 'chat',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 2
    },
    {
      h: 2,
      i: 'activities',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 3,
      y: 6
    }
  ],
  xs: [
    {
      h: 2,
      i: 'bases',
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 10
    },
    {
      h: 2,
      i: 'devices',
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 14
    },
    {
      h: 2,
      i: 'map',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 0
    },
    {
      h: 2,
      i: 'incidents',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 4
    },
    {
      h: 2,
      i: 'patients',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 6
    },
    {
      h: 2,
      i: 'materialOrders',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 8
    },
    {
      h: 2,
      i: 'chat',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 2
    },
    {
      h: 2,
      i: 'activities',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 12
    }
  ]
};

export const DEFAULT_ANALYTICS_LAYOUT = {
  lg: [
    {
      h: 4,
      i: 'heatmap',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 5,
      x: 0,
      y: 0
    },
    {
      h: 1,
      i: 'incidentProcessingStats',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 8,
      x: 5,
      y: 0
    },
    {
      h: 3,
      i: 'incidentStats',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 4,
      x: 5,
      y: 1
    },
    {
      h: 3,
      i: 'patientStats',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 4,
      x: 9,
      y: 1
    }
  ],
  md: [
    {
      h: 3,
      i: 'heatmap',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 5,
      x: 0,
      y: 1
    },
    {
      h: 1,
      i: 'incidentProcessingStats',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 5,
      x: 0,
      y: 0
    },
    {
      h: 2,
      i: 'incidentStats',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 4,
      x: 5,
      y: 2
    },
    {
      h: 2,
      i: 'patientStats',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 4,
      x: 5,
      y: 0
    }
  ],
  sm: [
    {
      h: 3,
      i: 'heatmap',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 6,
      x: 0,
      y: 1
    },
    {
      h: 1,
      i: 'incidentProcessingStats',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 6,
      x: 0,
      y: 0
    },
    {
      h: 2,
      i: 'incidentStats',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 6,
      x: 0,
      y: 4
    },
    {
      h: 2,
      i: 'patientStats',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 6,
      x: 0,
      y: 6
    }
  ],
  xs: [
    {
      h: 3,
      i: 'heatmap',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 1
    },
    {
      h: 1,
      i: 'incidentProcessingStats',
      minH: 1,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 0
    },
    {
      h: 2,
      i: 'incidentStats',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 4
    },
    {
      h: 2,
      i: 'patientStats',
      minH: 2,
      minW: 3,
      moved: false,
      static: false,
      w: 3,
      x: 0,
      y: 6
    }
  ]
};

// lg: 13,
// md: 9,
// sm: 6,
// xs: 3
export const MAP_ONLY_LAYOUT = {
  lg: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 13,
      static: true
    }
  ]
};

export const MAP_CHAT_LAYOUT = {
  lg: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 8,
      static: true
    },
    {
      i: 'chat',
      x: 8,
      y: 0,
      h: 4,
      w: 5,
      static: true
    }
  ],
  md: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 5,
      static: true
    },
    {
      i: 'chat',
      x: 8,
      y: 0,
      h: 4,
      w: 4,
      static: true
    }
  ],
  sm: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 3,
      static: true
    },
    {
      i: 'chat',
      x: 8,
      y: 0,
      h: 4,
      w: 3,
      static: true
    }
  ],
  xs: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 3,
      static: true
    },
    {
      i: 'chat',
      x: 0,
      y: 5,
      h: 4,
      w: 3,
      static: true
    }
  ]
};

export const MAP_INCIDENTS_LAYOUT = {
  lg: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 8,
      static: true
    },
    {
      i: 'incidents',
      x: 8,
      y: 0,
      h: 4,
      w: 5,
      static: true
    }
  ],
  md: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 5,
      static: true
    },
    {
      i: 'incidents',
      x: 8,
      y: 0,
      h: 4,
      w: 4,
      static: true
    }
  ],
  sm: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 3,
      static: true
    },
    {
      i: 'incidents',
      x: 8,
      y: 0,
      h: 4,
      w: 3,
      static: true
    }
  ],
  xs: [
    {
      i: 'map',
      x: 0,
      y: 0,
      h: 4,
      w: 3,
      static: true
    },
    {
      i: 'incidents',
      x: 0,
      y: 5,
      h: 4,
      w: 3,
      static: true
    }
  ]
};

export const DEFAULT_EVENTS_LAYOUT = {
  lg: [
    {
      i: 'events',
      x: 0,
      y: 0,
      h: 4,
      w: 8,
      static: true
    },
    {
      i: 'qrCodes',
      x: 8,
      y: 0,
      h: 2,
      w: 5,
      static: true
    },
    {
      i: 'users',
      x: 8,
      y: 2,
      h: 2,
      w: 5,
      static: true
    }
  ],
  md: [
    {
      i: 'events',
      x: 0,
      y: 0,
      h: 4,
      w: 6,
      static: true
    },
    {
      i: 'qrCodes',
      x: 9,
      y: 0,
      h: 2,
      w: 3,
      static: true
    },
    {
      i: 'users',
      x: 9,
      y: 0,
      h: 2,
      w: 3,
      static: true
    }
  ],
  sm: [
    {
      i: 'events',
      x: 0,
      y: 0,
      h: 2,
      w: 6,
      static: true
    },
    {
      i: 'qrCodes',
      x: 0,
      y: 2,
      h: 2,
      w: 3,
      static: true
    },
    {
      i: 'users',
      x: 3,
      y: 2,
      h: 2,
      w: 3,
      static: true
    }
  ]
};

export const NEW_EVENT_LAYOUT = {
  lg: [
    {
      i: 'events',
      x: 0,
      y: 0,
      h: 4,
      w: 13,
      static: true
    }
  ]
};

export const DEFAULT_BRIEFINGS_LAYOUT = {
  lg: [
    {
      i: 'briefings',
      x: 0,
      y: 0,
      h: 4,
      w: 13,
      static: true
    }
  ]
};

export const DEFAULT_SETTINGS_LAYOUT = {
  lg: [
    {
      i: 'email',
      x: 0,
      y: 0,
      h: 2,
      w: 3,
      static: true
    },
    {
      i: 'qrCodes',
      x: 3,
      y: 0,
      h: 4,
      w: 5,
      static: true
    },
    {
      i: 'users',
      x: 8,
      y: 0,
      h: 4,
      w: 5,
      static: true
    },
    {
      i: 'devices',
      x: 0,
      y: 2,
      h: 2,
      w: 3,
      static: true
    }
  ],
  md: [
    {
      i: 'email',
      x: 0,
      y: 0,
      h: 2,
      w: 3,
      static: true
    },
    {
      i: 'qrCodes',
      x: 3,
      y: 0,
      h: 4,
      w: 3,
      static: true
    },
    {
      i: 'users',
      x: 8,
      y: 0,
      h: 4,
      w: 3,
      static: true
    },
    {
      i: 'devices',
      x: 0,
      y: 2,
      h: 2,
      w: 3,
      static: true
    }
  ]
};
