export const FILTER_BY_EVENT = 'FILTER_BY_EVENT';
export const FILTER_BY_BASE = 'FILTER_BY_BASE';
export const FILTER_BY_START = 'FILTER_BY_START';
export const FILTER_BY_END = 'FILTER_BY_END';
export const FILTER_RESET = 'FILTER_RESET';
export const FILTER_SET_TRAJ_TS = 'FILTER_SET_TRAJ_TS';

export const TOGGLE_VIEW = 'toggle_view';
export const SWAP_VIEW = 'swap_view';
export const CHANGE_WIDTH = 'change_width';
export const CHANGE_HEIGHT = 'change_height';
export const CHANGE_SETTINGS = 'change_settings';
export const TOGGLE_VIEW_MODE = 'toggle_view_mode';
export const SET_LAYOUTS = 'SET_LAYOUTS';
export const ADD_TO_LAYOUT = 'ADD_TO_LAYOUT';
export const REMOVE_FROM_LAYOUT = 'REMOVE_FROM_LAYOUT';
