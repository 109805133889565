import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import * as Fuse from 'fuse.js';

import { REMOVE, EDIT, NEW, VIEW, getInitialDoc } from '../../../constants';
import {
  Panel,
  ListHeader,
  List,
  QuestionDialog,
  DynDialog,
  EditDialog,
  ProcessDialog
} from '../../../components';
import { deleteDoc, updateDoc } from '../../../Couchbase';
import { timeConverter, wait } from '../../../utils';
import { patientDialogStyle, questionDialogStyles } from '../../../ModalStyles';

import {
  getFilteredBasesCanLogIn,
  getFilteredPatients,
  getCurrentUser,
  getAppAssets,
  getPatientForm,
  getCurrentEvent,
  getPermissions,
  getAppSettings,
  getEncryptionDetails
} from '../../../selectors';

import { exportCSV, getTitle, exportAsPDF } from '../exportHelper';

import { setPrivateKey } from '../actions';
import withEncryption from '../../../encryption/withEncryption';

const HEIGHT_OFFSET = 120;
const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'doc.baseName',
    'doc.eventName',
    'doc.userName',
    'doc.data.label',
    'doc.data.value',
    'doc.data.extra'
  ]
};

class Patients extends Component {
  state = {
    searchActive: false,
    functionType: undefined,
    patientDoc: undefined,
    generatingPDF: false,
    exporting: false
  };

  componentWillReceiveProps({ patients }) {
    // the edit dialog is open, so we need to update the patient doc to have the correct _rev nummer and
    // not create conflicts
    const { patientDoc } = this.state;
    if (patientDoc) {
      this.setState({
        patientDoc: patients.find(i => i._id === patientDoc._id)
      });
    }
  }

  sendNotification = notification =>
    this.notificationSystem.addNotification(notification);

  componentDidUpdate(prevProps) {
    const { t, patients, currentUserId } = this.props;

    const newIncident = patients[patients.length - 1];

    if (
      patients.length > prevProps.patients.length &&
      prevProps.patients.length > 0 &&
      newIncident.userId !== currentUserId
    ) {
      this.sendNotification({
        title: t('Neuer Patient'),
        message: 'von ' + newIncident.userName,
        position: 'tc',
        autoDismiss: 120,
        level: 'warning'
      });
    }
  }

  setUpPatientData = () => {
    const rowData = [];
    const { patients, bases, currentUser, t, form, assets } = this.props;

    patients
      .sort((p1, p2) => p1.ts > p2.ts)
      .forEach(p => {
        const title = getTitle(p, form);

        const tsString = timeConverter(p.ts);
        let details = p.case ? `[${p.case}] ` : '';
        details += `${t('Gemeldet am')} ${tsString}\n`;
        details += `${t('von')} ${p.userName}, ${t('Posten')}: ${p.baseName}`;

        const baseDoc = bases.find(b => p.baseId === b._id); // p.baseId == b._id.split(':')[3]

        const highlighted = _.some(
          p.data,
          item =>
            item &&
            ((Array.isArray(item.value) && _.some(item.value, 'highlight')) ||
              (item.value && item.value.highlight) ||
              (item.component === 'ButtonGroup' &&
                p.className === 'PatientTreatment' &&
                item.extra))
        );

        const data = {
          highlighted,
          title,
          _id: p._id,
          ts: p.ts,
          details,
          rowAction: () => {
            this.openModal(p, baseDoc, VIEW);
          },
          handleDrop: payload => {
            this.handleMsgDrop(p._id, payload);
          },
          leftIcon: {
            icon: 'notes-medical'
          },
          rightIcons: [],
          doc: p
        };

        if (currentUser && currentUser.admin) {
          data.rightIcons.push({
            icon: 'trash',
            action: () => {
              this.openModal(p, undefined, REMOVE);
            }
          });
          data.rightIcons.push({
            icon: 'pen',
            action: () => {
              this.openModal(p, baseDoc, EDIT);
            }
          });
        }

        if (
          (p.activity && p.activity.length > 0) ||
          (p.messages && Object.keys(p.messages).length > 0)
        ) {
          data.rightIcons.push({
            icon: 'comments',
            action: () => {}
          });
        }
        if (p.completed) {
          data.rightIcons.push({
            icon: 'check',
            action: () => {}
          });
        }

        rowData.push(data);
      });

    return rowData.sort((a, b) => b.ts - a.ts); // sort decreasing
  };

  handleMsgDrop = (id, payload) => {
    const { patients, t } = this.props;

    const patientDoc = patients.find(i => i._id === id);
    if (patientDoc) {
      // init the message property if it does not exist
      if (!patientDoc.messages) {
        patientDoc.messages = {};
      }

      // add the message to the patient doc
      patientDoc.messages[payload._id] = payload;

      // send new version to db
      updateDoc(patientDoc, status => {
        if (!status) {
          this.notificationSystem.addNotification({
            message: t('Nachricht konnte nicht anhangen werden.'),
            level: 'error'
          });
        } else {
          this.notificationSystem.addNotification({
            message: t('Nachricht erfolgreich angehangen.'),
            level: 'success'
          });
        }
      });
    }
  };

  openModal = (patientDoc, baseDoc, functionType) => {
    this.setState({
      modalIsOpen: true,
      functionType,
      baseDoc,
      patientDoc
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  getInitalDoc = () => {
    const {
      currentEvent: { _id: eventId, name: eventName, appId },
      currentUser: { _id: userId, name: userName },
      form
    } = this.props;
    const initalDoc = getInitialDoc('PatientTreatment');

    return {
      ...initalDoc,
      appId,
      eventId,
      eventName,
      userId,
      userName,
      ts: Math.floor(Date.now() / 1000)
    };
  };

  closeModalSucces = patientDoc => {
    this.setState({ modalIsOpen: false });
    const { t } = this.props;

    const { functionType, patientDoc: currentPatientDoc } = this.state;
    switch (functionType) {
      case REMOVE:
        if (currentPatientDoc) {
          deleteDoc(currentPatientDoc, status => {
            if (status) {
              this.setState({ patientDoc: undefined });
              this.notificationSystem.addNotification({
                message: t('Patient erfolgreich gelöscht'),
                level: 'success'
              });
            }
          });
        }
        break;
      case NEW:
        updateDoc(patientDoc, status => {
          if (!status) {
            this.notificationSystem.addNotification({
              message: t('Patient konnte nicht erstellt werden.'),
              level: 'error'
            });
          } else {
            this.notificationSystem.addNotification({
              message: t('Patient erfolgreich erstellt.'),
              level: 'success'
            });
          }
        });
        break;
      case EDIT:
        updateDoc(patientDoc, status => {
          if (!status) {
            this.notificationSystem.addNotification({
              message: t('Patient konnte nicht bearbeitet werden.'),
              level: 'error'
            });
          } else {
            this.notificationSystem.addNotification({
              message: t('Patient erfolgreich bearbeitet.'),
              level: 'success'
            });
          }
        });
        break;
      default: // create and edit is the same code
    }
  };

  export = () => {
    const { patients, form, t } = this.props;
    exportCSV(patients, form, t('Patienten') + '.csv');
  };

  showPrintDialog = () => this.setState({ exporting: true });

  hidePrintDialog = () => this.setState({ exporting: false });

  print = async options => {
    const { patients, form, t, decryptItem, currentEvent } = this.props;

    this.setState({ generatingPDF: true });

    await wait(100);

    exportAsPDF(patients, {
      form,
      decryptItem,
      ...options,
      translations: {
        docTitle: `${t('Ereignisse')}: ${currentEvent.name}`,
        title: t('Ereignis'),
        createdAt: t('Created at'),
        createdBy: t('Created by'),
        lastEditAt: t('Last edit at'),
        lastEditBy: t('Last edit by'),
        event: t('Event'),
        base: t('Posten'),
        protocol: t('Weitere Schritte'),
        messages: t('Nachrichten')
      },
      filename: `${t('Patienten')}.pdf`
    })
      .catch(console.error)
      .finally(() => {
        // hide process dialog
        this.setState({ exporting: false, generatingPDF: false });
      });
  };

  getFunctionButtons = () => {
    const { assets, currentUser, t } = this.props;

    const { searchActive } = this.state;
    const res = [
      {
        action: () =>
          this.setState(state => ({ searchActive: !state.searchActive })),
        icon: 'search',
        active: searchActive,
        tooltip: t('Patienten durchsuchen')
      },
      {
        action: () => this.openModal(undefined, undefined, NEW),
        icon: 'plus-square'
      }
    ];
    if (currentUser.admin) {
      res.push({
        icon: 'file-download',
        action: () => this.export()
      });
      res.push({
        icon: 'print',
        action: () => this.showPrintDialog()
      });
    }
    return res;
  };

  search = (searchTerm, dataList) => {
    if (searchTerm.length < 2) {
      return dataList;
    }
    const fuse = new Fuse(dataList, SEARCH_OPTIONS);
    return fuse.search(searchTerm);
  };
  handleSearch = searchValue => this.setState({ searchValue });

  render() {
    const {
      modalIsOpen,
      functionType,
      patientDoc,
      baseDoc,
      searchActive,
      searchValue,
      exporting,
      generatingPDF
    } = this.state;
    const {
      patients,
      appSettings,
      bases,
      currentUser,
      currentEvent,
      form,
      permissions,
      encryption,
      setSecretKey,
      t,
      onClose
    } = this.props;

    const rowData = this.setUpPatientData();
    const currentRowData =
      searchActive && searchValue ? this.search(searchValue, rowData) : rowData;

    return (
      <Panel title={t('Patienten')} id="patients" onClose={onClose}>
        <ListHeader
          functionButtons={this.getFunctionButtons(appSettings, currentUser)}
          count={patients.length}
          searchActive={searchActive}
          onSearch={this.handleSearch}
        />
        <div
          style={{
            height: searchActive ? 'calc(100% - 120px)' : 'calc(100% - 80px)'
          }}
        >
          <List rowData={currentRowData} draggable />
        </div>

        <ProcessDialog
          visible={exporting}
          title={t('Generating PDF file...')}
          onPress={this.print}
          onClose={this.hidePrintDialog}
          loading={generatingPDF}
          showDecryptOption={encryption.enabled}
          t={t}
        />

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={
            functionType === REMOVE ? questionDialogStyles : patientDialogStyle
          }
          contentLabel="deleteAlert"
        >
          {functionType === REMOVE && (
            <QuestionDialog
              caption={t('Löschen')}
              content={t(
                'Sind sie sicher, dass sie den Eintrag löschen wollen?'
              )}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
            />
          )}
          {functionType === VIEW && (
            <EditDialog
              nextStepsActive={permissions.dashboard.features.nextSteps}
              doc={patientDoc}
              baseDoc={baseDoc}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
              appSettings={appSettings}
              currentUser={currentUser}
            />
          )}
          {(functionType === NEW || functionType === EDIT) && (
            <DynDialog
              form={form}
              mainDoc={patientDoc || this.getInitalDoc()}
              docType="PatientTreatment"
              baseDoc={baseDoc}
              bases={bases}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
              appSettings={appSettings}
              currentUser={currentUser}
              currentEvent={currentEvent}
              encryption={encryption}
              setPrivateKey={setSecretKey}
              t={t}
            />
          )}
        </Modal>
        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  const currentEvent = getCurrentEvent(state);

  return {
    patients: getFilteredPatients(state),
    bases: getFilteredBasesCanLogIn(state),
    currentUser: getCurrentUser(state),
    currentUserId: getCurrentUser(state)._id,
    assets: getAppAssets(state),
    appSettings: getAppSettings(state),
    form: getPatientForm(state),
    currentEvent,
    permissions: getPermissions(state),
    encryption: currentEvent
      ? getEncryptionDetails(state)[currentEvent._id]
      : {
          enabled: false
        }
  };
}

export default connect(mapStateToProps, { setSecretKey: setPrivateKey })(
  withEncryption(Patients)
);
