import { combineReducers } from 'redux';
import hoverActionReducer from './ReducerHoverZoom';
import addLocationReducer from './ReducerAddLocation';
import baseActionReducer from './ReducerBaseZoom';
import LatLngZoomReducer from './ReducerLatLngZoom';
import sidemenuReducer from './ReducerSidemenu';
import chatMessageReducer from './ReducerPendingChatMessages';
import encryptionReducer from './ReducerEncryption';

const dashboardReducer = combineReducers({
  hoverAction: hoverActionReducer,
  addLocation: addLocationReducer,
  baseAction: baseActionReducer,
  mapState: LatLngZoomReducer,
  sideMenuState: sidemenuReducer,
  unreadMessages: chatMessageReducer,
  encryption: encryptionReducer
});

export default dashboardReducer;
