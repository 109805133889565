import * as t from './actionTypes';

const putDoc = doc => ({
  type: t.PUT_DOC,
  doc
});

const removeDoc = doc => ({
  type: t.REMOVE_DOC,
  doc
});

const resetDB = () => ({
  type: t.RESET_DB
});

const enableDeviceUpdates = enabled => ({
  type: t.ENABLE_LIVE_UPDATE,
  enabled
});

const setAppSettings = appSettings => ({
  type: t.SET_APP,
  appDoc: appSettings
});

export default {
  putDoc,
  removeDoc,
  resetDB,
  enableDeviceUpdates,
  setAppSettings
};
