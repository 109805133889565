import * as types from '../actionTypes';

const INITALSTATE = {
  type: undefined,
  payload: undefined
};

export default function(state = INITALSTATE, action) {
  switch (action.type) {
    case types.BASE_ZOOM_INIT:
      return action;
    case types.BASE_ZOOM_DONE:
      return action;
    default:
      return state;
  }
}
