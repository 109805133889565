import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { View, Text, StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    ...Platform.select({ web: { zIndex: 'auto' } })
  },
  text: {
    fontSize: 18,
    padding: 3,
    color: '#33495D'
  },
  line: {
    height: 1,
    marginTop: 6,
    backgroundColor: '#999999'
  }
});

const Section = ({ title, children, containerStyle, textStyle }) => (
  <View style={[styles.container, containerStyle]}>
    {title && <Text style={[styles.text, textStyle]}>{title}</Text>}
    {children}
    <View style={styles.line} />
  </View>
);

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

Section.defaultProps = {
  title: undefined,
  children: undefined,
  containerStyle: {},
  textStyle: {}
};

export default Section;
