import React from 'react';
import { connect } from 'react-redux';
import { DropTarget } from 'react-dnd';

import { ToggleButton, ToggleButtonGroup, Badge } from 'react-bootstrap';
import { createSelector } from 'reselect';

import { ItemTypes } from '../../../constants';

import { stringToColor } from '../../../utils';
import {
  getCurrentEvent,
  getFilteredBasesCanLogIn,
  getSelectableTags
} from '../../../selectors';

const Avatar = ({ className, icon, name, color }) => {
  if (className === 'Base') {
    return (
      <img
        src={icon.iconRetinaUrl || icon.iconUrl}
        height={25}
        alt={`Base ${name}`}
      />
    );
  }

  let letters = name
    ? name
        .split(' ')
        .map(v => v[0])
        .slice(0, 2)
        .join('')
    : '';

  if (letters.length < 2) {
    letters = name ? name.slice(0, 2) : '';
  }

  letters = letters.toUpperCase();

  return (
    <div className="avatar" style={{ background: color }}>
      <font color="white">{letters}</font>
    </div>
  );
};

// const itemTarget = {
//   drop({ group, onChange }, monitor) {
//     const item = monitor.getItem();

//     // handle the dropped item
//     console.log('TODO handle drop', item);

//     // change group
//     onChange(group);

//     // post item in group;
//     // TODO
//   },
//   canDrop(props, monitor) {
//     return true;
//   }
// };

// const collectTrg = (connect, monitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: monitor.isOver(),
//   canDrop: monitor.canDrop()
// });

// const Button = ({
//   item,
//   selected,
//   unreadMessages,
//   connectDropTarget,
//   isOver,
//   canDrop,
//   ...props
// }) => {
//   const group = item.group || item._id;

//   let unread = unreadMessages[group];
//   if (Array.isArray(unread)) {
//     unread = unread.length;
//   }

//   return (
//     <ToggleButton {...props} style={{ marginBottom: '6px', width: '100%' }}>
//       {connectDropTarget(
//         <div
//           className={`${isOver ? 'dropTarget-is-over' : ''} ${canDrop ? 'dropTarget' : ''}`}
//           style={{
//             display: 'flex',
//             flexDirection: 'row',
//             alignContent: 'flex-start'
//           }}
//         >
//           <div style={{ width: '40px' }}>
//             <Avatar {...item} />
//           </div>
//           <div style={{ width: '100%', textAlign: 'left', whiteSpace: 'normal' }}>{item.name}</div>
//           <div style={{ width: '30px' }}>
//             {unread && selected !== group && <Badge>{unread}</Badge>}
//           </div>
//         </div>
//       )}
//     </ToggleButton>
//   );
// };

// const DropButton = DropTarget(ItemTypes.ITEM, itemTarget, collectTrg)(Button);

const Button = ({ item, selected, unreadMessages, ...props }) => {
  const group = item.group || item._id;

  let unread = unreadMessages[group];
  if (Array.isArray(unread)) {
    unread = unread.length;
  }

  return (
    <ToggleButton {...props} style={{ marginBottom: '6px', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'flex-start'
        }}
      >
        <div style={{ width: 50 }}>
          <Avatar {...item} />
        </div>
        <div style={{ width: '100%', textAlign: 'left', whiteSpace: 'normal' }}>
          {item.name}
        </div>
        <div style={{ width: 30 }}>
          {unread && selected !== group && <Badge>{unread}</Badge>}
        </div>
      </div>
    </ToggleButton>
  );
};

const GroupChats = ({ chats, selected, onSelect, unreadMessages }) => (
  <ToggleButtonGroup
    name="ChatGroup"
    type="radio"
    value={selected}
    onChange={value => {
      console.log(value, selected);
      onSelect(value);
    }}
    style={{ padding: '1px' }}
  >
    {chats.map(item => (
      <Button
        key={item.group || item._id}
        value={item.group || item._id}
        item={item}
        selected={selected}
        unreadMessages={unreadMessages}
      />
    ))}
  </ToggleButtonGroup>
);

const getChats = createSelector(
  getCurrentEvent,
  getFilteredBasesCanLogIn,
  getSelectableTags,
  (event, bases, tags) => {
    if (!event) {
      return [];
    }

    if (!event.color) {
      event.color = '#33495D';
    }

    return [event]
      .concat(
        tags.map(t => ({
          name: t.label,
          group: t.value,
          color: stringToColor(t.value)
        }))
      )
      .concat(bases);
  }
);

const mapStateToProps = state => ({
  chats: getChats(state),
  unreadMessages: state.dashboard.unreadMessages
});

export default connect(mapStateToProps)(GroupChats);
