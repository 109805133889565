import React, { useState } from 'react';
import Modal from 'react-modal';
// import { connect } from 'react-redux';
import Loading from 'react-loading-animation';
import { Dialog, DialogHeader, DialogContent } from '../../components';

import { cryptoDialogStyle } from '../../ModalStyles';

import {
  generateKeyPair,
  getPrivateKey,
  getPublicKey,
  toBase64
} from '../../encryption/encryption_sodium';

const CryptoDialog = ({
  visible,
  onClose,
  onEncrypt,
  t,
  encryption,
  setSecretKey
}) => {
  const [passphrase, setPassphrase] = useState('');
  const [keys, setKeys] = useState({
    privateKey: undefined,
    publicKey: encryption && encryption.publicKey,
    salt: encryption && encryption.salt
  });
  const [loading, setLoading] = useState(false);

  return (
    <Modal isOpen={visible} onRequestClose={onClose} style={cryptoDialogStyle}>
      <Dialog className="edit-user-dialog">
        <DialogHeader title={t('Encryption settings')} onClose={onClose} />
        <DialogContent className="edit-user-dialog-content">
          {keys.publicKey && (
            <div>
              <h4>{t('Public key')}</h4>
              <p>{keys.publicKey}</p>
            </div>
          )}

          {keys.privateKey && (
            <div>
              <h4>{t('Private key')}</h4>
              <p>{keys.privateKey}</p>
            </div>
          )}

          {keys.salt && (
            <div>
              <h4>{t('Salt')}</h4>
              <p>{keys.salt}</p>
            </div>
          )}

          <h4>{t('Passphrase')}</h4>
          <div className="row">
            <div className="col-md-8">
              <input
                type="text"
                value={passphrase}
                onChange={event => setPassphrase(event.target.value)}
                style={{ width: '100%', fontSize: '16px', lineHeight: 1.8 }}
              />
            </div>
            <div className="col-md-4">
              {encryption && encryption.publicKey && !keys.privateKey ? (
                <div className="row">
                  <button
                    type="button"
                    className="btn btn-default"
                    disabled={!passphrase || !encryption.salt}
                    onClick={() => {
                      setLoading(true);

                      // hack delay so that ui can be updated first
                      setTimeout(
                        () =>
                          getPrivateKey(passphrase, encryption.salt).then(sk =>
                            getPublicKey(sk)
                              .then(toBase64)
                              .then(pk => {
                                if (pk === encryption.publicKey) {
                                  setKeys({
                                    publicKey: pk,
                                    privateKey: toBase64(sk),
                                    salt: encryption.salt
                                  });
                                  setSecretKey(sk);
                                }
                                setLoading(false);
                              })
                          ),
                        200
                      );
                    }}
                  >
                    {t('Recover private key')}
                  </button>
                </div>
              ) : (
                <div className="row">
                  <button
                    type="button"
                    className="btn btn-danger"
                    disabled={!passphrase}
                    onClick={() => {
                      setLoading(true);

                      // hack delay so that ui can be updated first
                      setTimeout(
                        () =>
                          generateKeyPair(passphrase).then(keypair => {
                            setKeys(keypair);
                            setLoading(false);
                          }),
                        200
                      );
                    }}
                  >
                    {t('Generate new keypair')}
                  </button>
                </div>
              )}
            </div>
          </div>

          {loading && <Loading />}
        </DialogContent>

        <div className="edit-user-dialog-footer">
          <button type="button" className="btn btn-default" onClick={onClose}>
            {t('Abbrechen')}
          </button>
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={() => {
              onEncrypt(keys);
            }}
            disabled={keys === undefined}
          >
            {t('Encrypt documents')}
          </button>
        </div>
      </Dialog>
    </Modal>
  );
};

export default CryptoDialog;

// if keys
// show current public key and salt
// show enter passphrase unlock
// if valid passphrase show gen new keys
// else
// show generate new key pair
