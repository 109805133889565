export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4
  },
  overlay: {
    zIndex: 1002
  }
};

export const editUserStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    maxWidth: 600,
    maxHeight: 750,
    height: '95%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const editQrStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    maxWidth: 800,
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const questionDialogStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    maxWidth: 600,
    maxHeight: 200,
    height: '95%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const importUserStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    overflow: 'visible'
  },
  overlay: {
    zIndex: 1002,
    overflow: 'visible'
  }
};

export const patientDialogStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    maxWidth: '60%',
    maxHeight: '80%',
    height: '95%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const ordersDialogStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4
  },
  overlay: {
    zIndex: 1002
  }
};

export const incidentsDialogStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    maxWidth: 900,
    maxHeight: 600,
    height: '95%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const incidentsDialogStyleFilter = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    maxWidth: 450,
    maxHeight: 300,
    height: '95%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const incidentsDialogStyleEdit = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    maxWidth: '60%',
    maxHeight: '80%',
    height: '95%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const eventDialogStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    height: '95%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const cryptoDialogStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '50%',
    minWidth: 780,
    height: '40%',
    overflowY: 'hidden',
    userSelect: 'text'
  },
  overlay: {
    zIndex: '1003'
  }
};

export const cryptoPasswordDialogStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '50%',
    minHeight: 300,
    minWidth: 750,
    height: '20%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: '1003'
  }
};

export const progressDialogStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '50%',
    minHeight: 300,
    minWidth: 750,
    height: '20%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: '999'
  }
};

export const briefingDialogStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    borderRadius: 4,
    width: '95%',
    height: '95%',
    overflowY: 'hidden'
  },
  overlay: {
    zIndex: 1002
  }
};

export const alertOptions = {
  offset: 14,
  position: 'bottom left',
  theme: 'light',
  time: 5000,
  transition: 'scale'
};
