import * as types from '../actionTypes';
import { ROADMAP, DEFAULT_MAP_VIEWPORT } from '../../../constants';

const INITALSTATE = {
  viewport: DEFAULT_MAP_VIEWPORT,
  checkedLayer: ROADMAP
};

export default function(state = INITALSTATE, { type, payload }) {
  switch (type) {
    case types.MAP_VIEWPORT_CHANGE:
      return { ...state, ...payload };
    case types.MAP_LAYER_CHANGE:
      return { ...state, ...payload };
    default:
      return state;
  }
}
