import React from 'react';
import _ from 'lodash';
import { View } from 'react-native';
import ButtonSelect from './ButtonSelect';
import TextInput from '../Input/TextInput';

const ButtonSelectWithText = ({ options, value, onChange, t, ...props }) => {
  const extraValue =
    Array.isArray(value) && value.length > 0
      ? value[0].extra
      : value && value.extra;

  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      <ButtonSelect
        options={options}
        value={value}
        onChange={onChange}
        {...props}
      />
      {value && (_.some(value, 'extra') || value.extra) && (
        <TextInput
          label={t('Description')}
          value={typeof extraValue === 'string' ? extraValue : ''}
          isMultiline
          onChange={extra =>
            onChange(
              Array.isArray(value)
                ? value.map(v => ({ ...v, extra }))
                : { ...value, extra }
            )
          }
        />
      )}
    </View>
  );
};

ButtonSelectWithText.defaultProps = {
  t: v => v
};

export default ButtonSelectWithText;
