import React from 'react';

import Dialog from './Dialog';
import DialogHeader from './DialogHeader';
import DialogContent from './DialogContent';
import DialogFooter from './DialogFooter';

import { translate } from 'react-i18next';

const QuestionDialog = ({
  caption,
  content,
  errorHandler,
  succesHandler,
  t
}) => (
  <Dialog>
    <DialogHeader title={caption} onClose={errorHandler} />

    <DialogContent className="edit-dialog-content text-center">
      <span style={{ fontSize: 20 }}>{content}</span>
    </DialogContent>

    <div
      className="edit-dialog-footer"
      style={{ position: 'absolute', bottom: 0, width: '100%' }}
    >
      <button className="btn btn-default" onClick={errorHandler}>
        {t('Nein')}
      </button>
      <button className="btn btn-primary pull-right" onClick={succesHandler}>
        {t('Ja')}
      </button>
    </div>
  </Dialog>
);

export default translate('translations')(QuestionDialog);
