import _ from 'lodash';
import React from 'react';

import ReactTags from 'react-tag-autocomplete';

class Tags extends React.Component {
  state = {
    inputText: undefined
  };

  handleAdd = ({ name }) => {
    const { onChange, tags } = this.props;

    const updated = _.unionBy(tags, [{ label: name }], 'label');
    onChange(updated);
    this.clearText();
  };

  handleDelete = idx => {
    const { onChange, tags } = this.props;

    const updated = [...tags];
    updated.splice(idx, 1);
    onChange(updated);
    this.clearText();
  };

  handleChange = value => {
    this.setState({ inputText: value });
  };

  clearText = () => this.setState({ inputText: undefined });

  render() {
    const { tags, suggestions, placeholder } = this.props;
    const { inputText } = this.state;

    const filteredSuggestions = suggestions
      .filter(
        s =>
          !inputText ||
          (s.label && s.label.toLowerCase().startsWith(inputText.toLowerCase()))
      )
      .map((t, i) => ({
        id: i,
        name: t.label
      }));

    const tagsMapped = tags
      ? tags.map((t, i) => ({ id: i, name: t.label }))
      : [];

    return (
      <ReactTags
        tags={tagsMapped}
        suggestions={filteredSuggestions}
        handleInputChange={this.handleChange}
        handleAddition={this.handleAdd}
        handleDelete={this.handleDelete}
        onBlur={this.clearText}
        placeholder={placeholder}
        style={{ borderRadius: 4 }}
        allowNew
      />
    );
  }
}

export default Tags;
