import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TintIcon } from '../../components';

const iconStyle = { fontSize: 20, marginRight: 10 };
export default ({
  active,
  dropdownData,
  onClick,
  color,
  icon,
  iconUrl,
  iconColor,
  style
}) => (
  <Dropdown
    id={active && active.name}
    onSelect={(i, obj) => onClick(dropdownData[i])}
  >
    <Dropdown.Toggle
      bsStyle="default"
      className="organization-button"
      style={{ color, ...style }}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon} color={iconColor} style={iconStyle} />
      ) : (
        <TintIcon url={iconUrl} color={iconColor} />
      )}
      {active ? active.name : ''}
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu">
      {dropdownData.map((item, i) => (
        <MenuItem eventKey={i} key={i}>
          {item.name}
        </MenuItem>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);
