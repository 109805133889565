import React, { memo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import getAsset from './assets';
import ScaledImage from './ScaledImage';

import { HEIGHT, WIDTH, IDX_TO_IDX } from './constants';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
});

const BodyPart = ({ idx, face, scale, isDisabled, onChange, active }) => {
  const source = getAsset(idx, face, active);

  if (!isDisabled) {
    const key = IDX_TO_IDX[face][idx - 1];
    return (
      <TouchableOpacity
        onPress={() =>
          onChange({
            active: !active,
            key
          })
        }
      >
        <ScaledImage scale={scale} source={source} />
      </TouchableOpacity>
    );
  }

  return <ScaledImage scale={scale} source={source} />;
};

BodyPart.propTypes = {
  idx: PropTypes.number.isRequired,
  face: PropTypes.oneOf(['front', 'back']),
  scale: PropTypes.number,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  active: PropTypes.bool
};

BodyPart.defaultProps = {
  face: 'front',
  scale: 1,
  isDisabled: false,
  active: false,
  onChange: () => {}
};

const Body = ({ maxHeight, maxWidth, value, onChange, face }) => {
  const scale = Math.min(maxHeight / HEIGHT, maxWidth / WIDTH);

  const props = idx => {
    const key = IDX_TO_IDX[face][idx - 1];
    return {
      face,
      scale,
      idx,
      active: value && value[face] && value[face][key],
      onChange: ({ active }) => {
        const change = { ...value };

        if (!change[face]) {
          change[face] = { [key]: active };
        } else {
          change[face][key] = active;
        }

        // ensure that eyes are either wide or small
        switch (key) {
          case 'Pupille-Links-Klein':
            change[face]['Pupille-Links-Weit'] = false;
            break;
          case 'Pupille-Links-Weit':
            change[face]['Pupille-Links-Klein'] = false;
            break;
          case 'Pupille-Rechts-Klein':
            change[face]['Pupille-Rechts-Weit'] = false;
            break;
          case 'Pupille-Rechts-Weit':
            change[face]['Pupille-Rechts-Klein'] = false;
            break;
          default:
            break;
        }

        onChange(change, key);
      }
    };
  };

  const isDisabled = face === 'back';

  return (
    <View
      style={[
        styles.container,
        { width: WIDTH * scale, height: HEIGHT * scale }
      ]}
    >
      <View>
        <BodyPart {...props(1)} isDisabled={isDisabled} />
        <BodyPart {...props(4)} isDisabled={isDisabled} />
      </View>
      <View>
        <BodyPart {...props(2)} />
      </View>
      <View>
        <BodyPart {...props(3)} isDisabled={isDisabled} />
        <BodyPart {...props(5)} isDisabled={isDisabled} />
      </View>
      <View>
        <BodyPart {...props(6)} />
        <BodyPart {...props(9)} />
      </View>
      <View>
        <BodyPart {...props(7)} />
        <BodyPart {...props(11)} />
      </View>
      <View>
        <BodyPart {...props(8)} />
        <BodyPart {...props(10)} />
      </View>
      <View>
        <BodyPart {...props(12)} />
      </View>
      <View>
        <BodyPart {...props(13)} />
      </View>
      <View>
        <BodyPart {...props(14)} />
      </View>
      <View>
        <BodyPart {...props(15)} isDisabled />
      </View>
      <View>
        <BodyPart {...props(16)} />
        <BodyPart {...props(19)} />
      </View>
      <View>
        <BodyPart {...props(17)} />
        <BodyPart {...props(20)} />
      </View>
      <View>
        <BodyPart {...props(18)} isDisabled />
      </View>
    </View>
  );
};

Body.propTypes = {
  face: PropTypes.oneOf(['front', 'back']),
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  value: PropTypes.object,
  onChange: PropTypes.func
};

Body.defaultProps = {
  maxHeight: HEIGHT,
  maxWidth: WIDTH,
  face: 'front',
  value: undefined,
  onChange: () => {}
};

export default memo(Body, (next, prev) => next.value === prev.value);
