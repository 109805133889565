import React from 'react';

const renderField = ({
  input,
  label,
  placeholder,
  id,
  type,
  disabled,
  meta: { touched, error }
}) => (
  <div className="form-group">
    <label className="form-control-label" htmlFor={id}>
      {label}
    </label>

    <input
      id={id}
      {...input}
      placeholder={placeholder || label}
      type={type}
      disabled={disabled}
      autoComplete="new-password"
      className="form-control"
    />

    <div className="text-help">{touched ? error : ''}</div>
  </div>
);

export { renderField };
