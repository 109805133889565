import React, { Component } from 'react';
import { translate } from 'react-i18next';

import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter
} from '../../../components';

import {
  FILTER_BY_FINISHED,
  FILTER_BY_IMAGE,
  FILTER_CHAT,
  FILTER_INCIDENT,
  FILTER_PATIENT,
  FILTER_CROWD
} from '../../../constants';

class FilterIncidentDialog extends Component {
  constructor(props) {
    super(props);
    const { sortBy, filterType, t } = props;

    this.state = {
      sortBy,
      filterType
    };
  }

  onRadioChange = sortBy => this.setState({ sortBy });

  updateFilter = fType => {
    const { filterType } = this.state;
    if (filterType.includes(fType)) {
      const array = [...filterType];
      const index = filterType.indexOf(fType);
      array.splice(index, 1);
      this.setState({ filterType: array });
    } else {
      this.setState({ filterType: [...filterType, fType] });
    }
  };

  render() {
    const { caption, errorHandler, succesHandler, t } = this.props;
    const { sortOptions, sortBy, filterType } = this.state;

    return (
      <Dialog>
        <DialogHeader title={caption} onClose={errorHandler} />
        <DialogContent className="edit-incident-content">
          <div className="edit-incident-content-scroll-wrap">
            <div className="edit-incident-content-scroll">
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_BY_FINISHED)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_BY_FINISHED)}
                  />
                  {t('Abgeschlossene Ereignisse filtern.')}
                </label>
              </div>
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_BY_IMAGE)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_BY_IMAGE)}
                  />
                  {t('Ereignisse ohne Bilder filtern.')}
                </label>
              </div>
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_CHAT)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_CHAT)}
                  />
                  {t('Filter Chatnachrichten')}
                </label>
              </div>
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_INCIDENT)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_INCIDENT)}
                  />
                  {t('Filter Ereignisse')}
                </label>
              </div>
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_PATIENT)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_PATIENT)}
                  />
                  {t('Filter Patienten Meldungen')}
                </label>
              </div>
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_CROWD)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_CROWD)}
                  />
                  {t('Filter Crowdspotter Meldungen')}
                </label>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogFooter>
          <button className="btn btn-default" onClick={errorHandler}>
            {t('Abbrechen')}
          </button>
          <button
            className="btn btn-primary pull-right"
            onClick={() => succesHandler(this.state)}
          >
            {t('Anwenden')}
          </button>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default translate('translations')(FilterIncidentDialog);
