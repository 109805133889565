import { combineReducers } from 'redux';

import OrganisationComponent from './Organisation';
import * as actions from './Actions';
import FilterReducer from './FilterReducer';
import ViewModeReducer from './ViewModeReducer';
import LayoutReducer from './LayoutReducer';

const reducer = combineReducers({
  filter: FilterReducer,
  viewMode: ViewModeReducer,
  layouts: LayoutReducer
});

export { actions, reducer };

export default OrganisationComponent;
