/* eslint no-extend-native : 0 */
import moment from 'moment';
import _ from 'lodash';

export const wait = delay => new Promise(resolve => setTimeout(resolve, delay));

export const isActiveTag = (tags, suggestions) =>
  !Array.isArray(tags) ||
  tags.length === 0 ||
  _.intersectionBy(
    tags,
    suggestions.filter(t => t.active || t.active === undefined),
    'value'
  ).length > 0;

export const stringToColor = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

export const checkRoles = (requiredRoles, currentRoles) => {
  let rollFound = false;
  currentRoles.forEach(roll => {
    if (requiredRoles && requiredRoles.includes(roll)) {
      rollFound = true;
    }
  });
  return rollFound;
};

export const deepCopyNestedArray = arr => {
  if (_.isArray(arr)) {
    return _.map(arr, deepCopyNestedArray);
  }
  if (typeof arr === 'object') {
    throw 'Cannot clone array containing an object!';
  } else {
    return arr;
  }
};
export const getRandomArbitrary = (min, max) =>
  Math.random() * (max - min) + min;
export const randomSign = Math.random() < 0.5 ? -1 : 1;

export const addNoiseToCoordinates = coordinate => [
  coordinate[0] + randomSign * getRandomArbitrary(0.00001, 0.0001),
  coordinate[1] + randomSign * getRandomArbitrary(0.00001, 0.0001)
];

export const removeEmpty = obj => {
  // Remove undefined and null from objects and arrays recursively
  if (Array.isArray(obj)) {
    return obj
      .filter(v => v !== null && v !== undefined)
      .reduce((b, v) => [...b, typeof v === 'object' ? removeEmpty(v) : v], []);
  }

  return Object.keys(obj)
    .filter(k => obj[k] !== null && obj[k] !== undefined)
    .reduce(
      (newObj, k) => ({
        ...newObj,
        [k]: typeof obj[k] === 'object' ? removeEmpty(obj[k]) : obj[k]
      }),
      {}
    );
};

export const extendDocWithMeta = ({ appId, user, base, event }, doc) => {
  const ts = doc.ts ? doc.ts : Math.round(new Date().getTime() / 1000);
  const _id = doc._id
    ? doc._id
    : `${appId}:${doc.className}:${event._id.split(':').pop()}:${base._id
        .split(':')
        .pop()}:${ts}`;

  const meta = {
    _id,
    ts,
    appId,
    eventId: event._id,
    eventName: event.name,
    baseId: base._id,
    baseName: base.name,
    userName: user.name,
    userId: user._id,
    uploaded: false
  };

  return { ...doc, ...meta };
};

export const byTimestamp = (a, b) => b.ts - a.ts;

export const byName = (a, b) => {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

export const prettyArea = area => {
  if (area > 100000)
    return `${Math.round((area / 1000000.0) * 100) / 100.0} km<sup>2</sup>`;
  if (area > 1000) return `${Math.round((area / 100.0) * 100) / 100.0} ha`;
  return `${Math.floor(area)} m<sup>2</sup>`;
};

export const binarySearch = (arr, docId) => {
  let low = 0;
  let high = arr.length;
  let mid;
  while (low < high) {
    mid = (low + high) >>> 1; // faster version of Math.floor((low + high) / 2)
    if (arr[mid]._id < docId) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return low;
};

export const generateId = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  // generate TS plus 4 random numbers
  return `${Math.floor(Date.now() / 1000).toString()}-${s4()}`;
};

const padToTwo = numberString => {
  if (numberString.length < 2) {
    return `0${numberString}`;
  }
  return numberString;
};

const rgb2Hex = rgbString => {
  const result = /^rgb\((\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\)$/.exec(
    rgbString
  );

  return result
    .slice(1, 4)
    .map(rgbValue => rgbValue.toInt().toString(16))
    .reduce((previous, current) => previous + padToTwo(current), '#');
};

export const hexAverage = (...args) =>
  args
    .reduce(
      (previousValue, pCurrentValue) => {
        let currentValue = pCurrentValue;
        if (currentValue.indexOf('rgb') === 0) {
          currentValue = rgb2Hex(currentValue);
        }
        return currentValue
          .replace(/^#/, '')
          .match(/.{2}/g)
          .map((value, index) => previousValue[index] + parseInt(value, 16));
      },
      [0, 0, 0]
    )
    .reduce(
      (previousValue, currentValue) =>
        previousValue +
        padToTwo(Math.floor(currentValue / args.length).toString(16)),
      '#'
    );

export const timeConverter = (ts, withSeconds = true) => {
  if (withSeconds) {
    return moment(ts * 1000).format('ll, LTS');
  }

  return moment(ts * 1000).format('ll, LT');
};

export const timeConverterString = datetimeString => {
  const a = new Date(datetimeString);
  return timeConverter(a.getTime() / 1000.0, false);
};

export const timeSinceNowString = unixTimestamp => {
  const t = moment(unixTimestamp * 1000);
  const now = moment();
  const d = moment.duration(now.diff(t));
  // if (d.asDays>1)
  //  return t.humanize()
  return t.fromNow();
};

export const timeConverterDateOnly = unixTimestamp => {
  const a = new Date(unixTimestamp * 1000);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez'
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate() < 10 ? `0${a.getDate()}` : a.getDate();

  const time = `${date}. ${month} ${year}`;
  return time;
};

export const timeConverterTimeOnly = unixTimestamp => {
  const a = new Date(unixTimestamp * 1000);
  const hour = a.getHours() < 10 ? `0${a.getHours()}` : a.getHours();
  const min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
  const sec = a.getSeconds() < 10 ? `0${a.getSeconds()}` : a.getSeconds();
  const time = `${hour}:${min}:${sec}`;
  return time;
};

export const timestampToTime = dateStr => {
  const a = new Date(dateStr);
  const hour = a.getHours() < 10 ? `0${a.getHours()}` : a.getHours();
  const min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
  const time = `${hour}:${min}`;
  return time;
};
export const secondsToMin = sec => Math.ceil(sec / 60);

const toDegreesMinutesAndSeconds = coordinate => {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return `${degrees}°${minutes}.${seconds}' `;
};

export const latLngToDgreeString = latlng => {
  const latitude = toDegreesMinutesAndSeconds(latlng[0]);
  const latitudeCardinal = Math.sign(latlng[0]) >= 0 ? 'N' : 'S';

  const longitude = toDegreesMinutesAndSeconds(latlng[1]);
  const longitudeCardinal = Math.sign(latlng[1]) >= 0 ? 'E' : 'W';

  return `${latitude + latitudeCardinal}, ${longitude}${longitudeCardinal}`;
};

export const fliplatlon = doc => {
  if (doc && doc.geometry) {
    doc.geometry.coordinates.reverse();
  }
  return doc;
};

export const isEmail = str =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(str);

export const patchConsole = () => {
  let method;
  const noop = function() {};
  const methods = [
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profileEnd',
    'table',
    'time',
    'timeEnd',
    'timeStamp',
    'trace',
    'warn'
  ];
  let length = methods.length;
  const console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
};

export const printQRCodes = async codes => {
  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 40, 60, 40],
    info: {
      title: 'QR code',
      author: 'Antavi GmBH',
      subject: 'qr code for login in',
      keywords: 'qr code'
    },
    content: [],
    footer(currentPage, pageCount) {
      return {
        text: `${currentPage.toString()} of ${pageCount}`,
        alignment: 'center'
      };
    }
  };

  // docDefinition.content.push(		{
  //  image: getBase64FromImageUrl('https://s3.eu-central-1.amazonaws.com/srzevents/assets/logo_app.png'),
  // })
  docDefinition.content.push(
    {
      text: 'Ops Onboarding',
      alignment: 'left',
      fontSize: 20,
      bold: true
    },
    {
      text: 'App Download: http://ops.antavi.ch',
      alignment: 'left',
      fontSize: 10,
      bold: true,
      margin: [0, 10]
    }
  );
  const nCodesPerPage = 3;
  let i = 1;
  codes.forEach(c => {
    // QR Codes
    docDefinition.content.push({
      columns: [
        {
          qr: c.qrCode,
          width: 225,
          height: 240,
          fit: 80,
          alignment: 'center'
        },
        {
          stack: c.info,
          fontSize: 12
        }
      ],
      margin: [0, 70],
      pageBreak: i % nCodesPerPage == 0 && i < codes.length ? 'after' : null
    });
    i += 1;
  });
  // FOOTER
  docDefinition.content.push({
    text: 'Antavi GmbH | info@antavi.ch',
    italic: true,
    alignment: 'center',
    fontSize: 8
  });

  // dynamically load pdf module
  const pdfMake = (await import('pdfmake/build/pdfmake')).default;
  const pdfFonts = (await import('pdfmake/build/vfs_fonts')).default;
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  pdfMake.createPdf(docDefinition).download('qrcodes.pdf');
};

export const getBase64FromImageUrl = url => {
  const img = new Image();

  img.setAttribute('crossOrigin', 'anonymous');

  img.onload = function() {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(this, 0, 0);

    const dataURL = canvas.toDataURL('image/png');

    alert(dataURL.replace(/^data:image\/(png|jpg);base64,/, ''));
  };

  img.src = url;
};

export const metersPerPixel = (latitude, zoomLevel) => {
  const earthCircumference = 40075017;
  const latitudeRadians = latitude * (Math.PI / 180);
  return (
    (earthCircumference * Math.cos(latitudeRadians)) /
    Math.pow(2, zoomLevel + 8)
  );
};

export const metersToPixel = (latitude, meters, zoomLevel) =>
  meters / metersPerPixel(latitude, zoomLevel);

export const getCenterFromGeoJson = ({ features }) => {
  // TODO handle other geojson types than points

  if (features.length === 0) {
    return [0, 0];
  }

  const lngs = _.sortBy(features, f => f.geometry.coordinates[0]);
  const lats = _.sortBy(features, f => f.geometry.coordinates[1]);

  const idx = Math.floor(lngs.length / 2);

  return [lngs[idx].geometry.coordinates[0], lats[idx].geometry.coordinates[1]];
};

// =================================================================================================
// IE FIXES
// =================================================================================================
if (!String.prototype.includes) {
  process.env.NODE_ENV === 'development' &&
    console.log('Added includes method');
  String.prototype.includes = function(search, start) {
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    }
    return this.indexOf(search, start) !== -1;
  };
}

if (!Object.entries) {
  process.env.NODE_ENV === 'development' && console.log('Added entries method');
  Object.entries = function(obj) {
    let ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

if (!Array.prototype.includes) {
  process.env.NODE_ENV === 'development' &&
    console.log('Added includes method for array');
  Object.defineProperty(Array.prototype, 'includes', {
    value(searchElement, fromIndex) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      const n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return (
          x === y ||
          (typeof x === 'number' &&
            typeof y === 'number' &&
            isNaN(x) &&
            isNaN(y))
        );
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        // c. Increase k by 1.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        k++;
      }

      // 8. Return false
      return false;
    }
  });
}

// https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    value(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      const thisArg = arguments[1];

      // 5. Let k be 0.
      let k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        const kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    },
    configurable: true,
    writable: true
  });
}

// https://stackoverflow.com/questions/42830257/alternative-version-for-object-values
if (!Object.values) {
  Object.values = obj => Object.keys(obj).map(e => obj[e]);
}
