import React, { Component } from 'react';

const ICON_DIC_APP_2_WEB = {
  'ios-man': 'fa-male',
  'ios-woman': 'fa-female'
};

class ButtonGroup extends Component {
  constructor(props) {
    super(props);
    // number of rows to display
    const { selectedItem } = props;

    this.state = {
      selectedItem: selectedItem || { label: undefined }
    };
  }

  onPress = item => {
    this.setState({ selectedItem: item });
    this.props.onSelected({
      ...item,
      title: this.props.title
    });
  };

  renderExtra = () => (
    <div style={{ margin: 10 }}>
      <h3>Beschreibung</h3>
      <textarea
        value={this.state.selectedItem.value}
        rowSpan={4}
        className="form-control"
        style={{}}
        onChange={e => {
          console.log(e);
          const value = e.target.value;
          this.onPress({ ...this.state.selectedItem, value, extra: value });
        }}
      />
    </div>
  );

  renderItem = (i, item) => (
    <button
      onClick={() => this.onPress(item)}
      style={{ margin: 5 }}
      key={i}
      className={`btn ${
        item.label === this.state.selectedItem.label
          ? 'btn-success'
          : 'btn-default'
      }`}
    >
      {item.icon && ICON_DIC_APP_2_WEB[item.icon] ? (
        <i className={`fa ${ICON_DIC_APP_2_WEB[item.icon]}`} />
      ) : null}
      {` ${item.label}`}
    </button>
  );

  render() {
    const { data, dialogWidth } = this.props;
    const rowStyle = {
      //        "overflowX": "auto",  disable horizontal scrollbar
      //        "whiteSpace": "nowrap",
      width: `${dialogWidth}px`
    };
    return (
      <div style={{}}>
        <div style={rowStyle}>
          {data.map((item, i) => this.renderItem(i, item))}
        </div>
        {this.state.selectedItem && this.state.selectedItem.extra
          ? this.renderExtra()
          : null}
      </div>
    );
  }
}

export default ButtonGroup;
