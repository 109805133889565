import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';

import DateTimePicker from './DateTimePicker';

const styles = StyleSheet.create({
  container: {
    margin: 2,
    marginTop: 16,
    flex: 1,
    flexGrow: 1,
    ...Platform.select({ web: { zIndex: 'auto' } })
  },
  label: {
    fontSize: 12,
    fontWeight: '400',
    color: '#30424F',
    fontFamily: 'Helvetica'
  },
  input: {
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: '#FFFFFF',
    padding: 3,
    paddingBottom: 7,
    height: 32
    // fontSize: 16,
    // fontWeight: '400'
    // zIndex: 1
  },
  line: {
    backgroundColor: '#30424F',
    height: 2,
    zIndex: 0
  }
});

const DateTimeInput = ({ label, containerStyle, textStyle, ...rest }) => (
  <View style={[styles.container, containerStyle]}>
    <Text style={[styles.label, textStyle]}>{label}</Text>
    <DateTimePicker
      textStyle={styles.input}
      containerStyle={styles.input}
      {...rest}
    />
    <View style={styles.line} />
  </View>
);

DateTimeInput.defaultProps = {
  containerStyle: {},
  textStyle: {},
  label: ''
};

export default DateTimeInput;
