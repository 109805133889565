import React from 'react';
import Switch from 'react-bootstrap-switch';
import Select from 'react-select';
import moment from 'moment';
import TimePicker from 'react-bootstrap-time-picker';
import { RolePicker, DatePickerWithStyle } from '../../components';

export const renderRoleField = ({ color, role, label, id, properties }) => {
  const colorInput = (color || properties.color).input;
  const roleInput = (role || properties.role).input;

  const handleChange = change => {
    // console.log('handleChange renderFields', change);
    roleInput.onChange(change === null ? null : change.label);
    colorInput.onChange(change === null ? null : change.color);
  };

  // console.log(roleInput.value, colorInput.value);
  const selectedRole =
    roleInput.value === ''
      ? null
      : {
          color: colorInput.value,
          value: roleInput.value,
          label: roleInput.value
        };

  // console.log('selectedRole renderFields', selectedRole);

  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <RolePicker id={id} selectedRole={selectedRole} onChange={handleChange} />
    </div>
  );
};

export const renderSwitchField = ({ id, input, label }) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    <div>
      <Switch
        id={id}
        labelText={label}
        value={!!input.value}
        onChange={(event, value) => input.onChange(value)}
      />
    </div>
  </div>
);

export const renderMultiselectField = ({
  id,
  input,
  label,
  options,
  disabled
}) => (
  <div className="row" style={{ marginTop: '10px' }}>
    <div className="col-md-4">
      <input
        type="checkbox"
        id={id}
        style={{ marginRight: '15px', marginTop: '5px' }}
        checked={!Array.isArray(input.value)}
        disabled={disabled}
        onChange={() => input.onChange(Array.isArray(input.value) ? null : [])}
      />
      <label htmlFor={id}>{label}</label>
    </div>
    <div className="col-md-8">
      <Select
        value={
          Array.isArray(input.value)
            ? options.filter(o => input.value.indexOf(o.value) >= 0)
            : null
        }
        closeMenuOnSelect={false}
        isDisabled={disabled}
        onChange={selections =>
          input.onChange(selections ? selections.map(s => s.value) : null)
        }
        options={options}
        isMulti
      />
    </div>
  </div>
);

export const renderSelectField = ({ id, input, label, options, disabled }) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    <Select
      id={id}
      value={
        input.value
          ? options.find(option => option.value === input.value)
          : null
      }
      options={options}
      isDisabled={disabled}
      onChange={selection => input.onChange(selection ? selection.value : null)}
      placeholder={label}
    />
  </div>
);

export const renderDateTimeField = ({ input, id, label, minDate }) => {
  const now = moment();
  const currentMoment = input.value ? moment(input.value) : now;
  const currentHours = currentMoment.hours();

  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginRight: '10px' }}>
          <DatePickerWithStyle
            id={id}
            value={currentMoment.format('YYYY-MM-DDT00:00:00.000Z')}
            onChange={value => {
              const hours = currentMoment.hours();
              const date = moment(value).hours(hours);
              input.onChange(date.toISOString());
            }}
            minDate={minDate || now.toISOString()}
            onKeyDown={() => false}
            className="organization-button"
            //   color={color}
            //   placeholder={placeholder}
            //   placeholderColor={placeholderColor}
            showClearButton={false}
          />
        </div>
        <div style={{ width: '30%' }}>
          <TimePicker
            value={`${currentHours}:00`}
            onChange={value => {
              const hours = parseInt(value / 3600, 10);
              currentMoment.hours(hours);

              input.onChange(currentMoment.toISOString());
            }}
            //   start={
            //     new Date(date).getDate() === now.getDate()
            //       ? `${now.getHours()}:00`
            //       : '00:00'
            //   }
            //   style={{ color }}
            className="organization-button organization-time"
            step={60}
            format={24}
          />
        </div>
      </div>
    </div>
  );
};
