import React, { Component } from 'react';
import Loading from 'react-loading-animation';
import { CSSTransitionGroup } from 'react-transition-group';
import { translate } from 'react-i18next';

import { withLightbox, Message } from '../../../components';

const PAGE_SIZE = 30;

class MessageList extends Component {
  state = {
    loadedIndex: PAGE_SIZE
  };

  componentDidUpdate({ messages }) {
    if (messages.length < this.props.messages.length) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    if (this.messageList) {
      this.messageList.scrollTop = 0;
    }
  };

  loadMoreMessages = () => {
    // never gets zero rounding issues
    const { scrollHeight, scrollTop, clientHeight } = this.messageList;
    if (scrollHeight - scrollTop - clientHeight < 1) {
      this.setState(state => ({
        loadMoreMessages: true,
        loadedIndex: state.loadedIndex + PAGE_SIZE
      }));
    }
  };

  render() {
    const {
      userId,
      t,
      messages,
      style,
      unreadMessages,
      openGallery
    } = this.props;

    const { loadedIndex, loadMoreMessages } = this.state;
    const allMessageLoaded = loadedIndex >= messages.length;

    return (
      <div
        className="message-list"
        onScroll={this.loadMoreMessages}
        ref={m => {
          this.messageList = m;
        }}
      >
        <CSSTransitionGroup
          transitionName="message"
          transitionAppear
          transitionAppearTimeout={500}
          transitionEnterTimeout={200}
          transitionLeave={false}
        >
          {messages.slice(0, loadedIndex).map(message => (
            <Message
              key={message._id}
              openGallery={openGallery}
              fromMe={message.data.user._id === userId} // a boolean that defines if the message was sent from the current use
              data={message.data}
            />
          ))}
        </CSSTransitionGroup>

        <div style={{ textAlign: 'center' }}>
          {loadMoreMessages && !allMessageLoaded && <Loading />}
          {allMessageLoaded && <span>{t('Alle Nachrichen geladen')}</span>}
        </div>
      </div>
    );
  }
}

export default translate('translations')(withLightbox(MessageList));
