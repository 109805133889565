import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import * as Fuse from 'fuse.js';

import moment from 'moment';
import { createSelector } from 'reselect';

import {
  REMOVE,
  EDIT,
  NEW,
  VIEW,
  FILTER,
  FILTER_BY_FINISHED,
  FILTER_BY_IMAGE,
  FILTER_BY_MESSAGE,
  FILTER_INCIDENT,
  FILTER_PATIENT,
  FILTER_CHAT,
  FILTER_CROWD
} from '../../../constants';
import {
  Panel,
  ListHeader,
  List,
  QuestionDialog,
  EditDialog
} from '../../../components';
import { updateDoc } from '../../../Couchbase';
import {
  timeConverter,
  latLngToDgreeString,
  byTimestamp
} from '../../../utils';
import {
  incidentsDialogStyle,
  incidentsDialogStyleFilter,
  incidentsDialogStyleEdit,
  questionDialogStyles
} from '../../../ModalStyles';

import {
  getFilteredBasesCanLogIn,
  getCurrentUser,
  getAppSettings,
  getCurrentEvent,
  getPermissions,
  getFilteredCrowd,
  getFilteredIncidents,
  getFilteredMaterialOrders,
  getFilteredMessages,
  getFilteredPatients
} from '../../../selectors';

import FilterIncidentDialog from './FilterActivityDialog';

import { transformItem } from '../exportHelper';

const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'doc.baseName',
    'doc.eventName',
    'doc.userName',
    'doc.data.label',
    'doc.data.extra'
  ]
};

const Caption = ({ content, name, ts }) => (
  <div>
    <p>{content}</p>
    <span>{name}</span>
    <br />
    {moment(ts * 1000).format('LTS, ll')}
  </div>
);

class ActivityFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchActive: false,
      functionType: undefined,
      activeDoc: undefined,
      filterType: [],
      sortBy: {
        label: props.t('Erstellungsdatum'),
        value: 'newestEvent'
      },
      showExtendedInfo: false
    };
  }

  componentWillReceiveProps({ activities }) {
    // the edit dialog is open, so we need to update the incident doc to have the correct _rev nummer and
    // not create conflicts
    if (this.state.activeDoc) {
      const activeDoc = activities.find(
        i => i._id === this.state.activeDoc._id
      );
      this.setState({ activeDoc });
    }
  }

  setData = () => {
    const rowData = [];
    const { t, activities } = this.props;

    let data = [...activities];

    const { filterType } = this.state;

    // filtering
    if (filterType.length > 0) {
      if (filterType.includes(FILTER_BY_FINISHED)) {
        data = data.filter(i => !i.completed);
      }
      if (filterType.includes(FILTER_BY_IMAGE)) {
        data = data.filter(i => !(i.image === undefined || i.image === ''));
      }
      if (filterType.includes(FILTER_BY_MESSAGE)) {
        data = data.filter(
          i =>
            !(i.messages === undefined || Object.keys(i.messages).length === 0)
        );
      }

      data = data.filter(i => {
        switch (i.className) {
          case 'Incident':
            return !filterType.includes(FILTER_INCIDENT);
          case 'PatientTreatment':
            return !filterType.includes(FILTER_PATIENT);
          case 'ChatMessage':
            return !filterType.includes(FILTER_CHAT);
          case 'CrowdStatus':
            return !filterType.includes(FILTER_CROWD);
          default:
            return true;
        }
      });
    }

    data.forEach(p => {
      let title = `${p.className}`;

      const tsString = timeConverter(p.ts);
      let details = '';

      const baseDoc = this.props.bases.find(b => p.baseId === b._id); // p.baseId == b._id.split(':')[3]

      const highlighted = false;

      const {
        assets: {
          incidentIconUrl,
          cartIconUrl,
          incidentCompletedIconUrl,
          baseIconUrl,
          locationIconUrl,
          patientIconUrl,
          incidentWithMessagesIconUrl
        }
      } = this.props.appSettings;

      let iconUrl;
      let icon;
      let rowAction;
      let handleDrop = () => {};
      switch (p.className) {
        case 'ChatMessage':
          title = '';
          break;
        case 'CrowdStatus':
          iconUrl = baseIconUrl;
          icon = 'users';
          title = `${p.baseName}: ${p.data[0].label}`;
          details += `Level ${p.data[0].value}\n`;
          break;
        case 'Incident':
        case 'PatientTreatment': {
          const isIncident = p.className === 'Incident';
          title = isIncident ? t('Ereignis') : t('Patienten Meldung');
          iconUrl = isIncident ? incidentIconUrl : patientIconUrl;
          icon = isIncident ? 'bell' : 'notes-medical';
          const pData = p.data.map(i => {
            const ti = transformItem(i);
            if (!ti) {
              return '';
            }
            return `${ti.title}: ${ti.value}`;
          });
          details = pData.join('\n') + '\n';
          rowAction = () => this.openModal(p, baseDoc, VIEW);
          handleDrop = payload => this.handleMsgDrop(p._id, payload);
          break;
        }
        case 'MaterialOrder':
          title = t('Material Bestellung');
          details = `${p.data.length} Artikel bestellt`;
          iconUrl = cartIconUrl;
          icon = 'shopping-cart';
          break;
        default:
          break;
      }

      // add the messages if extended view is active
      if (this.state.showExtendedInfo && p.messages) {
        const msgkeys = Object.keys(p.messages);
        msgkeys.forEach(k => {
          details += p.messages[k].text;
          details += p.messages[k].image ? ' (img)' : '';
          details += p.messages[k].location
            ? ` (${latLngToDgreeString([
                p.messages[k].location.latitude,
                p.messages[k].location.longitude
              ])})`
            : '';
          details += '\n';
        });
      }

      details += p.case ? `[${p.case}] ` : '';
      details += `${t('Gemeldet am')} ${tsString}\n`;
      details += `${t('von')} ${p.userName}`;

      let leftIcon = {
        icon
      };

      if (p.className === 'ChatMessage') {
        details = '';
        leftIcon = undefined;
      }

      const row = {
        highlighted,
        title,
        _id: p._id,
        details,
        rowAction,
        handleDrop,
        leftIcon,
        rightIcons: [],
        doc: p
      };

      // add state indicator
      if (p.completed) {
        row.rightIcons.push({
          icon: 'check',
          action: () => {}
        });
      }

      rowData.push(row);
    });
    return rowData;
  };

  openModal = (activeDoc, baseDoc, functionType) => {
    this.setState({
      modalIsOpen: true,
      functionType,
      baseDoc,
      activeDoc
    });
  };

  handleMsgDrop = (id, payload) => {
    const activeDoc = this.props.activities.find(i => i._id === id);
    if (activeDoc) {
      // init the message property if it does not exist
      if (!activeDoc.messages) {
        activeDoc.messages = {};
      }

      // add the message to the incident doc
      activeDoc.messages[payload._id] = payload;

      // send new version to db
      updateDoc(activeDoc, status => {
        if (!status) {
          this.notificationSystem.addNotification({
            message: this.props.t('Nachricht konnte nicht anhangen werden.'),
            level: 'error'
          });
        } else {
          this.notificationSystem.addNotification({
            message: this.props.t('Nachricht erfolgreich angehangen.'),
            level: 'success'
          });
        }
      });
    }
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  closeModalSucces = activeDoc => {
    this.setState({ modalIsOpen: false });

    // switch (this.state.functionType) {
    //   case REMOVE:
    //     if (this.state.activeDoc) {
    //       deleteDoc(this.state.activeDoc, (status) => {
    //         if (status) {
    //           this.setState({ activeDoc: undefined });
    //           this.notificationSystem.addNotification({
    //             message: this.props.t('Ereignis erfolgreich gelöscht'),
    //             level: 'success'
    //           });
    //         }
    //       });
    //     }
    //     break;
    //   case NEW:
    //     updateDoc(activeDoc, (status) => {
    //       if (!status) {
    //         this.notificationSystem.addNotification({
    //           message: this.props.t('Ereignis konnte nicht erstellt werden.'),
    //           level: 'error'
    //         });
    //       } else {
    //         this.notificationSystem.addNotification({
    //           message: this.props.t('Ereignis erfolgreich erstellt.'),
    //           level: 'success'
    //         });
    //       }
    //     });
    //     break;
    //   case EDIT:
    //     updateDoc(activeDoc, (status) => {
    //       if (!status) {
    //         this.notificationSystem.addNotification({
    //           message: this.props.t('Ereignis konnte nicht bearbeitet werden.'),
    //           level: 'error'
    //         });
    //       } else {
    //         this.notificationSystem.addNotification({
    //           message: this.props.t('Ereignis erfolgreich bearbeitet.'),
    //           level: 'success'
    //         });
    //       }
    //     });
    //     break;
    //   default: // create and edit is the same code
    // }
  };

  getFunctionButtons = (appSettings, currentUser) => {
    const res = [
      {
        action: () => {
          this.setState({ searchActive: !this.state.searchActive });
        },
        icon: 'search',
        active: this.state.searchActive,
        tooltip: this.props.t('Aktivitätsfeed durchsuchen')
      },
      {
        action: () => {
          this.openModal(undefined, undefined, FILTER);
        },
        icon: 'filter',
        active: this.state.filterType.length > 0,
        tooltip: this.props.t('Aktivitätsfeed filtern')
      }
    ];

    return res;
  };

  changeSortSettingsChange = ({ filterType, sortBy }) => {
    this.setState({ filterType, sortBy, modalIsOpen: false });
  };

  getModalStyle = () => {
    switch (this.state.functionType) {
      case VIEW:
        return incidentsDialogStyle;
      case EDIT:
        return incidentsDialogStyleEdit;
      case NEW:
        return incidentsDialogStyleEdit;
      case FILTER:
        return incidentsDialogStyleFilter;
      case REMOVE:
        return questionDialogStyles;
      default:
        if (this.state.functionType) {
          console.log(`FunctionType: ${this.state.functionType} not supported`);
        }
        return undefined;
    }
  };

  search = (searchTerm, dataList) => {
    if (searchTerm.length < 2) {
      return dataList;
    }
    const fuse = new Fuse(dataList, SEARCH_OPTIONS);
    return fuse.search(searchTerm);
  };

  handleSearch = searchValue => this.setState({ searchValue });

  render() {
    const { t, permissions, appSettings, currentUser, onClose } = this.props;
    const {
      modalIsOpen,
      functionType,
      activeDoc,
      baseDoc,
      sortBy,
      filterType,
      searchActive,
      searchValue
    } = this.state;

    const rowData = this.setData();
    const currentRowData =
      searchActive && searchValue ? this.search(searchValue, rowData) : rowData;

    const images = currentRowData.reduce(
      (rows, { doc: { data, image, ts, userName } }) => {
        if (data && !Array.isArray(data) && data.image) {
          rows.push({
            src: data.image,
            caption: <Caption content={data.text} ts={ts} name={userName} />
          });
        }
        if (image) {
          rows.push({ src: image });
        }
        return rows;
      },
      []
    );

    return (
      <Panel title={t('Aktivitätsfeed')} id="devices" onClose={onClose}>
        <ListHeader
          functionButtons={this.getFunctionButtons(appSettings, currentUser)}
          count={rowData.length}
          searchActive={searchActive}
          onSearch={this.handleSearch}
        />
        <div
          style={{
            height: searchActive ? 'calc(100% - 120px)' : 'calc(100% - 80px)'
          }}
        >
          <List rowData={currentRowData} images={images} draggable />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={this.getModalStyle()}
          contentLabel="deleteAlert"
        >
          {functionType === REMOVE && (
            <QuestionDialog
              caption={t('Löschen')}
              content={t(
                'Sind sie sicher, dass sie den Eintrag löschen wollen?'
              )}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
            />
          )}
          {functionType === VIEW && (
            <EditDialog
              nextStepsActive={permissions.dashboard.features.nextSteps}
              doc={activeDoc}
              baseDoc={baseDoc}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
              appSettings={appSettings}
              currentUser={currentUser}
            />
          )}

          {functionType === FILTER && (
            <FilterIncidentDialog
              caption={t('Aktivitätsfeed filtern')}
              errorHandler={this.closeModal}
              succesHandler={this.changeSortSettingsChange}
              sortBy={sortBy}
              filterType={filterType}
            />
          )}
        </Modal>
        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />
      </Panel>
    );
  }
}

const getActivities = createSelector(
  getFilteredIncidents,
  getFilteredMessages,
  getFilteredMaterialOrders,
  getFilteredPatients,
  getFilteredCrowd,
  (incidents, chats, orders, patients, crowd) => {
    return []
      .concat(incidents, chats, orders, patients, crowd)
      .sort(byTimestamp);
  }
);

function mapStateToProps(state) {
  return {
    appSettings: getAppSettings(state),
    bases: getFilteredBasesCanLogIn(state),
    currentUser: getCurrentUser(state),
    // form: getIncidentForm(state),
    currentEvent: getCurrentEvent(state),
    permissions: getPermissions(state),
    activities: getActivities(state)
  };
}

export default connect(mapStateToProps)(ActivityFeed);
