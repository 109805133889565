const svg = {};

svg.back = `
<g id="Back">
  <g>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M59.855,40.515c-3.485-0.264-4.68-2.823-4.97-5.137
			c-0.49-3.915,0.414-5.533,3.395-6.637c-0.243-2.702,0.031-5.375,0.868-7.995c1.292-4.044,4.016-6.646,8.034-7.804
			c4.092-1.18,8.229-1.14,12.217,0.452c4.371,1.744,6.613,5.226,7.297,9.749c0.279,1.844,0.277,3.729,0.406,5.627
			c2.615,0.953,4.006,3.49,3.365,6.411c-0.441,2.013-1.041,3.954-3.219,4.859c-1.27,0.528-2.344,1.639-3.059,2.886
			c-0.436,0.759-0.916,1.5-1.436,2.205c-1.982,2.686-1.584,5.285,1.027,7.475c2.613,2.192,5.787,3.115,8.896,4.23
			c2.949,1.059,5.938,2.056,8.789,3.344c3.945,1.781,6.545,4.959,8.316,8.854c1.729,3.799,2.213,7.899,2.822,11.954
			c0.789,5.25,1.447,10.519,2.105,15.787c0.281,2.259,0.496,4.531,0.609,6.804c0.139,2.701,1.408,5.038,2.184,7.536
			c1.227,3.945,2.662,7.862,3.438,11.901c0.84,4.375,1.008,8.878,1.498,13.322c0.303,2.738,0.723,5.463,0.963,8.206
			c0.127,1.441,0.861,2.39,1.973,3.099c4.863,3.104,7.994,7.68,10.752,12.574c0.211,0.375,0.346,0.812,0.426,1.238
			c0.299,1.562-0.869,2.732-2.404,2.301c-0.93-0.26-1.781-0.791-2.832-1.277c0.662,1.707,1.352,3.297,1.895,4.936
			c0.434,1.312,0.766,2.674,0.982,4.039c0.07,0.439-0.252,1.227-0.604,1.381c-0.445,0.195-1.242,0.09-1.641-0.211
			c-0.746-0.564-1.318-1.357-1.953-2.066c-0.305-0.34-0.588-0.701-1.074-1.285c0.107,1.293,0.244,2.273,0.254,3.254
			c0.01,0.896,0.135,1.977-1,2.324c-1.096,0.334-1.791-0.361-2.346-1.23c-0.266-0.418-0.594-0.795-1.064-1.416
			c-0.094,0.695-0.057,1.152-0.23,1.506c-0.213,0.432-0.555,1.023-0.928,1.096c-0.445,0.088-1.135-0.166-1.449-0.514
			c-0.539-0.602-0.867-1.391-1.318-2.158c-0.135,0.121-0.238,0.174-0.279,0.256c-0.361,0.719-0.682,1.572-1.654,1.496
			c-1.082-0.084-1.682-0.9-1.928-1.865c-0.457-1.811-0.775-3.654-1.211-5.471c-0.572-2.396-1.32-4.758-1.766-7.176
			c-0.35-1.896-0.588-3.874-0.451-5.785c0.182-2.521,0.098-4.895-0.967-7.245c-1.74-3.842-3.402-7.724-4.996-11.63
			c-2.072-5.081-3.443-10.352-3.789-15.846c-0.164-2.631-0.996-5.065-1.857-7.515c-1.094-3.115-2.115-6.256-3.174-9.384
			c-0.174-0.515-0.373-1.021-0.561-1.531c-0.121,0.004-0.242,0.009-0.361,0.014c-0.15,1.279-0.354,2.554-0.441,3.837
			c-0.18,2.667-0.408,5.337-0.42,8.007c-0.014,3.637,1.141,7.059,2.301,10.456c1.098,3.209,1.67,6.52,1.561,9.886
			c-0.17,5.266,0.604,10.403,1.557,15.552c0.994,5.364,1.285,10.79,0.74,16.239c-0.43,4.291-0.762,8.596-1.354,12.865
			c-0.676,4.887-1.559,9.746-2.41,14.605c-0.826,4.713-1.697,9.418-2.602,14.115c-0.701,3.639-1.68,7.234-2.172,10.898
			c-0.363,2.699-0.145,5.479-0.182,8.221c-0.053,4.223,0.115,8.463-0.205,12.666c-0.531,7.016-2.561,13.748-4.383,20.514
			c-0.93,3.459-1.736,6.961-2.393,10.482c-0.547,2.939,0.316,5.816,1.178,8.578c1.457,4.668,3.197,9.25,4.785,13.879
			c0.412,1.201,0.787,2.43,1.025,3.674c0.27,1.406-0.215,2.514-1.277,3.613c-1.654,1.715-3.914,1.883-5.797,2.883
			c-1.523,0.807-3.076,0.42-4.539,1.066c-1.592,0.703-3.371-0.932-3.711-3.066c-0.219-1.367-0.588-2.717-0.725-4.092
			c-0.205-2.055-0.514-4.145-0.336-6.182c0.326-3.756-0.08-7.287-1.76-10.707c-1.094-2.227-1.195-4.609-0.279-6.979
			c0.787-2.035,0.703-4.156,0.588-6.277c-0.461-8.457-0.838-16.922-1.412-25.371c-0.33-4.854,0.326-9.609,0.715-14.414
			c0.475-5.834,1-11.666,0.404-17.58c-1.215-12.043-2.314-24.105-2.469-36.227c-0.002-0.193-0.037-0.389-0.055-0.551
			c-0.107-0.072-0.168-0.15-0.234-0.156c-2.098-0.182-2.061-0.18-2.168,1.893c-0.404,7.822-0.762,15.648-1.282,23.465
			c-0.325,4.898-1.106,9.77-1.331,14.67c-0.285,6.195,0.422,12.357,1.052,18.525c0.427,4.184,0.333,8.439,0.218,12.654
			c-0.134,4.912-0.559,9.814-0.832,14.723c-0.197,3.539-0.43,7.08-0.487,10.623c-0.022,1.404,0.192,2.883,0.645,4.211
			c0.74,2.174,0.787,4.299-0.211,6.289c-1.848,3.682-2.083,7.545-1.862,11.543c0.139,2.5,0.23,5.004-0.846,7.424
			c-0.467,1.049-0.323,2.354-0.619,3.5c-0.488,1.895-2.076,2.611-3.897,1.92c-0.393-0.148-0.844-0.156-1.272-0.195
			c-0.356-0.033-0.753,0.08-1.07-0.039c-1.345-0.498-2.667-1.059-3.995-1.6c-0.146-0.061-0.289-0.215-0.422-0.203
			c-1.892,0.162-2.764-1.396-3.668-2.506c-0.624-0.766-0.735-2.184-0.615-3.26c0.148-1.318,0.682-2.621,1.214-3.861
			c2.601-6.057,4.737-12.271,5.742-18.789c0.303-1.969-0.073-4.131-0.535-6.111c-1.185-5.078-2.614-10.098-3.906-15.152
			c-2.17-8.486-3.618-17.09-2.529-25.85c0.657-5.285-0.611-10.221-1.597-15.26c-0.879-4.494-1.693-9.002-2.547-13.502
			c-0.513-2.699-1.173-5.379-1.547-8.098c-1.131-8.221-2.298-16.443-3.168-24.695c-0.559-5.301-0.021-10.609,1.043-15.851
			c1.084-5.342,1.525-10.744,1.494-16.199c-0.02-3.335,0.644-6.589,1.883-9.69c2.134-5.338,2.399-10.832,1.556-16.444
			c-0.218-1.453-0.367-2.917-0.827-4.449c-0.151,0.234-0.361,0.449-0.446,0.705c-0.833,2.526-1.619,5.068-2.474,7.586
			c-0.511,1.505-0.994,3.042-1.715,4.448c-1.089,2.123-0.933,4.435-1.165,6.656c-0.726,6.951-2.921,13.426-5.749,19.757
			c-1.458,3.263-2.708,6.626-3.889,10.001c-0.322,0.918-0.215,2.075-0.023,3.067c0.469,2.426,0.295,4.782-0.521,7.077
			c-1.102,3.1-1.687,6.299-2.115,9.545c-0.124,0.936-0.376,1.916-0.838,2.723c-0.4,0.699-1.127,1.551-1.807,1.648
			c-0.583,0.082-1.304-0.809-2.077-1.35c-0.208,0.326-0.497,0.98-0.967,1.457c-0.429,0.432-1.127,1-1.573,0.9
			c-0.449-0.1-0.773-0.922-1.072-1.473c-0.155-0.285-0.137-0.666-0.243-1.262c-0.456,0.713-0.776,1.201-1.084,1.699
			c-0.533,0.861-1.275,1.342-2.3,1.1c-0.987-0.23-0.935-1.115-0.937-1.852c-0.004-1.139,0.106-2.277,0.187-3.771
			c-0.576,0.719-0.95,1.203-1.344,1.672c-0.434,0.516-0.803,1.156-1.354,1.484c-0.549,0.328-1.406,0.615-1.905,0.4
			c-0.396-0.17-0.726-1.182-0.612-1.719c0.36-1.705,0.912-3.371,1.449-5.033c0.35-1.08,0.804-2.127,1.314-3.457
			c-0.942,0.443-1.597,0.771-2.268,1.062c-0.885,0.383-1.799,0.309-2.464-0.352c-0.673-0.666-0.757-1.611-0.267-2.438
			c1.232-2.078,2.443-4.179,3.824-6.157c2.111-3.023,4.69-5.584,7.821-7.608c0.65-0.42,1.117-1.485,1.232-2.313
			c0.545-3.913,0.994-7.842,1.374-11.775c0.672-6.956,1.427-13.875,4.121-20.434c1.008-2.455,2.003-4.989,2.452-7.583
			c0.7-4.044,0.906-8.172,1.364-12.26c0.303-2.697,0.638-5.393,1.038-8.077c0.487-3.273,1.01-6.543,1.603-9.799
			c1.018-5.595,3.252-10.589,8.149-13.806c2.263-1.486,4.931-2.382,7.466-3.42c3.053-1.25,6.22-2.236,9.226-3.584
			c2.242-1.006,4.046-2.67,4.916-5.094c0.389-1.084,0.37-2.054-0.422-3.119C61.895,44.257,61.016,42.474,59.855,40.515z
			 M132.471,171.795c0.17-0.082,0.34-0.164,0.508-0.246c-0.072-0.496-0.051-1.029-0.229-1.484c-1.406-3.594-2.861-7.166-4.26-10.762
			c-0.143-0.361-0.016-0.828-0.014-1.246c0.363,0.158,0.811,0.232,1.076,0.49c1.057,1.025,1.969,2.223,3.115,3.127
			c0.637,0.504,1.629,0.555,2.461,0.811c-0.131-0.805-0.039-1.744-0.432-2.389c-1.24-2.033-2.578-4.021-4.033-5.908
			c-1.555-2.021-3.252-3.928-5.518-5.24c-1.656-0.959-2.803-2.32-3.023-4.328c-0.137-1.268-0.393-2.524-0.529-3.792
			c-0.568-5.272-1.225-10.54-1.633-15.825c-0.361-4.653-2.115-8.892-3.484-13.253c-0.787-2.5-1.863-4.954-2.279-7.515
			c-0.697-4.289-0.9-8.655-1.441-12.972c-0.602-4.79-1.33-9.565-2.039-14.341c-0.561-3.783-1.627-7.408-3.83-10.585
			c-2.441-3.523-5.99-5.501-9.951-6.857c-3.236-1.107-6.492-2.159-9.684-3.384c-2.443-0.938-4.588-2.371-6.051-4.65
			c-1.541-2.4-1.488-4.4,0.25-6.584c1.158-1.454,2.146-2.98,2.369-4.882c0.08-0.674,0.105-1.359,0.252-2.019
			c0.061-0.276,0.373-0.496,0.572-0.741c0.193,0.259,0.48,0.493,0.561,0.784c0.098,0.361,0.023,0.769,0.023,1.154
			c1.389,0.01,2.281-0.422,2.949-1.363c1.291-1.818,1.25-3.856,0.789-5.869c-0.332-1.455-0.953-1.803-2.424-1.672
			c-0.896,0.08-0.938-0.307-0.947-1.042c-0.029-2.222,0.08-4.485-0.305-6.655c-0.777-4.414-3.346-7.363-7.822-8.493
			c-3.492-0.881-6.949-0.862-10.392,0.203c-3.037,0.939-5.119,2.924-6.398,5.815c-1.294,2.923-1.208,5.997-1.045,9.077
			c0.042,0.802-0.062,1.197-1.019,1.088c-1.305-0.149-2.194,0.497-2.329,1.801c-0.138,1.336-0.171,2.718,0.008,4.044
			c0.25,1.856,1.972,3.149,3.707,2.989c0.007-0.148-0.025-0.318,0.028-0.454c0.181-0.467,0.391-0.921,0.59-1.38
			c0.22,0.436,0.604,0.862,0.631,1.31c0.138,2.276,0.912,4.256,2.381,6.007c0.977,1.163,1.789,2.337,1.234,4.095
			c-1.069,3.39-3.444,5.563-6.508,6.874c-4.018,1.719-8.205,3.037-12.288,4.609c-3.114,1.2-5.679,3.152-7.618,5.913
			c-2.14,3.049-3.197,6.522-3.749,10.114c-1.024,6.662-1.805,13.361-2.755,20.035c-0.698,4.903-0.693,9.856-2.794,14.604
			c-2.615,5.908-3.884,12.267-4.436,18.758c-0.457,5.366-1.179,10.71-1.891,16.05c-0.102,0.767-0.671,1.6-1.26,2.15
			c-1.911,1.782-4.136,3.264-5.855,5.204c-1.966,2.218-3.559,4.776-5.224,7.247c-0.425,0.631-1.123,1.639-0.244,2.209
			c0.428,0.277,1.615-0.188,2.188-0.65c1.201-0.971,2.203-2.182,3.333-3.244c0.258-0.244,0.703-0.291,1.062-0.428
			c-0.005,0.385,0.101,0.814-0.033,1.15c-1.328,3.322-2.718,6.621-4.033,9.949c-0.301,0.764-0.379,1.613-0.56,2.424
			c0.167,0.082,0.335,0.164,0.503,0.246c0.811-1.096,1.728-2.129,2.41-3.301c1.1-1.889,2.017-3.885,3.08-5.799
			c0.23-0.412,0.752-0.664,1.14-0.988c0.053,0.465,0.245,0.967,0.136,1.391c-0.417,1.621-1.027,3.197-1.385,4.828
			c-0.394,1.793-0.591,3.627-0.873,5.443c0.147,0.061,0.295,0.121,0.443,0.18c0.26-0.197,0.601-0.344,0.763-0.602
			c0.443-0.705,0.908-1.422,1.188-2.197c0.757-2.094,1.402-4.227,2.149-6.324c0.117-0.328,0.537-0.549,0.817-0.818
			c0.107,0.365,0.347,0.746,0.302,1.092c-0.312,2.412-0.697,4.814-1.02,7.225c-0.057,0.426,0.067,0.873,0.107,1.311
			c0.535-0.254,0.832-0.535,0.966-0.879c0.433-1.113,0.847-2.236,1.192-3.379c0.437-1.449,0.775-2.928,1.213-4.377
			c0.082-0.27,0.434-0.459,0.662-0.688c0.16,0.279,0.376,0.541,0.46,0.842c0.059,0.207-0.081,0.465-0.105,0.701
			c-0.188,1.865-0.404,3.727-0.528,5.596c-0.032,0.482,0.229,0.986,0.355,1.48c0.126,0.004,0.253,0.01,0.379,0.016
			c0.269-0.469,0.65-0.906,0.783-1.41c0.344-1.309,0.57-2.648,0.857-3.971c0.472-2.174,0.921-4.354,1.444-6.516
			c0.532-2.195,1.542-4.307,0.972-6.665c-0.654-2.704-0.26-5.294,0.813-7.833c2.061-4.874,4.125-9.748,6.103-14.655
			c1.375-3.412,2.175-6.977,2.509-10.651c0.173-1.903,0.254-3.867,0.801-5.675c1.436-4.751,3.163-9.415,4.65-14.152
			c0.566-1.802,1.328-3.785,1.08-5.556c-0.675-4.809-1.843-9.548-2.813-14.316c-0.159-0.78-0.345-1.56-0.429-2.349
			c-0.026-0.249,0.24-0.53,0.373-0.796c0.237,0.165,0.514,0.295,0.699,0.506c0.148,0.168,0.216,0.429,0.266,0.66
			c0.573,2.65,1.211,5.289,1.685,7.957c1.268,7.145,2.705,14.259,3.225,21.528c0.248,3.465-0.277,6.759-1.095,10.049
			c-0.509,2.046-1.494,4-1.797,6.064c-0.526,3.58-0.696,7.213-1.001,10.825c-0.164,1.949-0.198,3.913-0.467,5.846
			c-0.497,3.554-1.249,7.075-1.668,10.636c-0.718,6.104-0.382,12.213,0.354,18.299c0.479,3.961,1.022,7.914,1.596,11.863
			c0.464,3.193,0.992,6.379,1.538,9.559c0.391,2.277,0.837,4.543,1.296,6.807c0.816,4.021,1.655,8.041,2.497,12.059
			c1.017,4.852,2.003,9.703,1.272,14.709c-0.873,5.979-0.261,11.928,0.958,17.752c1.473,7.047,3.488,13.98,5.134,20.994
			c0.531,2.262,0.971,4.635,0.871,6.934c-0.201,4.65-1.763,9.051-3.282,13.416c-0.968,2.779-2.081,5.51-3.173,8.244
			c-0.33,0.826-0.088,1.318,0.686,1.57c2.068,0.67,4.122,1.41,6.229,1.932c2.901,0.719,2.897,0.641,2.567,3.648
			c-0.045,0.41,0.276,1.232,0.417,1.229c0.605-0.01,1.243-0.182,1.792-0.451c0.254-0.123,0.402-0.592,0.478-0.932
			c0.397-1.777,0.905-3.547,1.087-5.35c0.201-1.977,0.143-3.986,0.101-5.98c-0.051-2.436-0.303-4.877,0.625-7.223
			c0.468-1.182,0.94-2.363,1.404-3.547c0.687-1.754,1.049-3.533,0.288-5.357c-0.875-2.094-0.859-4.287-0.75-6.488
			c0.442-8.939,0.913-17.877,1.329-26.816c0.107-2.309,0.205-4.635,0.038-6.934c-0.204-2.816-0.83-5.602-1.03-8.416
			c-0.538-7.59-0.314-15.164,0.422-22.746c0.714-7.354,1.166-14.732,1.649-22.107c0.149-2.283,0.023-4.584,0.023-6.922
			c-0.558,0.322-1.139,0.711-1.763,1.012c-2.912,1.406-5.975,1.639-9.105,1.014c-2.067-0.412-4.119-0.92-6.157-1.459
			c-0.295-0.078-0.478-0.578-0.712-0.881c0.366-0.08,0.759-0.283,1.095-0.213c1.012,0.211,1.999,0.543,3.001,0.803
			c2.732,0.705,5.515,0.982,8.309,0.602c3.34-0.455,5.831-2.141,6.822-5.508c0.367-1.248,0.406-2.592,0.648-3.883
			c0.057-0.303,0.328-0.564,0.5-0.846c0.224,0.271,0.578,0.516,0.645,0.822c0.158,0.738,0.188,1.504,0.258,2.26
			c0.402,4.291,3.494,6.789,7.578,7.203c3.74,0.379,7.303-0.398,10.842-1.455c0.326-0.098,0.738,0.088,1.111,0.143
			c-0.24,0.33-0.414,0.852-0.73,0.959c-1.279,0.436-2.59,0.809-3.914,1.088c-2.904,0.609-5.838,1.1-8.77,0.227
			c-1.459-0.434-2.861-1.061-4.422-1.65c0,0.459-0.02,0.959,0.004,1.457c0.23,5.031,0.395,10.066,0.723,15.09
			c0.359,5.459,0.887,10.908,1.314,16.363c0.23,2.943,0.662,5.902,0.553,8.838c-0.182,4.943-0.816,9.867-1.082,14.811
			c-0.219,4.059-0.369,8.139-0.213,12.197c0.305,7.936,0.896,15.859,1.252,23.793c0.096,2.129,0.146,4.393-0.459,6.391
			c-0.85,2.793-0.742,5.287,0.49,7.871c1.143,2.4,1.709,4.918,1.6,7.637c-0.143,3.531-0.564,7.111,0.764,10.555
			c0.137,0.355,0.008,0.805,0.107,1.184c0.219,0.834,0.348,1.762,0.824,2.426c0.277,0.385,1.166,0.436,1.77,0.412
			c0.172-0.006,0.416-0.795,0.443-1.236c0.043-0.672-0.039-1.359-0.137-2.031c-0.133-0.924,0.236-1.244,1.154-1.367
			c2.648-0.35,5.279-0.766,7.676-2.119c0.887-0.5,1.09-0.906,0.678-1.863c-2.189-5.09-4.227-10.248-5.43-15.67
			c-0.559-2.512-1.201-5.141-1-7.65c0.268-3.346,1.279-6.637,2.043-9.934c1.482-6.387,3.182-12.729,4.473-19.15
			c1.059-5.271,0.822-10.646,0.416-16.002c-0.162-2.141-0.279-4.348,0.057-6.449c0.695-4.365,1.787-8.666,2.574-13.02
			c1.543-8.516,3.072-17.035,4.439-25.58c0.73-4.564,1.182-9.178,1.631-13.781c0.51-5.23,0.426-10.463-0.471-15.657
			c-0.949-5.5-1.951-10.979-1.922-16.607c0.014-2.614-0.279-5.267-0.773-7.836c-0.449-2.332-1.395-4.564-2.061-6.858
			c-1.451-4.996-1.131-10.101-0.398-15.111c1.033-7.086,2.486-14.111,3.787-21.157c0.209-1.133,0.531-2.25,0.871-3.352
			c0.078-0.255,0.445-0.422,0.678-0.629c0.152,0.29,0.332,0.569,0.439,0.875c0.045,0.125-0.076,0.302-0.109,0.459
			c-1.074,5.34-2.152,10.68-3.207,16.024c-0.098,0.495-0.164,1.062-0.023,1.527c1.354,4.454,2.641,8.933,4.18,13.322
			c1.084,3.095,2.086,6.182,2.32,9.46c0.387,5.43,1.895,10.567,3.979,15.553c1.598,3.822,3.248,7.623,4.896,11.423
			c0.898,2.072,1.256,4.211,0.811,6.432c-0.461,2.299-0.111,4.494,0.52,6.713c0.629,2.215,1.092,4.477,1.594,6.725
			c0.279,1.244,0.436,2.516,0.748,3.75c0.211,0.832,0.391,1.748,1.424,2.398c0.129-0.793,0.34-1.381,0.293-1.947
			c-0.148-1.828-0.344-3.654-0.621-5.465c-0.105-0.686-0.172-1.182,0.826-1.391c1.246,3.104,1.473,6.621,3.518,9.457
			c0.166-0.605,0.301-1.201,0.225-1.771c-0.299-2.25-0.688-4.49-0.982-6.742c-0.049-0.371,0.209-0.783,0.324-1.178
			c0.279,0.301,0.686,0.555,0.82,0.91c0.947,2.49,1.799,5.016,2.773,7.494c0.242,0.617,0.826,1.102,1.252,1.646
			c0.199-0.086,0.396-0.174,0.596-0.262c-0.281-1.658-0.479-3.338-0.867-4.973c-0.471-1.977-1.115-3.91-1.646-5.873
			c-0.062-0.234,0.115-0.533,0.18-0.803c0.281,0.068,0.646,0.053,0.824,0.229c0.271,0.268,0.428,0.66,0.615,1.01
			c0.949,1.791,1.838,3.619,2.861,5.367C130.814,169.631,131.686,170.689,132.471,171.795z M84.123,297.582
			c0.129,0.871,0.17,1.629,0.373,2.342c0.098,0.344,0.482,0.824,0.77,0.85c0.586,0.051,1.062-0.266,0.957-1.025
			c-0.107-0.775-0.174-1.555-0.27-2.422C85.314,297.414,84.775,297.49,84.123,297.582z M59.235,297.34
			c-0.045,0.977-0.174,1.854-0.079,2.705c0.034,0.299,0.587,0.629,0.966,0.756c0.144,0.047,0.619-0.41,0.677-0.691
			c0.167-0.809,0.199-1.645,0.292-2.547C60.459,297.486,59.92,297.422,59.235,297.34z M56.667,296.768
			c-0.09,0.893-0.236,1.639-0.203,2.375c0.012,0.277,0.442,0.537,0.682,0.805c0.229-0.209,0.6-0.383,0.663-0.633
			c0.18-0.715,0.235-1.461,0.344-2.227C57.655,296.98,57.27,296.896,56.667,296.768z M87.1,297.1
			c0.141,0.82,0.207,1.529,0.404,2.201c0.072,0.254,0.441,0.422,0.676,0.629c0.223-0.281,0.625-0.559,0.631-0.842
			c0.014-0.73-0.15-1.463-0.256-2.291C88.072,296.898,87.656,296.984,87.1,297.1z M90.305,298.979
			c1.193-0.322,1.4-1.465,0.629-3.016C89.52,296.4,89.52,296.4,90.305,298.979z M55.034,298.957c0.087-0.42,0.18-0.836,0.259-1.256
			c0.079-0.418,0.144-0.84,0.223-1.309c-0.434-0.166-0.821-0.312-1.218-0.465C53.639,298.338,53.713,298.656,55.034,298.957z
			 M93.283,294.887c-1.756,0.59-0.76,1.496-0.729,2.229c0.117,0.021,0.234,0.041,0.354,0.061
			C93.023,296.471,93.139,295.764,93.283,294.887z M52.359,297.082c0.139-0.025,0.278-0.049,0.417-0.072
			c0.062-0.721,0.861-1.607-0.742-2.145C52.159,295.721,52.259,296.4,52.359,297.082z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M59.979,219.014c1.013,0.193,2.11,0.381,3.194,0.627
			c0.21,0.047,0.368,0.324,0.549,0.494c-0.261,0.107-0.567,0.363-0.776,0.301c-2.219-0.674-4.418-0.461-6.633,0.004
			c-0.279,0.059-0.615-0.154-0.924-0.24c0.27-0.197,0.515-0.516,0.812-0.574C57.411,219.385,58.638,219.223,59.979,219.014z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M89.348,220.506c-2.516-0.514-4.787-0.768-7.074-0.059
			c-0.16,0.051-0.416-0.209-0.627-0.324c0.182-0.168,0.348-0.463,0.545-0.486c1.527-0.18,3.059-0.383,4.592-0.426
			c0.818-0.021,1.65,0.227,2.461,0.42c0.242,0.057,0.432,0.334,0.645,0.512C89.654,220.299,89.422,220.457,89.348,220.506z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M65.199,77.544c-0.188-0.297-0.462-0.571-0.549-0.896
			c-0.329-1.227-0.611-2.467-0.874-3.71c-0.041-0.19,0.11-0.42,0.172-0.631c0.2,0.083,0.544,0.125,0.579,0.253
			c0.431,1.582,0.816,3.176,1.213,4.766C65.561,77.399,65.38,77.472,65.199,77.544z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M64.372,272.035c0.791,0.51,1.594,1.002,2.36,1.549
			c0.106,0.076,0.028,0.41,0.035,0.625c-0.206-0.029-0.503,0.02-0.604-0.1c-0.92-1.08-2.043-1.031-3.268-0.711
			c-0.267,0.07-0.6-0.113-0.902-0.18c0.22-0.236,0.4-0.59,0.67-0.686c0.477-0.168,1.009-0.178,1.519-0.254
			C64.245,272.197,64.308,272.115,64.372,272.035z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M83.328,273.664c-1.793-0.559-3.422-0.906-4.682,1.021
			c-0.15-1.299,0.584-2.104,1.672-2.309C81.877,272.086,83.025,272.484,83.328,273.664z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M80.293,77.727c-0.318-0.361-0.516-0.491-0.498-0.579
			c0.309-1.548,0.635-3.092,0.988-4.629c0.027-0.119,0.312-0.269,0.453-0.246c0.133,0.022,0.354,0.279,0.328,0.384
			C81.18,74.278,80.76,75.891,80.293,77.727z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M84.975,137.106c-1.436,0.948-2.713,1.791-3.992,2.635
			C81.275,138.169,83.83,136.549,84.975,137.106z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M60.576,136.961c1.024-0.283,3.164,1.044,3.963,2.37
			C63.908,139.627,61.794,138.386,60.576,136.961z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M105.889,108.556c0.152,0.211,0.408,0.407,0.439,0.635
			c0.129,0.94,0.209,1.889,0.26,2.837c0.01,0.181-0.195,0.374-0.301,0.561c-0.178-0.122-0.494-0.227-0.512-0.369
			c-0.148-1.184-0.242-2.375-0.352-3.564C105.578,108.623,105.732,108.589,105.889,108.556z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M86.244,136.253c0.125-0.206,0.211-0.546,0.383-0.597
			c0.943-0.28,1.904-0.508,2.869-0.707c0.164-0.034,0.375,0.16,0.562,0.249c-0.104,0.183-0.174,0.489-0.316,0.528
			c-1.086,0.298-2.186,0.544-3.281,0.807C86.389,136.44,86.316,136.347,86.244,136.253z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M58.918,136.566c-1.277-0.331-2.554-0.661-3.831-0.991
			c0.888-0.791,3.26-0.244,3.957,0.579C59.002,136.291,58.96,136.428,58.918,136.566z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M79.512,83.869c0-1.212,0-2.424,0-3.786c0.727,0.692,0.832,2.819,0.332,3.795
			C79.732,83.875,79.621,83.872,79.512,83.869z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M39.696,108.601c0.266,1.34-0.069,2.624-0.471,3.9
			c-0.135-0.013-0.271-0.025-0.406-0.038c0.116-1.304,0.231-2.608,0.347-3.912C39.343,108.567,39.52,108.584,39.696,108.601z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M31.45,114.414c-0.164-0.37-0.374-0.609-0.333-0.792
			c0.163-0.723,0.379-1.437,0.635-2.132c0.058-0.159,0.35-0.231,0.535-0.343c0.085,0.154,0.266,0.327,0.239,0.458
			c-0.148,0.728-0.33,1.451-0.542,2.163C31.929,113.954,31.721,114.094,31.45,114.414z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M32.658,110.009c0.055-1.203,0.848-2.351,2.197-2.72
			c-0.717,1.158-1.277,2.061-1.836,2.964C32.898,110.172,32.778,110.091,32.658,110.009z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M112.623,111.263c1.309,0.549,1.701,2.102,0.941,3.183
			C113.215,113.265,112.918,112.264,112.623,111.263z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M65.741,79.97c0,1.44,0,2.646,0,3.873C64.934,83.469,64.82,81.078,65.741,79.97z
			"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M112.814,109.626c-0.154,0.205-0.219,0.379-0.334,0.42
			c-0.123,0.045-0.373,0.02-0.43-0.066c-0.482-0.718-0.941-1.452-1.377-2.198c-0.043-0.075,0.08-0.32,0.188-0.392
			c0.109-0.072,0.385-0.089,0.434-0.021C111.814,108.103,112.305,108.859,112.814,109.626z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M82.014,92.949c-0.408-0.819-0.758-1.472-1.053-2.147
			c-0.074-0.168-0.014-0.422,0.055-0.611c0.023-0.062,0.377-0.094,0.396-0.052c0.385,0.787,0.746,1.585,1.088,2.393
			C82.516,92.572,82.289,92.715,82.014,92.949z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M64.209,88.98c0.186-0.878,0.329-1.705,0.561-2.504
			c0.04-0.137,0.593-0.303,0.683-0.218C65.948,86.73,65.096,88.811,64.209,88.98z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M62.819,92.847c-0.117-1.223,0.635-1.957,1.117-2.93
			C64.511,90.922,64.003,92.201,62.819,92.847z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M81.131,89.091c-1.191-0.461-1.6-2.114-0.789-3.157
			C80.621,87.054,80.854,87.984,81.131,89.091z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M107.084,116.565c-0.283-0.699-0.584-1.392-0.83-2.103
			c-0.039-0.107,0.135-0.375,0.27-0.431c0.127-0.053,0.449,0.035,0.48,0.129c0.24,0.732,0.43,1.481,0.637,2.226
			C107.455,116.446,107.27,116.506,107.084,116.565z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M92.082,135.19c0.85-0.967,1.752-0.985,2.633,0
			C93.773,135.19,92.982,135.19,92.082,135.19z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M50.626,135.23c0.797-1.064,1.793-1.042,2.459,0
			C52.272,135.23,51.543,135.23,50.626,135.23z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M30.943,117.704c0-0.632,0-1.263,0-1.896c0.112-0.039,0.223-0.079,0.335-0.118
			c0.144,0.268,0.409,0.536,0.406,0.801c-0.005,0.449-0.18,0.896-0.284,1.344C31.248,117.792,31.096,117.748,30.943,117.704z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M113.662,115.556c1.178,0.556,0.85,1.344,0.779,2.031
			c-0.008,0.078-0.277,0.21-0.383,0.178c-0.152-0.046-0.369-0.212-0.379-0.341C113.637,116.879,113.662,116.329,113.662,115.556z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M84.564,95.704c-0.309-0.14-0.721-0.202-0.906-0.439
			c-0.264-0.34-0.365-0.809-0.535-1.222c0.137-0.068,0.271-0.137,0.408-0.205c0.416,0.479,0.832,0.959,1.248,1.439
			C84.709,95.418,84.637,95.561,84.564,95.704z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M60.976,95.913c-0.208-0.343-0.298-0.446-0.28-0.47
			c0.373-0.512,0.758-1.015,1.14-1.52c0.124,0.129,0.372,0.345,0.351,0.375C61.825,94.822,61.429,95.323,60.976,95.913z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M108.666,119.095c-0.312-0.416-0.637-0.826-0.916-1.264
			c-0.02-0.032,0.281-0.27,0.432-0.412c0.326,0.424,0.652,0.849,0.979,1.273C108.996,118.827,108.832,118.961,108.666,119.095z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M38.878,114.256c-0.203,0.715-0.405,1.43-0.607,2.145
      c-0.161-0.042-0.322-0.084-0.483-0.126c0.194-0.721,0.387-1.441,0.581-2.163C38.539,114.161,38.708,114.208,38.878,114.256z"/>
  </g>
</g>
`;

svg.front = `
<g id="Front">
  <g>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M47.998,100.193c-0.543,1.649-1.068,3.306-1.633,4.948
			c-1.062,3.084-2.29,6.12-3.169,9.254c-0.577,2.054-0.683,4.244-0.963,6.377c-0.553,4.205-1.436,8.319-3.099,12.245
			c-2.031,4.793-4.035,9.598-6.017,14.41c-0.727,1.766-0.645,3.627-0.213,5.428c0.426,1.775,0.202,3.359-0.665,4.955
			c-1.703,3.137-1.833,6.646-2.263,10.086c-0.158,1.26-0.333,2.566-0.809,3.725c-0.323,0.787-1.083,1.658-1.84,1.908
			c-0.446,0.146-1.269-0.844-2.041-1.41c0,0-0.193,0.221-0.341,0.469c-0.283,0.477-0.448,1.072-0.836,1.424
			c-0.367,0.334-1.02,0.654-1.435,0.547c-0.404-0.105-0.729-0.727-0.967-1.184c-0.166-0.318-0.124-0.742-0.214-1.406
			c-0.533,0.697-0.916,1.174-1.274,1.67c-0.53,0.732-1.189,1.254-2.127,0.969c-0.948-0.289-1.033-1.145-1.008-1.965
			c0.033-1.105,0.131-2.211,0.22-3.604c-0.779,0.951-1.371,1.715-2.011,2.438c-0.338,0.383-0.722,0.752-1.152,1.021
			c-1.123,0.701-2.208,0.166-2.079-1.129c0.136-1.365,0.488-2.73,0.914-4.041c0.492-1.512,1.176-2.959,1.757-4.441
			c0.125-0.322,0.167-0.676,0.326-1.348c-0.651,0.559-1.058,0.908-1.465,1.258c-0.967,0.828-2.404,1.029-3.214,0.453
			c-0.791-0.562-1.122-1.732-0.39-2.947c1.555-2.58,3.261-5.074,5.007-7.531c1.607-2.262,3.675-4.06,6.056-5.506
			c0.952-0.578,1.605-1.432,1.734-2.6c0.417-3.77,0.923-7.531,1.235-11.309c0.68-8.231,2.067-16.305,5.317-23.942
			c1.197-2.812,1.518-5.735,1.868-8.693c0.755-6.379,1.434-12.771,2.349-19.127c0.544-3.778,1.266-7.56,2.286-11.235
			c1.614-5.813,5.389-9.842,11.064-11.994c3.528-1.337,7.106-2.543,10.611-3.938c1.944-0.774,3.61-2.021,4.802-3.816
			c1.241-1.871,1.01-3.55-0.39-5.265c-1.095-1.341-1.884-2.931-2.83-4.396c-0.202-0.313-0.457-0.751-0.757-0.817
			c-2.87-0.632-3.905-2.809-4.203-5.321c-0.162-1.359,0.228-2.837,0.612-4.191c0.388-1.365,1.576-1.917,3.121-2.028
			c0-1.242-0.159-2.494,0.035-3.688c0.36-2.225,0.66-4.52,1.467-6.597c1.345-3.461,4.332-5.069,7.824-5.802
			c3.369-0.707,6.75-0.691,10.117,0.007c4.244,0.88,7.535,3.309,8.535,7.838c0.588,2.667,0.744,5.431,1.109,8.274
			c2.531,0.2,3.094,2.201,3.373,4.429c0.275,2.216-0.688,5.935-3.131,6.654c-1.299,0.382-1.844,1.163-2.35,2.297
			c-0.48,1.075-1.156,2.142-1.992,2.96c-1.395,1.365-1.082,2.85-0.506,4.29c0.998,2.503,3.051,4.078,5.43,5.087
			c3.037,1.287,6.164,2.364,9.277,3.46c4.074,1.436,7.67,3.514,10.082,7.242c1.955,3.023,3.133,6.359,3.693,9.89
			c0.926,5.831,1.826,11.669,2.586,17.523c0.537,4.155,0.738,8.354,1.268,12.51c0.188,1.457,0.922,2.841,1.379,4.267
			c1.357,4.244,2.92,8.439,3.979,12.757c0.725,2.961,0.734,6.096,1.078,9.152c0.494,4.403,1.012,8.804,1.492,13.208
			c0.135,1.237,0.637,2.216,1.707,2.867c4.76,2.89,7.721,7.384,10.633,11.918c0.215,0.332,0.363,0.711,0.514,1.078
			c0.543,1.322,0.41,2.416-0.346,2.979c-0.889,0.66-2.342,0.443-3.387-0.5c-0.35-0.316-0.709-0.621-1.318-0.811
			c0.346,0.953,0.701,1.904,1.035,2.861c0.619,1.766,1.225,3.535,1.836,5.303c0.039,0.113,0.088,0.23,0.094,0.346
			c0.041,0.828,0.297,1.799-0.623,2.266c-0.885,0.449-1.617-0.135-2.207-0.77c-0.742-0.801-1.439-1.645-2.387-2.734
			c0.086,1.406,0.186,2.451,0.205,3.5c0.014,0.811-0.055,1.68-1.021,1.951c-0.992,0.279-1.674-0.271-2.209-1.051
			c-0.324-0.471-0.66-0.934-1.111-1.57c-0.201,0.779-0.264,1.33-0.482,1.809c-0.506,1.1-1.527,1.215-2.234,0.242
			c-0.48-0.658-0.836-1.406-1.242-2.107c-0.158,0.07-0.264,0.082-0.285,0.131c-0.348,0.779-0.762,1.791-1.701,1.516
			c-0.705-0.207-1.551-1.033-1.727-1.734c-0.588-2.352-1.104-4.756-1.268-7.166c-0.18-2.625-1.107-4.963-2.113-7.297
			c-0.574-1.33-0.875-2.595-0.5-4.05c0.691-2.689,0.227-5.224-0.898-7.787c-2.299-5.242-4.592-10.506-6.467-15.906
			c-1.072-3.086-1.527-6.438-1.875-9.71c-0.363-3.409-1.318-6.604-2.451-9.794c-1.025-2.887-1.93-5.817-2.896-8.724
			c-0.137-0.407-0.33-0.794-0.572-1.369c-0.176,0.419-0.334,0.652-0.369,0.903c-0.75,5.313-1.389,10.639-0.178,15.963
			c0.402,1.776,1.057,3.495,1.602,5.238c1.027,3.291,1.717,6.671,1.652,10.104c-0.104,5.434,0.65,10.739,1.631,16.057
			c0.951,5.162,1.332,10.396,0.705,15.634c-0.965,8.043-1.916,16.09-3.098,24.102c-0.754,5.125-1.898,10.193-2.893,15.281
			c-0.916,4.697-2.027,9.363-2.73,14.092c-0.363,2.453-0.008,5.01-0.008,7.52c0.002,3.867,0.207,7.752-0.084,11.6
			c-0.607,8.035-2.955,15.727-5.047,23.465c-0.756,2.795-1.375,5.639-1.861,8.494c-0.514,3.027,0.33,5.986,1.213,8.814
			c1.518,4.854,3.41,9.588,5.051,14.406c0.518,1.516,1.311,3.117,0.623,4.75c-0.748,1.779-1.896,3.232-4.021,3.572
			c-0.469,0.074-0.861,0.57-1.336,0.703c-1.521,0.424-3.064,0.771-4.602,1.135c-0.27,0.064-0.592-0.006-0.82,0.115
			c-2.176,1.143-4.084-0.287-4.395-2.75c-0.5-3.938-1.453-7.797-1.352-11.852c0.068-2.768-0.68-5.619-1.441-8.328
			c-0.891-3.168-1.496-6.139-0.311-9.445c0.695-1.943,0.625-4.252,0.52-6.377c-0.328-6.607-0.949-13.203-1.242-19.811
			c-0.203-4.613-0.471-9.289,0.055-13.85c0.607-5.285,1.043-10.572,1.203-15.871c0.098-3.256-0.377-6.531-0.639-9.795
			c-0.375-4.697-0.895-9.385-1.166-14.088c-0.348-6.023-0.508-12.055-0.748-18.084c-0.02-0.479,0.008-0.965-0.07-1.434
			c-0.023-0.146-0.309-0.316-0.496-0.346c-0.117-0.018-0.344,0.178-0.394,0.322c-0.074,0.217-0.038,0.473-0.046,0.713
			c-0.164,5.512-0.196,11.033-0.528,16.535c-0.346,5.738-0.93,11.465-1.502,17.188c-0.499,4.98-0.772,9.936-0.227,14.945
			c0.569,5.232,1.107,10.492,1.19,15.748c0.08,5.182-0.401,10.373-0.683,15.559c-0.266,4.898-0.648,9.791-0.854,14.693
			c-0.059,1.367,0.088,2.84,0.554,4.113c1.112,3.035,0.718,5.928-0.185,8.896c-1.106,3.637-1.844,7.34-1.679,11.191
			c0.062,1.457-0.186,2.938-0.375,4.395c-0.21,1.615-0.49,3.221-0.789,4.822c-0.385,2.064-1.921,4.234-4.541,2.877
			c-0.29-0.15-0.726,0.014-1.061-0.084c-1.59-0.463-3.171-0.961-4.75-1.463c-0.292-0.092-0.547-0.344-0.839-0.385
			c-2.142-0.295-3.296-1.773-4.034-3.562c-0.69-1.674,0.066-3.33,0.68-4.852c2.041-5.064,3.87-10.188,5.338-15.455
			c1.245-4.469,0.941-8.775-0.22-13.191c-1.871-7.115-3.726-14.246-5.215-21.445c-1.149-5.559-1.356-11.26-0.638-16.92
			c0.508-4.004-0.021-7.871-0.872-11.756c-0.956-4.363-1.838-8.744-2.687-13.129c-0.917-4.736-1.867-9.469-2.602-14.234
			c-0.845-5.475-1.508-10.98-2.181-16.48c-0.7-5.727-1.021-11.473-0.274-17.22c0.34-2.609,0.757-5.214,1.264-7.796
			c0.601-3.062,0.987-6.136,0.868-9.255c-0.177-4.641,0.349-9.19,1.961-13.551c1.161-3.14,1.975-6.353,1.922-9.676
			c-0.054-3.427-0.468-6.849-0.743-10.271c-0.027-0.341-0.18-0.672-0.274-1.007C48.166,100.21,48.082,100.202,47.998,100.193z
			 M90.314,294.814c0.209-0.037,0.42-0.072,0.629-0.107c0.283,0.762,0.568,1.521,0.852,2.283c0.391-0.414,0.666-0.867,0.605-1.27
			c-0.211-1.408-0.305-2.902-0.863-4.18c-2.137-4.863-3.848-9.861-5.164-14.998c-0.773-3.018-1.521-6.053-0.773-9.201
			c1.018-4.295,1.953-8.615,3.107-12.875c1.492-5.508,2.783-11.045,3.537-16.705c0.711-5.35,0.305-10.682-0.059-16.029
			c-0.133-1.965,0.111-3.992,0.459-5.943c0.725-4.08,1.66-8.123,2.459-12.191c0.898-4.578,1.787-9.16,2.596-13.756
			c0.703-4.008,1.355-8.029,1.908-12.061c0.537-3.914,0.953-7.846,1.361-11.775c0.574-5.551,0.613-11.103-0.396-16.608
			c-1.037-5.665-2.012-11.329-1.865-17.115c0.1-3.861-0.441-7.627-1.885-11.197c-1.527-3.778-2.143-7.688-1.842-11.696
			c0.328-4.375,0.754-8.759,1.473-13.083c0.969-5.816,2.246-11.581,3.436-17.359c0.086-0.416,0.531-0.756,0.811-1.132
			c0.135,0.467,0.461,0.972,0.377,1.396c-0.961,4.847-2.039,9.67-2.955,14.525c-0.252,1.337-0.387,2.848-0.021,4.121
			c1.182,4.129,2.551,8.208,3.98,12.26c1.051,2.977,2.035,5.966,2.254,9.122c0.354,5.064,1.602,9.898,3.525,14.565
			c1.727,4.194,3.553,8.348,5.316,12.525c0.809,1.918,1.059,3.879,0.693,5.973c-0.227,1.299-0.342,2.738-0.02,3.987
			c0.564,2.182,1.494,4.272,2.307,6.385c0.107,0.277,0.422,0.652,0.664,0.672c2.613,0.207,5.193,0.152,7.518-1.326
			c1.191-0.758,1.387-0.676,2.055,0.588c1.211,2.285,2.396,4.586,3.693,6.82c0.455,0.779,0.932,1.691,2.154,2.072
			c-0.133-0.764-0.141-1.338-0.334-1.838c-1.322-3.412-2.66-6.818-4.07-10.193c-0.137-0.326-0.814-0.627-1.225-0.605
			c-2.367,0.117-4.084-0.883-5.238-2.882c-0.193-0.336-0.119-0.826-0.168-1.244c0.361,0.186,0.855,0.279,1.062,0.574
			c1.004,1.422,2.18,2.363,4.068,2.212c1.807-0.145,3.221,0.59,4.219,2.17c0.396,0.627,0.994,1.141,1.557,1.643
			c0.627,0.562,1.32,1.498,2.189,0.791c0.293-0.24,0.049-1.521-0.305-2.113c-2.645-4.419-5.469-8.728-9.984-11.511
			c-1.781-1.098-2.541-2.676-2.762-4.623c-0.732-6.463-1.551-12.92-2.131-19.396c-0.459-5.123-2.555-9.752-3.955-14.592
			c-0.424-1.465-1.203-2.858-1.432-4.344c-0.553-3.584-0.852-7.206-1.297-10.807c-0.4-3.248-0.834-6.492-1.295-9.731
			c-0.797-5.6-1.254-11.275-3.529-16.56c-1.732-4.025-4.58-6.958-8.68-8.551c-3.223-1.252-6.529-2.292-9.746-3.562
			c-4.166-1.646-7.73-3.964-8.682-9.118c-5.17,4.213-10.447,4.719-15.843,0.523c-0.288,0.964-0.429,1.709-0.728,2.384
			c-1.067,2.416-2.977,4.093-5.312,5.093c-3.458,1.482-7.027,2.714-10.57,3.99c-5.449,1.963-9.114,5.567-10.988,11.13
			c-1.249,3.708-1.772,7.529-2.308,11.345c-0.92,6.561-1.618,13.152-2.43,19.728c-0.225,1.817-0.304,3.693-0.836,5.423
			c-0.886,2.879-2.096,5.657-3.146,8.486c-2.042,5.502-2.25,11.321-2.914,17.06c-0.449,3.886-0.844,7.778-1.269,11.668
			c-0.212,1.938-1.077,3.47-2.8,4.474c-0.513,0.298-0.993,0.656-1.477,1.004c-2.572,1.843-4.341,4.425-6.196,6.902
			c-1.042,1.39-1.842,2.97-2.658,4.515c-0.171,0.324,0.064,0.863,0.112,1.305c0.403-0.061,0.918,0.018,1.189-0.203
			c0.948-0.775,1.964-1.543,2.672-2.52c1.016-1.396,2.28-2.117,3.943-1.994c2.02,0.15,3.303-0.839,4.353-2.396
			c0.16-0.238,0.605-0.285,0.918-0.42c0.002,0.355,0.141,0.793-0.014,1.054c-1.177,1.977-2.792,3.276-5.254,3.049
			c-0.783-0.072-1.22,0.176-1.533,0.953c-0.996,2.473-2.143,4.887-3.11,7.371c-0.519,1.334-0.791,2.764-1.174,4.15
			c0.136,0.055,0.273,0.109,0.409,0.166c3.139-2.68,4.055-6.85,6.438-10.168c2.452,1.906,5.167,2.25,8.028,2.145
			c0.922-0.033,1.206-0.402,1.424-1.205c0.385-1.42,0.769-2.869,1.406-4.186c0.905-1.867,1.243-3.629,0.737-5.74
			c-0.352-1.467-0.261-3.266,0.271-4.675c1.885-4.989,4.078-9.86,6.089-14.803c1.493-3.669,2.49-7.478,2.934-11.425
			c0.245-2.178,0.259-4.437,0.885-6.506c1.556-5.139,3.458-10.173,5.099-15.289c0.451-1.406,0.859-3.016,0.621-4.42
			c-0.917-5.408-2.116-10.769-3.165-16.156c-0.071-0.366,0.236-0.805,0.368-1.209c0.247,0.292,0.642,0.551,0.717,0.882
			c0.797,3.525,1.601,7.051,2.292,10.598c1.298,6.658,2.618,13.315,2.816,20.131c0.109,3.746-0.308,7.39-1.595,10.944
			c-1.252,3.459-2.071,7.002-2.193,10.736c-0.146,4.454-0.317,8.949-1.042,13.332c-1.001,6.053-1.915,12.086-1.453,18.218
			c0.359,4.773,0.917,9.539,1.553,14.285c0.619,4.617,1.412,9.211,2.199,13.803c0.672,3.926,1.417,7.842,2.18,11.75
			c0.725,3.717,1.487,7.426,2.283,11.129c0.684,3.184,1.068,6.383,0.732,9.635c-0.743,7.207-0.491,14.367,1.185,21.426
			c1.498,6.307,3.244,12.557,4.713,18.869c0.573,2.461,1.117,5.059,0.93,7.539c-0.449,5.963-2.59,11.539-4.728,17.082
			c-0.744,1.928-1.52,3.852-2.114,5.828c-0.277,0.92-0.402,1.998,0.399,3.086c0.388-0.926,0.695-1.662,1.003-2.396
			c0.145,0.037,0.289,0.074,0.433,0.111c-0.045,0.928-0.037,1.861-0.149,2.779c-0.126,1.033,0.334,1.262,1.187,1.131
			c0.271-1.072,0.524-2.078,0.824-3.268c1.052,0.762,0.624,1.559,0.631,2.246c0.008,0.73-0.642,1.828,0.54,1.982
			c1.165,0.152,0.783-1.125,1.035-1.787c0.236-0.621,0.369-1.283,0.547-1.926c0.136,0.021,0.272,0.041,0.409,0.062
			c0.045,0.406,0.137,0.816,0.126,1.223c-0.02,0.758-0.067,1.518-0.161,2.27c-0.102,0.814,0.399,1.176,1.025,1.113
			c0.32-0.033,0.72-0.59,0.843-0.984c0.224-0.715,0.238-1.494,0.399-2.234c0.07-0.322,0.285-0.615,0.434-0.92
			c0.142,0.033,0.284,0.066,0.426,0.098c-0.059,1.047-0.163,2.094-0.155,3.139c0.003,0.406,0.194,1.111,0.409,1.156
			c0.524,0.109,1.218,0.072,1.645-0.209c0.4-0.262,0.667-0.889,0.777-1.4c0.316-1.479,0.529-2.98,0.77-4.475
			c0.165-1.02,0.478-2.049,0.435-3.062c-0.212-4.926,0.561-9.695,2.114-14.352c0.87-2.607,0.73-5.143-0.201-7.725
			c-0.412-1.141-0.659-2.43-0.606-3.635c0.24-5.494,0.613-10.984,0.938-16.475c0.191-3.229,0.444-6.457,0.558-9.689
			c0.161-4.553,0.075-9.082-0.556-13.629c-0.575-4.145-0.785-8.365-0.795-12.555c-0.01-3.783,0.444-7.57,0.735-11.352
			c0.389-5.057,0.925-10.105,1.202-15.168c0.306-5.584,0.396-11.18,0.553-16.771c0.01-0.316-0.129-0.738-0.353-0.941
			c-2.262-2.047-4.629-3.982-6.819-6.104c-1.74-1.684-3.292-3.564-4.882-5.398c-0.19-0.221-0.1-0.684-0.139-1.035
			c0.336,0.072,0.798,0.028,0.99,0.236c1.621,1.74,3.114,3.607,4.8,5.281c2.023,2.006,4.158,3.91,6.33,5.758
			c0.957,0.814,2.941,0.619,3.938-0.117c4.029-2.982,7.234-6.793,10.553-10.49c0.359-0.402,0.635-1.232,1.348-0.623
			c0.152,0.128-0.113,1.009-0.408,1.353c-1.369,1.602-2.75,3.203-4.25,4.682c-2.068,2.039-4.158,4.08-6.424,5.889
			c-0.865,0.691-1.229,1.252-1.184,2.293c0.236,5.51,0.344,11.027,0.668,16.531c0.322,5.502,0.652,11.016,1.334,16.479
			c0.75,5.994,0.623,11.959,0.205,17.945c-0.318,4.562-1.061,9.129-0.969,13.682c0.172,8.619,0.793,17.232,1.258,25.846
			c0.162,3.027,0.555,6.057-0.629,8.988c-1.021,2.531-0.469,4.99,0.285,7.48c1.186,3.904,1.994,7.871,1.9,12
			c-0.039,1.699,0.236,3.412,0.432,5.111c0.104,0.9,0.305,1.795,0.523,2.68c0.217,0.873,0.373,1.812,0.828,2.555
			c0.227,0.367,1.068,0.439,1.635,0.455c0.145,0.004,0.41-0.713,0.439-1.111c0.055-0.754-0.045-1.518-0.033-2.275
			c0.006-0.344,0.121-0.686,0.188-1.029c0.117-0.008,0.232-0.018,0.35-0.027c0.125,0.303,0.301,0.594,0.363,0.906
			c0.156,0.783,0.195,1.592,0.412,2.354c0.1,0.355,0.486,0.863,0.768,0.881c0.596,0.037,1.193-0.193,1.098-1.051
			c-0.09-0.793-0.15-1.592-0.164-2.391c-0.008-0.406,0.117-0.812,0.184-1.221c0.143,0.004,0.287,0.008,0.432,0.01
			c0.211,0.955,0.365,1.928,0.662,2.855c0.115,0.357,0.553,0.613,0.844,0.914c0.23-0.396,0.613-0.779,0.656-1.197
			c0.064-0.619-0.164-1.264-0.164-1.898c0-0.301,0.236-0.604,0.365-0.906c0.199,0.248,0.492,0.465,0.58,0.746
			c0.236,0.74,0.385,1.508,0.586,2.346c0.824-0.158,1.332-0.346,1.131-1.318C90.406,296.637,90.398,295.717,90.314,294.814z
			 M59.03,29.966c0.087,0.003,0.174,0.006,0.261,0.009c0,1.599,0.018,3.197-0.003,4.795c-0.066,4.906,1.883,8.854,5.812,11.726
			c3.035,2.219,6.293,3.865,10.223,2.244c5.086-2.096,8.684-5.581,9.602-11.144c0.477-2.894,0.439-5.893,0.414-8.842
			c-0.02-2.503-0.172-5.035-0.59-7.499c-0.584-3.434-2.582-5.93-5.975-6.984c-3.686-1.146-7.455-1.152-11.254-0.455
			c-5.378,0.987-7.482,4.192-8.073,8.862C59.143,25.087,59.159,27.536,59.03,29.966z M18.545,173.248
			c2.446-2.51,3.054-5.834,4.099-9.027c-0.753-0.328-1.425-0.617-2.264-0.98C19.52,166.605,18.252,169.736,18.545,173.248z
			 M126.773,173.188c0.381-3.502-0.779-6.648-1.807-9.939c-0.811,0.365-1.479,0.666-2.186,0.984
			C123.82,167.441,124.428,170.691,126.773,173.188z M57.715,30.086c-0.984-0.233-1.488,0.312-1.792,1.047
			c-0.823,1.984-0.723,3.972,0.224,5.884c0.399,0.806,0.972,1.516,1.943,1.57C57.963,35.718,57.84,32.914,57.715,30.086z
			 M86.559,29.715c0,3.209,0,6.028,0,8.878c0.082-0.029,0.277-0.068,0.439-0.163c2.408-1.413,2.523-6.062,1.18-7.88
			C87.926,30.211,87.355,30.111,86.559,29.715z M22.74,172.877c0.253,0.062,0.507,0.127,0.76,0.189
			c0.893-2.709,1.786-5.418,2.724-8.262c-0.906-0.117-1.524-0.197-2.416-0.314C23.444,167.348,23.092,170.111,22.74,172.877z
			 M121.596,164.475c-0.912,0.135-1.525,0.227-2.252,0.336c0.838,2.881,1.348,5.73,2.814,8.236c0.18-0.037,0.357-0.076,0.537-0.113
			C122.336,170.164,121.977,167.396,121.596,164.475z M117.936,172.23c0.121,0,0.24,0,0.361,0c0.104-0.486,0.316-0.977,0.287-1.453
			c-0.1-1.621-0.318-3.234-0.428-4.855c-0.074-1.127-0.658-1.377-1.801-1.162C116.787,167.312,116.686,169.912,117.936,172.23z
			 M26.888,172.199c0.206,0.021,0.411,0.041,0.616,0.062c1.087-2.373,1.333-4.936,1.524-7.525c-1.184-0.229-1.702,0.137-1.756,1.246
			c-0.044,0.908-0.316,1.805-0.368,2.713C26.837,169.859,26.888,171.031,26.888,172.199z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M87.275,226.896c2.725-2.094,2.805-4.926,2.74-7.84
			c-0.029-1.348,0.133-2.699,0.26-4.045c0.018-0.186,0.336-0.344,0.516-0.514c0.115,0.189,0.338,0.387,0.328,0.568
			c-0.146,2.613-0.207,5.24-0.545,7.83c-0.152,1.17-0.779,2.314-1.363,3.375C88.916,226.807,88.367,227.477,87.275,226.896z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M54.986,214.488c0.146,0.832,0.374,1.66,0.421,2.498
			c0.102,1.793-0.025,3.605,0.197,5.379c0.216,1.729,0.668,3.461,2.56,4.352c-0.085,0.141-0.169,0.281-0.254,0.424
			c-0.424-0.131-1.025-0.127-1.24-0.414c-0.609-0.812-1.304-1.686-1.52-2.637c-0.375-1.654-0.437-3.381-0.588-5.082
			c-0.106-1.191-0.151-2.387-0.2-3.582c-0.013-0.307,0.063-0.615,0.098-0.924C54.634,214.496,54.81,214.492,54.986,214.488z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M74.229,134.317c0.016,1.738-0.592,2.729-1.551,2.678
			c-0.938-0.049-1.656-1.171-1.592-2.582c0.021-0.466,0.068-1.005,0.312-1.369c0.365-0.547,0.917-0.967,1.389-1.441
			c0.441,0.561,0.939,1.086,1.297,1.694C74.268,133.61,74.199,134.073,74.229,134.317z M72.305,135.534
			c0.161-0.005,0.322-0.01,0.482-0.014c0.711-0.82,0.602-1.801,0.416-2.77c-0.039-0.211-0.328-0.374-0.504-0.559
			c-0.16,0.199-0.453,0.395-0.459,0.598C72.217,133.704,72.275,134.618,72.305,135.534z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M76.957,62.222c0.932-1.556,2.342-1.888,3.715-2.137
			c2.266-0.411,4.559-0.69,6.844-0.993c0.217-0.029,0.463,0.169,0.697,0.262c-0.195,0.2-0.363,0.533-0.588,0.576
			c-1.094,0.209-2.199,0.355-3.305,0.498c-2.238,0.288-4.525,0.384-6.465,1.781C77.699,62.319,77.391,62.222,76.957,62.222z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M57.308,59.091c1.368,0.153,2.749,0.236,4.102,0.477
			c1.794,0.319,3.567,0.757,5.346,1.16c0.225,0.051,0.48,0.152,0.629,0.314c0.316,0.345,0.571,0.746,0.851,1.124
			c-0.393-0.123-0.781-0.269-1.181-0.365c-1.925-0.463-3.847-0.947-5.785-1.354c-1.088-0.229-2.211-0.295-3.306-0.501
			c-0.304-0.058-0.564-0.354-0.844-0.541C57.182,59.3,57.245,59.195,57.308,59.091z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M81.207,227.221c-0.381-0.209-0.799-0.291-0.889-0.508
			c-0.482-1.164-1.131-2.338-1.254-3.555c-0.209-2.051-0.09-4.139-0.045-6.211c0.006-0.295,0.361-0.58,0.557-0.871
			c0.111,0.303,0.311,0.602,0.322,0.908c0.092,2.262,0.055,4.533,0.256,6.783C80.248,224.832,80.775,225.857,81.207,227.221z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M66.344,216.125c0.039,1.814,0.233,3.643,0.071,5.439
			c-0.148,1.641-0.646,3.256-1.08,4.857c-0.087,0.324-0.58,0.537-1.042,0.654c1.846-3.533,1.381-7.303,1.248-11.021
			C65.809,216.078,66.077,216.102,66.344,216.125z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M58.544,82.284c-0.693-0.769-1.466-1.236-1.487-1.734
			c-0.036-0.847,0.764-1.208,1.615-1.17c0.768,0.035,1.448,0.403,1.275,1.2C59.832,81.117,59.186,81.54,58.544,82.284z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M86.98,82.344c-0.711-0.803-1.357-1.218-1.488-1.757
			c-0.195-0.812,0.512-1.171,1.256-1.19c0.752-0.02,1.586,0.168,1.502,1.08C88.201,81.011,87.586,81.493,86.98,82.344z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M40.065,111.173c-0.243,0.013-0.498,0.081-0.726,0.028
			c-1.532-0.349-3.062-0.71-4.582-1.104c-0.247-0.064-0.445-0.315-0.665-0.479c0.166-0.135,0.345-0.402,0.496-0.387
			c1.943,0.197,3.891,0.405,5.546,1.542C40.111,110.907,40.088,111.04,40.065,111.173z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M105.293,111.221c0.727-1.299,4.762-2.321,6.125-1.521
			C109.332,110.218,107.383,110.702,105.293,111.221z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M63.316,99.827c1.202-1.045,2.15-1.869,3.174-2.758
			C66.816,98.184,64.863,99.977,63.316,99.827z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M81.572,100.135c-0.467-0.312-0.949-0.603-1.396-0.942
			c-0.473-0.357-0.93-0.74-1.35-1.157c-0.119-0.12-0.113-0.398-0.1-0.6c0.006-0.054,0.316-0.172,0.379-0.123
			c0.961,0.761,1.904,1.545,2.85,2.324C81.828,99.802,81.699,99.969,81.572,100.135z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M59.973,103.503c0.73-1.743,1.73-2.479,2.697-2.049
			C61.878,102.194,61.339,103.147,59.973,103.503z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M82.906,101.447c0.746-0.425,1.744,0.27,2.434,1.694
			C84.621,103.411,84.02,102.992,82.906,101.447z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M59.171,104.829c-0.619,0.676-1.12,1.256-1.672,1.781
			c-0.06,0.057-0.597-0.183-0.612-0.319C56.825,105.734,58.245,104.721,59.171,104.829z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M88.312,106.995c-0.736-0.784-1.334-1.421-2.088-2.223
			C87.637,104.748,88.424,105.605,88.312,106.995z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M65.144,29.358c-0.875,0-1.751,0.028-2.623-0.021
			c-0.211-0.012-0.409-0.276-0.612-0.424c0.188-0.175,0.372-0.497,0.565-0.503c1.776-0.052,3.555-0.069,5.331-0.035
			c0.199,0.003,0.39,0.385,0.584,0.592c-0.246,0.158-0.484,0.433-0.74,0.451c-0.832,0.058-1.669,0.021-2.505,0.021
			C65.144,29.413,65.144,29.385,65.144,29.358z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M79.318,29.428c-0.875,0-1.75,0.029-2.621-0.021
			c-0.215-0.012-0.416-0.281-0.625-0.431c0.191-0.21,0.379-0.599,0.574-0.603c1.775-0.034,3.551-0.017,5.324,0.035
			c0.191,0.006,0.371,0.327,0.557,0.502c-0.236,0.152-0.465,0.42-0.709,0.436c-0.83,0.055-1.666,0.021-2.5,0.021
			C79.318,29.388,79.318,29.408,79.318,29.428z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M63.216,31.98c1.405-1.258,3.844-1.276,5.011-0.037
			C67.417,33.152,64.317,33.174,63.216,31.98z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M81.195,31.972c-1.15,1.15-3.879,1.169-5.045,0.085
			C77.152,30.746,79.645,30.669,81.195,31.972z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M68.468,42.766c2.379,0,4.478,0,6.64,0
			C74.221,44.22,70.234,44.292,68.468,42.766z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M74.812,38.103c-0.842,0.636-1.684,1.271-2.592,1.956
			c-0.902-0.666-1.746-1.288-2.589-1.91c0.039-0.089,0.078-0.178,0.117-0.267c0.815,0.193,1.636,0.553,2.446,0.534
			c0.836-0.02,1.662-0.409,2.492-0.636C74.729,37.887,74.77,37.995,74.812,38.103z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M70.939,30.431c-0.216,1.694-0.029,3.434-0.605,5.088
			c-0.18-0.021-0.359-0.041-0.539-0.062c0.219-1.694,0.437-3.387,0.654-5.081C70.613,30.395,70.776,30.413,70.939,30.431z"/>

		<path fill-rule="evenodd" clip-rule="evenodd" d="M12.76,30.814c3.474-5.642,8.545-8.589,15.072-8.691
			c6.519-0.102,11.696,2.662,15.346,8.125C36.877,38.834,20.907,40.165,12.76,30.814z M27.985,36.686
			c5.101-0.116,9.837-1.765,13.747-5.564c0.634-0.616,0.615-1.022,0.113-1.699c-6.62-8.924-20.987-8.845-27.519,0.127
			c-0.743,1.021-0.599,1.58,0.283,2.336C18.39,35.131,22.796,36.591,27.985,36.686z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M102.77,30.788c3.629-5.812,8.873-8.737,15.598-8.665
			c6.32,0.068,11.328,2.843,14.828,8.168C126.252,39.289,110.332,39.751,102.77,30.788z M116.031,36.651
			c7.156,0.006,12.164-2.007,15.723-5.553c0.572-0.57,0.615-0.957,0.146-1.607c-6.525-9.037-21.193-8.93-27.66,0.187
			c-0.619,0.872-0.57,1.376,0.217,2.069C108.266,35.099,112.74,36.594,116.031,36.651z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M117.824,19.679c-5.473-0.153-10.258-1.783-14.238-5.555
			c-0.76-0.72-0.711-1.207-0.168-1.995c2.883-4.18,6.84-6.711,11.824-7.432c6.963-1.008,12.73,1.244,17.162,6.786
			c0.701,0.878,0.693,1.52-0.148,2.347C128.215,17.796,123.303,19.52,117.824,19.679z M132.416,12.688
			c-0.219-0.351-0.369-0.63-0.553-0.883c-6.566-8.99-21.078-8.918-27.555,0.103c-0.66,0.922-0.701,1.458,0.209,2.238
			c5.105,4.364,11,5.611,17.463,4.419C126.051,17.814,129.547,15.897,132.416,12.688z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M28.349,4.395c5.996,0.287,10.714,2.483,14.268,7.045
			c0.734,0.943,0.698,1.575-0.168,2.439c-7.66,7.643-21.192,7.699-28.753,0.108c-0.567-0.569-0.63-0.972-0.197-1.616
			C17.119,6.99,22.292,4.576,28.349,4.395z M42.656,12.688c-0.22-0.353-0.37-0.632-0.555-0.886
			c-6.571-8.989-21.074-8.914-27.554,0.108c-0.667,0.928-0.691,1.463,0.214,2.236c5.105,4.362,10.999,5.611,17.463,4.417
			C36.294,17.812,39.788,15.894,42.656,12.688z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M33.665,29.776c-0.025,3.061-2.614,5.61-5.689,5.6
			c-3.139-0.009-5.682-2.597-5.651-5.75c0.03-3.056,2.64-5.637,5.682-5.62C31.111,24.024,33.691,26.655,33.665,29.776z
			 M23.037,29.752c0.013,2.738,2.205,4.926,4.942,4.932c2.78,0.005,4.995-2.235,4.972-5.031c-0.022-2.729-2.23-4.932-4.951-4.94
			C25.236,24.706,23.023,26.952,23.037,29.752z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M123.666,29.765c-0.021,3.065-2.598,5.612-5.68,5.612
			c-3.135,0-5.689-2.589-5.662-5.739c0.027-3.058,2.627-5.64,5.67-5.632C121.094,24.014,123.686,26.646,123.666,29.765z
			 M122.951,29.75c0.014-2.791-2.209-5.043-4.975-5.037c-2.719,0.006-4.926,2.214-4.939,4.941c-0.016,2.809,2.186,5.038,4.965,5.029
			C120.738,34.676,122.936,32.485,122.951,29.75z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M123.654,12.019c0.006,3.085-2.531,5.665-5.613,5.708
			c-3.094,0.043-5.721-2.585-5.713-5.718c0.008-3.075,2.592-5.652,5.664-5.648C121.068,6.364,123.648,8.941,123.654,12.019z
			 M118,17.026c2.809-0.006,5.027-2.234,5.002-5.025c-0.025-2.762-2.324-5.016-5.08-4.982c-2.709,0.033-4.924,2.276-4.934,5
			C112.977,14.826,115.186,17.032,118,17.026z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M33.894,12.013c0.009,3.086-2.524,5.667-5.606,5.713
			c-3.095,0.047-5.727-2.582-5.72-5.712c0.007-3.077,2.584-5.653,5.659-5.654C31.301,6.359,33.885,8.936,33.894,12.013z
			 M23.229,11.98c-0.031,2.756,2.133,5.007,4.853,5.046c2.895,0.042,5.159-2.147,5.161-4.987c0.001-2.712-2.194-4.951-4.922-5.021
			C25.582,6.95,23.26,9.211,23.229,11.98z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M23.757,29.693c-0.003-2.299,1.847-4.127,4.214-4.16
			c2.377-0.033,4.35,1.88,4.323,4.194c-0.026,2.27-1.954,4.107-4.298,4.098C25.642,33.816,23.76,31.982,23.757,29.693z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M113.758,29.697c-0.006-2.301,1.842-4.127,4.209-4.164
			c2.377-0.036,4.352,1.876,4.326,4.19c-0.023,2.269-1.949,4.109-4.293,4.102C115.646,33.818,113.762,31.985,113.758,29.697z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M118.023,10.732c0.48,0.58,0.791,0.953,1.1,1.327
			c-0.357,0.294-0.74,0.848-1.068,0.817c-0.408-0.038-0.771-0.559-1.152-0.872C117.207,11.658,117.514,11.312,118.023,10.732z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M29.509,12.199c-0.378,0.246-0.78,0.716-1.129,0.68
			c-0.435-0.044-0.828-0.506-1.239-0.788c0.333-0.322,0.639-0.864,1.006-0.909c0.415-0.051,0.885,0.343,1.332,0.544
      C29.488,11.883,29.499,12.041,29.509,12.199z"/>
  </g>
</g>
`;

svg['Thorax'] = `
<g id="thorax">		<radialGradient id="SVGID_1_" cx="53.8271" cy="74.8906" r="9.1458" gradientTransform="matrix(2.6342 0 0 3.0687 -69.2691 -144.7631)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_1_)" d="M96.613,85.052c0,15.504-10.785,28.067-24.093,28.067
		c-13.303,0-24.09-12.562-24.09-28.067c0-15.5,10.787-28.065,24.09-28.065C85.828,56.987,96.613,69.551,96.613,85.052z"/>
</g>
`;

svg['Fuss-Rechts'] = `
<g id="Fuss-Rechts">		<radialGradient id="SVGID_2_" cx="51.1187" cy="181.6367" r="9.1465" gradientTransform="matrix(1.1224 0.1786 -0.394 2.4761 74.7573 -172.7103)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_2_)" d="M70.842,287.803c-1.99,12.51-8.199,21.916-13.869,21.014
		c-5.669-0.902-8.653-11.771-6.662-24.279c1.99-12.508,8.199-21.916,13.868-21.014C69.85,264.426,72.832,275.295,70.842,287.803z"/>
</g>
`;

svg['Fuss-Links'] = `
<g id="Fuss-Links">		<radialGradient id="SVGID_3_" cx="66.7651" cy="182.9697" r="9.1458" gradientTransform="matrix(1.2348 -0.0973 0.1969 2.4995 -34.3442 -162.3964)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_3_)" d="M95.416,287.555c0.994,12.629-3.254,23.26-9.492,23.75
		c-6.236,0.492-12.1-9.342-13.095-21.971c-0.995-12.625,3.257-23.258,9.491-23.748C88.561,265.094,94.422,274.93,95.416,287.555z"/>
</g>
`;

svg['Abdomen'] = `
<g id="Abdomen">		<radialGradient id="SVGID_4_" cx="57.1479" cy="96.9688" r="9.146" gradientTransform="matrix(2.4102 0 0 1.2003 -64.9131 12.0091)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_4_)" cx="72.827" cy="128.397" rx="22.044" ry="10.978"/>
</g>
`;

svg['Becken'] = `
<g id="Becken">		<radialGradient id="SVGID_5_" cx="57.2744" cy="114.7085" r="9.146" gradientTransform="matrix(3.1485 0 0 1.6159 -107.7308 -26.2571)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_5_)" cx="72.594" cy="159.098" rx="28.796" ry="14.781"/>
</g>
`;

svg['Kopf'] = `
<g id="Kopf">
		<radialGradient id="SVGID_6_" cx="56.1592" cy="51.4805" r="9.1458" gradientTransform="matrix(1.616 0 0 1.9804 -19.6682 -70.0098)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_6_)" cx="71.082" cy="31.943" rx="14.779" ry="18.113"/>
</g>`;

svg['Bein-Rechts'] = `
<g id="Bein-Rechts">
		<radialGradient id="SVGID_7_" cx="48.5425" cy="143.6187" r="9.146" gradientTransform="matrix(1.3394 0 0 4.8077 -4.441 -468.7185)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_7_)" d="M72.826,221.754c0,24.289-5.484,43.971-12.25,43.971
		c-6.765,0-12.25-19.682-12.25-43.971c0-24.287,5.485-43.971,12.25-43.971C67.342,177.783,72.826,197.467,72.826,221.754z"/>
</g>`;

svg['Bein-Links'] = `
<g id="Bein-Links">
		<radialGradient id="SVGID_8_" cx="66.6611" cy="143.2822" r="9.1458" gradientTransform="matrix(1.3394 0 0 4.8077 -4.441 -468.7185)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_8_)" d="M97.094,220.137c0,24.289-5.484,43.971-12.25,43.971
		s-12.25-19.682-12.25-43.971c0-24.285,5.484-43.971,12.25-43.971S97.094,195.852,97.094,220.137z"/>
</g>`;

svg['Schulter-Links'] = `
<g id="Schulter-Links">
		<radialGradient id="SVGID_9_" cx="79.6885" cy="69.7666" r="8.489" gradientTransform="matrix(1.051 0 0 1.0151 18.5709 -9.5829)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_9_)" cx="102.32" cy="61.233" rx="8.922" ry="8.617"/>
</g>`;

svg['Schulter-Rechts'] = `
<g id="Schulter-Rechts">
		<radialGradient id="SVGID_10_" cx="38.6611" cy="69.8086" r="9.1462" gradientTransform="matrix(0.9894 0 0 1.0151 4.5538 -9.5829)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_10_)" cx="42.806" cy="61.276" rx="9.05" ry="9.284"/>
</g>`;

svg['Pupille-Rechts-Klein'] = `
<g id="Pupille-Rechts-Klein">
		<radialGradient id="SVGID_11_" cx="23.645" cy="20.9629" r="9.146" gradientTransform="matrix(0.9894 0 0 1.0151 4.5538 -9.5829)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_11_)" cx="27.949" cy="11.695" rx="9.05" ry="9.284"/>
</g>`;

svg['Pupille-Rechts-Weit'] = `
<g id="Pupille-Rechts-Weit">
		<radialGradient id="SVGID_12_" cx="23.645" cy="38.6362" r="9.146" gradientTransform="matrix(0.9894 0 0 1.0151 4.5538 -9.5829)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_12_)" cx="27.949" cy="29.634" rx="9.05" ry="9.284"/>
</g>`;

svg['Pupille-Links-Weit'] = `
<g id="Pupille-Links-Weit">
		<radialGradient id="SVGID_13_" cx="114.2393" cy="38.6362" r="9.1462" gradientTransform="matrix(0.9894 0 0 1.0151 4.5538 -9.5829)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_13_)" cx="117.587" cy="29.634" rx="9.05" ry="9.284"/>
</g>`;

svg['Pupille-Links-Klein'] = `
<g id="Pupille-Links-Klein">
		<radialGradient id="SVGID_14_" cx="114.123" cy="21.166" r="9.1458" gradientTransform="matrix(0.9894 0 0 1.0151 4.5538 -9.5829)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_14_)" cx="117.472" cy="11.901" rx="9.05" ry="9.284"/>
</g>`;

svg['Arm-Links'] = `
<g id="Arm-Links">
		<radialGradient id="SVGID_15_" cx="85.6191" cy="92.3438" r="9.146" gradientTransform="matrix(1.2951 -0.3417 0.886 3.3579 -82.0416 -166.1179)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_15_)" d="M122.51,111.579c4.477,16.966,2.801,32.111-3.742,33.838
		c-6.539,1.726-15.471-10.621-19.947-27.587c-4.475-16.961-2.799-32.109,3.74-33.835C109.104,82.269,118.033,94.617,122.51,111.579z
		"/>
</g>`;

svg['Arm-Rechts'] = `
<g id="Arm-Rechts">
		<radialGradient id="SVGID_16_" cx="29.4692" cy="91.3452" r="9.146" gradientTransform="matrix(1.2988 0.3271 -0.8482 3.3677 74.0544 -204.1418)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_16_)" d="M46.735,116.109
		c-4.285,17.015-13.076,29.462-19.637,27.81c-6.56-1.652-8.406-16.778-4.121-33.793c4.285-17.011,13.076-29.46,19.636-27.808
		C49.173,83.971,51.019,99.098,46.735,116.109z"/>
</g>`;

svg['Hand-Links'] = `
<g id="Hand-Links">
		<radialGradient id="SVGID_17_" cx="96.0449" cy="116.915" r="9.146" gradientTransform="matrix(1.3394 0 0 1.9804 -4.441 -70.0098)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_17_)" cx="124.201" cy="161.533" rx="12.25" ry="18.113"/>
</g>`;

svg['Hand-Rechts'] = `
<g id="Hand-Rechts">
		<radialGradient id="SVGID_18_" cx="20.165" cy="115.6855" r="9.146" gradientTransform="matrix(1.3394 0 0 1.9804 -4.441 -70.0098)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style="stop-color:#FF0000"/>
		<stop  offset="0.7688" style="stop-color:#FF0000;stop-opacity:0.0243"/>
		<stop  offset="0.7879" style="stop-color:#FF0000;stop-opacity:0"/>
	</radialGradient>
	<ellipse fill-rule="evenodd" clip-rule="evenodd" fill="url(#SVGID_18_)" cx="22.568" cy="159.099" rx="12.25" ry="18.113"/>
</g>`;

svg['Rücken-Oben'] = svg['Thorax'];
svg['Rücken-Unten'] = svg['Abdomen'];
svg['Gesäss'] = svg['Becken'];

export default (face, value) => {
  const background = svg[face];

  const activeParts =
    value && value[face]
      ? Object.keys(value[face])
          .filter(part => value[face][part])
          .map(part => svg[part])
          .join('')
      : '';

  return `
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
  width="71.625px" height="156px" viewBox="0 0 143.25 312">
    ${background}
    ${activeParts}
  </svg>`;
};
