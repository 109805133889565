import _ from 'lodash';
import { SET_LAYOUTS, ADD_TO_LAYOUT, REMOVE_FROM_LAYOUT } from './ActionTypes';
import {
  DEFAULT_DASHBOARD_LAYOUT,
  DEFAULT_SETTINGS_LAYOUT,
  DEFAULT_EVENTS_LAYOUT,
  DEFAULT_BRIEFINGS_LAYOUT,
  DEFAULT_ANALYTICS_LAYOUT
} from '../../constants';

const initialState = {
  dashboard: DEFAULT_DASHBOARD_LAYOUT,
  events: DEFAULT_EVENTS_LAYOUT,
  briefings: DEFAULT_BRIEFINGS_LAYOUT,
  analytics: DEFAULT_ANALYTICS_LAYOUT,
  settings: DEFAULT_SETTINGS_LAYOUT
};

const getDefault = (route, breakpoint, key) => {
  switch (route) {
    case 'dashboard':
      return DEFAULT_DASHBOARD_LAYOUT[breakpoint].find(l => l.i === key);
    case 'events':
      return DEFAULT_EVENTS_LAYOUT[breakpoint].find(l => l.i === key);
    case 'briefings':
      return DEFAULT_BRIEFINGS_LAYOUT[breakpoint].find(l => l.i === key);
    case 'analytics':
      return DEFAULT_ANALYTICS_LAYOUT[breakpoint].find(l => l.i === key);
    case 'settings':
      return DEFAULT_SETTINGS_LAYOUT[breakpoint].find(l => l.i === key);
    default:
      return {};
  }
};

export default (state = initialState, action) => {
  if (action.type === SET_LAYOUTS) {
    const { route, layouts } = action;

    if (!layouts) {
      return state;
    }

    if (_.isEqual(route ? state[route] : state, layouts)) {
      return state;
    }

    if (!route) {
      return layouts;
    }

    return {
      ...state,
      [route]: layouts
    };
  }

  if (action.type === ADD_TO_LAYOUT) {
    const { route, key } = action;

    if (!state[route]) {
      console.warn(`No layout for route ${route}.`);
      return state;
    }

    // add component to all layouts (lg, md, sm) of route
    // adds component below other components in last column
    // TODO w,h, minW and minH be dependent on current layout (lg, md, sm)
    const breakpoints = _.keys(state[route]);
    const layouts = {};
    breakpoints.forEach(bp => {
      const layout = state[route][bp];
      layouts[bp] = [
        ...layout,
        {
          ...getDefault(route, bp, key),
          x: Math.max(...layout.map(item => item.x)),
          y: Math.max(...layout.map(item => item.y)) + 1
        }
      ];
    });

    // const layouts = _.mapValues(state[route], layout => [
    //   ...layout,
    //   {...getDefault(route, key),

    //   }
    // ]);

    return {
      ...state,
      [route]: layouts
    };
  }

  if (action.type === REMOVE_FROM_LAYOUT) {
    const { route, key } = action;

    if (!state[route]) {
      console.warn(`No layout for route ${route}.`);
      return state;
    }

    // remove component from all layouts (lg, md, sm) of route
    const layouts = _.mapValues(state[route], layout =>
      layout.filter(l => l && l.i !== key)
    );

    return {
      ...state,
      [route]: layouts
    };
  }

  return state;
};
