import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';

import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import calcArea from '@turf/area';
import { polygon } from '@turf/helpers';

import { generateId, isActiveTag } from '../../utils';

const drawConfig = {
  rectangle: {
    shapeOptions: {
      clickable: true,
      color: '#ED4D70'
    }
  },
  polygon: {
    shapeOptions: {
      clickable: true,
      color: '#ED4D70'
    }
  },
  circle: false,
  polyline: {
    shapeOptions: {
      clickable: true,
      color: '#ED4D70'
    }
  },
  marker: false,
  circlemarker: false
};

const editConfig = {
  remove: false
};

class GeoJsonEdit extends React.Component {
  handleFeatureGroupReady = reactFGref => {
    this.fGroup = reactFGref;
    this.update();
  };

  update = () => {
    if (!this.fGroup) return;

    const { data, tags } = this.props;
    const leafletGeoJSON = new L.GeoJSON(data, {
      style: ({ properties }) => ({
        color: properties.fill,
        id: properties.id,
        tags: properties.tags
      }),
      filter: ({ properties }) => isActiveTag(properties.tags, tags)
    });

    this.fGroup.leafletElement.clearLayers();

    leafletGeoJSON.eachLayer(layer => {
      this.fGroup.leafletElement.addLayer(layer);
    });
  };

  componentDidUpdate() {
    this.update();
  }

  // only edits geometry, properties stay the same
  handleEditFeature = event => {
    const { data, onChange } = this.props;

    const geojson = {
      ...data,
      features: [...data.features]
    };

    event.layers.eachLayer(l => {
      const idx = geojson.features.findIndex(
        f => f.properties.id === l.options.id
      );

      if (idx > -1) {
        const { geometry } = l.toGeoJSON();
        geojson.features[idx].geometry = geometry;
      }
    });

    onChange(geojson);
  };

  // add a feature to the gis doc
  handleCreateFeature = event => {
    const feature = event.layer.toGeoJSON();

    // set default properties
    feature.properties = {
      id: generateId(),
      name: '',
      tags: [],
      stroke: '#ED4D70',
      'stroke-width': 2,
      'stroke-opacity': 1,
      fill: '#ED4D70',
      'fill-opacity': 0.5
    };

    // compute the area
    if (feature.geometry.type === 'Polygon') {
      feature.properties.area = calcArea(polygon(feature.geometry.coordinates));
    }

    // update
    const geojson = {
      ...this.props.data,
      features: [...this.props.data.features, feature]
    };

    const { onChange } = this.props;
    onChange(geojson);
  };

  handleClick = event => {
    const { data, onSelected } = this.props;
    const feature = data.features.find(
      f => f.properties.id === event.layer.options.id
    );
    onSelected(feature);
  };

  render() {
    return (
      <FeatureGroup
        ref={this.handleFeatureGroupReady}
        onClick={this.handleClick}
      >
        <EditControl
          position="topleft"
          onEdited={this.handleEditFeature}
          onCreated={this.handleCreateFeature}
          draw={drawConfig}
          edit={editConfig}
        />
      </FeatureGroup>
    );
  }
}

GeoJsonEdit.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  onSelected: PropTypes.func
};

GeoJsonEdit.defaultProps = {
  onChange: () => {},
  onSelected: () => {}
};

export default GeoJsonEdit;
