/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import update from 'immutability-helper';

import { fliplatlon } from '../utils';
import * as t from './actionTypes';

const removes = (state, doc, seq) => {
  const cmd = {};
  const [, className] = doc._id.split(':');

  if (state[className]) {
    const idx = state[className].findIndex(d => d._id === doc._id);
    const current = state[className][idx];

    if (current && current._id === doc._id) {
      // found
      cmd[className] = { $splice: [[idx, 1]] };
      cmd.count = { $set: state.count - 1 };
    }
  }

  if (seq) {
    cmd.seq = { $set: seq };
  }

  return update(state, cmd);
};

const puts = (state, action, pending = false) => {
  const cmd = {};
  const { seq } = action;
  const doc = fliplatlon(action.doc);

  const { pendingInstance } = state;
  if (doc && doc.className) {
    const { className } = doc;

    if (state[className]) {
      const idx = state[className].findIndex(d => d._id === doc._id);
      const current = state[className][idx];

      if (current && current._id === doc._id) {
        // update item in array
        cmd[className] = { $splice: [[idx, 1, doc]] };
      } else {
        // insert item to array
        cmd.count = { $set: state.count + 1 };
        cmd[className] = { $push: [doc] };
      }
    } else {
      // init array with item
      cmd.count = { $set: state.count + 1 };
      cmd[className] = { $set: [doc] };
    }
  }

  if (pending) {
    // add to pending
    if (state.pending[pendingInstance]) {
      cmd.pending = { [pendingInstance]: { $push: [doc] } };
    } else {
      cmd.pending = { [pendingInstance]: { $set: [doc] } };
    }
  } else {
    // remove from pending
    const pendingDocs = state.pending[pendingInstance] || [];
    const idx = pendingDocs.findIndex(d => d._id === doc._id);
    const current = pendingDocs[idx];
    if (current && current._id === doc._id) {
      // found
      cmd.pending = { [pendingInstance]: { $splice: [[idx, 1]] } };
    }
  }

  if (seq) {
    cmd.seq = { $set: seq };
  }
  return update(state, cmd);
};

const batch = (state, docs, seq, progress) => {
  let cmd = {};
  docs.forEach(dd => {
    const d = fliplatlon(dd);

    if (!d || !d.className) {
      // filter undefined
      return;
    }

    if (cmd[d.className]) {
      cmd[d.className].push(d);
    } else {
      cmd[d.className] = [d];
    }
  });

  cmd = _.mapValues(cmd, v => {
    const { className } = v[0];
    if (!state[className]) {
      return { $set: v };
    }

    return { $set: _.unionBy(v, state[className], '_id') };
  });

  if (seq) {
    cmd.seq = { $set: seq };
  }

  if (progress) {
    cmd.progress = { $set: progress };
  }

  cmd.count = { $set: state.count + docs.length };

  return update(state, cmd);
};

// state = {
//   className: [],
// }
// e.g.
// state = {
//   PatientTreatment: [{}, ...}],
//   MaterialOrder: [{}, ...]
// }

const INITIAL_STATE = {
  isLoading: false,
  count: 0,
  init: false,
  seq: 0,
  progress: undefined,
  pending: {},
  pendingInstance: undefined,
  appSettingsLoaded: undefined
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.PUT_DOC_PENDING:
      return puts(state, action, true);

    case t.PUT_DOC_SUCCESS: {
      return puts(state, action);
    }

    case t.PUT_DOC_PENDING_ERROR: {
      const { pendingInstance } = state;
      const pendingDocs = state.pending[pendingInstance];

      if (!pendingDocs) {
        return state;
      }

      // TODO
      // possible because of conflict
      // should also be visible to user
      // should also remove doc
      const cmd = {
        pending: {
          [pendingInstance]: {
            $set: pendingDocs.filter(d => d._id !== action.doc._id)
          }
        }
      };
      return update(state, cmd);
    }

    case t.BATCH_PUT_DOC_SUCCESS:
      return batch(state, action.docs, action.seq, action.progress);

    case t.REMOVE_DOC_SUCCESS: {
      return removes(state, action.doc, action.seq);
    }

    case t.APP_SETTINGS_LOADED:
      return { ...state, appSettingsLoaded: true };

    case t.RESET_DB:
      return INITIAL_STATE;

    case t.LOAD_DB_PENDING:
      return {
        ...state,
        isLoading: true,
        pendingInstance: action.pendingInstance
      };

    case t.LOAD_DB_SUCCESS:
      return { ...state, isLoading: false, init: true };

    case t.SET_APP:
      return { ...state, appSettings: action.appDoc };

    default:
      return state;
  }
};
