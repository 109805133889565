import { fliplatlon } from '../utils';
import { getClient, initializeClient } from './SyncGatewayClient';
import { DB_URL } from '../config';

initializeClient(DB_URL);

export const deleteDoc = (doc, cb) => {
  const obs = getClient().deleteDoc(doc);
  if (cb) {
    return obs.subscribe(
      () => cb(true),
      err => cb(false, err)
    );
  }

  return obs.toPromise();
};

export const updateDoc = (doc, cb) => {
  const obs = getClient().upsertDoc(fliplatlon(doc));

  if (cb) {
    return obs.subscribe(
      res => {
        if (typeof cb === 'function') {
          cb(true, { ...doc, _rev: res.rev });
        }
      },
      err => {
        if (typeof cb === 'function') {
          cb(false, err);
        }
      }
    );
  }

  return obs.toPromise();
};

export { default as CouchbaseReducer } from './reducer';
export { default as CouchbaseEpic } from './epic';
export { default } from './actions';
