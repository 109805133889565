import React, { Component } from 'react';
import _ from 'lodash';
import { translate } from 'react-i18next';
import { Dropdown, MenuItem } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TintIcon } from '../../components';

const iconStyle = { fontSize: 20, marginRight: 10 };
const renderItem = (
  { activeComponents, handleChangeChk, permissions, route },
  id,
  title
) => {
  if (_.isEmpty(permissions)) return;

  if (!permissions[route]) {
    console.warn(`No permission found for route ${route}`, permissions);
    return;
  }
  if (!permissions[route][id]) {
    console.warn(`No permission found for key ${route}.${id}`, permissions);
    return;
  }

  if (!permissions[route][id].permissionGranted) return;

  return (
    <MenuItem className="small" onClick={() => handleChangeChk(id)}>
      <input
        type="checkbox"
        id={id}
        readOnly
        checked={activeComponents[id] === true}
        style={{ marginRight: '5px' }}
      />
      {title}
    </MenuItem>
  );
};

const renderCheckbox = (callback, id, title, curValue) => (
  <MenuItem className="small" onClick={() => callback(id)}>
    <input
      type="radio"
      id={id}
      readOnly
      checked={curValue === id}
      style={{ marginRight: '5px' }}
    />
    {title}
  </MenuItem>
);

class OrganisationSelector extends Component {
  renderDashboardContent = () => {
    const { t, viewMode, changeViewMode, permissions, route } = this.props;

    const chatPermissionGranted =
      !_.isEmpty(permissions) && permissions[route].chat.permissionGranted;
    const incidentPermissionGranted =
      !_.isEmpty(permissions) && permissions[route].incidents.permissionGranted;
    const overviewPermissionGranted =
      !_.isEmpty(permissions) &&
      (permissions[route].bases.permissionGranted ||
        permissions[route].devices.permissionGranted);
    const mapPermissionGranted =
      !_.isEmpty(permissions) && permissions[route].map.permissionGranted;
    const feedPermissionGranted =
      !_.isEmpty(permissions) &&
      (permissions[route].patients.permissionGranted ||
        permissions[route].incidents.permissionGranted ||
        permissions[route].materialOrders.permissionGranted ||
        permissions[route].chat.permissionGranted);
    return (
      <Dropdown.Menu className="dropdown-menu">
        <MenuItem header>{t('Ansicht')}</MenuItem>
        {renderCheckbox(changeViewMode, 'mapOnly', t('Nur Karte'), viewMode)}
        {chatPermissionGranted &&
          renderCheckbox(
            changeViewMode,
            'mapComm',
            t('Karte/ Komunikation'),
            viewMode
          )}
        {incidentPermissionGranted &&
          renderCheckbox(
            changeViewMode,
            'mapInc',
            t('Karte/ Ereignis'),
            viewMode
          )}
        {renderCheckbox(
          changeViewMode,
          'custom',
          t('Benutzerdefiniert'),
          viewMode
        )}

        {overviewPermissionGranted && viewMode === 'custom' && (
          <MenuItem header>{t('Übersicht')}</MenuItem>
        )}
        {viewMode === 'custom' && renderItem(this.props, 'bases', t('Posten'))}
        {viewMode === 'custom' &&
          renderItem(this.props, 'devices', t('Geräte'))}

        {mapPermissionGranted && viewMode === 'custom' && (
          <MenuItem header>{t('Karte')}</MenuItem>
        )}
        {viewMode === 'custom' && renderItem(this.props, 'map', t('Karte'))}

        {feedPermissionGranted && viewMode === 'custom' && (
          <MenuItem header>{t('Feed')}</MenuItem>
        )}
        {viewMode === 'custom' &&
          renderItem(this.props, 'patients', t('Patienten'))}
        {viewMode === 'custom' &&
          renderItem(this.props, 'incidents', t('Ereignisse'))}
        {viewMode === 'custom' &&
          renderItem(this.props, 'materialOrders', t('Bestellungen'))}
        {viewMode === 'custom' && renderItem(this.props, 'chat', t('Chat'))}
        {viewMode === 'custom' &&
          renderItem(this.props, 'activities', t('Aktivitätsfeed'))}
      </Dropdown.Menu>
    );
  };

  renderAnalyticsContent = () => {
    const { t } = this.props;

    return (
      <Dropdown.Menu className="dropdown-menu">
        <MenuItem header>{t('Übersicht')}</MenuItem>
        {renderItem(
          this.props,
          'incidentProcessingStats',
          t('Bearbeitungsdauer Ereignisse')
        )}
        {renderItem(this.props, 'patientStats', t('Patient Statistik'))}
        {renderItem(this.props, 'incidentStats', t('Ereignis Statistik'))}
        {renderItem(this.props, 'heatmap', t('Heatmap'))}
      </Dropdown.Menu>
    );
  };

  render() {
    const { icon, iconUrl, iconColor, color, route } = this.props;

    if (!route) return null;

    return (
      <Dropdown id="dropdown-selector">
        <Dropdown.Toggle
          bsStyle="default"
          className="organization-button"
          style={{ color }}
        >
          {icon ? (
            <FontAwesomeIcon icon={icon} color={iconColor} style={iconStyle} />
          ) : (
            <TintIcon url={iconUrl} color={iconColor} />
          )}
        </Dropdown.Toggle>
        {route === 'dashboard' && this.renderDashboardContent()}
        {route === 'analytics' && this.renderAnalyticsContent()}
      </Dropdown>
    );
  }
}
export default translate('translations')(OrganisationSelector);
