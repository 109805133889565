import { memo } from 'react';

import Button from './Button/Button';
import HumanBody from './HumanBody/HumanBody';
import BodyAnnotation from './HumanBody/BodyAnnotation';
import Section from './Form/Section';
import FormHeader from './Form/Header';

import TextInput from './Input/TextInput';
import DateTimeInput from './Input/DateTimeInput';
import FormInput from './Input/FormInput';
import PickerInput from './Input/Picker';
import ButtonSelect from './ButtonSelect/ButtonSelectWithText';
import ButtonGroup from './ButtonSelect/ButtonGroup';

export const getComponentByName = name => {
  switch (name) {
    case 'Button':
      return Button;
    case 'BodyAnnotation':
      return BodyAnnotation;
    case 'HumanBody':
      return HumanBody;
    case 'ButtonSelect':
      return ButtonSelect;
    case 'ButtonGroup':
      return ButtonGroup;
    case 'Section':
      return Section;
    case 'DateTimeInput':
      return DateTimeInput;
    case 'FormInput':
      return FormInput;
    case 'PickerInput':
      return PickerInput;
    case 'FormHeader':
      return FormHeader;
    case 'TextInput':
      return TextInput;
    default:
      console.warn(`Could not find Component for "${name}".`);
      return null;
  }
};

export const Field = memo(
  ({ render, onChange, value }) => render({ onChange, value }),
  (next, prev) => next.value === prev.value
);
