import React, { useState } from 'react';

import L from 'leaflet';

import Control from 'react-leaflet-control';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';

import { Tooltip } from 'react-tippy';

import ReactDistortableImageOverlay from './ReactDistortableImageOverlay';

import LeafletMapEditInfo from './LeafletMapEditInfo';

import { generateId, isActiveTag } from '../../utils';

export default ({
  overlays,
  onUpdate,
  mapHeight,
  tags,
  eventId,
  t,
  onSelected
}) => {
  const [selected, setSelected] = useState();

  const addOverlay = (data, filename) => {
    const overlay = {
      name: filename,
      original: {
        url: data
      },
      opacity: 1,
      tags: [],
      filename,
      id: generateId()
    };

    const update = Array.isArray(overlays) ? [...overlays, overlay] : [overlay];

    onUpdate(update);
  };

  const updateOverlay = (idx, update) => {
    const updates = [...overlays];
    updates[idx] = update;

    onUpdate(updates);
  };

  const removeOverlay = idx => {
    const updated = [...overlays];
    updated.splice(idx, 1);
    onUpdate(updated);
    if (selected === idx) {
      setSelected(undefined);
    }
  };

  const handleDrop = accepted => {
    accepted.forEach(file => {
      const reader = new FileReader();

      reader.onload = () => addOverlay(reader.result, file.name);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.readAsDataURL(file);
    });
  };

  return (
    <React.Fragment>
      {Array.isArray(overlays) &&
        overlays.map(
          (
            {
              name,
              tags: overlayTags,
              opacity,
              original: { url, corners },
              id
            },
            idx
          ) => {
            if (!isActiveTag(overlayTags, tags)) {
              return null;
            }

            return (
              <ReactDistortableImageOverlay
                key={id}
                url={url}
                corners={corners}
                opacity={opacity}
                onUpdateCorners={({ corners, points }) =>
                  updateOverlay(idx, {
                    ...overlays[idx],
                    original: { ...overlays[idx].original, corners, points }
                  })
                }
                onSelected={checked => {
                  if (checked) {
                    setSelected(idx);
                    onSelected(idx);
                  } else {
                    setSelected(undefined);
                  }
                }}
              />
            );
          }
        )}

      <Control position="topright" className="leaflet-control-layers">
        <Tooltip title="Add image as overlay">
          <Dropzone
            accept={['image/png', 'image/jpeg']}
            className="leaflet-touch leaflet-control-custom"
            multiple={false}
            onDrop={handleDrop}
          >
            <FontAwesomeIcon
              icon="image"
              style={{ fontSize: 22 }}
              color="#464646"
            />
          </Dropzone>
        </Tooltip>
      </Control>

      <Control position="bottomright">
        <div
          ref={container => {
            if (container) {
              // prevent scroll events to propagate through to map
              L.DomEvent.disableClickPropagation(
                container
              ).disableScrollPropagation(container);
            }
          }}
        >
          <div
            style={{
              maxHeight: mapHeight - 90,
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <LeafletMapEditInfo
              onChange={overlay => updateOverlay(selected, overlay)}
              onDelete={() => removeOverlay(selected)}
              onClose={() => setSelected(undefined)}
              visible={selected !== undefined}
              properties={overlays && overlays[selected]}
              tags={tags}
              eventId={eventId}
              t={t}
            />
          </div>
        </div>
      </Control>
    </React.Fragment>
  );
};
