import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import _ from 'lodash';

import { NEW_EVENT } from '../../constants';
import { Panel, ListHeader, QuestionDialog } from '../../components';
import { deleteDoc, updateDoc } from '../../Couchbase';
import { questionDialogStyles } from '../../ModalStyles';

import { setPrivateKey } from '../dashboard/actions';
import {
  getFilteredBases,
  getAppSettings,
  getCurrentUser,
  getPermissions,
  getPatients,
  getIncidents,
  getEncryptionDetails,
  getCurrentEvent,
  getCurrentGis,
  getCurrentEventId
} from '../../selectors';

import EditEvent from './EditEvent';
import { filterByEvent } from '../organisation/Actions';

class EventComponent extends Component {
  state = {
    functionType: undefined,
    eventDoc: undefined,
    gisDoc: undefined,
    baseDocs: undefined,
    escPressed: false,
    searchActive: false
  };

  componentWillUnmount() {
    const { currentEvent, setEventFilter } = this.props;
    if (!currentEvent) {
      setEventFilter(undefined);
    }
  }

  setUpFunctionData = () => {
    const { t } = this.props;

    const functionData = [
      {
        action: this.createNewEvent,
        icon: 'plus-square',
        tooltip: t('Neue Veranstaltung')
      },
      {
        action: this.showDeleteDialog,
        icon: 'trash',
        tooltip: t('Veranstaltung löschen')
      }
    ];

    return functionData;
  };

  showDeleteDialog = () => {
    this.setState({ modalIsOpen: true });
  };

  hideDeleteDialog = () => {
    this.setState({ modalIsOpen: false });
  };

  deleteEvent = async () => {
    const { t, eventDoc, gisDoc, setEventFilter } = this.props;

    try {
      await deleteDoc(eventDoc);

      if (gisDoc) {
        await deleteDoc(gisDoc);
      }

      this.notificationSystem.addNotification({
        message: t('Event erfolgreich gelöscht'),
        level: 'success'
      });

      setEventFilter(undefined);
    } catch (error) {
      this.notificationSystem.addNotification({
        message: t('Event wurde nicht gelöscht'),
        level: 'error'
      });
    }

    this.hideDeleteDialog();
  };

  createNewEvent = () => {
    const { setEventFilter } = this.props;
    setEventFilter(NEW_EVENT);
  };

  resetEsc = () => {
    this.setState({ escPressed: false });
  };

  closeModalSucces = async (
    eventDoc,
    gisDoc,
    baseDocs,
    deletedBaseDocs,
    docs
  ) => {
    const { t, setEventFilter, currentEventId } = this.props;

    try {
      await updateDoc(eventDoc);

      if (gisDoc) {
        await updateDoc(gisDoc);
      }

      if (Array.isArray(baseDocs) && baseDocs.length > 0) {
        await Promise.all(baseDocs.map(updateDoc));
      }

      if (Array.isArray(deletedBaseDocs) && deletedBaseDocs > 0) {
        await Promise.all(deletedBaseDocs.map(updateDoc));
      }

      if (Array.isArray(docs) && docs.length > 0) {
        await Promise.all(docs.map(updateDoc));
      }

      this.notificationSystem.addNotification({
        message:
          currentEventId === NEW_EVENT
            ? t('Event erfolgreich angelegt.')
            : t('Event erfolgreich editiert.'),
        level: 'success'
      });
      setEventFilter(eventDoc._id);
    } catch (error) {
      this.notificationSystem.addNotification({
        message: t('Erstellen des Events ist fehlgeschlagen'),
        level: 'error'
      });
      console.error(error);
    }
  };

  render() {
    const { modalIsOpen, escPressed } = this.state;
    const {
      t,
      appSettings: { markerIcons, assets, appId, defaultForms },
      currentUser: { _id },
      permissions,
      patients,
      incidents,
      encryption,
      setSecretKey,
      eventDoc,
      gisDoc,
      baseDocs,
      currentEventId
    } = this.props;

    return (
      <Panel title={t('Karte')} id="events">
        <ListHeader functionButtons={this.setUpFunctionData()} />

        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />

        {permissions.events.eventEditor.permissionGranted && (
          <div style={{ height: 'calc(100% - 80px)' }}>
            <EditEvent
              key={currentEventId} // creates new component when id changes
              assets={assets}
              eventDoc={eventDoc}
              gisDoc={gisDoc}
              markerIcons={markerIcons || []}
              escPressed={escPressed}
              resetEsc={this.resetEsc}
              baseDocs={baseDocs}
              appId={appId}
              user={_id}
              succesHandler={this.closeModalSucces}
              gisEnabled={
                !_.isEmpty(permissions) &&
                permissions.dashboard.features.tactics
              }
              encryptionEnabled={
                !_.isEmpty(permissions) &&
                permissions.dashboard.features.encryption
              }
              patients={patients}
              incidents={incidents}
              encryption={encryption}
              setSecretKey={setSecretKey}
              defaultForms={defaultForms}
              t={t}
            />
          </div>
        )}

        <Modal
          isOpen={modalIsOpen}
          style={questionDialogStyles}
          shouldCloseOnOverlayClick={false}
          onRequestClose={this.hideDeleteDialog}
          contentLabel="deleteAlert"
        >
          <QuestionDialog
            caption={t('Löschen')}
            content={t('Sind sie sicher, dass sie den Eintrag löschen wollen?')}
            errorHandler={this.hideDeleteDialog}
            succesHandler={this.deleteEvent}
          />
        </Modal>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    eventDoc: getCurrentEvent(state),
    currentEventId: getCurrentEventId(state),
    gisDoc: getCurrentGis(state),
    currentUser: getCurrentUser(state),
    patients: getPatients(state),
    incidents: getIncidents(state),
    baseDocs: getFilteredBases(state),
    appSettings: getAppSettings(state),
    permissions: getPermissions(state),
    encryption: getEncryptionDetails(state)
  };
}

export default connect(mapStateToProps, {
  setSecretKey: setPrivateKey,
  setEventFilter: filterByEvent
})(EventComponent);
