import axios from 'axios';
import fx from '../../../node_modules/glfx/glfx';

import { getS3Url } from '../../config';

export const uploadImage = async (base64, name) => {
  const base64Data = Buffer.from(
    base64.replace(/^data:image\/\w+;base64,/, ''),
    'base64'
  );
  const type = base64.split(';')[0].split('/')[1];

  const { signedRequest, url } = await axios
    .get(getS3Url({ name, type }))
    .then(res => res.data);

  await axios.put(signedRequest, base64Data, {
    header: {
      'Content-Encoding': 'base64',
      'Content-Type': type
    }
  });

  return url;
};

const loadImage = url =>
  new Promise(resolve => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => resolve(image);
    image.src = url;
  });

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

function canvasToBlobUrl(canvas) {
  const blob = dataURItoBlob(canvas.toDataURL('image/png'));
  return window.URL.createObjectURL(blob);
}

const getXY = points => {
  const x = [];
  for (let i = 0; i < points.length; i += 2) {
    x.push(points[i]);
  }

  const y = [];
  for (let i = 1; i < points.length; i += 2) {
    y.push(points[i]);
  }

  return [x, y];
};

const getSize = points => {
  const [x, y] = getXY(points);

  const width = Math.max(...x) - Math.min(...x);
  const height = Math.max(...y) - Math.min(...y);

  return { width, height };
};

export const getBounds = latlngs => {
  // latlngs = [[lat, lng], ...]
  let top = -90;
  let bottom = 90;

  let left = 180;
  let right = -180;

  latlngs.forEach(p => {
    // latitude
    top = Math.max(p[0], top);
    bottom = Math.min(p[0], bottom);

    // longitude
    left = Math.min(p[1], left);
    right = Math.max(p[1], right);
  });

  return [
    [top, left],
    [bottom, right]
  ];
};

const removeOffset = (points, w, h) => {
  // points = [ax,ay, bx,by, cx, cy, ...]
  const [x, y] = getXY(points);

  const northmost = Math.min.apply(null, y);
  const westmost = Math.min.apply(null, x);

  const ab = Math.sqrt(Math.pow(x[0] - x[1], 2) + Math.pow(y[0] - y[1], 2));
  const bc = Math.sqrt(Math.pow(x[1] - x[2], 2) + Math.pow(y[1] - y[2], 2));

  const ratio = Math.min(ab / w, bc / h) || 1;
  // const ratio = Math.max(Math.min(width / w, height / h), 1 / Math.sqrt(2)) || 1;

  return points.map((p, i) =>
    i % 2 === 0
      ? Math.round((p - westmost) / ratio)
      : Math.round((p - northmost) / ratio)
  );
};

const warpWebGl = (image, before, after, download) => {
  const { width, height } = getSize(after);

  const texture = document.createElement('canvas');
  const ctx = texture.getContext('2d');
  texture.width = Math.max(width, image.width);
  texture.height = Math.max(height, image.height);

  ctx.drawImage(image, 0, 0);

  const canvas = fx.canvas();
  const fxTexture = canvas.texture(texture);

  canvas
    .draw(fxTexture)
    .perspective(before, after)
    .update();

  // resize image
  const nc = document.createElement('canvas');
  const c = nc.getContext('2d');
  nc.width = width;
  nc.height = height;
  c.drawImage(canvas, 0, 0);

  if (download) {
    const burl = canvasToBlobUrl(nc);
    window.open(burl);
  }

  return nc.toDataURL();
};

export const transformImage = async (url, points) => {
  const image = await loadImage(url);

  const { height, width } = image;
  const before = [0, 0, width, 0, width, height, 0, height];
  const pp = points.map(p => [p.x, p.y]).flat();
  const after = removeOffset(pp, width, height);

  return warpWebGl(image, before, after);
};
