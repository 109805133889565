import React from 'react';
import ProptTypes from 'prop-types';

// eslint-disable-next-line import/no-unresolved
import { View, StyleSheet } from 'react-native';

import HumanBody from './HumanBody';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row'
  }
});

const BodyAnnotation = ({ width, ...rest }) => (
  <View style={[styles.container, { maxWidth: width }]}>
    <HumanBody maxWidth={width / 2} face="front" {...rest} />
    <HumanBody maxWidth={width / 2} face="back" {...rest} />
  </View>
);

BodyAnnotation.propTypes = {
  width: ProptTypes.number,
  value: ProptTypes.object, // TODO check value shape
  onChange: ProptTypes.func
};

BodyAnnotation.defaultProps = {
  width: 600,
  value: undefined,
  onChange: () => {}
};

export default BodyAnnotation;
