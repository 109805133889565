/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import {
  PUT_DOC_SUCCESS,
  LOAD_DB_SUCCESS
} from '../../../Couchbase/actionTypes';
import { LAST_SEEN_MESSAGES } from '../actionTypes';

const initialState = {};

export default (state = initialState, action) => {
  if (action.type === LOAD_DB_SUCCESS) {
    return {};
  }

  if (action.type === LAST_SEEN_MESSAGES) {
    const { group } = action;

    return { ...state, [group]: undefined };
  }

  if (
    action.type !== PUT_DOC_SUCCESS ||
    action.doc.className !== 'ChatMessage'
  ) {
    return state;
  }

  // chat message
  const { doc } = action;
  const group = doc.data.group || doc.eventId;

  if (Array.isArray(state[group])) {
    if (state[group].indexOf(doc._id) < 0) {
      // not yet in list, append
      return { ...state, [group]: [...state[group], doc._id] };
    }

    // already list
    return state;
  }
  // init list
  return { ...state, [group]: [doc._id] };
};
// dont show badge in current group
