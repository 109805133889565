import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';
import { Map, Marker } from 'react-leaflet';
import { GoogleLayer } from 'react-leaflet-google';
import moment from 'moment';

// import { timestampToTime, latLngToDgreeString } from '../utils';
import { ROADMAP, KEY, ItemTypes } from '../constants';
import { hoverIn, hoverOut } from '../routes/dashboard/actions';

import { getUsers, getAppSettings } from '../selectors';

const messageSource = {
  beginDrag(props) {
    // get the message
    const { data: payload } = props;
    return {
      payload
    };
  }
};

const collectSrc = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
});

class Message extends Component {
  getUserColor = () => {
    let color = '#7ED321';

    // find the user
    const sender = this.props.users.find(
      u => u._id === this.props.data.user._id
    );

    if (sender && sender.color) {
      color = sender.color;
    }
    return color;
  };

  componentDidMount() {
    const {
      connectDragPreview,
      data: { location, image },
      appSettings: {
        assets: { dndPreviewIconUrl }
      }
    } = this.props;
    // use preview only for images with location
    if (location === undefined) return;
    if (image !== undefined) return;

    const img = new Image();
    img.src = dndPreviewIconUrl;
    img.onload = () => connectDragPreview && connectDragPreview(img);
  }

  addAvatar = () => {
    const nameArray = this.props.data.user.name.split(' ');
    let fstName = '';
    let scdName = '';
    if (nameArray.length > 1) {
      fstName = nameArray[0].charAt(0).toUpperCase();
      scdName = nameArray[1].charAt(0).toUpperCase();
    } else {
      fstName = nameArray[0].charAt(0).toUpperCase();
    }
    const color = this.getUserColor();

    return (
      <div
        className="col-md-1 col-sm-1 col-xs-1 "
        style={{ paddingLeft: '0px' }}
      >
        <div className="avatar" style={{ background: color }}>
          <font color="white">{fstName + scdName}</font>
        </div>
      </div>
    );
  };

  addImage = () => (
    <div style={{ overflow: 'hidden', maxWidth: 80 }} className="pull-right">
      <img
        src={this.props.data.image}
        alt="chat"
        style={{ cursor: 'pointer', maxHeight: 80 }}
        onClick={() => {
          this.props.openGallery(this.props.data.image);
        }}
        onMouseOver={() => {
          if (this.props.data.location) {
            // Add special marker to payload
            const payload = this.props.data.location;
            payload.icon = this.props.appSettings.assets.photoIconUrl;
            this.props.hoverIn(payload);
          }
        }}
        onMouseOut={() => {
          if (this.props.data.location) this.props.hoverOut();
        }}
      />
    </div>
  );

  addLocation = withLocation => {
    const { data } = this.props;
    if (withLocation) {
      return (
        <Map
          center={[data.location.latitude, data.location.longitude]}
          onMouseOver={() => {
            this.props.hoverIn(data.location);
          }}
          onMouseOut={() => {
            this.props.hoverOut();
          }}
          zoomControl={false}
          className="pull-right"
          dragging={false}
          touchZoom={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          boxZoom={false}
          keyboard={false}
          tap={false}
          attributionControl={false}
          style={{ height: '80px', width: '80px' }}
          zoom={15}
        >
          <GoogleLayer googlekey={KEY} maptype={ROADMAP} />
          <Marker
            position={[data.location.latitude, data.location.longitude]}
          />
        </Map>
      );
    }
    return null;
  };

  render() {
    const {
      connectDragSource,
      fromMe,
      data: {
        createdAt,
        location,
        image,
        text,
        user: { name }
      }
    } = this.props;
    // Was the message sent by the current user. If so, add a css class
    const fromMeCss = fromMe ? '-from-me' : '';
    const withLocation = location !== undefined;
    const withImage = image !== undefined;

    const messageStyle = {
      marginTop: '8px'
    };
    const divStyle = {
      paddingTop: '5px',
      fontSize: '12px',
      position: 'relative',
      height: '100%'
    };
    if (withLocation || withLocation) {
      messageStyle.position = 'absolute';
      messageStyle.bottom = 0;
      divStyle.minHeight = '80px';
      divStyle.paddingBottom = '35px';
    }

    return connectDragSource(
      <div className="row" style={{ margin: '0px', marginRight: '7px' }}>
        {!fromMe && this.addAvatar()}
        <div
          className={`col-md-11 col-sm-11 col-xs-11   message-container${fromMeCss}`}
        >
          <div
            className={`row message${fromMeCss}`}
            style={{
              marginLeft: '7px',
              marginRight: '0px',
              marginTop: '0px',
              marginBottom: '10px'
            }}
          >
            <div className="row">
              <div
                className={
                  withImage || withLocation
                    ? 'col-md-8 col-sm-8 col-xs-8 '
                    : 'col-md-12 col-sm-12 col-xs-12 '
                }
                style={divStyle}
              >
                <div className="message-text">{text}</div>
                <div style={messageStyle}>
                  {moment(createdAt).format('LTS, ll')}
                  <span
                    style={{ color: this.getUserColor(), marginLeft: '8px' }}
                  >
                    {name}
                  </span>
                  {/**     <br />
                    {withLocation
                      ? latLngToDgreeString([location.latitude, location.longitude])
                      : null}
              */}
                </div>
              </div>
              {(withImage || withLocation) && (
                <div
                  className="col-md-4 col-sm-4 col-xs-4 pull-right"
                  style={{ paddingRight: '0px' }}
                >
                  {withImage ? this.addImage() : this.addLocation(withLocation)}
                </div>
              )}
            </div>
          </div>
        </div>
        {fromMe && this.addAvatar()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: getUsers(state),
    appSettings: getAppSettings(state)
  };
}
export default connect(mapStateToProps, { hoverIn, hoverOut })(
  DragSource(ItemTypes.MESSAGE, messageSource, collectSrc)(Message)
);

// TODO open gallery
