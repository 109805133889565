/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import {
  Marker,
  LayersControl,
  Polyline,
  Polygon,
  ImageOverlay
} from 'react-leaflet';
import AntPath from 'react-leaflet-ant-path';
import Control from 'react-leaflet-control';
import { icon as iconOriginal, divIcon as divIconOriginal } from 'leaflet';
import memoize from 'memoizee';
import _ from 'lodash';

import { connect } from 'react-redux';
import 'leaflet.smooth_marker_bouncing';
import { Tooltip } from 'react-tippy';

import { detect } from 'detect-browser';
import { TRAJECTORIES_VISIBLE_DURATION } from '../../../constants';
import { BaseMap } from '../../../components';
import { updateDoc } from '../../../Couchbase';
import {
  getIconUrl,
  getDivIconLeaflet as getDivIconLeafletOriginal,
  getStatusIcon as getStatusIconOriginal
} from '../../../LeafletHelper';
import { timeConverter } from '../../../utils';

import {
  CHAT_LOCATION_HOVER_IN,
  CHAT_LOCATION_SET,
  CHAT_LOCATION_INIT
} from '../actionTypes';
import { locationSet, mapViewportChange, mapLayerChange } from '../actions';

import LeafletMapInfo from './LeafletMapInfo';
import TagFilter from './TagFilter';

const browser = detect();
const { Overlay } = LayersControl;

// memoize functions
const icon = memoize(iconOriginal);
const divIcon = memoize(divIconOriginal, { primitive: true });
const getStatusIcon = memoize(getStatusIconOriginal);
const getDivIconLeaflet = memoize(getDivIconLeafletOriginal);

const mergeTags = (oldTags, gis, bases) => {
  // TODO immutable if nothing changed
  const tags = _.unionBy(
    ...oldTags,
    ...(gis ? gis.features.map(f => f.properties.tags) : []),
    ...(bases ? bases.map(b => b.tags) : []),
    'value'
  );

  return _.sortBy(tags, 'label');
};
class LeafletMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDevices: true, // flag if the devices should be deisplayed
      visibleTrajectorys: {},

      activeMarkerId: undefined, // hovered marker id

      tags: [],

      mapInfoProps: {
        // state for map info component
        visible: false,
        name: '',
        details: '',
        lastSeen: '',
        iconUrl: undefined
      }
    };

    // memoize functions
    this.createHandlerShowTrajectory = memoize(
      this.createHandlerShowTrajectory
    );
    this.createHandlerMouseOverDevice = memoize(
      this.createHandlerMouseOverDevice
    );
    this.createHandlerMouseOverBase = memoize(this.createHandlerMouseOverBase);
  }

  componentDidMount() {
    setTimeout(this.fitMapToBases, 1000); // delay fit to marker, so that map is initialized
  }

  componentDidUpdate(prevProps) {
    const { eventId } = this.props;
    if (this.chatLocationMarker) {
      this.chatLocationMarker.leafletElement.setBouncingOptions({
        bounceHeight: 30, // height of the bouncing
        bounceSpeed: 54 // bouncing speed coefficient
      });
      this.chatLocationMarker.leafletElement.bounce(2); // 2 times
    }

    if (eventId !== prevProps.eventId) {
      // event changed => fit map to markers
      this.fitMapToBases();
    }
  }

  static getDerivedStateFromProps({ bases, gisDoc }, { tags }) {
    return {
      tags: mergeTags(tags || [], gisDoc, bases)
    };
  }

  // update the active value in the tags array
  handleTagChange = label => {
    const { tags } = this.state;
    const updateIndex = tags.findIndex(t => t.label === label);
    if (updateIndex > -1) {
      const updatedTags = [...tags];
      updatedTags[updateIndex].active =
        updatedTags[updateIndex].active === undefined
          ? false
          : !updatedTags[updateIndex].active;
      this.setState({ tags: updatedTags });
    }
  };

  triggerLocationUpdateViaPush = () => {
    // create or refresh push document
    const {
      appSettings: { appId },
      eventId
    } = this.props;
    const d = new Date();
    const pushDocument = {
      appId,
      baseId: '0',
      baseName: 'Zentrale',
      className: 'Push',
      eventId, // keep for later, when we have multiple events at the same time
      ts: Math.floor(d.getTime() / 1000),
      uploaded: false,
      // TODO where does this come from, TODO remove old push docs, TODO creates conflicts when clicked twice within 10 seconds
      _id: `${appId}:Push:${eventId}:${Math.floor(
        d.getTime() / 10000
      ).toString()}`
    };
    pushDocument.antaviAction = 'locate';
    updateDoc(pushDocument, status => {
      if (!status) {
        console.error('An error occured during the message sending.');
      }
    });
  };

  fitMapToBases = () => {
    if (!this.map || !this.map.leafletElement) {
      return;
    }

    const { bases } = this.props;
    if (bases.length > 1) {
      const bounds = bases.map(b => b.geometry.coordinates);

      this.map.leafletElement.fitBounds(bounds, { padding: [25, 25] });
    } else if (bases.length === 1) {
      // could be a possible fix for: https://trello.com/c/kPYHb3bK
      // If Map has only 1 Base and app has only 1 event, error loading infinite tiles
      // occures only in production
      // possible reason this.map._zoom is undefined => tries to load infinited files
      this.map.leafletElement.setView(
        bases[0].geometry.coordinates,
        this.map._zoom || 15
      );
    }
  };

  createHandlerMouseOverBase = baseId => () => {
    // NOTE parameter need to be primitives for memoizeation
    const { materialOrders, patients, devices, crowd, t, bases } = this.props;

    const base = bases.find(b => b._id === baseId);

    if (!base) return;

    let orders = 0;
    materialOrders.forEach(m => {
      if (m.baseId === baseId) {
        orders += 1;
      }
    });

    let patientCnt = 0;
    patients.forEach(p => {
      if (p.baseId === baseId) {
        patientCnt += 1;
      }
    });

    const details =
      `${orders +
        (orders === 1 ? ` ${t('Bestellung')}` : ` ${t('Bestellungen')}`)}, ` +
      `${patientCnt}${
        patientCnt === 1 ? ` ${t('Patient')}` : ` ${t('Patienten')}`
      }`;

    let info = '';
    const device = devices.find(d => d.baseId === baseId);
    if (device) {
      info += `${t('zul. aktiv um')} ${timeConverter(device.lastActive)}`;
    }

    const crowdDoc = crowd.find(d => d.baseId === baseId);
    if (crowdDoc) {
      info += ` ${t('Besucherdichte')}: ${crowdDoc.data
        .map(d => d.label)
        .join(' - ')}`;
    }

    this.setState({
      mapInfoProps: {
        visible: true,
        name: base.name,
        iconUrl: base.icon.iconUrl.replace('base.png', 'base_active.png'),
        details,
        lastSeen: info
      },
      activeMarkerId: baseId
    });
  };

  createHandlerMouseOverDevice = (deviceId, lastSeen, iconUrl) => () => {
    // NOTE parameter need to be primitives for memoizeation
    const { t, devices } = this.props;

    const device = devices.find(d => d._id === deviceId);

    this.setState({
      mapInfoProps: {
        iconUrl,
        visible: true,
        name: device.role
          ? `${device.userName} [${device.role}]`
          : device.userName,
        details: device.baseName,
        lastSeen: `${t('zul. ges. um')} ${timeConverter(lastSeen)}`
      },
      activeMarkerId: deviceId
    });
  };

  createHandlerShowTrajectory = (ui, color) => () =>
    this.setState(({ visibleTrajectorys }) => {
      const update = { ...visibleTrajectorys };

      if (update[ui]) {
        delete update[ui];
      } else {
        update[ui] = color;
      }

      return { visibleTrajectorys: update };
    });

  handleMouseOut = () =>
    setTimeout(() => {
      // delay needed to remove flicker when mouse over mapInfo
      if (this.mouseover) return;

      this.setState({
        activeMarkerId: undefined,
        mapInfoProps: {
          visible: false
        }
      });
    }, 40);

  // callback to switch the visiblity of devices
  handleShowDevices = (elm, showDevices) => {
    this.setState({ showDevices });
  };

  // add a marker for every base post to the map
  addMarkerForBase = () => {
    const { activeMarkerId, tags } = this.state;

    const { crowd, bases } = this.props;

    const markerComponents = [];
    if (bases && bases.length > 0) {
      bases.forEach(base => {
        const b = { ...base };

        if (b.tags && b.tags.length > 0) {
          let visible = false;
          b.tags.forEach(t => {
            const tIndex = tags.findIndex(
              stateTag => stateTag.label === t.label
            );
            if (tIndex > -1) {
              visible = tags[tIndex].active !== false || visible;
            }
          });

          // do not add to the map if not vissible
          if (!visible) {
            return;
          }
        }

        b.icon.iconUrl = getIconUrl(b, activeMarkerId === b._id);
        b.icon.iconRetinaUrl = b.icon.iconUrl;

        let baseIcon = icon(b.icon);

        const crowdDoc = crowd.find(d => d.baseId === b._id); // assume sorted by timestamp
        if (crowdDoc) {
          crowdDoc.status = {
            value: crowdDoc.data[0].value
            // TODO
            // trend: crowdDoc.data[1].value
          };

          baseIcon = getStatusIcon(b.icon, crowdDoc.status);
        }

        markerComponents.push(
          <Marker
            key={b._id}
            icon={baseIcon}
            position={b.geometry.coordinates}
            onMouseOver={this.createHandlerMouseOverBase(b._id)}
            onMouseOut={this.handleMouseOut}
          />
        );

        // add the title as div icon
        if (
          this.map &&
          this.map.leafletElement &&
          this.map.leafletElement._zoom > 15
        ) {
          const style =
            'text-shadow: -1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000; color: white';
          const options = {
            className: 'gis-div-icon',
            html: `<span style='${style}'>${b.name || ''}</span>`,
            toString: () => b.name || ''
          };

          const dIcon = divIcon(options);
          markerComponents.push(
            <Marker
              key={`divMarker-${b._id}`}
              position={b.geometry.coordinates}
              icon={dIcon}
            />
          );
        }
      });
    }
    return markerComponents;
  };

  handleViewportChange = ({ zoom }) => {
    // hack to redraw labels of bases and gis objects on zoom
    if (zoom === 15 || zoom === 16) {
      this.forceUpdate();
    }
  };

  // callback for the mouse on map click,
  // only trigger if a location should be added to a chat message.
  addChatLocation = e => {
    const {
      addLocation: { type, payload }
    } = this.props;
    if (type === CHAT_LOCATION_INIT) {
      if (payload.location === undefined) {
        const location = {
          latitude: e.latlng.lat,
          longitude: e.latlng.lng
        };
        // dispatch action with the new location for the chat compo
        // eslint-disable-next-line react/destructuring-assignment
        this.props.locationSet(location);
      }
    }
  };

  // add a draggable marker for the chat location
  addMarkerForLocationAdding = () => {
    const {
      addLocation: { type, payload }
    } = this.props;
    if (type === CHAT_LOCATION_SET && payload.location) {
      return (
        <Marker
          position={[payload.location.latitude, payload.location.longitude]}
          draggable
          onDragend={e => {
            const location = {
              latitude: e.target._latlng.lat,
              longitude: e.target._latlng.lng
            };

            // eslint-disable-next-line react/destructuring-assignment
            this.props.locationSet(location);
          }}
        />
      );
    }
    return null;
  };

  // add a marker for every device post to the map
  addMarkerForDevice = () => {
    const markers = [];
    const { devices, deviceLocations } = this.props;

    devices.forEach(deviceStatus => {
      // console.log("addMarkerForDevice", deviceStatus._id);

      const deviceLocation = deviceLocations.find(
        doc => doc._id === deviceStatus.deviceLocationId
      );
      const tLatest = new Date().getTime() - 10 * 60 * 1000;
      const tOld = new Date().getTime() - 24 * 3600 * 1000;

      if (
        deviceLocation &&
        deviceLocation.ts > tOld &&
        deviceStatus.loggedIn !== false &&
        deviceLocation.la &&
        deviceLocation.lo
      ) {
        const color = deviceStatus.color || '#7ED321';

        const myicon = getDivIconLeaflet(
          deviceStatus.userName,
          color,
          !(deviceLocation.ts < tLatest)
        );

        const lastSeen = Math.round(deviceLocation.ts / 1000);
        const ui = deviceStatus._id.split(':').pop();
        markers.push(
          <Marker
            icon={myicon}
            key={deviceLocation._id}
            position={[deviceLocation.la, deviceLocation.lo]}
            onMouseOver={this.createHandlerMouseOverDevice(
              deviceStatus._id,
              lastSeen,
              myicon.options.iconUrl || myicon.options.html
            )}
            onMouseOut={this.handleMouseOut}
            onClick={this.createHandlerShowTrajectory(ui, color)}
          />
        );
      }
    });

    return markers;
  };

  createAntPathForDevice = (ui, color) => {
    const { trajectories } = this.props;

    const trajTSNow = Date.now() - TRAJECTORIES_VISIBLE_DURATION;
    const positions = trajectories
      .filter(trajectory => trajectory.ui === ui)
      .reduce((points, trajectory) => {
        if (trajectory.ts > trajTSNow) {
          return points.concat(
            ...trajectory.points.filter(p => p.ts > trajTSNow)
          );
        }

        return points;
      }, [])
      .map(p => [p.la, p.lo]);

    return (
      <AntPath
        key={`trajectory:${ui}`}
        positions={positions}
        options={{ color }}
      />
    );
  };

  addAntPathForTrajectories = () => {
    const { visibleTrajectorys } = this.state;
    return _.keys(visibleTrajectorys).map(ui =>
      this.createAntPathForDevice(ui, visibleTrajectorys[ui])
    );
  };

  // if a chat message with location is hovered show the marker
  addMarkerForChat = () => {
    const {
      hoverAction: { type, payload }
    } = this.props;

    if (type === CHAT_LOCATION_HOVER_IN) {
      let markerIcon;
      if (payload.location.icon) {
        markerIcon = icon({
          iconUrl: payload.location.icon,
          iconRetinaUrl: payload.location.icon,
          iconSize: [27, 35],
          iconAnchor: [13, 35],
          popupAnchor: [0, 17]
        });
      }
      return (
        <Marker
          {...(markerIcon ? { icon: markerIcon } : {})}
          ref={c => {
            this.chatLocationMarker = c;
          }}
          position={[payload.location.latitude, payload.location.longitude]}
        />
      );
    }
    return null;
  };

  addGisLayer = () => {
    const jsxComps = [];
    const { tags: selectedTags } = this.state;
    const { gisDoc } = this.props;

    if (gisDoc && gisDoc.features) {
      gisDoc.features.forEach(({ geometry, properties }, i) => {
        // check if the feature contains tags and if there are active
        // show if the feature contains one active tag
        const { tags } = properties;
        if (Array.isArray(tags) && tags.length > 0) {
          let visible = false;
          tags.forEach(({ value }) => {
            const tIndex = selectedTags.findIndex(tt => tt.value === value);
            if (tIndex > -1) {
              visible = selectedTags[tIndex].active !== false || visible;
            }
          });
          // do not add to the map if not vissible
          if (!visible) {
            return;
          }
        }

        if (geometry.type === 'Polygon') {
          const positions = geometry.coordinates[0].map(c => [c[1], c[0]]);
          const { name, stroke, id } = properties;
          jsxComps.push(
            <Polygon key={id} color={stroke} positions={positions} />
          );

          if (
            this.map &&
            this.map.leafletElement &&
            this.map.leafletElement._zoom > 15
          ) {
            // compute center of mass
            const centerOfMass = positions.reduce((a, b) => [
              a[0] + b[0],
              a[1] + b[1]
            ]);
            centerOfMass[0] /= positions.length;
            centerOfMass[1] /= positions.length;

            // add div icon with name and color
            const style =
              'color:#FFFFFF;text-shadow: -1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000;';
            const html = `<span style='${style}'>${name || ''}</span>`;
            const myIcon = divIcon({
              className: 'gis-div-icon',
              html,
              toString: () => name || ''
            });
            jsxComps.push(
              <Marker
                key={`divMarker-${i}`}
                position={[centerOfMass[0], centerOfMass[1]]}
                icon={myIcon}
              />
            );
          }
        }
        if (geometry.type === 'LineString') {
          const positions = geometry.coordinates.map(c => [c[1], c[0]]);
          const { stroke } = properties;
          jsxComps.push(
            <Polyline key={i} color={stroke} positions={positions} />
          );
        }
      });
    }

    return jsxComps;
  };

  renderOverlays = () => {
    const { event } = this.props;

    return event && Array.isArray(event.overlays)
      ? event.overlays.map(
          ({ id, name, opacity, transformed: { bounds, url } }) => (
            <Overlay key={id} name={name || ''}>
              <ImageOverlay bounds={bounds} url={url} opacity={opacity || 1} />
            </Overlay>
          )
        )
      : null;
  };
  render() {
    const {
      recenterIconUrl,
      locateIconUrl,
      tagsIconUrl,
      permissions,
      mapState
    } = this.props;

    const { mapInfoProps, showDevices, tags } = this.state;

    const iconSize = browser && browser.name === 'safari' ? 36 : 44;
    // console.log("render map");
    return (
      <BaseMap
        ref={m => {
          if (m !== null) {
            this.map = m;
          }
        }}
        maxZoom={20}
        onClick={this.addChatLocation}
        viewport={mapState}
        onBaselayerChange={e => {
          mapLayerChange(e.layer._type);
        }}
        onViewportChanged={viewport => {
          this.handleViewportChange(viewport);
          mapViewportChange(viewport);
        }}
        renderOverlays={this.renderOverlays}
      >
        {this.addAntPathForTrajectories()}

        {this.addMarkerForChat()}

        {this.addMarkerForLocationAdding()}

        {this.addMarkerForBase()}

        {showDevices && this.addMarkerForDevice()}

        {!_.isEmpty(permissions) &&
          permissions.dashboard.features.tactics &&
          this.addGisLayer()}

        <Control position="topright">
          <Tooltip title="Center map">
            <button
              className="leaflet-control-layers btn btn-default"
              type="button"
              style={{
                padding: '2px',
                width: 48,
                height: 48
              }}
              onClick={this.fitMapToBases}
            >
              <img src={recenterIconUrl} width="30px" alt="Center map" />
            </button>
          </Tooltip>
        </Control>

        {!_.isEmpty(permissions) && permissions.dashboard.features.tactics && (
          <Control position="topright">
            <Tooltip title="Locate devices">
              <button
                className="leaflet-control-layers btn btn-default"
                type="button"
                style={{
                  padding: '2px',
                  width: 48,
                  height: 48
                }}
                onClick={this.triggerLocationUpdateViaPush}
              >
                <img src={locateIconUrl} width="30px" alt="Locate devices" />
              </button>
            </Tooltip>
          </Control>
        )}

        <Control position="topright" className="leaflet-control-layers">
          <TagFilter
            visible
            tags={tags}
            width={iconSize}
            height={iconSize}
            tagChangeHandler={this.handleTagChange}
            tagsIconUrl={tagsIconUrl}
          />
        </Control>

        <Control position="topright">
          <LeafletMapInfo
            {...mapInfoProps}
            onMouseOver={() => {
              this.mouseover = true;
            }}
            onMouseOut={() => {
              this.mouseover = false;
              this.handleMouseOut();
            }}
          />
        </Control>
      </BaseMap>
    );
  }
}

// TODO remove connection here and move to parent
export default connect(null, {
  locationSet,
  mapViewportChange,
  mapLayerChange
})(LeafletMap);
