import { connect } from 'react-redux';

import {
  createLayoutSelector,
  getAppAssets,
  getCurrentViewMode
} from '../../selectors';
import Layout from '../layout';
import { setLayouts, removeFromLayout } from '../organisation/Actions';

const getLayouts = createLayoutSelector('dashboard');
const handleLayoutChange = (layout, layouts) =>
  setLayouts('dashboard', layouts);
const handleClose = key => removeFromLayout('dashboard', key);

const mapStateToProps = state => ({
  title: 'DASHBOARD',
  backgroundImage: getAppAssets(state).dashboardBackground,
  layouts: getLayouts(state),
  viewMode: getCurrentViewMode(state),
  organisationProps: {
    route: 'dashboard',
    withAllEvents: false
  }
});

export default connect(mapStateToProps, {
  onLayoutChange: handleLayoutChange,
  onClose: handleClose
})(Layout);
