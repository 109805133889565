/* eslint-disable global-require */
export default (idx, face = 'front', active = false) => {
  if (face === 'front') {
    if (active === false) {
      switch (idx) {
        case 1:
          return require('./front/human-body_01.png');
        case 2:
          return require('./front/human-body_02.png');
        case 3:
          return require('./front/human-body_03.png');
        case 4:
          return require('./front/human-body_04.png');
        case 5:
          return require('./front/human-body_05.png');
        case 6:
          return require('./front/human-body_06.png');
        case 7:
          return require('./front/human-body_07.png');
        case 8:
          return require('./front/human-body_08.png');
        case 9:
          return require('./front/human-body_09.png');
        case 10:
          return require('./front/human-body_10.png');
        case 11:
          return require('./front/human-body_11.png');
        case 12:
          return require('./front/human-body_12.png');
        case 13:
          return require('./front/human-body_13.png');
        case 14:
          return require('./front/human-body_14.png');
        case 15:
          return require('./front/human-body_15.png');
        case 16:
          return require('./front/human-body_16.png');
        case 17:
          return require('./front/human-body_17.png');
        case 18:
          return require('./front/human-body_18.png');
        case 19:
          return require('./front/human-body_19.png');
        default:
          return require('./front/human-body_20.png');
      }
    } else {
      switch (idx) {
        case 1:
          return require('./front-active/human-body_01.png');
        case 2:
          return require('./front-active/human-body_02.png');
        case 3:
          return require('./front-active/human-body_03.png');
        case 4:
          return require('./front-active/human-body_04.png');
        case 5:
          return require('./front-active/human-body_05.png');
        case 6:
          return require('./front-active/human-body_06.png');
        case 7:
          return require('./front-active/human-body_07.png');
        case 8:
          return require('./front-active/human-body_08.png');
        case 9:
          return require('./front-active/human-body_09.png');
        case 10:
          return require('./front-active/human-body_10.png');
        case 11:
          return require('./front-active/human-body_11.png');
        case 12:
          return require('./front-active/human-body_12.png');
        case 13:
          return require('./front-active/human-body_13.png');
        case 14:
          return require('./front-active/human-body_14.png');
        case 15:
          return require('./front-active/human-body_15.png');
        case 16:
          return require('./front-active/human-body_16.png');
        case 17:
          return require('./front-active/human-body_17.png');
        case 18:
          return require('./front-active/human-body_18.png');
        case 19:
          return require('./front-active/human-body_19.png');
        default:
          return require('./front-active/human-body_20.png');
      }
    }
  } else if (active === false) {
    switch (idx) {
      case 1:
        return require('./back/human-body_01.png');
      case 2:
        return require('./back/human-body_02.png');
      case 3:
        return require('./back/human-body_03.png');
      case 4:
        return require('./back/human-body_04.png');
      case 5:
        return require('./back/human-body_05.png');
      case 6:
        return require('./back/human-body_06.png');
      case 7:
        return require('./back/human-body_07.png');
      case 8:
        return require('./back/human-body_08.png');
      case 9:
        return require('./back/human-body_09.png');
      case 10:
        return require('./back/human-body_10.png');
      case 11:
        return require('./back/human-body_11.png');
      case 12:
        return require('./back/human-body_12.png');
      case 13:
        return require('./back/human-body_13.png');
      case 14:
        return require('./back/human-body_14.png');
      case 15:
        return require('./back/human-body_15.png');
      case 16:
        return require('./back/human-body_16.png');
      case 17:
        return require('./back/human-body_17.png');
      case 18:
        return require('./back/human-body_18.png');
      case 19:
        return require('./back/human-body_19.png');
      default:
        return require('./back/human-body_20.png');
    }
  } else {
    switch (idx) {
      case 1:
        return require('./back-active/human-body_01.png');
      case 2:
        return require('./back-active/human-body_02.png');
      case 3:
        return require('./back-active/human-body_03.png');
      case 4:
        return require('./back-active/human-body_04.png');
      case 5:
        return require('./back-active/human-body_05.png');
      case 6:
        return require('./back-active/human-body_06.png');
      case 7:
        return require('./back-active/human-body_07.png');
      case 8:
        return require('./back-active/human-body_08.png');
      case 9:
        return require('./back-active/human-body_09.png');
      case 10:
        return require('./back-active/human-body_10.png');
      case 11:
        return require('./back-active/human-body_11.png');
      case 12:
        return require('./back-active/human-body_12.png');
      case 13:
        return require('./back-active/human-body_13.png');
      case 14:
        return require('./back-active/human-body_14.png');
      case 15:
        return require('./back-active/human-body_15.png');
      case 16:
        return require('./back-active/human-body_16.png');
      case 17:
        return require('./back-active/human-body_17.png');
      case 18:
        return require('./back-active/human-body_18.png');
      case 19:
        return require('./back-active/human-body_19.png');
      default:
        return require('./back-active/human-body_20.png');
    }
  }
};
