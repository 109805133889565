import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import NotificationSystem from 'react-notification-system';

import { updateDoc } from '../../Couchbase';
import { renderField, Panel } from '../../components';
import { isEmail } from '../../utils';
import { getAppSettings } from '../../selectors';

class AppManagement extends Component {
  handleSubmit = values =>
    updateDoc(values, status => {
      const { t } = this.props;
      if (status) {
        this.notificationSystem.addNotification({
          message: t('Daten erfolgreich editiert'),
          level: 'success'
        });
      }
    });

  render() {
    const {
      handleSubmit,
      initialValues,
      pristine,
      submitting,
      t,
      invalid
    } = this.props;

    return (
      <Panel id="management" title={t('E-Mail Benachrichtigung')}>
        <NotificationSystem
          ref={c => {
            this.notificationSystem = c;
          }}
        />
        <div style={{ margin: 8 }}>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <Field
              name="notifications.MaterialOrder.to"
              type="email"
              component={renderField}
              label={t('bei Bestellungen')}
            />
            <Field
              name="notifications.PatientTreatment.to"
              type="email"
              component={renderField}
              label={t('bei Patientenanmeldung')}
            />
            {// TODO HACK this is only to prevent welcome emails to be send out in case of srz app, as these link to ops in app store
            // TODO generalise to have custom email templates
            initialValues.appId.indexOf('srz') < 0 && (
              <Field
                name="notifications.User.subject"
                type="text"
                component={renderField}
                label={t('Titel der Willkommens E-Mail an neue Benutzer')}
              />
            )}
            <button
              type="submit"
              disabled={pristine || submitting || invalid}
              className="btn btn-primary pull-right"
              style={{ marginRight: '8%', marginBottom: '10px' }}
            >
              {t('Speichern')}
            </button>
          </form>
        </div>
      </Panel>
    );
  }
}

const validate = ({ notifications }) => {
  if (!notifications) return {};

  const errors = { notifications: { MaterialOrder: {}, PatientTreatment: {} } };
  if (
    notifications.MaterialOrder.to &&
    !isEmail(notifications.MaterialOrder.to)
  ) {
    errors.notifications.MaterialOrder = { to: 'Invalid email address' };
  }

  if (
    notifications.PatientTreatment.to &&
    !isEmail(notifications.PatientTreatment.to)
  ) {
    errors.notifications.PatientTreatment = { to: 'Invalid email address' };
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    initialValues: getAppSettings(state)
  };
}

export default connect(mapStateToProps)(
  reduxForm(
    {
      form: 'ManagementForm',
      enableReinitialize: true,
      validate
    },
    mapStateToProps
  )(AppManagement)
);
