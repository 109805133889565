import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  Fields,
  reduxForm,
  formValueSelector,
  change
} from 'redux-form';
import { translate } from 'react-i18next';

import { getEvents, getBases, getUsers, getAppSettings } from '../../selectors';
import { Dialog, DialogHeader, renderField } from '../../components';
import { getQRCodeUrl } from '../../config';

import { prepareCodePDF } from './preparePDF';

import {
  renderRoleField,
  renderSwitchField,
  renderMultiselectField,
  renderDateTimeField,
  renderSelectField
} from './renderFields';

class EditQRDialog extends Component {
  handlePrint = () => {
    const { events, bases, _id, properties, t } = this.props;
    // encapsule by QR-properties field
    prepareCodePDF([{ properties, _id }], t, bases, events);

    this.handleCancel();
  };

  handleSubmit = values => {
    const { succesHandler } = this.props;

    succesHandler(values);
  };

  handleCancel = () => {
    const { errorHandler } = this.props;
    errorHandler();
  };

  render() {
    const {
      caption,
      errorHandler,
      events,
      bases,
      users,
      _id,
      properties,
      setAllEventsBasesTags,
      tags,
      allEventsSelected,
      isAdmin,
      handleSubmit,
      t,
      invalid,
      pristine,
      showValidateBy
    } = this.props;

    return (
      <Dialog className="edit-qr-dialog">
        <DialogHeader title={caption} onClose={errorHandler} />
        <form
          onSubmit={handleSubmit(this.handleSubmit)}
          onKeyPress={e => {
            if (e.charCode === 13) e.preventDefault();
          }}
        >
          <div className="edit-qr-dialog-content">
            <div className="row">
              <div className="col-md-4">
                <img alt="QR-Code" width={200} src={getQRCodeUrl(_id)} />
              </div>

              <div className="col-md-8">
                <Field
                  name="properties.title"
                  label={t('Description')}
                  component={renderField}
                />

                <Field
                  name="properties.company"
                  label={t('Firma')}
                  component={renderField}
                />

                <div className="row">
                  <div className="col-md-6">
                    <Field
                      name="properties.validFrom"
                      label={t('Gültig ab')}
                      component={renderDateTimeField}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="properties.validUntil"
                      label={t('Gültig bis')}
                      component={renderDateTimeField}
                    />
                  </div>
                </div>

                <Fields
                  names={['properties.color', 'properties.role']}
                  component={renderRoleField}
                  label={t('Funktion')}
                />

                {showValidateBy && (
                  <Field
                    name="properties.validateBy"
                    component={renderSelectField}
                    label={t('Starke Authentisierung durch')}
                    options={users}
                  />
                )}

                <Field
                  name="properties.admin"
                  label="Admin"
                  type="checkbox"
                  component={renderSwitchField}
                />

                <Field
                  name="properties.events"
                  label={t('Alle Events')}
                  component={renderMultiselectField}
                  options={events}
                  disabled={isAdmin}
                  onChange={(event, value) => {
                    if (value === null) {
                      setAllEventsBasesTags(properties);
                    }
                  }}
                />

                <Field
                  name="properties.bases"
                  label={t('Alle Posten')}
                  component={renderMultiselectField}
                  options={bases}
                  disabled={isAdmin || allEventsSelected}
                />

                <Field
                  name="properties.tags"
                  label={t('Alle Tags')}
                  component={renderMultiselectField}
                  options={tags}
                  disabled={isAdmin || allEventsSelected}
                />
              </div>
            </div>
          </div>

          <div className="edit-qr-dialog-footer">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleCancel}
            >
              {t('Abbrechen')}
            </button>
            <button
              type="submit"
              className="btn btn-primary pull-right"
              disabled={pristine || invalid}
            >
              {t('Speichern')}
            </button>
            <button
              type="button"
              className="btn btn-success pull-right"
              onClick={this.handlePrint}
              style={{ marginRight: 10 }}
            >
              {t('Drucken')}
            </button>
          </div>
        </form>
      </Dialog>
    );
  }
}

const validate = ({ properties }) => {
  if (!properties) {
    return null;
  }

  const { eventId, baseId, validFrom, validUntil, color } = properties;

  const errors = {
    properties: {}
  };

  if (!eventId) errors.properties.eventId = 'Required';
  if (!baseId) errors.properties.baseId = 'Required';
  if (!validFrom) errors.properties.validFrom = 'Required';
  if (!validUntil) errors.properties.validUntil = 'Required';
  if (!color) errors.properties.color = 'Required';

  return errors;
};

const EditQRDialogWithForm = reduxForm({
  form: 'editQRCodeForm',
  validate
})(translate('translations')(EditQRDialog));

const selector = formValueSelector('editQRCodeForm');

function mapStateToProps(state) {
  const properties = selector(state, 'properties') || {};
  const { events: selectedEvents, admin: isAdmin } = properties;

  const allEventsSelected = selectedEvents === null;

  const bases = getBases(state).filter(
    b =>
      !Array.isArray(selectedEvents) || selectedEvents.indexOf(b.eventId) >= 0
  );

  const events = getEvents(state);

  const tags = _.unionBy(
    ...bases.map(b => (Array.isArray(b.tags) ? b.tags : [])),
    'value'
  );

  const users = getUsers(state);
  const appSettings = getAppSettings(state);

  return {
    _id: selector(state, '_id'),
    events: events.map(e => ({ value: e._id, label: e.name })),
    bases: bases.map(b => ({ value: b._id, label: b.name })),
    users: users.map(u => ({ value: u._id, label: u.name })),
    tags,
    allEventsSelected,
    isAdmin,
    properties,
    showValidateBy: appSettings.requiresTwoFactorAuth
  };
}

export default connect(mapStateToProps, dispatch => ({
  setAllEventsBasesTags: properties => {
    dispatch(
      change('editQRCodeForm', 'properties', {
        ...properties,
        events: null,
        bases: null,
        tags: null
      })
    );
  }
}))(translate('translations')(EditQRDialogWithForm));
