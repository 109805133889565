/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint react/no-array-index-key : 0 */
import React from 'react';
import { DropTarget, DragSource } from 'react-dnd';
import { ItemTypes } from '../constants';

import ListItem from './ListItem';

const listItemTarget = {
  drop({ handleDrop }, monitor) {
    const { payload } = monitor.getItem();

    // handle the dropped payload
    if (handleDrop) {
      handleDrop(payload);
    }
  },
  canDrop() {
    return true;
  }
};

const collectTrg = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
});

const ListItemDragableComp = ({
  connectDropTarget,
  canDrop,
  isOver,
  connectDragSource,
  ...otherProps
}) =>
  connectDragSource(
    connectDropTarget(
      <div
        className={`${isOver ? 'dropTarget-is-over' : ''} ${
          canDrop ? 'dropTarget' : ''
        }`}
      >
        <ListItem {...otherProps} />
      </div>
    )
  );

// accept messages to be dropped
const ListItemDragable = DropTarget(
  ItemTypes.MESSAGE,
  listItemTarget,
  collectTrg
)(ListItemDragableComp);

const itemSource = {
  beginDrag(props) {
    // get item
    const { _id, value, details } = props;
    console.log('drag src', _id, value, details);
    return {
      _id,
      title: value,
      details
    };
  }

  // endDrag(props, monitor, component) {
  //   if (!monitor.didDrop()) {
  //     // You can check whether the drop was successful
  //     // or if the drag ended but nobody handled the drop
  //     return
  //   }

  //   // When dropped on a compatible target, do something.
  //   // Read the original dragged item from getItem():
  //   const item = monitor.getItem();

  //   // You may also read the drop result from the drop target
  //   // that handled the drop, if it returned an object from
  //   // its drop() method.
  //   const dropResult = monitor.getDropResult();

  //   // This is a good place to call some Flux action
  //   console.log('action dropEnd', item, dropResult);
  // }
};

const collectSrc = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
});

// make item draggable
export default DragSource(
  ItemTypes.ITEM,
  itemSource,
  collectSrc
)(ListItemDragable);
