import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import * as Fuse from 'fuse.js';

import { REMOVE, EDIT } from '../../../constants';
import { Panel, ListHeader, List, QuestionDialog } from '../../../components';
import { updateDoc, deleteDoc } from '../../../Couchbase';
import { timeConverter } from '../../../utils';
import { ordersDialogStyle, questionDialogStyles } from '../../../ModalStyles';

import {
  getFilteredBases,
  getFilteredMaterialOrders,
  getCurrentUser,
  getAppSettings
} from '../../../selectors';

import EditMaterialOrderDialog from './EditMaterialOrderDialog';

import { downloadCSV } from '../exportHelper';

const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    'doc.userName',
    'doc.baseName',
    'doc.eventName',
    'doc.data.category',
    'doc.data.name',
    'doc.data.units'
  ]
};

class MaterialOrders extends Component {
  state = {
    functionType: undefined,
    materialOrderDoc: undefined,
    baseDoc: undefined,
    searchActive: false
  };

  setUpMaterialData = () => {
    const rowData = [];
    const { materialOrders, bases, appSettings, currentUser, t } = this.props;
    materialOrders
      .sort((p1, p2) => p1.ts > p2.ts)
      .forEach(p => {
        const value = `${p.data.length} ${t('Artikel für Posten')} ${
          p.baseName
        }`;
        const tsString = timeConverter(p.ts);
        const details = `${t('Bestellt am')} ${tsString}\n${t('von')} ${
          p.userName
        }`;

        const baseDoc = bases.find(b => p.baseId === b._id);

        const data = {
          title: value,
          _id: p._id,
          ts: p.ts,
          details,
          rowAction: () => {
            this.openModal(p, baseDoc, EDIT);
          },
          leftIcon: {
            icon: 'shopping-cart',
            indicator: {
              visible: p.status === 'new',
              color: '#4B91E3'
            }
          },
          doc: p
        };
        if (currentUser && currentUser.admin) {
          data.rightIcons = [
            {
              icon: 'trash',
              action: () => {
                this.openModal(p, baseDoc, REMOVE);
              }
            }
          ];
        }

        rowData.push(data);
      });
    return rowData.sort((a, b) => b.ts - a.ts); // sort decreasing
  };

  openModal = (mDoc, baseDoc, functionType) => {
    const materialOrderDoc = { ...mDoc };
    if (materialOrderDoc.status === 'new') {
      materialOrderDoc.status = 'seen';
      updateDoc(materialOrderDoc, (status, newDoc) => {
        if (status) {
          this.setState({ materialOrderDoc: newDoc });
        }
      });
    }
    this.setState({
      modalIsOpen: true,
      functionType,
      baseDoc,
      materialOrderDoc
    });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  closeModalSucces = materialOrderDoc => {
    this.setState({ modalIsOpen: false });
    const { t } = this.props;

    switch (this.state.functionType) {
      case REMOVE:
        if (this.state.materialOrderDoc) {
          deleteDoc(this.state.materialOrderDoc, status => {
            if (status) {
              this.setState({ materialOrderDoc: undefined });

              this.notificationSystem.addNotification({
                message: t('Materialbestellung erfolgreich gelöscht!'),
                level: 'success'
              });
            }
          });
        }
        break;
      default:
        // create and edit is the same code
        if (materialOrderDoc) {
          updateDoc(materialOrderDoc, status => {
            if (status) {
              this.notificationSystem.addNotification({
                message: t('Materialbestellung erfolgreich editiert.'),
                level: 'success'
              });
            }
          });
        }
    }
  };

  exportCSV = () => {
    const data = [];
    this.props.materialOrders.forEach(
      ({
        _id: id,
        eventId,
        baseId,
        ts,
        eventName,
        baseName,
        userName,
        data
      }) => {
        data.forEach(({ name, units }) => {
          data.push({
            id,
            ts,
            eventId,
            baseId,
            dateTime: timeConverter(ts),
            eventName,
            baseName,
            userName,
            name,
            units
          });
        });
      }
    );

    downloadCSV({
      filename: 'Materialbestellung.csv',
      data
    });
  };

  getFunctionButtons = (appSettings, currentUser) => {
    const res = [
      {
        action: () => {
          this.setState({ searchActive: !this.state.searchActive });
        },
        icon: 'search',
        active: this.state.searchActive,
        tooltip: this.props.t('Bestellungen durchsuchen')
      }
    ];
    if (currentUser.admin) {
      res.push({
        icon: 'file-download',
        action: () => {
          this.exportCSV();
        }
      });
    }
    return res;
  };

  search = (searchTerm, dataList) => {
    if (searchTerm.length < 2) {
      return dataList;
    }
    const fuse = new Fuse(dataList, SEARCH_OPTIONS);
    return fuse.search(searchTerm);
  };

  handleSearch = searchValue => this.setState({ searchValue });

  render() {
    let newCount = 0;
    const { materialOrders, appSettings, currentUser, t, onClose } = this.props;
    const {
      modalIsOpen,
      functionType,
      materialOrderDoc,
      baseDoc,
      searchActive,
      searchValue
    } = this.state;
    materialOrders.forEach(({ status }) => {
      if (status === 'new') newCount += 1;
    });

    const rowData = this.setUpMaterialData();
    const currentRowData =
      searchActive && searchValue ? this.search(searchValue, rowData) : rowData;

    return (
      <Panel title={t('Bestellungen')} id="patients" onClose={onClose}>
        <ListHeader
          functionButtons={this.getFunctionButtons(appSettings, currentUser)}
          count={materialOrders.length}
          searchActive={searchActive}
          onSearch={this.handleSearch}
        />
        <div
          style={{
            height: searchActive ? 'calc(100% - 120px)' : 'calc(100% - 80px)'
          }}
        >
          <List rowData={currentRowData} />
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={
            functionType === REMOVE ? questionDialogStyles : ordersDialogStyle
          }
          contentLabel="deleteAlert"
        >
          {functionType === REMOVE ? (
            <QuestionDialog
              caption={t('Löschen')}
              content={t(
                'Sind sie sicher, dass sie den Eintrag löschen wollen?'
              )}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
            />
          ) : (
            <EditMaterialOrderDialog
              materialOrderDoc={materialOrderDoc}
              iconUrl={appSettings.assets.cartIconUrl}
              baseDoc={baseDoc}
              errorHandler={this.closeModal}
              succesHandler={this.closeModalSucces}
            />
          )}
        </Modal>
        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    appSettings: getAppSettings(state),
    materialOrders: getFilteredMaterialOrders(state),
    bases: getFilteredBases(state),
    currentUser: getCurrentUser(state)
  };
}

export default connect(mapStateToProps)(MaterialOrders);
