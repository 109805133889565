import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Fuse from 'fuse.js';
import _ from 'lodash';

import { List, Panel, ListHeader } from '../../../components';
import {
  getFilteredBasesCanLogIn,
  getFilteredMaterialOrders,
  getFilteredPatients,
  getFilteredDevices,
  getFilteredDeviceLocations,
  getUsers,
  getAppSettings,
  getPermissions
} from '../../../selectors';

import { mapViewportChange } from '../actions';

import { timeSinceNowString } from '../../../utils';

const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['doc.name']
};

class BasesOverview extends Component {
  state = {
    count: 0,
    searchActive: false
  };

  componentWillMount() {
    // trigger redraw at least every min
    this.timeout = setInterval(() => {
      this.forceUpdate();
    }, 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  setUpOverviewData = () => {
    const rowData = [];
    const {
      bases,
      materialOrders,
      patients,
      devices,
      deviceLocation,
      mapViewportChange,
      t,
      permissions,
      appSettings: {
        assets: { indicatorRedIconUrl, indicatorGreenIconUrl, locationIconUrl }
      }
    } = this.props;

    bases.forEach(b => {
      const title = b.name;
      let orders = 0;
      materialOrders.forEach(m => {
        if (m.baseId === b._id) {
          // grab the id .split(':')[3]
          orders += 1;
        }
      });
      let patientCnt = 0;
      patients.forEach(p => {
        if (p.baseId === b._id) {
          // .split(':')[3]
          patientCnt += 1;
        }
      });
      let details = '';
      // only show infos if the permissions are granted
      if (
        !_.isEmpty(permissions) &&
        permissions.dashboard.materialOrders.permissionGranted
      ) {
        details += `${orders} ${t('Bestellungen')}, `;
      }
      if (
        !_.isEmpty(permissions) &&
        permissions.dashboard.patients.permissionGranted
      ) {
        details += `${patientCnt} ${t('Patienten')},`;
      }

      let lastSeen = 0;
      let lastActive = 0;
      let loggedIn;
      let oneUserOnlineFlag = false;

      for (let i = 0; i < devices.length; i += 1) {
        if (devices[i].baseId === b._id) {
          let locationDoc;
          // check if a location doc exists
          if (devices[i].deviceLocationId) {
            locationDoc = deviceLocation.find(
              res => res._id === devices[i].deviceLocationId
            );
          }

          // check if the user is logged in
          loggedIn = devices[i].loggedIn;

          // break when the first logged in user is found
          if (loggedIn) {
            oneUserOnlineFlag = true;
            details += `\n${devices[i].userName}`;
            details += `\n  ${t('zul. aktiv um')} ${timeSinceNowString(
              devices[i].lastActive
            )}`;

            // check if this users last active is more recent
            lastActive =
              devices[i].lastActive < lastActive
                ? lastActive
                : devices[i].lastActive;

            if (locationDoc) {
              lastSeen =
                parseInt(locationDoc.ts / 1000, 10) < lastSeen
                  ? lastSeen
                  : parseInt(locationDoc.ts / 1000, 10); // set lastSeen
              details += `\n  ${t('zul. ges. um')} ${timeSinceNowString(
                parseInt(locationDoc.ts / 1000, 10)
              )}`;
            } else {
              lastSeen = lastActive < lastSeen ? lastSeen : lastActive;
            }
          }
        }
      }

      const isRecent = (ts, oneUserOnlineFlag) => {
        if (oneUserOnlineFlag === false) {
          return false;
        }
        const nowTS = parseInt(new Date() / 1000, 10);
        const tenMinInSec = 600;
        if (ts > nowTS - tenMinInSec) {
          return true;
        }
        return false;
      };

      const ts = lastActive < lastSeen ? lastSeen : lastActive;
      rowData.push({
        title,
        details,
        _id: b._id,
        ts,
        leftIcon: {
          icon: 'map-marker-alt',
          hoverInAction: () => {
            mapViewportChange({ center: b.geometry.coordinates, zoom: 16 });
          },
          indicator: {
            visible: true,
            pulse: isRecent(ts, oneUserOnlineFlag),
            color: isRecent(ts, oneUserOnlineFlag) ? '#0BC819' : '#D0011B'
          }
        },
        doc: b
      });
    });

    return rowData;
  };

  setUpFunctionData = () => [
    {
      action: () => {
        this.setState({ searchActive: !this.state.searchActive });
      },
      icon: 'search',
      active: this.state.searchActive,
      tooltip: this.props.t('Posten durchsuchen')
    }
  ];

  search = (searchTerm, dataList) => {
    if (searchTerm.length < 2) {
      return dataList;
    }
    const fuse = new Fuse(dataList, SEARCH_OPTIONS);
    return fuse.search(searchTerm);
  };

  render() {
    const { count, searchActive, searchValue } = this.state;
    const { onClose, t } = this.props;

    const rowData = this.setUpOverviewData();
    const currentRowData =
      searchActive && searchValue ? this.search(searchValue, rowData) : rowData;
    return (
      <Panel title={t('Übersicht')} id="overview" onClose={onClose}>
        <ListHeader
          headerTitle={t('Posten')}
          functionButtons={this.setUpFunctionData()}
          count={count}
          searchActive={searchActive}
          onSearch={value => this.setState({ searchValue: value })}
        />
        <div
          style={{
            height: searchActive ? 'calc(100% - 120px)' : 'calc(100% - 80px)'
          }}
        >
          <List rowData={currentRowData} />
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    bases: getFilteredBasesCanLogIn(state),
    materialOrders: getFilteredMaterialOrders(state),
    patients: getFilteredPatients(state),
    devices: getFilteredDevices(state),
    deviceLocation: getFilteredDeviceLocations(state),
    users: getUsers(state),
    appSettings: getAppSettings(state),
    permissions: getPermissions(state)
  };
}

export default connect(mapStateToProps, {
  mapViewportChange
})(BasesOverview);

// TODO fix search
