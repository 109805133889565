import _ from 'lodash';
import React from 'react';
import chroma from 'chroma-js';
import { GithubPicker } from 'react-color';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

import { connect } from 'react-redux';

import { updateDoc } from '../Couchbase';
import { getAppSettings } from '../selectors';

const { CrossIcon } = components;

const defaultColors = [
  '#ed4d70',
  '#f6a623',
  '#9658ce',
  '#4990e2',
  '#b0b0b0',
  '#5cd556',
  '#5fd4d9'
]; // Note need to be lower case!

const customStyles = {
  singleValue: (provided, { data: { color } }) => ({
    ...provided,
    backgroundColor: color || 'white',
    color: chroma.contrast(color || 'white', 'white') > 2 ? 'white' : 'black',
    borderRadius: 4,
    padding: 4,
    fontWeight: 'bold'
  })
};

const createCustomOption = handleRemove => ({
  innerRef,
  innerProps,
  data,
  label,
  isFocused,
  isDisabled
}) => {
  let backgroundColor;
  let textColor;

  const color = chroma(data.color || 'white');

  if (isFocused) {
    backgroundColor = color.hex();
    textColor = color.darken(2).hex();
  } else if (isDisabled) {
    backgroundColor = null;
    textColor = '#333333';
  } else {
    backgroundColor = color.hex();
    textColor = chroma.contrast(color, 'white') > 2 ? 'white' : 'black';
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          backgroundColor,
          borderRadius: 4,
          margin: 4,
          width: '100%',
          paddingLeft: 4,
          paddingTop: 4,
          paddingBottom: 4,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'left',
          color: textColor
        }}
      >
        {label}
      </div>
      <CrossIcon
        style={{
          color: '#6B6B6B',
          ':hover': { color: '#000000' },
          marginRight: 4
        }}
        onClick={() => handleRemove(data)}
      />
    </div>
  );
};

class RolePicker extends React.Component {
  state = {
    roles: undefined,
    displayColorPicker: false
  };

  static getDerivedStateFromProps({ appSettings }, state) {
    const roles =
      appSettings && Array.isArray(appSettings.roles) ? appSettings.roles : [];

    if (state.roles === undefined) {
      return {
        roles
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { roles } = this.state;
    if (roles !== prevState.roles) {
      const { appSettings } = this.props;

      updateDoc({ ...appSettings, roles }, status => {
        if (!status) {
          console.error(status);
        }
      });
    }
  }

  handleCreateOption = value => {
    const newRole = { color: 'white', label: value, value };

    this.handleChange(newRole);
    this.setState({ displayColorPicker: true });
  };

  setSelectedColor = ({ hex: color }) => {
    const { selectedRole } = this.props;
    const { roles } = this.state;

    // set color
    const role = { ...selectedRole, color };

    this.handleChange(role);
    this.setState({
      displayColorPicker: false,
      roles: [...roles, role]
    });
  };

  handleChange = role => {
    const { onChange } = this.props;
    if (onChange) onChange(role);
  };

  handleRemove = role =>
    this.setState(state => ({
      roles: state.roles.filter(r => r.label !== role.label)
    }));

  render() {
    const { selectedRole } = this.props;
    const { roles, displayColorPicker } = this.state;

    return (
      <React.Fragment>
        <CreatableSelect
          value={selectedRole}
          options={roles}
          onChange={this.handleChange}
          onCreateOption={this.handleCreateOption}
          styles={customStyles}
          closeMenuOnSelect
          isClearable
          isDisabled={displayColorPicker}
          components={{ Option: createCustomOption(this.handleRemove) }}
        />
        {displayColorPicker && selectedRole && (
          <GithubPicker
            colors={_.union(
              defaultColors,
              roles.map(r => r.color)
            )}
            color={selectedRole.color}
            onChange={this.setSelectedColor}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  appSettings: getAppSettings(state)
});

export default connect(mapStateToProps)(RolePicker);
