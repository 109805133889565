/* eslint-disable no-underscore-dangle */
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { stringify } from 'query-string';

export const ONLINE = 'online';
export const OFFLINE = 'offline';

class SyncGatewayClient {
  constructor(url) {
    this.url = url.endsWith('/') ? url.slice(0, -1) : url;
    this.connectionStatus = new Subject();

    // when traffic stops for 30 seconds, ping db and retry every 2 seconds until successful
    this.connectionStatus
      .filter(({ status }) => status === ONLINE)
      .timeout(30 * 1000)
      .retryWhen(notifier =>
        notifier.flatMap(() =>
          this.dbInfo().retryWhen(error => error.delay(2000))
        )
      )
      .subscribe();
  }

  dbInfo = () =>
    Observable.ajax({
      url: `${this.url}/`,
      method: 'GET'
    })
      .map(({ response }) => response)
      .do(() => this.connectionStatus.next({ status: ONLINE }))
      .catch(error => {
        if (error.status === 0) {
          this.connectionStatus.next({ status: OFFLINE });
        }
        throw error;
      });

  allDocs = options =>
    Observable.ajax({
      url: `${this.url}/_all_docs?${stringify(options)}`,
      method: 'GET',
      withCredentials: true
    })
      .map(({ response }) => response)
      .do(() => this.connectionStatus.next({ status: ONLINE }))
      .catch(error => {
        if (error.status === 0) {
          this.connectionStatus.next({ status: OFFLINE });
        }
        throw error;
      });

  getChanges = options => {
    let seq = options.since || 0;

    return Observable.defer(() =>
      Observable.ajax({
        url: `${this.url}/_changes?${stringify({ ...options, since: seq })}`,
        method: 'GET',
        withCredentials: true
      })
    )
      .map(({ response }) => {
        seq = response.last_seq;

        // filter out _user and _role of sync gateway
        response.results = response.results.filter(
          change =>
            change.id.indexOf('_user') < 0 && change.id.indexOf('_role') < 0
        );

        return response;
      })
      .do(() => this.connectionStatus.next({ status: ONLINE }))
      .catch(error => {
        if (error.status === 0) {
          this.connectionStatus.next({ status: OFFLINE });
        }
        throw error;
      });
  };

  getChangesViaPost = options => {
    let seq = options.since || 0;

    return Observable.defer(() =>
      Observable.ajax({
        url: `${this.url}/_changes`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: { ...options, since: seq },
        withCredentials: true
      })
    )
      .map(({ response }) => {
        seq = response.last_seq;

        // filter out _user and _role of sync gateway
        response.results = response.results.filter(
          change =>
            change.id.indexOf('_user') < 0 && change.id.indexOf('_role') < 0
        );

        return response;
      })
      .do(() => this.connectionStatus.next({ status: ONLINE }))
      .catch(error => {
        if (error.status === 0) {
          this.connectionStatus.next({ status: OFFLINE });
        }
        throw error;
      });
  };

  putDoc = doc =>
    Observable.ajax({
      url: `${this.url}/${doc._id}`,
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: doc,
      withCredentials: true
    })
      .do(() => this.connectionStatus.next({ status: ONLINE }))
      .catch(error => {
        if (error.status === 0) {
          this.connectionStatus.next({ status: OFFLINE });
        }
        throw error;
      });

  getDoc = _id =>
    Observable.ajax({
      url: `${this.url}/${_id}`,
      headers: { 'Content-Type': 'application/json' },
      method: 'GET',
      withCredentials: true
    })
      .do(() => this.connectionStatus.next({ status: ONLINE }))
      .catch(error => {
        if (error.status === 0) {
          this.connectionStatus.next({ status: OFFLINE });
        }
        throw error;
      });

  deleteDoc = doc =>
    Observable.ajax({
      url: `${this.url}/${doc._id}?rev=${doc._rev}`,
      method: 'DELETE',
      withCredentials: true
    })
      .do(() => this.connectionStatus.next({ status: ONLINE }))
      .catch(error => {
        if (error.status === 0) {
          this.connectionStatus.next({ status: OFFLINE });
        }
        throw error;
      });

  upsertDoc = doc =>
    this.getDoc(doc._id)
      .do(() => this.connectionStatus.next({ status: ONLINE }))
      .catch(error => {
        // handle missing doc

        if (error.status === 0) {
          this.connectionStatus.next({ status: OFFLINE });
        }

        if (
          error.status === 404 // doc not found
        ) {
          // if doc is missing return empty
          return Observable.of({});
        }

        // else throw error
        throw error;
      })
      .flatMap(({ response }) => this.putDoc({ ...response, ...doc }));

  getConnectionStatusUpdates = () =>
    this.connectionStatus.distinctUntilKeyChanged('status');

  offline = () =>
    this.getConnectionStatusUpdates().filter(
      ({ status }) => status === OFFLINE
    );

  online = () =>
    this.getConnectionStatusUpdates().filter(({ status }) => status === ONLINE);
}

let client;

export const initializeClient = url => {
  client = new SyncGatewayClient(url);
};

export const getClient = () => client;

export default getClient;

// TODO refactor into operator so that code is not duplicated
// .do(() => this.connectionStatus.next({ status: ONLINE }))
// .catch((error) => {
//   if (error.status === 0) {
//     this.connectionStatus.next({ status: OFFLINE });
//   }
//   throw error;
// });
