import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-unresolved
import { View, StyleSheet, Platform } from 'react-native';

import getComponentByName from './getComponentByName';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...Platform.select({ web: { zIndex: 'auto' } })
  }
});

const FormInput = ({ options, onChange, value, width, t }) => (
  <View style={styles.container}>
    {options.map(({ label, key, name, component, properties }, idx) => {
      const Comp = getComponentByName(component);
      if (Comp === null) {
        return null;
      }

      const k = key || name || label || `${idx}`;
      const minWidth =
        properties && properties.small ? width * 0.4 : width * 0.8; // TODO better responsive layout
      return (
        <View
          key={k}
          style={{
            flex: 1,
            minWidth,
            flexGrow: 1,
            ...Platform.select({ web: { zIndex: 'auto' } })
          }}
        >
          {/**
          <Field
            onChange={change => onChange({ ...value, [key]: change }, key)}
            value={value[key]}
            render={({ onChange, value }) => (

            )}
          />
 */}
          <Comp
            onChange={change => onChange({ ...value, [k]: change }, k)}
            value={value[k]}
            label={label}
            {...properties}
            t={t}
          />
        </View>
      );
    })}
  </View>
);

// optionType
// { key: 'string', component: 'string', 'label': 'string', properties: {}}

FormInput.propTypes = {
  options: PropTypes.array, // TODO
  onChange: PropTypes.func,
  value: PropTypes.object, // TODO,
  width: PropTypes.number.isRequired,
  t: PropTypes.func
};

FormInput.defaultProps = {
  options: [],
  value: {},
  onChange: () => {},
  t: v => v
};

export default FormInput;

// TODO layout
// TODO validation / numbers / degrees ....
// row wrap minWidth / fillRow / large
