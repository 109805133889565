/* eslint react/no-array-index-key : 0 */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { translate } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tippy';
import SearchBar from '@opuscapita/react-searchbar';

class ListHeaderComp extends Component {
  componentDidUpdate() {
    const { searchActive } = this.props;
    if (searchActive && this.searchbar) {
      // TODO
      const x = ReactDOM.findDOMNode(this.searchbar);
      if (x && x.children.length > 0 && x.children[0].children.length > 0) {
        x.children[0].children[0].focus();
      }
    }
  }

  addFunctionButtons = () => {
    const { functionButtons } = this.props;
    if (functionButtons) {
      const buttons = [];
      functionButtons.forEach(({ icon, tooltip, url, active, action }, i) => {
        buttons.push(
          <Tooltip title={tooltip} disabled={!tooltip} key={i}>
            <button
              type="button"
              onClick={action}
              className="btn btn-default btn-sm"
              // style={{ height: 22, width: 22 }}
            >
              {icon ? (
                <FontAwesomeIcon
                  icon={icon}
                  color={active ? '#900' : '#464646'}
                />
              ) : (
                <img src={url} style={{ height: '16px' }} alt="action" />
              )}
            </button>
          </Tooltip>
        );
      });
      return <div className="btn-group pull-right">{buttons}</div>;
    }
    return null;
  };

  addCountBadge = () => {
    const { count, headerTitle } = this.props;
    if (count) {
      return (
        <span className="badge" style={headerTitle && { marginLeft: 8 }}>
          {count.toString()}
        </span>
      );
    }
    return null;
  };

  addSearchBar = () => {
    const { t, onSearch } = this.props;
    // TODO fix search bar
    return (
      <div className="list-header-search">
        <SearchBar
          ref={input => {
            this.searchbar = input;
          }}
          onSearch={onSearch}
          translations={{
            searchPlaceHolder: t('Suchen ...'),
            tooltip: ''
          }}
          minChars={2}
          isDynamic
          isTooltipEnabled={false}
        />
      </div>
    );
  };

  render() {
    const { headerTitle, searchActive } = this.props;
    return (
      <div className="list-header">
        {headerTitle}
        {this.addCountBadge()}
        {this.addFunctionButtons()}
        {searchActive && this.addSearchBar()}
      </div>
    );
  }
}

const ListHeader = translate('translations')(ListHeaderComp);
export default ListHeader;
