import moment from 'moment';
import { timeConverterString, printQRCodes } from '../../utils';

export const prepareCodePDF = (usedQrCodes, t, bases, events) => {
  // get codes and translation function, and all events, and bases for lookup
  const now = moment();
  const validCodes = usedQrCodes.filter(qr => {
    if (now < moment(qr.properties.validUntil)) return qr;
  });
  const printDocs = validCodes.map(qrCode => {
    const { properties, _id } = qrCode;
    const info = [
      // title
      properties.title && {
        text: ` ${properties.title}`,
        fontSize: 20,
        bold: true
      }, // refactor to style sheet

      // role and origin
      properties.role &&
        properties.color && {
          text: [
            { text: `${properties.role}`, background: `${properties.color}` },
            { text: ' ' },
            {
              text: `(${properties.admin ? t('Administrator') : t('Benutzer')}`
            },
            properties.company && { text: `, ${properties.company})` }
          ],
          fontSize: 10
        },

      // access rights
      {
        text: properties.events
          ? `${t('Events')}: ${events
              .filter(b => properties.events.indexOf(b._id) >= 0)
              .map(b => b.name)
              .join(', ')}`
          : `${t('Alle Events')}`,
        fontSize: 10
      },
      {
        text: properties.bases
          ? `${t('Posten')}: ${bases
              .filter(b => properties.bases.indexOf(b._id) >= 0)
              .map(b => b.name)
              .join(', ')}`
          : `${t('Alle Posten')}`,
        fontSize: 10
      },
      {
        text: properties.tags
          ? `${t('Tags')}: ${properties.tags
              .map(t => t.split(':').pop())
              .join(', ')}`
          : `${t('Alle Tags')}`,
        fontSize: 10
      },

      // validity
      {
        text: [
          { text: `${t('Gültigkeit')}` },
          { text: ` ${timeConverterString(properties.validFrom)}` },
          { text: ` - ${timeConverterString(properties.validUntil)}` }
        ],
        fontSize: 8,
        margin: [0, 10]
      }
    ];

    return { qrCode: _id, info };
  });

  printQRCodes(printDocs);
};
