import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { Panel } from '../../../components';
import {
  genderGraph,
  defaultPieChart,
  defaultBarChart,
  defaultStackedBarChart
} from '../../../components/Graphs';

import {
  getCurrentUser,
  getAppSettings,
  getFilteredPatients,
  getPatientForm
} from '../../../selectors';
import { COLOR_SCHEME } from '../heatmap/mapConsts';

// const colorPlatte = getColorPlatte();
const GRAPH_DEFS_PATIENT = {
  genderChart: {
    style: {
      barColor: COLOR_SCHEME
    }
  },
  defaultBarChart: {
    style: {
      fill: COLOR_SCHEME[0]
    }
  },
  defaultPieChart: {
    style: {
      pieColor: COLOR_SCHEME
    }
  },
  defaultStackedBarChart: {
    style: {
      barColor: [...COLOR_SCHEME]
    }
  }
};

// Example analyticsOptions
// const analyticsOptions = {
//   0: ['genderGraph'],
//   1: ['defaultPieChart'],
//   2: ['defaultBarGraph', 'stackedBarChart']
// };

class PatientStats extends Component {
  state = {
    stackedBarChart: {}
  };

  computeGenderGraph = index => {
    const { patients, t, form } = this.props;
    const { barColor } = GRAPH_DEFS_PATIENT.genderChart.style;
    const gender = [];
    form.data[index].options.forEach(({ label }) => {
      gender.push({
        name: t(label),
        value: patients.filter(
          p => p.data[index] && p.data[index].label === label
        ).length
      });
    });

    return genderGraph(
      gender,
      form.data[index].title,
      barColor,
      t,
      `patient-genderChart-${index}`
    );
  };

  computePieChart = index => {
    const { pieColor } = GRAPH_DEFS_PATIENT.defaultPieChart.style;
    const { patients, t, form } = this.props;
    const data = [];
    form.data[index].options.forEach(({ label }) => {
      data.push({
        name: t(label),
        value: patients.filter(
          p => p.data[index] && p.data[index].label === label
        ).length
      });
    });

    return defaultPieChart(
      data,
      form.data[index].title,
      pieColor,
      `patient-pieChart-${index}`
    );
  };

  handleOptionsChange = (index, value) => {
    const stackedBarChart = { ...this.state.stackedBarChart };
    stackedBarChart[index] = value;
    this.setState({ stackedBarChart });
  };

  computeStackedBarChart = index => {
    // 1 hour, 4 hours, 6 hours
    if (!this.state.stackedBarChart[index]) this.handleOptionsChange(index, 1);
    const tsInHours = this.state.stackedBarChart[index]
      ? this.state.stackedBarChart[index]
      : 1;
    const timestepInSec = tsInHours * 60 * 60;
    const secInADay = 24 * 60 * 60;

    const { patients, form, t } = this.props;
    const { barColor } = GRAPH_DEFS_PATIENT.defaultStackedBarChart.style;

    // init the data structure
    const data = {};
    let i = 0;
    const baseCase = {};

    const allLabels = form.data[index].options.map(({ label }) => label);

    while (i < 24) {
      baseCase.ts = `${i < 10 ? `0${i}` : i}:00`;

      data[i] = { ...baseCase };
      i += tsInHours;
    }

    patients.forEach(p => {
      const tsIndex =
        Math.floor((p.ts % secInADay) / timestepInSec) * tsInHours;
      const v = p.data[index].value || p.data[index];
      const values = Array.isArray(v) ? v : [v];

      values.forEach(({ label }) => {
        if (data[tsIndex][label]) {
          data[tsIndex][label] += 1;
        } else {
          data[tsIndex][label] = 1;
        }
      });
    });

    return (
      <div key={`patient-stackedBarChart-cont-${index}`}>
        {defaultStackedBarChart(
          Object.values(data),
          form.data[index].title,
          allLabels,
          barColor,
          t,
          `patient-stackedBarChart-${index}`
        )}
        <div className="row">
          <div className="col-sm-12">
            <form style={{ width: '100%', textAlign: 'center' }}>
              <div className="radio-inline">
                <label>
                  <input
                    type="radio"
                    value="option1"
                    checked={this.state.stackedBarChart[index] === 1}
                    onChange={() => this.handleOptionsChange(index, 1)}
                  />
                  {t('1 Stunde')}
                </label>
              </div>
              <div className="radio-inline">
                <label>
                  <input
                    type="radio"
                    value="option2"
                    checked={this.state.stackedBarChart[index] === 4}
                    onChange={() => this.handleOptionsChange(index, 4)}
                  />
                  {t('4 Stunde')}
                </label>
              </div>
              <div className="radio-inline">
                <label>
                  <input
                    type="radio"
                    value="option3"
                    checked={this.state.stackedBarChart[index] === 6}
                    onChange={() => this.handleOptionsChange(index, 6)}
                  />
                  {t('6 Stunde')}
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  computeBarChart = index => {
    const {
      patients,
      t,
      form,
      appSettings: { styles }
    } = this.props;
    let { fill } = GRAPH_DEFS_PATIENT.defaultBarChart.style;
    if (typeof fill === 'undefined' && styles) {
      fill = styles.menuBackgroundColor;
    }
    const data = [];
    form.data[index].options.forEach(({ label }) => {
      data.push({
        name: t(label),
        value: patients.filter(
          p => p.data[index] && p.data[index].label === label
        ).length
      });
    });

    return defaultBarChart(
      data,
      form.data[index].title,
      fill,
      t,
      `patient-barChart-${index}`
    );
  };

  getGraphs = () => {
    const { form } = this.props;

    if (!Array.isArray(form.data)) {
      return null;
    }

    return form.data.reduce((graphs, item, idx) => {
      // default
      let graphDefs = Array.isArray(item.options) ? ['defaultBarChart'] : [];

      // customized
      if (form.analyticsOptions !== undefined) {
        graphDefs = Array.isArray(form.analyticsOptions[idx])
          ? form.analyticsOptions[idx]
          : [];
      }

      graphDefs.forEach(graphDef => {
        if (graphDef === 'genderChart') {
          graphs.push(this.computeGenderGraph(idx));
        } else if (graphDef === 'defaultBarChart') {
          graphs.push(this.computeBarChart(idx));
        } else if (graphDef === 'defaultPieChart') {
          graphs.push(this.computePieChart(idx));
        } else if (graphDef === 'stackedBarChart') {
          graphs.push(this.computeStackedBarChart(idx));
        }
      });

      return graphs;
    }, []);
  };

  render() {
    const { t, onClose } = this.props;
    return (
      <Panel
        title={t('Patienten Statistik')}
        id="patients-stats"
        onClose={onClose}
      >
        <div
          style={{
            height: 'calc(100% - 40px)',
            overflowY: 'auto',
            padding: 16
          }}
        >
          {this.getGraphs()}
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    appSettings: getAppSettings(state),
    currentUser: getCurrentUser(state),
    patients: getFilteredPatients(state),
    form: getPatientForm(state)
  };
}

export default connect(mapStateToProps)(
  translate('translations')(PatientStats)
);
