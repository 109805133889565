import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  StyleSheet,
  TextInput,
  Platform
  // eslint-disable-next-line import/no-unresolved
} from 'react-native';

const styles = StyleSheet.create({
  container: {
    margin: 3,
    paddingTop: 16,
    flex: 1
    // flexGrow: 1
  },
  label: {
    fontSize: 12,
    fontWeight: '400',
    color: '#30424F',
    fontFamily: 'Helvetica'
  },
  input: {
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: '#FFFFFF',
    paddingBottom: 7,
    height: 40,
    fontSize: 16,
    fontWeight: '400',
    ...Platform.select({ web: { outlineStyle: 'none', height: 32 } })
  },
  line: {
    backgroundColor: '#30424F',
    height: 2
  }
});

const Input = ({
  onChange,
  value,
  label,
  inputProps,
  isDisabled,
  isMultiline,
  lines
}) => (
  <View style={styles.container}>
    <Text style={styles.label}>{label}</Text>
    <TextInput
      {...inputProps}
      style={[
        styles.input,
        { color: isDisabled ? '#333333' : '#30424F' },
        isMultiline && { height: lines * 20 }
      ]}
      value={value}
      editable={!isDisabled}
      multiline={isMultiline}
      onChangeText={onChange}
    />
    <View style={styles.line} />
  </View>
);

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  inputProps: PropTypes.object, // TODO
  isDisabled: PropTypes.bool,
  isMultiline: PropTypes.bool,
  lines: PropTypes.number
};

Input.defaultProps = {
  value: '',
  label: '',
  onChange: () => {},
  inputProps: {},
  isDisabled: false,
  isMultiline: false,
  lines: 3
};

export default Input;
