import * as types from './ActionTypes';

import { removeEmpty } from '../../utils';

export const filterByEvent = id => ({
  type: types.FILTER_BY_EVENT,
  payload: {
    id
  }
});

export const filterByBase = id => ({
  type: types.FILTER_BY_BASE,
  payload: {
    id
  }
});

export const filterByStart = ts => ({
  type: types.FILTER_BY_START,
  payload: {
    ts
  }
});

export const filterByEnd = ts => ({
  type: types.FILTER_BY_END,
  payload: {
    ts
  }
});

export const setTrajTS = tsInMills => ({
  type: types.FILTER_SET_TRAJ_TS,
  payload: {
    tsInMills
  }
});

export const resetDateFilters = () => ({
  type: types.FILTER_RESET,
  payload: {}
});

export const changeViewMode = viewMode => ({
  type: types.TOGGLE_VIEW_MODE,
  payload: {
    viewMode
  }
});

export const setLayouts = (route, layouts) => ({
  type: types.SET_LAYOUTS,
  route,
  layouts: removeEmpty(layouts)
});

export const addToLayout = (route, key) => ({
  type: types.ADD_TO_LAYOUT,
  route,
  key
});

export const removeFromLayout = (route, key) => ({
  type: types.REMOVE_FROM_LAYOUT,
  route,
  key
});

// TODO
// // ------------------------------- settings action persist to db ------------------------------------------------------
// export const toggleActiveComponent = (key, visible, doc) => {
//   const update = _.cloneDeep(doc);

//   if (!update.activeComponents) {
//     update.activeComponents = {};
//   }

//   update.activeComponents[key] = visible;

//   updateDoc(update, (status) => {
//     if (!status) {
//       console.error(status);
//     }
//   });
// };

// export const saveLayout = (route, layouts, doc) => {
//   const update = _.cloneDeep(doc);

//   if (!update.layouts) {
//     update.layouts = {};
//   }

//   if (!update.layouts[route]) {
//     update.layouts[route] = {};
//   }

//   update.layouts[route] = layouts;

//   updateDoc(update, (status) => {
//     if (!status) {
//       console.error(status);
//     }
//   });
// };
