import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { Text, TouchableOpacity, StyleSheet } from 'react-native';

const styles = {
  container: StyleSheet.create({
    base: {
      borderRadius: 3,
      margin: 3,
      height: 40,
      maxHeight: 40,
      padding: 3,
      borderStyle: 'solid',
      borderWidth: 1,
      flexGrow: 1,
      backgroundColor: '#33495D',
      borderColor: '#33495D'
    },
    primary: {},
    light: {
      backgroundColor: '#FFFFFF',
      borderColor: '#33495D'
    },
    active: {
      backgroundColor: '#DBFF49',
      borderColor: '#DBFF49'
    },
    warning: {
      backgroundColor: '#FF6436',
      borderColor: '#FF6436'
    },
    success: {
      backgroundColor: '#53CA21',
      borderColor: '#53CA21'
    }
  }),
  text: StyleSheet.create({
    base: {
      textAlign: 'center',
      textAlignVertical: 'center',
      flex: 1,
      fontSize: 16,
      paddingTop: 6,
      color: '#FFFFFF'
    },
    primary: {
      color: '#DBFF49'
    },
    light: {
      color: '#33495D'
    },
    active: {
      color: '#33495D'
    },
    warning: {
      color: '#FFFFFF'
    },
    success: {
      color: '#FFFFFF'
    }
  })
};

const Button = ({
  caption,
  onLayout,
  onPress,
  buttonStyle,
  textStyle,
  variant
}) => (
  <TouchableOpacity
    onPress={onPress}
    onLayout={onLayout}
    style={[styles.container.base, styles.container[variant], buttonStyle]}
  >
    <Text style={[styles.text.base, styles.text[variant], textStyle]}>
      {caption}
    </Text>
  </TouchableOpacity>
);

Button.propTypes = {
  onPress: PropTypes.func,
  onLayout: PropTypes.func,
  caption: PropTypes.string,
  textStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  buttonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  variant: PropTypes.oneOf(['primary', 'light', 'active', 'warning', 'success'])
};

Button.defaultProps = {
  onPress: () => {},
  onLayout: () => {},
  textStyle: {},
  buttonStyle: {},
  caption: '',
  variant: 'primary'
};

export default Button;
