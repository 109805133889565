export const HEIGHT = 1300;
export const WIDTH = 636;

export const FRONT_IDX_TO_KEY = [
  'Pupille-Rechts-Klein',
  'Kopf',
  'Pupille-Links-Klein',
  'Pupille-Rechts-Weit',
  'Pupille-Links-Weit',
  'Schulter-Rechts',
  'Thorax',
  'Schulter-Links',
  'Arm-Rechts',
  'Arm-Links',
  'Abdomen',
  'Hand-Rechts',
  'Becken',
  'Hand-Links',
  undefined,
  'Bein-Rechts',
  'Bein-Links',
  undefined,
  'Fuss-Rechts',
  'Fuss-Links'
];

export const BACK_IDX_TO_KEY = [
  undefined,
  'Kopf',
  undefined,
  undefined,
  undefined,
  'Schulter-Rechts',
  'Rücken-Oben',
  'Schulter-Links',
  'Arm-Rechts',
  'Arm-Links',
  'Rücken-Unten',
  'Hand-Rechts',
  'Gesäss',
  'Hand-Links',
  undefined,
  'Bein-Rechts',
  'Bein-Links',
  undefined,
  'Fuss-Rechts',
  'Fuss-Links'
];

export const IDX_TO_IDX = {
  front: FRONT_IDX_TO_KEY,
  back: BACK_IDX_TO_KEY
};
