import * as types from '../actionTypes';
import { TOGGLE_VIEW_MODE } from '../../organisation/ActionTypes';

const INITALSTATE = {
  extended: true
};

export default function(state = INITALSTATE, { type, payload }) {
  switch (type) {
    case types.SIDEMENU_TOGGLED:
      return { extended: !state.extended };
    case TOGGLE_VIEW_MODE:
      if (payload.viewMode !== 'custom') {
        return { extended: false };
      }
      return state;
    default:
      return state;
  }
}
