/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import reactCSS from 'reactcss';
import { GithubPicker } from 'react-color';

import { Tags } from '../../components';

const COLORS = [
  '#ED4D70',
  '#F6A623',
  '#9658CE',
  '#4990E2',
  '#43CBAD',
  '#B0B0B0'
];
class LeafletMapEditGIS extends Component {
  state = {
    displayColorPicker: false
  };

  componentDidMount() {
    if (this.nameInput) {
      // auto focus name
      this.nameInput.focus();
    }
  }

  handleKeyPress = ({ charCode }) => {
    if (charCode === 13) {
      // handle Enter
      const { onClose } = this.props;

      onClose();
    }
  };

  toggleColorPicker = () =>
    this.setState(state => ({ displayColorPicker: !state.displayColorPicker }));

  closeColorPicker = () => this.setState({ displayColorPicker: false });

  handleColorChange = ({ hex }) => {
    const { properties, onChange } = this.props;

    const updatedProperties = { ...properties };
    updatedProperties.fill = hex;

    this.setState({ displayColorPicker: false }, () =>
      onChange(updatedProperties)
    );
  };

  handleNameChange = event => {
    const { properties, onChange } = this.props;

    const updatedProperties = { ...properties };
    updatedProperties.name = event && event.target.value;
    onChange(updatedProperties);
  };

  handleSelectableChange = () => {
    const { properties, onChange } = this.props;

    const updatedProperties = { ...properties };
    updatedProperties.selectable = !properties.selectable;
    onChange(updatedProperties);
  };

  handleTagChange = selected => {
    const { properties, onChange, eventId } = this.props;

    const updatedProperties = { ...properties };
    updatedProperties.tags = selected.map(({ label }) => ({
      label,
      value: `${eventId}:Tag:${label.replace(/\s/g, '_')}`
    }));

    onChange(updatedProperties);
  };

  handleIconChange = iconUrl => {
    const { properties, onChange } = this.props;

    const updatedProperties = { ...properties };
    updatedProperties.icon.iconUrl = iconUrl;
    updatedProperties.icon.retinaIconUrl = iconUrl;
    onChange(updatedProperties);
  };

  handleOpacityChange = event => {
    const { properties, onChange } = this.props;

    const updatedProperties = {
      ...properties,
      opacity: Number.parseFloat(event.target.value)
    };
    onChange(updatedProperties);
  };

  renderMarkerIcons = () => {
    const { markerIcons } = this.props;

    if (!markerIcons) {
      return null;
    }

    return markerIcons.map((iconUrl, i) => (
      <div
        className="marker-container"
        key={i}
        onClick={() => this.handleIconChange(iconUrl)}
      >
        <img
          key={`marker-icon-${i}`}
          src={iconUrl}
          className="leaflet-map-info-icon"
          alt="icon"
        />
      </div>
    ));
  };

  render() {
    const { displayColorPicker } = this.state;

    const { tags, properties, visible, onClose, onDelete, t } = this.props;

    if (!visible || !properties) {
      return null;
    }

    const styles = reactCSS({
      default: {
        color: {
          // width: '50px',
          height: '20px',
          borderRadius: '2px',
          background: (properties && properties.fill) || '#ED4D70'
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    });

    return (
      <div className="leaflet-map-info">
        <FontAwesomeIcon
          pull="right"
          style={{ position: 'sticky', top: 0 }}
          icon="times"
          onClick={onClose}
        />

        <div className="form-group">
          <div className="row">
            <div className="col-md-12 text-center">
              {this.renderMarkerIcons()}
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="form-control-label" htmlFor="nameInput">
            {t('Name')}
          </label>
          <input
            placeholder={t('Name')}
            id="nameInput"
            type="text"
            ref={input => {
              this.nameInput = input;
            }}
            value={properties.name || ''}
            onKeyPress={this.handleKeyPress}
            onChange={this.handleNameChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label className="form-control-label" htmlFor="tags">
            {t('Tags')}
          </label>
          <Tags
            id="tags"
            suggestions={tags}
            tags={properties.tags}
            onChange={this.handleTagChange}
            placeholder={t('Neuen Tag anlegen')}
          />
        </div>

        {properties.fill && (
          <div className="form-group">
            <label className="form-control-label" htmlFor="color">
              {t('Farbe')}
            </label>
            <div style={styles.swatch} onClick={this.toggleColorPicker}>
              <div style={styles.color} />
            </div>
            {displayColorPicker && (
              <div style={styles.popover}>
                <div style={styles.cover} onClick={this.closeColorPicker} />
                <GithubPicker
                  color={properties.fill}
                  onChange={this.handleColorChange}
                  colors={COLORS}
                />
              </div>
            )}
          </div>
        )}

        {properties.opacity !== undefined && (
          <div className="form-group">
            <label className="form-control-label" htmlFor="opacity">
              {t('Opacity')}
            </label>
            <input
              type="range"
              id="opacity"
              name="Opacity"
              min={0}
              max={1}
              step={0.1}
              value={properties.opacity}
              style={{ marginRight: 10, marginTop: 10 }}
              onChange={this.handleOpacityChange}
            />
          </div>
        )}

        {properties.className === 'Base' && (
          <div className="form-group">
            <input
              id="selectableInput"
              type="checkbox"
              checked={!!properties.selectable}
              style={{ marginRight: 10, marginTop: 10 }}
              onChange={this.handleSelectableChange}
            />
            <label className="form-control-label" htmlFor="selectableInput">
              {t('User kann sich am Posten anmelden')}
            </label>
          </div>
        )}

        <div>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => onDelete(properties)}
          >
            {t('Löschen')}
          </button>
          <button
            type="button"
            className="btn btn-default  pull-right"
            onClick={onClose}
          >
            {t('Ok')}
          </button>
        </div>
      </div>
    );
  }
}

export default LeafletMapEditGIS;
