import React from 'react';

export default () => (
  <div>
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px'
      }}
    >
      <div className="logo">
        <div className="pulse-logo dot" id="biggest">
          <div className="pulse-logo dot" id="small" />
          <div className="pulse-logo dot" id="big" />
        </div>
      </div>
    </div>
  </div>
);
