import DatePicker from 'react-16-bootstrap-date-picker';
import Radium, { Style } from 'radium';
import React from 'react';

class DatePickerComp extends React.Component {
  render() {
    // Radium extends the style attribute to accept an array. It will merge
    // the styles in order. We use this feature here to apply the primary
    // or warning styles depending on the value of the `kind` prop. Since its
    // all just JavaScript, you can use whatever logic you want to decide which
    // styles are applied (props, state, context, etc).
    const {
      id,
      value,
      placeholder,
      onChange,
      className,
      color,
      placeholderColor,
      showClearButton,
      minDate,
      style
    } = this.props;
    return (
      <div>
        <Style
          scopeSelector={`.${className}::-webkit-input-placeholder`}
          rules={{
            color: placeholderColor
          }}
        />
        <DatePicker
          id={id}
          value={value}
          minDate={minDate}
          onKeyDown={() => false} // restrict user input
          placeholder={placeholder}
          onChange={onChange}
          className={className}
          style={{ color, ...style }}
          showClearButton={showClearButton}
        />
      </div>
    );
  }
}

const DatePickerWithStyle = Radium(DatePickerComp);
export { DatePickerWithStyle };
