import * as types from './actionTypes';

export const hoverIn = location => ({
  type: types.CHAT_LOCATION_HOVER_IN,
  payload: {
    location
  }
});

export const hoverOut = () => ({
  type: types.CHAT_LOCATION_HOVER_OUT,
  payload: {}
});

export const locationInit = () => ({
  type: types.CHAT_LOCATION_INIT,
  payload: {}
});

export const locationSet = location => ({
  type: types.CHAT_LOCATION_SET,
  payload: {
    location
  }
});

export const locationDone = () => ({
  type: types.CHAT_LOCATION_DONE,
  payload: {}
});

export const loadMoreMessages = () => ({
  type: types.CHAT_LOAD_MORE_MESSAGES,
  payload: {}
});

export const mapViewportChange = viewport => ({
  type: types.MAP_VIEWPORT_CHANGE,
  payload: { viewport }
});

export const mapLayerChange = checkedLayer => ({
  type: types.MAP_LAYER_CHANGE,
  payload: { checkedLayer }
});

export const toogleSidemenu = () => ({
  type: types.SIDEMENU_TOGGLED,
  payload: {}
});

export const updateLastSeenForChatGroup = group => ({
  type: types.LAST_SEEN_MESSAGES,
  group
});

export const setPrivateKey = (privateKey, eventId) => ({
  type: types.SET_PRIVATE_KEY,
  privateKey,
  eventId
});
