import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import FuzzySet from 'fuzzyset.js';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';
import jschardet from 'jschardet';

import { Dialog, DialogHeader, DialogContent } from '../../components';
// set the inital state and preassume a attribute to column mapping
const INITAL_STATE = {
  csv: undefined,
  attributeNames: {
    name: 'Name',
    surname: 'Surname',
    company: 'Company',
    email: 'E-Mail',
    phone: 'Telephone',
    admin: 'Admin',
    password: 'Password',
    color: 'Color'
  },
  attributeMap: {
    name: 0, // mandatory
    surname: 1,
    company: 2,
    email: 3, // mandatory
    phone: 4, // mandatory
    admin: 5,
    password: 6, // mandatory
    color: 7
  },
  rowHover: undefined,
  activeRows: undefined,
  allActive: true,
  hasHeader: true
};

const HEADER_SEPERATOR = 'HEADER_SEPERATOR';
const MIN_REQUIRED_ARRAY_FLIEDS = 8; // no header case, each data row needs at least 8 fields (out of bounds)

class ImportUserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATE;
  }

  // set the inital state on a mount (reset)
  componentWillUnmount() {
    this.setState(INITAL_STATE);
  }

  // get the name of the attributes and create a table header jsx element
  getTableHeader = () => {
    const { attributeNames } = this.state;
    const { t } = this.props;
    let tableHeaders = [];
    let j = 0;
    for (var key in attributeNames) {
      if (attributeNames.hasOwnProperty(key)) {
        // translate the table headers into the setted language
        tableHeaders.push(
          <th key={key} className={`csv-import-ckb-${j}`}>
            {t(attributeNames[key])}
          </th>
        );
        j++;
      }
    }

    return tableHeaders;
  };

  // update the state with a new column index for a attribute
  updateAttributeMapping = (key, newIndex) => {
    this.setState(prevState => ({
      attributeMap: {
        ...prevState.attributeMap,
        [key]: newIndex
      }
    }));
  };
  // get dropdown selection for the case without a header => dropdown contains numbers
  getDropDownRowNoHeader = () => {
    const { attributeMap } = this.state;
    const { t } = this.props;

    const allKeys = Object.keys(attributeMap);
    return allKeys.map((key, j) => {
      return (
        <td key={key} className={`csv-import-ckb-${j}`}>
          <Dropdown
            id={'dropdown-' + key}
            onSelect={i => {
              this.updateAttributeMapping(key, i);
            }}
          >
            <Dropdown.Toggle bsStyle="default">
              {`${attributeMap[key].toString()} ${t('Spalte')}`}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              {allKeys.map((_, i) => (
                <MenuItem eventKey={i} key={i}>{`${i.toString()} ${t(
                  'Spalte'
                )}`}</MenuItem>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      );
    });
  };

  // get a table row with all the dropdown menus (for matching the colums of the csv to the correct attribute)
  getDropDownRow = () => {
    // get the csv file and the mapping from attributes to column numbers
    const { csv, attributeMap } = this.state;
    // get the csv file header fields
    const { fields } = csv.meta;

    // get all the keys into an array
    const allKeys = Object.keys(attributeMap);
    return allKeys.map((key, j) => {
      return (
        <td key={key} className={`csv-import-ckb-${j}`}>
          <Dropdown
            id={'dropdown-' + key}
            onSelect={i => {
              this.updateAttributeMapping(key, i);
            }}
          >
            <Dropdown.Toggle bsStyle="default">
              {fields[attributeMap[key]]}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              {fields.map((name, i) =>
                name !== HEADER_SEPERATOR ? (
                  <MenuItem eventKey={i} key={i}>
                    {name}
                  </MenuItem>
                ) : (
                  <MenuItem key={i} header>
                    {'Standardwerte'}
                  </MenuItem>
                )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      );
    });
  };

  // generate a random password of length 8
  generatePassword = () => {
    var length = 8,
      charset = 'abcdefghkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789',
      retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  // update the currently hovered row
  setTDHovered = rowHover => {
    this.setState({ rowHover });
  };

  // toggle the activity of a row (user)
  handleChangeChk = index => {
    const activeRows = this.state.activeRows;
    activeRows[index] = !activeRows[index];
    this.setState({ activeRows });
  };

  // deselect all users
  handleChangeChkAll = () => {
    this.setState({
      allActive: !this.state.allActive,
      activeRows: new Array(this.state.csv.data.length).fill(
        !this.state.allActive
      )
    });
  };

  // render a preview of the current data with the current selection
  renderDataPreview = () => {
    const { csv, attributeMap, hasHeader } = this.state;
    const { fields } = csv.meta;

    // get all the keys into an array
    const allKeys = Object.keys(attributeMap);
    return csv.data.map((row, index) => {
      return (
        <tr key={index}>
          <td key={index} className={'csv-import-ckb'}>
            <input
              key={index}
              type="checkbox"
              checked={this.state.activeRows[index]}
              onChange={() => this.handleChangeChk(index)}
            />
          </td>
          {allKeys.map((key, j) => {
            const dataKey = hasHeader
              ? fields[attributeMap[key]]
              : attributeMap[key];
            if (key === 'color') {
              return (
                <td
                  key={j}
                  className={`csv-import-ckb-${j}`}
                  style={
                    this.state.rowHover === row[dataKey]
                      ? {
                          backgroundColor: row[dataKey]
                        }
                      : {}
                  }
                  onMouseEnter={() => this.setTDHovered(row[dataKey])}
                  onMouseLeave={() => this.setTDHovered(undefined)}
                >
                  {row[dataKey]}
                </td>
              );
            } else if (key === 'admin') {
              return (
                <td key={j} className={`csv-import-ckb-${j}`}>
                  {row[dataKey] && row[dataKey].toLowerCase() === 'true'
                    ? 'true'
                    : 'false'}
                </td>
              );
            } else {
              return (
                <td key={j} className={`csv-import-ckb-${j}`}>
                  {row[dataKey]}
                </td>
              );
            }
          })}
        </tr>
      );
    });
  };
  // render the preview table of the data, which will be imported.
  // the user can check if the data is matched correctly
  renderTable = () => {
    const { t } = this.props;
    return (
      <div>
        <div>
          <h2>{t('Zuordnung von Zeilen zu Benutzerdaten.')}</h2>
          <table className="table table-condensed">
            <thead>
              <tr>
                <th className="csv-import-ckb">
                  {this.state.allActive ? t('Keiner') : t('Alle')}
                </th>
                {this.getTableHeader()}
              </tr>
              <tr>
                <td className={`csv-import-ckb`}>
                  <input
                    type="checkbox"
                    checked={this.state.allActive}
                    onChange={() => this.handleChangeChkAll()}
                  />
                </td>
                {this.state.hasHeader
                  ? this.getDropDownRow()
                  : this.getDropDownRowNoHeader()}
              </tr>
            </thead>
          </table>
          <div className="div-table-content">
            <table
              id="userImport"
              className="table table-hover table-striped table-condensed"
            >
              <tbody>{this.renderDataPreview()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  // use fuzzy select to precompute the most likely mapping from attributes to colums (only used in the with header case)
  precomputeMapping = csv => {
    const headers = FuzzySet(csv.meta.fields);
    const { attributeNames, attributeMap } = this.state;

    for (var key in attributeNames) {
      if (attributeNames.hasOwnProperty(key)) {
        let res = headers.get(attributeNames[key]);
        if (res && res.length > 0) {
          res = res[0];
          const rowTitle = res[1];
          const index = csv.meta.fields.indexOf(rowTitle);
          attributeMap[key] = index;
        } else {
          // no match found by word similarity use the first as default
          attributeMap[key] = 0;
        }
      }
    }

    const activeRows = new Array(csv.data.length).fill(true);

    this.setState({ attributeMap, activeRows });
  };

  // add default values to the data set, also only for the with header case
  precomputeDefaults = csv => {
    const modifiedCSV = { ...csv };
    modifiedCSV.meta.fields.push(HEADER_SEPERATOR);
    modifiedCSV.meta.fields.push('Neues Password');
    modifiedCSV.meta.fields.push('color-Default');
    modifiedCSV.meta.fields.push('admin-Default');
    modifiedCSV.meta.fields.push('tel-Default');
    modifiedCSV.meta.fields.push('company-Default');

    modifiedCSV.data = modifiedCSV.data.map(row => {
      return {
        ...row,
        'Neues Password': this.generatePassword(),
        'color-Default': '#7ED321', // #' + Math.floor(Math.random() * 16777215).toString(16),
        'admin-Default': false,
        'tel-Default': 'Kein Telefon',
        'company-Default': this.props.defaultCompany
      };
    });

    return modifiedCSV;
  };

  // extent every entry in the csv data to have enough fields
  extentCSV = csv => {
    const modifiedCSV = { ...csv };
    modifiedCSV.data = modifiedCSV.data.map(row => {
      const diff = MIN_REQUIRED_ARRAY_FLIEDS - row.length;
      if (diff > 0) {
        // extend every row to be at least the required length
        return row.concat(Array(diff).fill(''));
      }
      return [...row];
    });

    return modifiedCSV;
  };

  // triggered if the user dnd a file onto the dialog
  _onDrop = accepted => {
    const { t } = this.props;
    // accept only valid csv files
    if (
      accepted.length > 0 &&
      (accepted[0].name.endsWith('csv') || accepted[0].name.endsWith('CSV'))
    ) {
      // callback if the csv parsing of the file has finished
      const onCompleted = csv => {
        if (!this.state.hasHeader) {
          const modifiedCSV = this.extentCSV(csv);
          const activeRows = new Array(csv.data.length).fill(true);
          this.setState({ csv: modifiedCSV, activeRows });
          return;
        }
        const modifiedCSV = this.precomputeDefaults(csv);
        this.precomputeMapping(modifiedCSV);
        this.setState({ csv: modifiedCSV });
      };
      onCompleted.bind(this);
      // detect encoding before parsing
      var encoderReader = new FileReader();
      encoderReader.onload = e => {
        const result = jschardet.detect(e.target.result);

        let encoding = 'UTF-8';
        // update the encoding if the confidence is higher then 80 %
        if (result && result.confidence > 0.8) {
          encoding = result.encoding;
        }
        // parse the selected files
        Papa.parse(accepted[0], {
          header: this.state.hasHeader,
          complete: onCompleted,
          keepEmptyRows: false,
          skipEmptyLines: true,
          encoding
        });
      };

      encoderReader.readAsBinaryString(accepted[0]);
    } else {
      alert(t('Bitte nur Dateien mit der Endung .csv importieren.'));
    }
  };

  // process the data (only submit selected items) and create submission
  preprocessAndSubmit = () => {
    const { csv, attributeMap, hasHeader } = this.state;
    if (csv === undefined) {
      this.props.errorHandler();
      return;
    }
    const { fields } = csv.meta;
    const allKeys = Object.keys(attributeMap);
    let response = csv.data
      .filter((_, index) => this.state.activeRows[index])
      .map(row => {
        let user = {};
        allKeys.forEach(key => {
          const dataKey = hasHeader
            ? fields[attributeMap[key]]
            : attributeMap[key];

          user[key] = row[dataKey];
          if (key === 'email' && user[key]) {
            user[key] = user[key].toLowerCase();
          }
        });

        // combine name and surname
        user['name'] = user['name'] + ' ' + user['surname'];
        delete user['surname'];

        return user;
      });

    // check for all mandatory fields
    response = response.filter(user => {
      return !(
        user['email'] === undefined ||
        user['password'] === undefined ||
        user['name'] === undefined ||
        user['admin'] === undefined
      );
    });

    this.props.succesHandler(response);
  };

  // render function
  render() {
    const { hasHeader } = this.state;
    const { t } = this.props;
    return (
      <Dialog className="import-user-dialog">
        <DialogHeader
          title={this.props.caption}
          onClose={this.props.errorHandler}
        />
        <DialogContent className="import-user-dialog-content">
          {// render ether drop area or preview table
          this.state.csv ? (
            this.renderTable()
          ) : (
            <div>
              <input
                id="hasHeaderCheckbox"
                type="checkbox"
                checked={!!hasHeader}
                onChange={() => this.setState({ hasHeader: !hasHeader })}
              />
              <label className="form-control-label" htmlFor="hasHeaderCheckbox">
                {t('Die CSV-Datei besitzt eine Tabellenkopfzeile')}
              </label>
              <Dropzone
                className="dropzone"
                multiple={false}
                onDrop={this._onDrop}
              >
                <p>
                  {t(
                    'CSV daten importieren via drag and drop oder auf die Fläche klicken'
                  )}
                  <br />
                  {t('Beispieldaten')}:
                  <br />
                  <img
                    src={
                      hasHeader
                        ? require('../../images/demo.png')
                        : require('../../images/demo_no_header.png')
                    }
                    style={{ width: 600 }}
                    alt="Demo import"
                  />
                </p>
              </Dropzone>
            </div>
          )}
        </DialogContent>
        <div className="edit-user-dialog-footer">
          <button className="btn btn-default" onClick={this.props.errorHandler}>
            {t('Abbrechen')}
          </button>
          <button
            onClick={() => this.preprocessAndSubmit()}
            className="btn btn-primary pull-right"
          >
            {t('Importieren')}
          </button>
        </div>
      </Dialog>
    );
  }
}

export default translate('translations')(ImportUserDialog);
