import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import auth from '../../auth';

class Logout extends Component {
  componentWillMount() {
    process.env.NODE_ENV === 'development' && console.log('LOGOUT');
    this.props.logout();
  }

  render() {
    if (!this.props.auth.isLoggedIn) {
      return <Redirect to="/login" />;
    }

    return null;
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps, { logout: auth.logout })(Logout);
