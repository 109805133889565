/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';

import _ from 'lodash';
import { Map, Marker, Polyline } from 'react-leaflet';
import { icon } from 'leaflet';
import { GoogleLayer } from 'react-leaflet-google';
import { translate } from 'react-i18next';

import { ROADMAP, KEY, DEFAULTZOOM } from '../constants';

import { timeConverter } from '../utils';
import { updateDoc } from '../Couchbase';

import ListHeader from './ListHeader';
import List from './List';
import ListItem from './ListItem';
import Dialog from './Dialog';
import DialogHeader from './DialogHeader';
import DialogContent from './DialogContent';
import DialogFooter from './DialogFooter';

import Message from './Message';

import { transformItem } from '../routes/dashboard/exportHelper';

class EditDialog extends Component {
  constructor(props) {
    super(props);
    const { doc } = props;

    this.state = {
      doc,
      comment: ''
    };
  }

  componentDidMount() {
    this.setUpData();
  }

  componentWillReceiveProps({ doc }) {
    this.setState({ doc });
  }

  setUpComments = () => {
    let rowData = [];
    const { doc } = this.state;

    if (doc.activity) {
      const sortedActivity = doc.activity.sort((a, b) => b.ts - a.ts);
      rowData = sortedActivity.map(d => ({
        key: timeConverter(d.ts),
        title: d.comment,
        details: timeConverter(d.ts)
      }));
    }
    return rowData;
  };

  setUpData = () => {
    const { doc } = this.state;

    return doc.data.map((item, idx) => {
      const d = transformItem(item);
      return {
        _id: idx,
        title: d && `${d.value}${d.extra ? `: ${d.extra}` : ''}`,
        details: d && d.title
      };
    });
  };

  // todo: refactor to leaflet helpers
  addMarker = (position, highlight) => {
    const { baseDoc } = this.state;
    if (baseDoc && baseDoc.icon) {
      return <Marker position={position} icon={icon(baseDoc.icon)} />;
    }
    if (highlight) {
      return (
        <Marker
          position={position}
          icon={icon({
            iconUrl:
              'https://srzevents.s3.eu-central-1.amazonaws.com/assets/marker/info%402x.png',
            iconSize: [25, 32],
            iconAnchor: [12, 32]
          })}
        />
      );
    }
    //srzevents.s3.eu-central-1.amazonaws.com/assets/base.png
    https: return (
      <Marker
        position={position}
        icon={icon({
          iconUrl:
            'https://srzevents.s3.eu-central-1.amazonaws.com/assets/base.png',
          iconSize: [25, 32],
          iconAnchor: [12, 32]
        })}
      />
    );
  };

  // save a comment to the patient data
  saveComment = () => {
    const { doc } = this.props;
    const { comment } = this.state;

    if (doc.completed) return;

    // update the doc in the db
    const commentEntry = {
      ts: parseInt(Date.now() / 1000, 10),
      comment
    };
    const updatedDoc = _.cloneDeep(doc);

    if (Array.isArray(updatedDoc.activity)) {
      updatedDoc.activity.push(commentEntry);
    } else {
      updatedDoc.activity = [commentEntry];
    }

    // send to couchbase
    updateDoc(updatedDoc);

    this.setState({ comment: '' });
  };

  toggleState = () => {
    const { t, errorHandler } = this.props;
    const { doc } = this.state;
    const updatedDoc = _.cloneDeep(doc);

    const commentEntry = {
      ts: parseInt(Date.now() / 1000, 10), // UNIX timestamp
      comment: doc.completed === true ? t('Wiedereröffnet') : t('Abgeschlossen')
    };

    if (updatedDoc.activity) {
      updatedDoc.activity.push(commentEntry);
    } else {
      updatedDoc.activity = [commentEntry];
    }

    updatedDoc.completed = !updatedDoc.completed;
    updateDoc(updatedDoc);

    errorHandler();
  };

  deleteMessage = mId => {
    const { doc } = this.state;
    if (doc.messages && doc.messages[mId] && !doc.completed) {
      const updatedDoc = _.cloneDeep(doc);
      delete updatedDoc.messages[mId];
      updateDoc(updatedDoc);
    }
  };

  render() {
    const {
      t,
      disabled,
      caption,
      errorHandler,
      nextStepsActive,
      currentUser,
      appSettings: {
        assets: { deleteIconUrl }
      },
      baseDoc
    } = this.props;

    const {
      comment,
      doc: { messages, completed, userName, baseName, ts, coordinates, image }
    } = this.state;

    const rowData = this.setUpData();

    const position =
      coordinates && coordinates.latitude && coordinates.longitude
        ? [coordinates.latitude, coordinates.longitude]
        : baseDoc && baseDoc.geometry.coordinates;

    const iMessages = messages ? Object.values(messages) : [];
    const jsxMessages = iMessages.map((data, i) => (
      <div key={`div-1 ${i}`}>
        <div style={{ float: 'left', width: '85%' }}>
          <Message
            openGallery={() => {}}
            fromMe={data.user._id === currentUser._id} // a boolean that defines if the message was sent from the current use
            data={data}
          />
        </div>
        <div className="pull-right text-center">
          <img
            alt="deleteIcon icon"
            src={deleteIconUrl}
            onClick={e => {
              this.deleteMessage(data._id);
              e.stopPropagation();
            }}
            style={completed ? {} : { cursor: 'pointer' }}
            className="list-icon pull-right"
          />
        </div>
      </div>
    ));

    return (
      <Dialog>
        <DialogHeader title={caption} onClose={errorHandler} />
        <DialogContent className="edit-incident-content">
          <div className="edit-incident-content-scroll-wrap">
            <div className="edit-incident-content-scroll">
              <div className="row">
                <div className="col-md-4" style={{ textAlign: 'left' }}>
                  {t('Protokolliert von')}
                  <b>{` ${userName}`}</b>
                  <br />
                  {t('für Posten')}
                  <b>{` ${baseName} `}</b>
                  <br />
                  <b>{timeConverter(ts)}</b>
                  <br />
                  <br />
                  <span className="input-group-btn">
                    <button
                      className="btn btn-warning"
                      style={{ marginBottom: 10 }}
                      type="button"
                      onClick={this.toggleState}
                    >
                      {completed === true
                        ? t('Wiedereröffnen')
                        : t('Abschliessen')}
                    </button>
                  </span>
                </div>
                <div className="col-md-4" style={{ textAlign: 'right' }}>
                  {image && (
                    <img
                      src={image}
                      style={{ height: '100%', width: '100%' }}
                      alt="attached"
                    />
                  )}
                </div>
                <div className="col-md-4">
                  <div className="edit-incident-dialog-map">
                    {position && (
                      <Map
                        center={position}
                        zoomControl={false}
                        attributionControl={false}
                        zoom={DEFAULTZOOM}
                        style={{ height: '200px' }}
                      >
                        <GoogleLayer googlekey={KEY} maptype={ROADMAP} />

                        {baseDoc.geometry.coordinates
                          ? this.addMarker(baseDoc.geometry.coordinates)
                          : null}
                        {position ? this.addMarker(position, true) : null}
                        {position && baseDoc.geometry.coordinates && (
                          <Polyline
                            color="#ED4D70"
                            positions={[position, baseDoc.geometry.coordinates]}
                          />
                        )}
                      </Map>
                    )}
                  </div>
                </div>
              </div>
              {nextStepsActive ? (
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-group" style={{ marginTop: 10 }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            'weitere Schritte oder Massnahmen hinzufügen'
                          )}
                          value={comment}
                          disabled={disabled}
                          onKeyPress={e => {
                            if (e.key === 'Enter') this.saveComment();
                          }}
                          onChange={e => {
                            this.setState({ comment: e.target.value });
                          }}
                        />
                        <span className="input-group-btn">
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled={disabled}
                            onClick={this.saveComment}
                          >
                            {t('Speichern')}
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <ListHeader headerTitle={t('Formular Daten')} />
                      {rowData.map(({ title, details }) => (
                        <ListItem key={title} value={title} details={details} />
                      ))}
                    </div>
                    <div className="col-md-3">
                      <ListHeader headerTitle={t('Weitere Schritte')} />
                      {this.setUpComments().map(({ key, title, details }) => (
                        <ListItem key={key} value={title} details={details} />
                      ))}
                    </div>
                    <div className="col-md-5">
                      <ListHeader headerTitle={t('Nachrichten')} />
                      <div style={{ background: '#f9f9f9' }}>{jsxMessages}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div>
                    <List rowData={rowData} headerTitle={t('Formular Daten')} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>

        <DialogFooter
          errorHandler={errorHandler}
          className="edit-incident-dialog-footer"
        />
      </Dialog>
    );
  }
}

export default translate('translations')(EditDialog);
