import React, { Component } from 'react';
import { translate } from 'react-i18next';

import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter
} from '../../../components';

import {
  FILTER_BY_FINISHED,
  FILTER_BY_IMAGE,
  FILTER_BY_MESSAGE
} from '../../../constants';

class FilterIncidentDialog extends Component {
  constructor(props) {
    super(props);
    const { sortBy, filterType, t } = props;

    this.state = {
      sortOptions: [
        {
          label: t('Erstellungsdatum'),
          value: 'newestEvent'
        },
        {
          label: t('Letzte Aktivität'),
          value: 'newestActivity'
        },
        {
          label: t('Letzte Nachricht'),
          value: 'newestMessage'
        }
      ],
      sortBy,
      filterType
    };
  }

  onRadioChange = sortBy => this.setState({ sortBy });

  updateFilter = fType => {
    const { filterType } = this.state;
    if (filterType.includes(fType)) {
      const array = [...filterType];
      const index = filterType.indexOf(fType);
      array.splice(index, 1);
      this.setState({ filterType: array });
    } else {
      this.setState({ filterType: [...filterType, fType] });
    }
  };

  render() {
    const { caption, errorHandler, succesHandler, t } = this.props;
    const { sortOptions, sortBy, filterType } = this.state;

    return (
      <Dialog>
        <DialogHeader title={caption} onClose={errorHandler} />
        <DialogContent className="edit-incident-content">
          <div className="edit-incident-content-scroll-wrap">
            <div className="edit-incident-content-scroll">
              <div className="row">
                <p>
                  {t(
                    'Nach welchem Kriterium wollen Sie die Ereignisse sortieren?'
                  )}
                </p>
                <form>
                  {sortOptions.map(s => (
                    <div className="radio" key={`div-${s.value}`}>
                      <label key={`label-${s.value}`}>
                        <input
                          type="radio"
                          name="site_name"
                          value={s.value}
                          key={s.value}
                          checked={sortBy.value === s.value}
                          onChange={() => this.onRadioChange(s)}
                        />
                        {s.label}
                      </label>
                    </div>
                  ))}
                </form>
              </div>
              <div className="row">
                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_BY_FINISHED)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_BY_FINISHED)}
                  />
                  {t('Abgeschlossene Ereignisse filtern.')}
                </label>

                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_BY_IMAGE)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_BY_IMAGE)}
                  />
                  {t('Ereignisse ohne Bilder filtern.')}
                </label>

                <label>
                  <input
                    type="checkbox"
                    checked={filterType.includes(FILTER_BY_MESSAGE)}
                    style={{ marginRight: 5 }}
                    onChange={() => this.updateFilter(FILTER_BY_MESSAGE)}
                  />
                  {t('Ereignisse ohne Nachrichten filtern.')}
                </label>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogFooter>
          <button className="btn btn-default" onClick={errorHandler}>
            {t('Abbrechen')}
          </button>
          <button
            className="btn btn-primary pull-right"
            onClick={() => succesHandler(this.state)}
          >
            {t('Anwenden')}
          </button>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default translate('translations')(FilterIncidentDialog);
