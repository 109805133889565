import { connect } from 'react-redux';

import { getAppAssets, createLayoutSelector } from '../../selectors';
import Layout from '../layout';

const getLayouts = createLayoutSelector('settings');
const mapStateToProps = state => ({
  title: 'EINSTELLUNGEN',
  backgroundImage: getAppAssets(state).dashboardBackground,
  layouts: getLayouts(state),
  organisationProps: {
    onlyEvent: true,
    withAllEvents: true
  }
});

export default connect(mapStateToProps)(Layout);
