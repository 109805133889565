import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'react-tippy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const leftIconStyle = { fontSize: 20, marginLeft: 3 };

const Left = ({
  hoverInAction,
  hoverOutAction,
  action,
  icon,
  url,
  color,
  active,
  pulse,
  indicator
}) => {
  return (
    <div
      style={{
        cursor: action ? 'pointer' : 'auto',
        padding: 3,
        paddingTop: 10,
        minWidth: 35,
        fontSize: 10
      }}
      onMouseEnter={e => {
        e.stopPropagation();

        if (hoverInAction) {
          hoverInAction(e);
        }
      }}
      onMouseLeave={e => {
        e.stopPropagation();

        if (hoverOutAction) {
          hoverOutAction(e);
        }
      }}
      onClick={e => {
        e.stopPropagation();

        if (action) {
          action(e);
        }
      }}
    >
      {indicator && indicator.visible && (
        <FontAwesomeIcon
          icon="circle"
          color={indicator.color}
          className={indicator.pulse ? 'pulse' : ''}
        />
      )}

      {icon ? (
        <FontAwesomeIcon
          icon={icon}
          color={color || (active ? '#900' : '#777')}
          style={leftIconStyle}
          className={`${pulse ? 'pulse' : ''}`}
        />
      ) : (
        <img alt="left icon" src={url} className="list-icon" />
      )}
    </div>
  );
};

class ListItem extends Component {
  constructor(props) {
    super(props);

    // timer to differ between short and long press
    this.timerRunning = false;
    this.pressEvent = undefined;
  }

  handlePress = e => {
    const { longPressHandler } = this.props;
    this.pressEvent = e;
    this.timerRunning = true;
    this.buttonPressTimer = setTimeout(() => {
      this.timerRunning = false;
      if (longPressHandler) longPressHandler();
    }, 750);
  };

  handleRelease = () => {
    const { selectionClickHandler, selectionModeActive } = this.props;
    if (this.timerRunning) {
      clearTimeout(this.buttonPressTimer);
      // normal click detected trigger handler
      if (selectionModeActive && selectionClickHandler) {
        selectionClickHandler(this.pressEvent);
      }
    }
  };

  handleRowClick = () => {
    const { rowAction } = this.props;
    if (rowAction) {
      rowAction();
    }
  };

  addRightIcons = () => {
    const { rightIcons } = this.props;
    if (rightIcons && rightIcons.length > 0) {
      return (
        <div style={{ textAlign: 'right', width: 90 }}>
          {rightIcons.map(({ url, icon, active, tooltip, action }, i) => (
            <Tooltip key={i} title={tooltip} disabled={!tooltip}>
              {icon ? (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={e => {
                    e.stopPropagation();
                    if (action) {
                      action(e);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={icon}
                    color={active ? '#900' : '#777'}
                  />
                </button>
              ) : (
                <img
                  alt="right icon"
                  src={url}
                  onClick={e => {
                    e.stopPropagation();
                    if (action) {
                      action(e);
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                  className="list-icon"
                />
              )}
            </Tooltip>
          ))}
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      highlighted,
      rowAction,
      selected,
      value,
      details,
      children,
      leftIcon
    } = this.props;

    return (
      <div
        className={`${selected ? 'list-item-selected' : ''} ${
          highlighted ? 'list-item-highlighted' : ''
        }`}
        style={{
          display: 'flex',
          cursor: rowAction ? 'pointer' : 'auto'
        }}
        onMouseDown={this.handlePress}
        onMouseUp={this.handleRelease}
        onClick={this.handleRowClick}
      >
        {leftIcon && <Left {...leftIcon} />}

        <div
          style={{ flexGrow: 4, textAlign: 'left' }}
          className={`${highlighted ? 'list-item-details-highlighted' : ''}`}
        >
          <h4 className="list-item-name">{value}</h4>
          <div className="list-item-details">{details}</div>
          {children}
        </div>

        {this.addRightIcons()}
      </div>
    );
  }
}

ListItem.propTypes = {
  highlighted: PropTypes.bool,
  selected: PropTypes.bool,
  rowAction: PropTypes.func,
  longPressHandler: PropTypes.func,
  leftIcon: PropTypes.object,
  rightIcons: PropTypes.array,
  value: PropTypes.string.isRequired,
  details: PropTypes.string,

  selectionClickHandler: PropTypes.func,
  selectionModeActive: PropTypes.bool
};

ListItem.defaultProps = {
  highlighted: false,
  selected: false,
  selectionModeActive: false,

  leftIcon: undefined,
  details: undefined,
  rightIcons: [],

  rowAction: undefined,
  longPressHandler: undefined,
  selectionClickHandler: undefined
};

export default ListItem;
