// TODO move somewhere better

import L, { divIcon, Point } from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export function getIconUrl(base, isActice) {
  const {
    icon: { iconUrl }
  } = base;

  if (isActice) {
    return iconUrl.replace('base.png', 'base_active.png');
  }
  if (iconUrl.indexOf('base_active.png') >= 0) {
    return iconUrl.replace('base_active.png', 'base.png');
  }
  return iconUrl;
}

export function getDivIconLeaflet(name, color, animated) {
  // get the creditials
  const nameArray = name.split(' ');
  let fstName = '';
  let scdName = '';
  if (nameArray.length > 1) {
    fstName = nameArray[0].charAt(0).toUpperCase();
    scdName = nameArray[1].charAt(0).toUpperCase();
  } else {
    fstName = nameArray[0].charAt(0).toUpperCase();
  }
  const textColor =
    color === '#ffffff' || color === '#FFFFFF' || color === 'white'
      ? 'black'
      : 'white'; // TODO improve hack
  let html = `<div style="background: ${color};" class="leaflet-div-icon-avatar">`;
  html += `<div style="clear:both;"><font color=${textColor}>${fstName}${scdName}</font></div>`;
  if (animated) {
    html += '<div class="gps_ring"></div>';
  }
  html += '</div>';
  return divIcon({
    html,
    className: 'leaflet-div-icon-container',
    iconSize: new Point(32, 32)
  });
}

export const getStatusIcon = (icon, status) => {
  const factor = status && status.trend ? 4 : 3;
  const x = Math.round(icon.iconSize[0] / factor) * factor;
  const y = Math.round(icon.iconSize[1] / factor) * factor;
  const value = status && status.value ? status.value : 0;
  const trend = status && status.trend ? status.trend : 'const';
  const width = icon && icon.iconSize ? x / factor - 2 : 14;
  const height = width;

  let html = '<div>';

  html += `<img src="${icon.iconUrl}" width="${x}" height="${y}">`;
  if (status && status.value !== undefined) {
    html += `<div style="width: ${width}px; height: ${height}px;
                background-color:${value < 0.25 ? '#525252' : '#ff0000'};
                float: left; margin-left: 1px; margin-top: 2px; margin-bottom: 2px; margin-right: 1px;"></div>

                <div style="width: ${width}px; height: ${height}px; background-color:${
      value < 0.5 ? '#525252' : '#ff0000'
    };
                float: left; margin-left: 1px; margin-top: 2px; margin-bottom: 2px; margin-right: 1px;"></div>

                <div style="width: ${width}px; height: ${height}px; background-color:${
      value < 0.75 ? '#525252' : '#ff0000'
    };
                float: left; margin-left: 1px; margin-top: 2px; margin-bottom: 2px; margin-right: 1px;"></div>`;
  }
  if (status && status.trend) {
    const fa = {
      up: { icon: 'fa-arrow-circle-up', color: '#ff0000' },
      down: { icon: 'fa-arrow-circle-down', color: '#00ff00' },
      const: { icon: 'fa-arrow-circle-right', color: '#ffffff' }
    }[trend];

    html += `<div style="width: ${width}px; height: ${height}px; background-color: #525252; color: ${fa.color}; text-align: center;
                float: left; margin-left: 1px; margin-top: 2px; margin-bottom: 2px; margin-right: 1px; font-size: ${height}px; line-height: ${height}px">
                   <i class="fa ${fa.icon}"></i>
                </div>`;
  }

  html += '</div>';
  html += '<style type="text/css">body,html {margin: 0;padding: 0;}</style>';

  return divIcon({
    html,
    className: 'leaflet-div-icon-container',
    iconSize: new Point(x, y + height + 4)
    // TODO anchor
  });
};
