import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { List } from './index';
import dbActions from '../Couchbase/actions';
import { selectByClassName } from '../selectors';

class AppSettingsPicker extends Component {
  setUpData = () => {
    const rowData = [];
    const { docs, selectAppSettings } = this.props;

    docs.forEach(p => {
      const title = `${p.appId}`;
      // check if the form props are set and a preview order is defined
      const details = '';

      const data = {
        highlighted: false,
        title,
        _id: p._id,
        details,
        rowAction: () => selectAppSettings(p),
        handleDrop: () => {},
        rightIcons: [],
        doc: p
      };

      rowData.push(data);
    });
    return rowData;
  };

  render() {
    const { t } = this.props;
    return (
      <div id="init-container">
        <div id="init-content">
          <List
            rowData={this.setUpData()}
            headerTitle={t('App Einstellungen')}
            dragable={false}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    docs: selectByClassName('AppSettings')(state)
  };
}

export default connect(mapStateToProps, {
  selectAppSettings: dbActions.setAppSettings
})(translate('translations')(AppSettingsPicker));
