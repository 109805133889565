import React from 'react';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import { translate } from 'react-i18next';

import { InitalSpinner, AppSettingsPicker, Loading } from '../../components';

import Settings from '../settings';
import Briefings from '../briefings';
import SideMenu from './sidemenu';
import { selectByClassName, getAppSettings } from '../../selectors';

import Dashboard from '../dashboard';
import Events from '../events';

// const Dashboard = loadable({
//   loader: () => import('../dashboard'),
//   loading: Loading,
//   delay: 300
// });

const Analytics = loadable({
  loader: () => import('../analytics'),
  loading: Loading,
  delay: 300
});

// const Events = loadable({
//   loader: () => import('../events'),
//   loading: Loading,
//   delay: 300
// });

const Home = ({
  isLoading,
  progress,
  t,
  extended,
  appSettingsDocs,
  appSettings
}) => {
  console.log('render home');
  if (!appSettings && appSettingsDocs.length > 1) {
    // handle client manager case
    return <AppSettingsPicker />;
  }

  if (isLoading) {
    return (
      <CSSTransitionGroup
        transitionName="fade"
        transitionAppear
        transitionAppearTimeout={300}
        transitionEnterTimeout={50}
        transitionLeaveTimeout={50}
      >
        <InitalSpinner
          progress={progress}
          loadingMsg={`${t('Dokumente werden geladen')} ...`}
        />
      </CSSTransitionGroup>
    );
  }

  return (
    <React.Fragment>
      <SideMenu />
      <div className="main-container">
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/briefings" component={Briefings} />
          <Route path="/events" component={Events} />
          <Route path="/settings" component={Settings} />
          <Route path="/analytics" component={Analytics} />
        </Switch>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isLoading: state.docs.isLoading || !state.docs.init, // TODO use reselect | should component update
  progress: state.docs.progress,
  extended: state.dashboard.sideMenuState.extended,
  appSettingsDocs: selectByClassName('AppSettings')(state),
  appSettings: getAppSettings(state)
});

export default connect(mapStateToProps)(translate('translations')(Home));
