import React, { memo, useState, useEffect } from 'react';

// image return from ajax request and hex color string as args
export const tintImage = (image, color, blendingOption) => {
  if (
    !(
      image &&
      image.complete &&
      typeof image.naturalWidth !== 'undefined' &&
      image.naturalWidth !== 0
    )
  ) {
    console.error('Image not loaded! Tinting not possible');
    return undefined;
  }

  const sourceImage = image;
  const kBlendModeDestinationIn = 'destination-in';
  const kBlendModeSourceOver = 'source-over';

  const blendingMode = blendingOption || kBlendModeDestinationIn;
  const buffer = document.createElement('canvas');

  if (
    typeof sourceImage.naturalWidth !== 'undefined' &&
    typeof sourceImage.naturalHeight !== 'undefined'
  ) {
    buffer.width = sourceImage.naturalWidth;
    buffer.height = sourceImage.naturalHeight;
  } else {
    buffer.width = sourceImage.width;
    buffer.height = sourceImage.height;
  }

  const context = buffer.getContext('2d');
  const width = buffer.width;
  const height = buffer.height;

  // If we can't create a context, return the source image's src attribute.
  if (!buffer || !context) {
    return sourceImage.src;
  }

  // Clear the context.
  context.clearRect(0, 0, width, height);

  // Set the fill color.
  context.globalCompositeOperation = kBlendModeSourceOver;
  context.fillStyle = color;

  // Fill the context.
  context.fillRect(0, 0, width, height);

  // Draw image the specified blending mode.
  context.globalCompositeOperation = blendingMode;
  context.drawImage(sourceImage, 0, 0);

  // Finish operation if previous blending mode was not kBlendModeDestinationIn.
  if (blendingMode !== kBlendModeDestinationIn) {
    context.globalCompositeOperation = kBlendModeDestinationIn;
    context.drawImage(sourceImage, 0, 0);
  }

  // Return data in png format.
  const imageData = buffer.toDataURL('image/png');

  return imageData;
};

const IconTint = ({ url, color, className, style }) => {
  const [image, setIconImage] = useState();

  useEffect(() => {
    const image = new Image();
    image.crossOrigin = '*';

    image.onload = () => {
      setIconImage(tintImage(image, color || '#fff'));
    };

    image.onerror = () => {
      console.error(`Could not load image from ${url}`);
    };

    image.src = url;
  }, [color, url]);

  if (!image) {
    return <div style={style} className={className} />;
  }
  return <img alt={url} src={image} className={className} style={style} />;
};

export default memo(IconTint);
