import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { create, stdDependencies } from 'mathjs/number';

import Modal from 'react-modal';

import { Panel, EditDialog } from '../../../components';
import { secondsToMin } from '../../../utils';

import {
  getCurrentUser,
  getAppSettings,
  getFilteredIncidents,
  getFilteredBases,
  getIncidentForm,
  getPermissions
} from '../../../selectors';

import { incidentsDialogStyle } from '../../../ModalStyles';

import { getTitle } from '../../dashboard/exportHelper';

const math = create({ stdDependencies });

class IncidentProcessingStats extends Component {
  state = {
    modalIsOpen: false
  };

  closeModal = () => this.setState({ modalIsOpen: false });

  openModal = (incidentDoc, baseDoc) =>
    this.setState({ modalIsOpen: true, baseDoc, incidentDoc });

  closeModalSucces = () => {
    console.log('success handler');
  };

  computeStats = () => {
    let { incidents, t } = this.props;
    let mean = t('Nicht genug Daten');
    let std = 0;
    let best = [];
    let worst = [];

    incidents = incidents.filter(i => i.activity && i.completed); // only use incidents with activity for stats
    if (!incidents) {
      // no incident found
    } else if (incidents && incidents.length === 0) {
      // do nothing
    } else if (incidents && incidents.length === 1) {
      mean =
        incidents[0].activity[incidents[0].activity.length - 1].ts -
        incidents[0].ts;
      incidents[0].duration = mean;
      best.push(incidents[0]);
    } else {
      // comupute mean
      mean = 0;
      let cnt = 0;
      incidents.forEach(i => {
        i.duration = i.activity[i.activity.length - 1].ts - i.ts;
        mean += i.duration;
        cnt += 1;
      });
      if (cnt > 0) {
        mean /= cnt;
        // compute std
        std = math.std(incidents.map(i => i.duration));

        const durationSorted = incidents.sort(
          (a, b) => a.duration > b.duration
        );
        if (cnt > 3) {
          best = durationSorted.slice(0, 3);
          const revDuration = [...durationSorted].reverse();
          worst = revDuration.slice(0, 3);
        } else {
          best = durationSorted;
          const revDuration = [...durationSorted].reverse();
          worst = revDuration;
        }
      }
    }

    return {
      mean,
      std,
      best,
      worst
    };
  };

  getFontColor = mean =>
    // could be used in the future
    // if(mean < 30) return "#707070";
    // if(mean < 90) return "#707070";
    '#707070';

  getTitle = p => {
    const { t, form } = this.props;
    return `${getTitle(p, form)}, ${t('Dauer')}: ${secondsToMin(
      p.duration
    )} min`;
  };

  addItems = ({ worst, best }) => {
    const { bases } = this.props;
    return best.map((b, i) => (
      <tr key={b._id}>
        <td
          onClick={() => {
            this.openModal(
              b,
              bases.find(base => b.baseId === base._id)
            );
          }}
        >
          {this.getTitle(b)}
        </td>
        <td
          onClick={() => {
            this.openModal(
              worst[i],
              bases.find(b => worst[i].baseId === b._id)
            );
          }}
        >
          {worst[i] ? this.getTitle(worst[i]) : ''}
        </td>
      </tr>
    ));
  };

  render() {
    const { modalIsOpen, incidentDoc, baseDoc } = this.state;
    const { t, permissions, appSettings, currentUser, onClose } = this.props;
    const stats = this.computeStats();

    return (
      <Panel
        title={t('Bearbeitungsdauer Ereignisse')}
        id="incidentprocessingstats"
        onClose={onClose}
      >
        <div
          style={{ height: 'calc(100% - 40px)', overflowY: 'auto', padding: 8 }}
        >
          <div>
            <h1
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: this.getFontColor(secondsToMin(stats.mean))
              }}
            >
              {stats.mean > 0
                ? `\u2300 ${secondsToMin(stats.mean)} min +/- ${secondsToMin(
                    stats.std
                  )}`
                : stats.mean}
            </h1>
          </div>
          <div>
            <table
              className="table table-hover table-striped"
              id="dev-table"
              style={{ tableLayout: 'fixed' }}
            >
              <tbody>
                <tr>
                  <th>{t('Top')}</th>
                  <th>{t('Flop')}</th>
                </tr>
                {this.addItems(stats)}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={incidentsDialogStyle}
          contentLabel="viewDialog"
        >
          <EditDialog
            nextStepsActive={permissions.dashboard.features.nextSteps}
            doc={incidentDoc}
            baseDoc={baseDoc}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
            appSettings={appSettings}
            currentUser={currentUser}
            disabled
          />
        </Modal>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    appSettings: getAppSettings(state),
    currentUser: getCurrentUser(state),
    incidents: getFilteredIncidents(state),
    form: getIncidentForm(state),
    bases: getFilteredBases(state),
    permissions: getPermissions(state)
  };
}

export default connect(mapStateToProps)(
  translate('translations')(IncidentProcessingStats)
);
