import ColorScheme from 'color-scheme';

export const MAPGL_ACCESS_TOKEN =
  'pk.eyJ1IjoidWxmYmxhbmtlIiwiYSI6ImNpams5aXNjazAwNXh2ZW00d2d4eDc3cDAifQ.GFLasDdY7GYZLuG02BMTdA';
export const HEATMAP_LAYER_ID = 'density-heatmap';
export const MARKER_CLUSTER_LAYER_ID = 'message-image-cluster';

export const GEO_JSON_TEMPLATE = {
  type: 'FeatureCollections',
  total: 0,
  start: -1,
  stop: -1,
  maxUsersPerCell: 1,
  step: 'hour',
  features: []
};

// COLOR CONSTS
const scheme = new ColorScheme();
scheme
  .from_hue(212)
  .scheme('tetrade')
  .distance(0.67)
  //.add_complement(false)
  //.variation('pastel')
  .web_safe(true);
export const COLOR_SCHEME = scheme.colors().map(c => `#${c}`);
