import { TOGGLE_VIEW_MODE } from './ActionTypes';

const INITALSTATE = {
  viewMode: 'custom'
};

export default (state = INITALSTATE, { type, payload }) => {
  switch (type) {
    case TOGGLE_VIEW_MODE:
      // console.log('toogle detected');
      return { ...state, viewMode: payload.viewMode };
    default:
      return state;
  }
};
