import React, { Component } from 'react';
import Modal from 'react-modal';
import NotificationSystem from 'react-notification-system';
import * as Fuse from 'fuse.js';

import { deleteDoc, updateDoc } from '../Couchbase';
import { timeConverter, timeSinceNowString } from '../utils';
import { questionDialogStyles } from '../ModalStyles';

import { Panel, ListHeader, List, QuestionDialog } from './index';
import { DeviceLocationRequestDialog } from './DeviceLocationRequestDialog';
import { REMOVE_ALL, REMOVE } from '../constants';

const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['doc.userName', 'doc._id', 'doc.baseName', 'doc.eventName']
};

class Devices extends Component {
  state = {
    deviceDoc: undefined,
    modalIsOpen: false,
    modalType: REMOVE,
    searchActive: false
  };

  setUpData = () => {
    const rowData = [];
    const sortFkt = (a, b) => {
      // both are set: use normal compare
      if (!!a.baseName && !!b.baseName) {
        a.baseName.localeCompare(b.baseName);
      } else {
        // both are undefined
        if (!a.baseName && !b.baseName) return 0;

        return !a.baseName ? 1 : -1;
      }
    };
    const activeDevices = [];
    const unactiveDevices = [];

    // calc timeout after the ui needs to update
    const nowTS = parseInt(new Date() / 1000, 10);
    const tenMinInSec = 600;
    const tDelta = nowTS - tenMinInSec;

    // split the devices into two array
    this.props.devices.forEach(device => {
      const d = { ...device };
      const locationDoc = this.props.deviceLocation.find(
        tmp => tmp._id === d.deviceLocationId
      );
      if (locationDoc) {
        d.lastSeen = locationDoc.ts / 1000; // set lastSeen
      } else {
        d.lastSeen = d.lastActive; // or use last active.
      }
      const mostRecentLifeSignal =
        d.lastSeen > d.lastActive ? d.lastSeen : d.lastActive;

      if (mostRecentLifeSignal > tDelta) {
        activeDevices.push(d);
      } else {
        unactiveDevices.push(d);
      }
    });
    // presort the devices
    activeDevices.sort(sortFkt);
    unactiveDevices.sort(sortFkt);
    const sortedArray = activeDevices.concat(unactiveDevices);

    sortedArray.forEach(e => {
      const title = `${e.userName} (${e._id.slice(-4)})`;
      // let details = `${e.baseName}, zul. ges. ${timeConverter(e.lastSeen)}, zul. aktiv ${timeConverterTimeOnly(e.lastActive)}`;

      let details = `${e.baseName},\n${this.props.t(
        'zul. ges. um'
      )} ${timeSinceNowString(e.lastSeen)}`;

      if (e.loggedIn === false) {
        details = `\n${this.props.t('abgemeldet')}\n${timeConverter(e.ts)}`;
      }

      const ts = e.lastSeen > e.lastActive ? e.lastSeen : e.lastActive;

      const isRecent = ts > nowTS - tenMinInSec;
      const data = {
        _id: e._id,
        ts,
        title,
        details,
        leftIcon: {
          icon: 'mobile-alt',
          hoverInAction: () => {
            console.log('hoverInAction', e._id);
            this.hoverHandler(e);
          },
          hoverOutAction: () => {
            console.log('hoverOutAction', e._id);
          },
          indicator: {
            visible: true,
            pulse: isRecent,
            color: isRecent ? '#0BC819' : '#D0011B'
          }
        },
        doc: e
      };
      if (this.props.currentUser && this.props.currentUser.admin) {
        data.rightIcons = [
          // {
          //   url: this.props.appSettings.assets.deleteIconUrl,
          //   action: () => {
          //     this.openModal(e, REMOVE);
          //   }
          // }
        ];

        if (this.props.appSettings.enableLocationRequest) {
          data.rightIcons.push({
            url: this.props.appSettings.assets.pushLocate,
            action: () => {
              this.openModal(e, 'LocationRequest');
            }
          });
        }
      }
      rowData.push(data);
    });
    return rowData;
  };

  hoverHandler = e => {
    if (
      !this.props.permissions ||
      !this.props.permissions.dashboard.permissionGranted ||
      !this.props.permissions.dashboard.map.permissionGranted
    ) {
      return;
    }
    // get location if any
    const location = this.props.deviceLocation.find(
      res => res._id === e.deviceLocationId
    );
    if (!location) {
      // alert("Person konnte noch nicht geortet werden.");
    } else {
      const nowTS = parseInt(Date.now(), 10);
      const tenMinInSec = 600000;
      if (e.loggedIn !== false && location.ts > nowTS - tenMinInSec) {
        this.props.mapViewportChange({
          center: [location.la, location.lo],
          zoom: 16
        });
      }
    }
  };

  openModal = (deviceDoc, modalType) => {
    this.setState({ modalIsOpen: true, modalType, deviceDoc });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  closeModalSucces = duration => {
    this.setState({ modalIsOpen: false });
    const { t } = this.props;
    switch (this.state.modalType) {
      case REMOVE:
        if (this.state.deviceDoc) {
          const l = this.props.deviceLocation.find(
            res => res._id === this.state.deviceDoc.deviceLocationId
          );
          if (l) {
            deleteDoc(l, () => {
              /* do not do anything */
            });
          }

          deleteDoc(this.state.deviceDoc, status => {
            if (status) {
              this.setState({ deviceDoc: undefined });
              this.notificationSystem.addNotification({
                message: t('Gerät erfolgreich gelöscht'),
                level: 'success'
              });
            }
          });
        }
        break;
      case REMOVE_ALL:
        const promises = [];
        this.props.devices.forEach(d => {
          const l = this.props.deviceLocation.find(
            res => res._id === d.deviceLocationId
          );
          if (l) {
            promises.push(deleteDoc(l));
          }
          promises.push(deleteDoc(d));
        });
        Promise.all(promises)
          .then(response => {
            this.notificationSystem.addNotification({
              message: t('Alle Geräte erfolgreich gelöscht.'),
              level: 'success'
            });
          })
          .catch((error, response) => {
            this.notificationSystem.addNotification({
              message:
                response && response.message
                  ? response.message
                  : t('Das Löschen ist fehlgeschlagen.'),
              level: 'error'
            });
          });
        this.setState({ deviceDoc: undefined });

        break;
      default: {
        // create and edit is the same code
        if (duration > -1) {
          const ts = Math.floor(Date.now() / 1000);
          const pushDocument = {
            appId: this.props.appSettings.appId,
            className: 'LocationRequest',
            ts,
            uploaded: false,
            data: {
              duration,
              to: this.state.deviceDoc.userId
            },
            userName: this.props.currentUser.name,
            userId: this.props.currentUser._id,
            _id: `${this.props.appSettings.appId}:LocationRequest:${ts}`
          };
          updateDoc(pushDocument, status => {
            if (!status) {
              console.error('An error occured during the message sending.');
            } else {
              this.notificationSystem.addNotification({
                message: t('Anfrage wurde verschickt!'),
                level: 'success'
              });
            }
          });
        }
      }
    }
  };

  setUpFunctionData = () => {
    const data = [
      {
        action: () => {
          this.setState({ searchActive: !this.state.searchActive });
        },
        icon: 'search',
        active: this.state.searchActive,
        tooltip: this.props.t('Geräte durchsuchen')
      }
    ];

    return data;
  };

  search = (searchTerm, dataList) => {
    if (searchTerm.length < 2) {
      return dataList;
    }
    const fuse = new Fuse(dataList, SEARCH_OPTIONS);
    return fuse.search(searchTerm);
  };

  renderDialog = () => {
    const { t } = this.props;
    switch (this.state.modalType) {
      case REMOVE:
        return (
          <QuestionDialog
            caption={t('Löschen')}
            content={t('Sind sie sicher, dass sie den Eintrag löschen wollen?')}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      case REMOVE_ALL:
        return (
          <QuestionDialog
            caption={t('Geräte löschen')}
            content={t('Sind sie sicher, dass sie alle Geräte löschen wollen?')}
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      default: {
        return (
          <DeviceLocationRequestDialog
            errorHandler={this.closeModal}
            succesHandler={this.closeModalSucces}
          />
        );
      }
    }
  };

  render() {
    const { onClose, t } = this.props;
    const { modalIsOpen, searchActive, searchValue } = this.state;

    const rowData = this.setUpData();
    const currentRowData =
      searchActive && searchValue ? this.search(searchValue, rowData) : rowData;

    return (
      <Panel title={t('Geräte')} id="devices" onClose={onClose}>
        <ListHeader
          functionButtons={this.setUpFunctionData()}
          searchActive={searchActive}
          onSearch={value => this.setState({ searchValue: value })}
        />
        <div
          style={{
            height: searchActive ? 'calc(100% - 120px)' : 'calc(100% - 80px)'
          }}
        >
          <List rowData={currentRowData} />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={questionDialogStyles}
          contentLabel="deleteAlert"
        >
          {this.renderDialog()}
        </Modal>
        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />
      </Panel>
    );
  }
}

export default Devices;
