const urlBase = `${window.location.protocol}//${window.location.hostname}`;

// export const API_URL = 'https://staging.myantavi.com/api/';
export const API_URL = 'https://ops.myantavi.com/api/';

// export const API_URL = `${urlBase}/api/`;
export const USER_URL = `${API_URL}users/`;
export const DB_URL = `${API_URL}db/todos/`;

export const getQRCodeUrl = (value, scale = 2) =>
  `${API_URL}barcode?bcid=qrcode&text=${value}&scale=${scale}`;
export const getS3Url = file =>
  `${API_URL}/s3/sign-upload?fileName=chat/${file.name}&fileType=${file.type}`;
