/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import * as Fuse from 'fuse.js';
import { Panel, ListHeader } from '../../../components';
import {
  getFilteredMessages,
  getCurrentUser,
  getCurrentEventId,
  getAppAssets
} from '../../../selectors';

import ChatGroups from './ChatGroups';
import ChatInput from './ChatInput';
import MessageList from './MessageList';

import { updateLastSeenForChatGroup } from '../actions';

const HEIGHT_OFFSET = 120;
const SEARCH_OPTIONS = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['baseName', 'eventName', 'userName', 'data.createdAt', 'data.text']
};

const playSound = notifySound => {
  if (!notifySound) {
    return Promise.resolve();
  }

  const audio = new Audio(notifySound);
  const promise = audio.play() || Promise.reject();

  return promise
    .then(() => {
      // Automatic playback started!
      if (process.env.NODE_ENV === 'development') {
        console.log('Play back started');
      }
    })
    .catch(error => {
      // Automatic playback failed.
      // Show a UI element to let the user manually start playback.
      console.error('Cant play sounds', error);
    });
};

class Chat extends Component {
  state = {
    maxHeight: window.innerHeight - HEIGHT_OFFSET,
    searchActive: false,
    searchValue: '',
    selectedGroup: undefined,
    currentEventId: undefined
  };

  static getDerivedStateFromProps({ currentEventId }, state) {
    if (currentEventId !== state.currentEventId) {
      return {
        selectedGroup: currentEventId,
        currentEventId
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    const { messages, currentUserId, t, notifySound } = this.props;
    const { currentEventId } = this.state;

    const current = messages.length;
    const prev = prevProps.messages.length;

    if (
      current > prev &&
      prev > 0 &&
      currentEventId === prevProps.currentEventId
    ) {
      // new messages
      const { selectedGroup } = this.state;

      messages.slice(0, current - prev).forEach(m => {
        const group = m.data.group || m.eventId;
        if (
          m.userId !== currentUserId &&
          (m.eventId === currentEventId || // current event
            //|| group === selectedGroup // selected channel
            m.data.to === currentUserId)
        ) {
          // to myself
          // notify
          this.notificationSystem.clearNotifications();

          this.sendNotification({
            title: t('Neue Nachricht'),
            message: m.data.text,
            position: 'tr',
            autoDismiss: 30,
            level: 'success'
          });

          playSound(notifySound);
        }
      });
    }
  }

  sendNotification = notification =>
    this.notificationSystem.addNotification(notification);

  search = () => {
    const { messages } = this.props;
    const { searchValue } = this.state;

    if (searchValue.length < 2) {
      return messages;
    }

    const fuse = new Fuse([...messages], SEARCH_OPTIONS);
    return fuse.search(searchValue);
  };

  getFunctionButtons = () => {
    const { searchActive } = this.state;
    const { t } = this.props;

    return [
      {
        action: () => {
          this.setState(state => ({ searchActive: !state.searchActive }));
        },
        icon: 'search',
        active: searchActive,
        tooltip: t('Nachrichten durchsuchen')
      }
    ];
  };

  selectChatGroup = group => {
    const { updateLastSeen } = this.props;
    const { selectedGroup } = this.state;
    updateLastSeen(selectedGroup);
    this.setState({ selectedGroup: group });
  };

  handleSearch = searchValue => this.setState({ searchValue });

  render() {
    const {
      messages,
      currentUserId,
      onClose,
      currentEventId,
      unreadMessages,
      t,
      openGallery
    } = this.props;

    const { searchActive, selectedGroup } = this.state;

    return (
      <Panel title={t('Kommunikation')} id="chat" onClose={onClose}>
        <NotificationSystem
          ref={n => {
            this.notificationSystem = n;
          }}
        />

        <div
          style={{
            height: 'calc(100% - 40px)',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            alignItems: 'strech'
          }}
        >
          <div
            style={{
              flexGrow: 1,
              flexBasis: '40%'
              // backgroundColor: 'red'
            }}
          >
            <ListHeader headerTitle={t('Chat Gruppen')} />
            <div
              style={{
                margin: 3,
                marginTop: 0,
                height: 'calc(100% - 40px)',
                overflowY: 'auto'
                // backgroundColor: 'green'
              }}
            >
              <ChatGroups
                selected={selectedGroup}
                onSelect={this.selectChatGroup}
              />
            </div>
          </div>

          <div style={{ flexGrow: 1, flexBasis: '60%' }}>
            <ListHeader
              headerTitle={t('Chat')}
              functionButtons={this.getFunctionButtons()}
              searchActive={searchActive}
              onSearch={this.handleSearch}
            />

            <div style={{ padding: 3 }}>
              {!searchActive && <ChatInput group={selectedGroup} />}
            </div>
            <div
              style={{
                margin: 3,
                marginTop: 0,
                height: 'calc(100% - 130px)',
                overflowY: 'auto'
                // backgroundColor: 'green'
              }}
            >
              <MessageList
                messages={
                  searchActive
                    ? this.search().filter(
                        m => selectedGroup === (m.data.group || currentEventId)
                      )
                    : messages.filter(
                        m => selectedGroup === (m.data.group || currentEventId)
                      )
                }
                images={messages.reduce((rows, { data }) => {
                  if (data.image) {
                    rows.push({ src: data.image, caption: data.text });
                  }
                  return rows;
                }, [])}
                userId={currentUserId}
                unreadMessages={unreadMessages[selectedGroup]}
              />
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = state => ({
  currentUserId: getCurrentUser(state)._id,
  currentEventId: getCurrentEventId(state),
  messages: getFilteredMessages(state),
  unreadMessages: state.dashboard.unreadMessages,
  notifySound: getAppAssets(state).notifySound
});

export default connect(mapStateToProps, {
  updateLastSeen: updateLastSeenForChatGroup
})(Chat);
