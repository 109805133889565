import React, { Component } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';

import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter
} from '../components/';

class DeviceLocationRequestDialogComp extends Component {
  state = {
    displayText: this.props.t('Dauer'),
    value: -1
  };

  handleChange = ({ text: displayText, value }) => {
    this.setState({ displayText, value });
    /*
    if (this.state.requestDuration != null) {
      const curDate = new Date(this.state.toValue);
      curDate.setHours(parseInt(toTime / 3600, 10));
      const ts = parseInt(curDate.getTime() / 1000, 10); // Compute the timestamp
      this.props.filterByEnd(ts);
    }
    */
  };

  render() {
    const { errorHandler, succesHandler, t } = this.props;
    let demo1 = [
      {
        text: t('5 min'),
        value: 5 * 60
      },
      {
        text: t('15 min'),
        value: 15 * 60
      },
      {
        text: t('1 Stunde'),
        value: 60 * 60
      }
    ];

    return (
      <Dialog>
        <DialogHeader
          title={t('Locationanfrage senden.')}
          onClose={errorHandler}
        />
        <DialogContent>
          <div className="row">
            <div className="col-md-6">
              <p>
                {t('Für wie lange möchten Sie die Locationanfrage senden?')}
              </p>
            </div>
            <div className="col-md-6">
              <Dropdown
                id="dropdown-custom-2"
                onSelect={(i, obj) => this.handleChange(demo1[i])}
              >
                <Dropdown.Toggle bsStyle="default">
                  {this.state.displayText ? this.state.displayText : ''}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  {demo1.map((item, i) => (
                    <MenuItem eventKey={i} key={i}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </DialogContent>
        <DialogFooter>
          <button className="btn btn-default" onClick={errorHandler}>
            {t('Abbrechen')}
          </button>
          <button
            className="btn btn-primary pull-right"
            onClick={() => {
              succesHandler(this.state.value);
            }}
          >
            {t('Senden')}
          </button>
        </DialogFooter>
      </Dialog>
    );
  }
}

const DeviceLocationRequestDialog = translate('translations')(
  DeviceLocationRequestDialogComp
);
export { DeviceLocationRequestDialog };
