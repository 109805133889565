import React, { Component } from 'react';

import { DomEvent } from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'react-tippy';

const style = { fontSize: 22 };
export default class TagFilter extends Component {
  state = {
    hovering: false,
    extended: false
  };

  renderTagsList = tags =>
    tags.map((t, i) => {
      const handleMouseEnter = () => this.setState({ hovering: t.label });
      const handleMouseLeave = () => this.setState({ hovering: false });
      const style = {
        backgroundColor: this.state.hovering === t.label ? '#ddd' : undefined
      };
      return (
        <div
          key={`div-tag-${i}`}
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => {
            this.props.tagChangeHandler(t.label);
          }}
        >
          <input
            type="checkbox"
            key={`tag-${i}`}
            readOnly
            checked={t.active !== false}
            style={{ marginRight: '5px', marginLeft: '5px' }}
          />
          {t.label}
        </div>
      );
    });

  render() {
    const { extended } = this.state;
    const { visible, tags } = this.props;
    if (!visible || tags.length === 0) {
      return <div />;
    }
    const mouseEnter = () => this.setState({ extended: true });
    const mouseLeave = () => this.setState({ extended: false });

    return (
      <div
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        ref={container => {
          if (container) {
            DomEvent.disableClickPropagation(
              container
            ).disableScrollPropagation(container);
          }
        }}
      >
        {extended ? (
          <Tooltip title="Filter map elements by tag">
            <div
              className="leaflet-map-info-tag-extended"
              id="leaflet-map-info-tag"
            >
              <div className="row">
                <div className="col-md-12">
                  <h4 style={{ marginLeft: 5 }}>Tagfilter</h4>
                  <div style={{ height: 400, overflow: 'auto' }}>
                    {this.renderTagsList(tags)}
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>
        ) : (
          <div className="leaflet-touch leaflet-control-custom">
            <FontAwesomeIcon icon="tags" style={style} />
          </div>
        )}
      </div>
    );
  }
}
