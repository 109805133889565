import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import auth from './auth';
import { PrivateRoute, InitalSpinner } from './components';

import { Home, Login, Logout } from './routes';

class App extends Component {
  componentDidMount() {
    const { refreshToken } = this.props;
    refreshToken();
  }

  render() {
    const { isRefreshingToken, isLoggedIn, t } = this.props;

    if (isRefreshingToken) {
      return <InitalSpinner loadingMsg={t('Dokumente werden geladen ...')} />;
    }

    return (
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />

          <PrivateRoute path="/" component={Home} authenticated={isLoggedIn} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = ({ auth: { isLoggedIn, isRefreshingToken } }) => ({
  isLoggedIn,
  isRefreshingToken
});

export default connect(mapStateToProps, { refreshToken: auth.refreshToken })(
  DragDropContext(HTML5Backend)(translate('translations')(App))
);
