import React from 'react';
import { connect } from 'react-redux';
import { Subject } from 'rxjs';

// TODO cross platform
import { getEncryptionDetails } from '../selectors';
// TODO cross platform
import { setPrivateKey } from '../routes/dashboard/actions';

// import { PasswordDialog } from '../uiblocks';
import PasswordDialog from '../components/PasswordDialog';

import { getPrivateKey, encrypt, decrypt } from './encryption_sodium';

const wait = delay => new Promise(resolve => setTimeout(resolve, delay));

const withEncryption = Comp => {
  class Wrapper extends React.Component {
    state = {
      pwDialog: {
        visible: false,
        loading: false
      }
    };

    passphraseSubject = new Subject();

    encrypt = async data => {
      const {
        encryption: { publicKey }
      } = this.props;

      return encrypt(JSON.stringify(data), publicKey);
    };

    encryptItem = async item => {
      if (item.value === undefined) {
        return item;
      }

      try {
        const encoded = await this.encrypt(item.value);
        return {
          ...item,
          value: encoded,
          encrypted: true
        };
      } catch (error) {
        console.warn('Item could not be encrypted');
        console.warn(error);
      }

      return item;
    };

    decrypt = async (ct, eventId) => {
      const {
        encryption: { publicKey, privateKey },
        storePrivateKey,
        t
      } = this.props;

      const pk = await this.getPrivateKey();

      if (pk) {
        try {
          const pt = await decrypt(ct, publicKey, pk);

          if (storePrivateKey) {
            storePrivateKey(pk, eventId);
          }

          this.setState({ pwDialog: { visible: false, loading: false } });
          try {
            return JSON.parse(pt);
          } catch (error) {
            // parse error
            return undefined;
          }
        } catch (error) {
          this.setState({
            pwDialog: {
              visible: true,
              loading: false,
              errorMsg: t('Wrong passphrase')
            }
          });
          return this.decrypt(ct);
        }
      }
    };

    decryptItem = async (item, eventId) => {
      if (!item || !item.encrypted) {
        return item;
      }

      const decrypted = await this.decrypt(item.value, eventId);

      return {
        ...item,
        encrypted: false,
        value: decrypted
      };
    };

    handlePassword = passphrase => this.passphraseSubject.next(passphrase);

    handleCancel = () => this.passphraseSubject.next(null);

    getPrivateKey = async () => {
      const {
        encryption: { privateKey, salt },
        storePrivateKey
      } = this.props;

      if (privateKey) {
        return privateKey;
      }

      // ask for passphrase
      this.setState(state => ({
        pwDialog: { ...state.pwDialog, visible: true }
      }));

      const passphrase = await this.passphraseSubject.take(1).toPromise();

      if (storePrivateKey && salt && passphrase) {
        this.setState({ pwDialog: { visible: true, loading: true } });

        // wait for ui update, on web getPrivateKey blocks thread
        await wait(100);

        return getPrivateKey(passphrase, salt);
      }

      this.setState({ pwDialog: { visible: false, loading: false } });
      throw Error('User canceled passphrase dialog.');
    };

    render() {
      const { pwDialog } = this.state;
      return (
        <React.Fragment>
          <PasswordDialog
            {...pwDialog}
            onCancel={this.handleCancel}
            onClose={this.handleCancel}
            onPress={this.handlePassword}
            t={this.props.t}
          />
          <Comp
            decryptItem={this.decryptItem}
            encryptItem={this.encryptItem}
            {...this.props}
          />
        </React.Fragment>
      );
    }
  }

  const mapStateToProps = state => getEncryptionDetails(state);

  return connect(mapStateToProps, { storePrivateKey: setPrivateKey })(Wrapper);
};

export default withEncryption;
