import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';

const BasePicker = ({
  onClick,
  active,
  dropdownData,
  t,
  withTitle,
  withImage
}) => (
  <div style={{ marginTop: '5px', display: 'inline-block' }}>
    <div className="row" style={{ marginLeft: '-4px' }}>
      {withTitle && <div style={{ float: 'left' }}>{t('Posten')}</div>}
      <div
        style={{
          paddingLeft: '0px',
          display: 'inline-flex',
          minWidth: '150px',
          marginLeft: '5px',
          marginRight: '15px'
        }}
      >
        {active && withImage && (
          <img
            alt="event icon"
            src={active.icon.iconUrl}
            style={{ width: '25px', height: '35px' }}
            className="organization-image"
          />
        )}

        <Dropdown
          id="dropdown-custom-1"
          onSelect={i => onClick(dropdownData[i])}
        >
          <Dropdown.Toggle bsStyle="default" className="base-picker-button">
            {active ? active.name : ''}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            {dropdownData.map((item, i) => (
              <MenuItem eventKey={i} key={i}>
                {item.name}
              </MenuItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  </div>
);

export default translate('translations')(BasePicker);
