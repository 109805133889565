/* eslint-disable no-restricted-properties */
import L from 'leaflet';
import { MapLayer, withLeaflet } from 'react-leaflet';

import '../../../node_modules/leaflet-toolbar/dist/leaflet.toolbar';
import '../../../node_modules/leaflet-toolbar/dist/leaflet.toolbar.css';

import '../../../node_modules/leaflet-distortableimage/dist/leaflet.distortableimage';
import '../../../node_modules/leaflet-distortableimage/dist/leaflet.distortableimage.css';

class ReactDistortableImageOverlayMapLayer extends MapLayer {
  createLeafletElement(props: Props): LeafletElement {
    this.distortableImage = new L.DistortableImageOverlay(
      props.url,
      this.getOptions({
        actions: [
          L.ScaleAction,
          L.FreeRotateAction,
          L.DistortAction,
          L.RevertAction
        ],
        editable: true,
        corners: props.corners
      })
    );

    const updateCorners = event => {
      const corners = event.sourceTarget._corners;
      if (!corners) return;

      const latLngToLayerPoint = latlng =>
        event.sourceTarget._map.latLngToLayerPoint(latlng);

      const points = [
        latLngToLayerPoint(corners[0]),
        latLngToLayerPoint(corners[1]),
        latLngToLayerPoint(corners[3]),
        latLngToLayerPoint(corners[2])
      ];

      props.onUpdateCorners({
        corners,
        points
      });
    };

    this.distortableImage.on('load', event => {
      // clicked image
      L.DomEvent.on(event.sourceTarget._image, 'click', () =>
        props.onSelected(true)
      );

      // clicked map
      L.DomEvent.on(event.sourceTarget._map, 'click', () =>
        props.onSelected(false)
      );

      // first init of corners
      setTimeout(() => updateCorners(event), 10);
    });

    // update corners
    this.distortableImage.on('update', updateCorners);

    return this.distortableImage;
  }

  updateLeafletElement(fromProps, toProps) {
    if (fromProps.opacity !== toProps.opacity) {
      this.distortableImage.setOpacity(toProps.opacity);
    }
  }
}

export default withLeaflet(ReactDistortableImageOverlayMapLayer);
