import React, { useState } from 'react';
import Loading from 'react-loading-animation';
import Modal from 'react-modal';
import Dialog from './Dialog';
import DialogHeader from './DialogHeader';
import DialogContent from './DialogContent';

import { progressDialogStyle } from '../ModalStyles';

export default ({
  visible,
  onClose,
  onPress,
  errorMsg,
  loading,
  title,
  t,
  showDecryptOption
}) => {
  const [decryptData, setDecrypt] = useState(false);
  const [withMessages, setMessages] = useState(false);
  const [withProtocol, setProtocol] = useState(false);

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      style={progressDialogStyle}
    >
      <Dialog className="edit-user-dialog">
        <DialogHeader title={title} onClose={onClose} />
        <DialogContent className="edit-user-dialog-content">
          {loading ? (
            <div>
              <h4>{t('Generating PDF file...')}</h4>
              <p>
                {t(
                  'Depending on the amount of data, this process can take a few minutes.'
                )}
              </p>
            </div>
          ) : (
            <p>{t('Click to generate PDF.')}</p>
          )}
          <div className="row">
            {!loading && showDecryptOption && (
              <label htmlFor="decrypt">
                <input
                  id="decrypt"
                  type="checkbox"
                  checked={decryptData}
                  onChange={() => {
                    setDecrypt(!decryptData);
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      onPress({ decryptData, withMessages, withProtocol });
                    }
                  }}
                  style={{ marginRight: 5 }}
                />
                {t('Decrypt data')}
              </label>
            )}
          </div>
          <div className="row">
            {!loading && (
              <label htmlFor="withMessages">
                <input
                  id="withMessages"
                  type="checkbox"
                  checked={withMessages}
                  onChange={() => {
                    setMessages(!withMessages);
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      onPress({ decryptData, withMessages, withProtocol });
                    }
                  }}
                  style={{ marginRight: 5 }}
                />
                {t('Include chat messages')}
              </label>
            )}
          </div>
          <div className="row">
            {!loading && (
              <label htmlFor="protocol">
                <input
                  id="protocol"
                  type="checkbox"
                  checked={withProtocol}
                  onChange={() => {
                    setProtocol(!withProtocol);
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      onPress({ decryptData, withMessages, withProtocol });
                    }
                  }}
                  style={{ marginRight: 5 }}
                />
                {t('Include acitivity protocol')}
              </label>
            )}
          </div>
          {errorMsg && <i style={{ color: '#FF0000' }}>{errorMsg}</i>}
          {loading && <Loading />}
        </DialogContent>
        <div className="edit-user-dialog-footer">
          <button
            type="button"
            className={`btn btn-default ${loading ? 'disabled' : ''}`}
            onClick={onClose}
          >
            {t('Abbrechen')}
          </button>
          <button
            type="button"
            className={`btn btn-primary pull-right ${
              loading ? 'disabled' : ''
            }`}
            onClick={() => onPress({ decryptData, withMessages, withProtocol })}
          >
            {t('Ok')}
          </button>
        </div>
      </Dialog>
    </Modal>
  );
};
