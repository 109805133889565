/* eslint-disable camelcase */
import * as sodium from 'libsodium-wrappers';
import base64 from 'base64-arraybuffer';
import { TextEncoder, TextDecoder } from 'text-encoding-shim';

export const toArrayBuffer = message =>
  new Uint8Array(new TextEncoder('utf-8').encode(message));
export const fromArrayBuffer = ab => new TextDecoder('utf-8').decode(ab);

export const toBase64 = ab => base64.encode(ab);
export const fromBase64 = str => new Uint8Array(base64.decode(str));

export const encrypt = async (message, publicKey) => {
  // const sodium = await import(/* webpackChunkName: "libsodium" */ 'libsodium-wrappers');
  await sodium.ready;

  const key = typeof publicKey === 'string' ? fromBase64(publicKey) : publicKey;
  const encrypted = await sodium.crypto_box_seal(toArrayBuffer(message), key);
  return toBase64(encrypted);
};

export const decrypt = async (chiphertext, publicKey, privateKey) => {
  // const sodium = await import(/* webpackChunkName: "libsodium" */ 'libsodium-wrappers');
  await sodium.ready;

  const ct =
    typeof chiphertext === 'string' ? fromBase64(chiphertext) : chiphertext;
  const pk = typeof publicKey === 'string' ? fromBase64(publicKey) : publicKey;
  const sk =
    typeof privateKey === 'string' ? fromBase64(privateKey) : privateKey;
  const decrypted = await sodium.crypto_box_seal_open(ct, pk, sk);
  return fromArrayBuffer(decrypted);
};

export const getPrivateKey = async (passphrase, salt) => {
  // const sodium = await import(/* webpackChunkName: "libsodium" */ 'libsodium-wrappers');
  await sodium.ready;
  const keyLength = 32; // bytes / 256 bit

  return sodium.crypto_pwhash(
    keyLength,
    toArrayBuffer(passphrase),
    typeof salt === 'string' ? fromBase64(salt) : salt,
    100, // iterations
    1 * 1024 * 1024, // 8kb
    sodium.crypto_pwhash_ALG_ARGON2I13
  );
};

export const getPublicKey = async privateKey => {
  // const sodium = await import(/* webpackChunkName: "libsodium" */ 'libsodium-wrappers');
  await sodium.ready;
  return sodium.crypto_scalarmult_base(privateKey);
};

export const generatePrivateKey = async passphrase => {
  // const sodium = await import(/* webpackChunkName: "libsodium" */ 'libsodium-wrappers');
  await sodium.ready;
  const salt = await sodium.randombytes_buf(sodium.crypto_pwhash_SALTBYTES);
  const privateKey = await getPrivateKey(passphrase, salt);

  return { privateKey, salt };
};

export const generateKeyPair = async passphrase => {
  // const sodium = await import(/* webpackChunkName: "libsodium" */ 'libsodium-wrappers');
  await sodium.ready;

  const { privateKey, salt } = await generatePrivateKey(passphrase);
  const publicKey = await getPublicKey(privateKey);

  return {
    privateKey: toBase64(privateKey),
    publicKey: toBase64(publicKey),
    salt: toBase64(salt)
  };
};

// async function main() {
//   await sodium.ready;

//   const salt = 'sUy4TTXQct7mjm+n52MvdA==';
//   const passphrase = 'Ich bin doch nicht blöd.';
//   console.log('passphrase ab', toArrayBuffer(passphrase));

//   const privateKey = await getPrivateKey(passphrase, salt);
//   console.log('test', salt, toBase64(privateKey));

//   const publicKey = await getPublicKey(privateKey);
//   console.log('publicKey', toBase64(publicKey));

//   const keypair = { privateKey, publicKey };
//   // const keypair = await generateKeyPair(passphrase);
//   // console.log(keypair);

//   const message = 'Mein geheimer Text: !@#$%^&*()+üøo¨øÖäëøe´';
//   console.log('message', message);
//   const encrypted = await encrypt(message, keypair.publicKey);

//   // const encrypted = 'pqfyZSJLD8hfYzYiKy1tSIXBDvJVA/f0xqgICIujAltE/bpUEydPsAdjuaaYvK1xzPUnJ+OTDSbqedBmS+HCIZIdGzXDRvQd7jL8QMCsdZVVsToGN+lenwLUvK/aHTLWgzw5';
//   console.log('encrypted', encrypted);

//   const decrypted = await decrypt(
//     encrypted,
//     keypair.publicKey,
//     keypair.privateKey
//   );
//   console.log('decrypted', decrypted);
// }

// main().catch(error => console.warn(error.message));
