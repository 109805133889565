import React from 'react';
import Progress from 'react-progressbar';

const InitalSpinner = ({ progress, loadingMsg, color }) => (
  <div id="init-container">
    <div id="init-content">
      <div className="logo">
        <div className="pulse-logo dot" id="biggest">
          <div className="pulse-logo dot" id="small" />
          <div className="pulse-logo dot" id="big" />
        </div>
      </div>

      <div style={{ display: 'inlineBlock' }}>
        {progress ? (
          <div style={{ margin: 10, display: 'inlineBlock' }}>
            <Progress completed={progress} color={color || '#DBFF36'} />
            <span style={{ fontSize: 32, fontWeight: 'bold' }}>
              {progress > 0 && `${progress} %`}
            </span>
          </div>
        ) : null}
        <hr />
        <span>{loadingMsg}</span>
      </div>
    </div>
  </div>
);

export default InitalSpinner;
