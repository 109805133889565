// import 'core-js';

// css
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css';
import 'leaflet/dist/leaflet.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-tippy/dist/tippy.css';

import './App.css';

// rx
import 'rxjs';
import 'rxjs-compat';

import { I18nextProvider } from 'react-i18next';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Modal from 'react-modal';

import registerServiceWorker from './registerServiceWorker';
import App from './App';

import store from './configureStore';
import i18n from './i18n';
// Avoid `console` errors in browsers that lack a console.
import { patchConsole } from './utils';

import env from './env.json';

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

Modal.setAppElement('#root');

patchConsole();

// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
//   whyDidYouRender(React, { include: [/Input/] });
// }

const bugsnagClient = bugsnag({
  apiKey: 'abf4891978186fee8f05f13a53b949ee',
  notifyReleaseStages: ['production', 'staging'],
  appVersion: env.GIT_COMMIT
});

bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
registerServiceWorker();
