// @flow
import * as t from './actionTypes';

const login = (name, password, token) => ({
  type: t.LOGIN,
  payload: { name, password, token }
});

const logout = () => ({
  type: t.LOGOUT
});

const refreshToken = () => ({
  type: t.REFRESH_TOKEN
});

export default {
  login,
  logout,
  refreshToken
};
