import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const style = { cursor: 'pointer' };

const PanelHeader = ({ title, onClose }) => (
  <div className="panel-header">
    <h3 className="panel-title">
      {title}
      {onClose && (
        <FontAwesomeIcon
          icon="times-circle"
          pull="right"
          onClick={onClose}
          color="#a94442"
          style={style}
        />
      )}
    </h3>
  </div>
);

const Panel = ({ title, onClose, style, children }) => (
  <div className="panel-container" style={style}>
    <PanelHeader title={title} onClose={onClose} />

    {children}
  </div>
);

export default Panel;
