import { connect } from 'react-redux';
import { Devices } from '../../../components';
import {
  getFilteredDevices,
  getFilteredDeviceLocations,
  getCurrentUser,
  getPermissions,
  getAppSettings
} from '../../../selectors';

import { mapViewportChange } from '../actions';

function mapStateToProps(state) {
  return {
    appSettings: getAppSettings(state),
    devices: getFilteredDevices(state),
    deviceLocation: getFilteredDeviceLocations(state),
    currentUser: getCurrentUser(state),
    permissions: getPermissions(state)
  };
}

export default connect(mapStateToProps, {
  mapViewportChange
})(Devices);
