import React from 'react';
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  Legend
} from 'recharts';

export const genderGraph = (gender, title, barColor, t, key) => (
  <div key={'div-' + key}>
    <h3 style={{ textAlign: 'center' }}>{title}</h3>
    <BarChart
      width={400}
      height={200}
      data={gender}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      key={key}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis allowDecimals={false} />
      <Tooltip />
      <Bar dataKey="value" name={t('Anzahl')} barSize={50}>
        {gender.map((_, i) => (
          <Cell key={`cell-${i}`} fill={barColor[i % barColor.length]} />
        ))}
      </Bar>
    </BarChart>
  </div>
);

export const defaultPieChart = (data, title, pieColor, key) => (
  <div key={'div-' + key}>
    <h3 style={{ textAlign: 'center' }}>{title}</h3>
    <PieChart width={400} height={200} key={key}>
      <Tooltip />
      <Legend verticalAlign="top" height={36} />
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        outerRadius={80}
        label={false}
        nameKey="name"
        dataKey="value"
      >
        {data.map((_, i) => (
          <Cell key={`pie-cell-${i}`} fill={pieColor[i % pieColor.length]} />
        ))}
      </Pie>
    </PieChart>
  </div>
);

export const defaultBarChart = (data, title, fill, t, key) => (
  <div key={'div-' + key}>
    <h3 style={{ textAlign: 'center' }}>{title}</h3>
    <BarChart
      width={400}
      height={500}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      key={key}
      layout="vertical"
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis allowDecimals={false} type="number" />
      <YAxis dataKey="name" type="category" width={110} />
      <Tooltip />
      <Bar dataKey="value" name={t('Anzahl')} barSize={50} fill={fill} />
    </BarChart>
  </div>
);

export const defaultStackedBarChart = (
  data,
  title,
  allLabels,
  barColor,
  t,
  key
) => {
  const jsxBars = allLabels.map((label, i) => (
    <Bar
      key={label}
      dataKey={label}
      name={label}
      stackId="a"
      fill={barColor[i % barColor.length]}
    />
  ));
  return (
    <div key={'div-' + key}>
      <h3 style={{ textAlign: 'center' }}>{title}</h3>
      <BarChart
        width={400}
        height={500}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        key={key}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis allowDecimals={false} type="number" />
        <YAxis dataKey="ts" type="category" />
        <Tooltip />
        {jsxBars}
      </BarChart>
    </div>
  );
};
