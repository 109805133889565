import _ from 'lodash';
import React, { Component } from 'react';
import {
  Field,
  Fields,
  reduxForm,
  change,
  formValueSelector
} from 'redux-form';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';

import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

import { renderField, Dialog, DialogHeader } from '../../components';

import { isEmail } from '../../utils';
import { getEvents, getBases } from '../../selectors';

import {
  renderMultiselectField,
  renderRoleField,
  renderSwitchField
} from './renderFields';

const lower = value => value && value.toLowerCase();

class EditUserDialog extends Component {
  handleSubmit = values => {
    const { succesHandler } = this.props;
    succesHandler(values);
  };

  handleCancel = () => {
    const { errorHandler } = this.props;
    errorHandler();
  };

  render() {
    const {
      handleSubmit,
      caption,
      errorHandler,
      userDoc,
      t,
      events,
      bases,
      tags,
      isAdmin,
      setAllEventsBasesTags,
      allEventsSelected,
      pristine,
      invalid
    } = this.props;

    return (
      <Dialog className="edit-user-dialog">
        <DialogHeader title={caption} onClose={errorHandler} />
        <form
          onSubmit={handleSubmit(this.handleSubmit)}
          onKeyPress={e => {
            if (e.charCode === 13) e.preventDefault();
          }}
        >
          <div className="edit-user-dialog-content">
            <Field
              name="email"
              type="email"
              disabled={userDoc !== undefined}
              component={renderField}
              normalize={lower}
              label={t('Email')}
            />
            <Field
              name="password"
              type="password"
              component={renderField}
              label={t('Password')}
            />

            <div className="row">
              <div className="col-md-6">
                <Field
                  name="name"
                  type="text"
                  component={renderField}
                  label={t('Vorname Nachname')}
                />
              </div>

              <div className="col-md-6">
                <Field
                  name="company"
                  type="text"
                  component={renderField}
                  label={t('Firma')}
                />
              </div>
            </div>

            <Field
              name="phone"
              type="text"
              component={renderField}
              label={t('Telephone number')}
              placeholder="+1 999 999 999"
            />

            <Fields
              names={['color', 'role']}
              component={renderRoleField}
              label={t('Funktion')}
            />

            <Field
              name="admin"
              component={renderSwitchField}
              type="checkbox"
              label={t('Admin')}
              onChange={(event, value) => {
                if (value) {
                  setAllEventsBasesTags();
                }
              }}
            />

            <Field
              name="events"
              label={t('Alle Events')}
              component={renderMultiselectField}
              options={events}
              disabled={isAdmin}
              onChange={(event, value) => {
                if (value === null) {
                  setAllEventsBasesTags();
                }
              }}
            />

            <Field
              name="bases"
              label={t('Alle Posten')}
              component={renderMultiselectField}
              options={bases}
              disabled={isAdmin || allEventsSelected}
            />

            <Field
              name="tags"
              label={t('Alle Tags')}
              component={renderMultiselectField}
              options={tags}
              disabled={isAdmin || allEventsSelected}
            />
          </div>

          <div className="edit-user-dialog-footer">
            <button
              type="button"
              className="btn btn-default"
              onClick={this.handleCancel}
            >
              {t('Abbrechen')}
            </button>
            <button
              type="submit"
              className="btn btn-primary pull-right"
              disabled={pristine || invalid}
            >
              {t('Speichern')}
            </button>
          </div>
        </form>
      </Dialog>
    );
  }
}

const validate = (
  { email, password, company, name, phone, color },
  { userDoc }
) => {
  // TODO internationalize
  const errors = {};
  if (!email) {
    errors.email = 'Bitte E-mail eingeben.';
  } else if (!isEmail(email)) {
    errors.email = 'E-mail Adresse ungültig';
  }
  // only require a passowrd if it is a new user
  if (!password && !userDoc) {
    errors.password = 'Bitte ein Password eingeben.';
  }
  if (!company) {
    errors.company = 'Bitte Firma eingeben.';
  }

  if (!name) {
    errors.name = 'Bitte Namen eingeben.';
  }

  if (!phone) {
    errors.phone = 'Bitte Mobilnummer eingeben.';
  } else {
    const phoneNumber = parsePhoneNumberFromString(phone);
    if (!phoneNumber || !phoneNumber.isValid()) {
      errors.phone = 'Bitte eine valide Mobilnummer eingeben.';
    }
  }

  if (!color) {
    errors.color = 'Bitte eine Funktion auswählen.';
  }

  return errors;
};

const EditUserDialogWithForm = reduxForm({
  form: 'editUserForm',
  validate
})(translate('translations')(EditUserDialog));

const selector = formValueSelector('editUserForm');

const mapStateToProps = state => {
  const selectedEvents = selector(state, 'events');
  const isAdmin = selector(state, 'admin');
  const allEventsSelected = selectedEvents === null;

  const bases = getBases(state).filter(
    b =>
      !Array.isArray(selectedEvents) || selectedEvents.indexOf(b.eventId) >= 0
  );

  const events = getEvents(state);
  const tags = _.unionBy(
    ...bases.map(b => (Array.isArray(b.tags) ? b.tags : [])),
    'value'
  );

  return {
    events: events.map(e => ({ value: e._id, label: e.name })),
    bases: bases.map(b => ({ value: b._id, label: b.name })),
    tags,
    allEventsSelected,
    isAdmin
  };
};

export default connect(mapStateToProps, dispatch => ({
  setAllEventsBasesTags: () => {
    dispatch(change('editUserForm', 'events', null));
    dispatch(change('editUserForm', 'bases', null));
    dispatch(change('editUserForm', 'tags', null));
  }
}))(EditUserDialogWithForm);
