// @flow
export const LOGIN = 'auth/LOGIN';
export const LOGIN_PENDING = 'auth/LOGIN_PENDING';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'auth/LOGIN_ERROR';

export const LOGIN_REQUIRES_2FA = 'auth/LOGIN_REQUIRES_2FA';

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_PENDING = 'auth/LOGOUT_PENDING';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'auth/LOGOUT_ERROR';

export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const REFRESH_TOKEN_PENDING = 'auth/REFRESH_TOKEN_PENDING';
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'auth/REFRESH_TOKEN_ERROR';
