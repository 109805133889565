import React from 'react';
import TimePicker from 'react-bootstrap-time-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePickerWithStyle } from '../../components';

export default class OrganisationDateFilter extends React.Component {
  handleChangeFrom = (fromValue, fromFormattedValue) => {
    const {
      currentFilter: { startTs },
      filterByStart
    } = this.props;

    const fromDate = new Date(fromValue);

    const start = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate()
    );

    if (startTs) {
      start.setHours(new Date(startTs * 1000).getHours());
    }

    const ts = Math.round(start.getTime() / 1000);
    filterByStart(ts);
  };

  handleChangeFromTime = fromTime => {
    const {
      currentFilter: { startTs },
      filterByStart
    } = this.props;

    const start = new Date(startTs * 1000);
    start.setHours(Math.floor(fromTime / 3600));

    const ts = Math.round(start.getTime() / 1000);
    filterByStart(ts);
  };

  handleChangeTo = (toValue, toFormattedValue) => {
    const {
      currentFilter: { endTs },
      filterByEnd
    } = this.props;

    const toDate = new Date(toValue);
    const end = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate()
    );

    if (endTs) {
      end.setHours(new Date(endTs * 1000).getHours());
    }

    const ts = Math.round(end.getTime() / 1000);
    filterByEnd(ts);
  };

  handleChangeToTime = toTime => {
    const {
      currentFilter: { endTs },
      filterByEnd
    } = this.props;

    const end = new Date(endTs * 1000);
    end.setHours(Math.ceil(toTime / 3600));

    const ts = Math.round(end.getTime() / 1000);
    filterByEnd(ts);
  };

  render() {
    const {
      currentFilter: { startTs, endTs },
      resetDateFilters,
      iconColor,
      color,
      placeholderColor,
      t
    } = this.props;

    const startDate = new Date(startTs * 1000);
    const endDate = new Date(endTs * 1000);
    const fromValue = startTs ? startDate.toISOString() : null;
    const toValue = endTs ? endDate.toISOString() : null;

    const fromTime = startTs
      ? startDate.getHours() * 3600 + startDate.getMinutes() * 60
      : 0;
    const toTime = endTs
      ? endDate.getHours() * 3600 + endDate.getMinutes() * 60
      : 0;
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ fontSize: 20, marginTop: 6 }}>
          <FontAwesomeIcon icon="filter" color={iconColor} />
        </div>

        <DatePickerWithStyle
          id="from-datepicker"
          value={fromValue}
          onKeyDown={() => false}
          placeholder={t('Jederzeit')}
          onChange={this.handleChangeFrom}
          className="organization-button"
          color={color}
          placeholderColor={placeholderColor}
          showClearButton={false}
          style={{ width: 120 }}
        />

        {fromValue && (
          <React.Fragment>
            <TimePicker
              onChange={this.handleChangeFromTime}
              value={fromTime}
              style={{ color, width: 80 }}
              className="organization-button"
              step={60}
              format={24}
            />

            <span
              style={{
                color,
                marginTop: 7,
                marginLeft: 10
              }}
            >
              {t('bis')}
            </span>

            <DatePickerWithStyle
              id="to-datepicker"
              value={toValue}
              minDate={fromValue}
              onKeyDown={() => false} // restrict user input
              onChange={this.handleChangeTo}
              color={color}
              placeholder={t('Jederzeit')}
              placeholderColor={placeholderColor}
              className="organization-button"
              showClearButton={false}
              style={{ width: 120 }}
            />

            <TimePicker
              onChange={this.handleChangeToTime}
              value={toTime}
              style={{ color, width: 80 }}
              className="organization-button"
              step={60}
              format={24}
            />

            <div style={{ marginLeft: 3, cursor: 'pointer' }}>
              <FontAwesomeIcon
                icon="times-circle"
                color={color}
                onClick={resetDateFilters}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
