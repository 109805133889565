import * as t from './actionTypes';

const initialState = {
  session: null,
  isLoggingIn: false,
  isLoggingOut: false,
  isLoggedIn: false,
  isRefreshingToken: false,
  requiresTwoFactor: undefined,
  error: null
};

export default (state = initialState, { type, session, error }) => {
  switch (type) {
    case t.LOGIN_PENDING:
      return {
        ...state,
        session: null,
        isLoggingIn: true,
        error: null,
        requiresTwoFactor: undefined
      };

    case t.LOGIN_REQUIRES_2FA:
      return {
        ...state,
        isLoggingIn: false,
        requiresTwoFactor: true
      };

    case t.LOGIN_SUCCESS:
      return {
        ...state,
        session,
        requiresTwoFactor: undefined,
        isLoggingIn: false,
        isRefreshingToken: false,
        isLoggedIn: true,
        error: null
      };

    case t.LOGIN_ERROR:
      return {
        ...state,
        isLoggingIn: false,
        error
      };

    case t.LOGOUT_PENDING:
      return { ...state, isLoggingOut: true, error: null };

    case t.LOGOUT_SUCCESS:
      return {
        ...state,
        session: null,
        isLoggedIn: false,
        isLoggingOut: false,
        error: null
      };

    case t.LOGOUT_ERROR:
      return { ...state, isLoggingOut: false, error };

    case t.REFRESH_TOKEN_PENDING:
      return { ...state, isRefreshingToken: true, error: null };

    case t.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingOut: false,
        isRefreshingToken: false,
        error: null
      };

    case t.REFRESH_TOKEN_ERROR:
      return {
        ...state,
        isLoggingOut: false,
        isRefreshingToken: false,
        isLoggedIn: false,
        error
      };

    default:
      return state;
  }
};
