import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';

import loadable from 'react-loadable';

import { Panel, Loading } from '../../../components';
import {
  getAppSettings,
  getFilteredTrajectories,
  getFilteredMessages,
  getFilteredIncidents,
  getFilteredBases,
  getFilteredPatients
} from '../../../selectors';
import { GEO_JSON_TEMPLATE } from './mapConsts';
import { addNoiseToCoordinates } from '../../../utils';

const MyMap = loadable({
  loader: async () => {
    const withLayerPicker = (
      await import(/* webpackChunkName: "map" */ './withLayerPicker')
    ).default;
    const withControls = (
      await import(/* webpackChunkName: "map" */ './withControls')
    ).default;
    const withPlayer = (
      await import(/* webpackChunkName: "map" */ './withPlayer')
    ).default;
    const withMarkerCluster = (
      await import(/* webpackChunkName: "map" */ './withMarkerCluster')
    ).default;
    const withHeatmapLayer = (
      await import(/* webpackChunkName: "map" */ './withHeatmapLayer')
    ).default;
    const Map = (await import(/* webpackChunkName: "map" */ './mapboxMap'))
      .default;

    return withLayerPicker(
      withControls(withPlayer(withMarkerCluster(withHeatmapLayer(Map))))
    );
  },
  loading: Loading,
  delay: 300
});

class Heatmap extends Component {
  roundToHour = ts => {
    const date = new Date(ts);
    date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
  };

  trajectories2geojson = () => {
    const { trajectories } = this.props;
    if (trajectories.length === 0) {
      return undefined;
    }

    const data = _.cloneDeepWith(GEO_JSON_TEMPLATE);
    let tsMin = Number.MAX_SAFE_INTEGER;
    let tsMax = 0;
    let total = 0;
    trajectories.forEach(t => {
      t.points.forEach(p => {
        const rounded_ts = Math.round(this.roundToHour(p.ts) / 1000);
        tsMax = Math.max(tsMax, rounded_ts);
        tsMin = Math.min(tsMin, rounded_ts);
        data.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [p.lo, p.la]
          },
          properties: {
            users: {
              // to get visualization effects (color gradient)
              [rounded_ts]: 5
            },
            lat: p.la,
            lng: p.lo
          }
        });
        total += 1;
      });
    });
    data.total = total;
    data.start = tsMin;
    data.stop = tsMax;
    // to get visualization effects (color gradient)
    data.maxUsersPerCell = 10;

    return data;
  };

  getMarkerClusterGeojson = () => {
    const { messages, incidents, patients, bases } = this.props;
    const baseLookup = {};
    bases.forEach(b => {
      baseLookup[b._id] = b.geometry.coordinates;
    });
    const clData = _.cloneDeepWith(GEO_JSON_TEMPLATE);
    [...messages, ...incidents, ...patients].forEach(m => {
      let coordinates = null;
      if (m.className === 'ChatMessage') {
        if (m.data.location) {
          coordinates = m.data.location;
        }
      }

      if (
        m.className === 'Incident' ||
        m.className === 'PatientTreatment' ||
        m.className === 'Crowdspotter'
      ) {
        if (m.coordinates && !m.className === 'PatientTreatment') {
          // PAtients always contain coordinates
          coordinates = m.coordinates;
        } else if (baseLookup[m.baseId]) {
          coordinates = {
            longitude: baseLookup[m.baseId][1],
            latitude: baseLookup[m.baseId][0]
          };
        }
      }
      if (!coordinates) {
        return;
      }

      coordinates = addNoiseToCoordinates([
        coordinates.longitude,
        coordinates.latitude
      ]);

      clData.features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates
        },
        properties: { ...m }
      });
    });

    return clData.features;
  };

  render() {
    const { t, onClose, ...rest } = this.props;

    // let start = 0;
    // let stop = 0;
    // let step = 0;
    // let maxUsersPerCell = 0;
    // let features;
    const data = this.trajectories2geojson();

    if (!data) {
      return (
        <Panel
          title={t('Heatmap')}
          id="heatmap"
          onClose={onClose}
          style={{ overflow: 'hidden' }}
        >
          <p>No data available</p>
        </Panel>
      );
    }

    const { start, stop, step, maxUsersPerCell, features } = data;

    // const { start, stop, step, maxUsersPerCell, features } = data;
    const geojson = { type: 'FeatureCollection', features };
    const markerClusterData = {
      type: 'FeatureCollection',
      features: this.getMarkerClusterGeojson()
    };

    const controls = {
      heat: {
        value: [0, Math.ceil(0.8 * maxUsersPerCell)],
        min: 0,
        max: maxUsersPerCell,
        label: 'Heat [Users]'
      }, // TODO init values based on response => add stats to response
      radius: {
        value: 60,
        min: 30,
        max: 300,
        label: 'Radius [m]'
      },
      opacity: {
        value: 1,
        min: 0,
        max: 1,
        label: 'Opacity [0-1]',
        step: 0.05
      }
    };

    return (
      <Panel
        title={t('Heatmap')}
        id="heatmap"
        onClose={onClose}
        style={{ overflow: 'hidden' }}
      >
        <div style={{ height: '100%' }}>
          <MyMap
            geojson={geojson}
            markerClusterData={markerClusterData}
            images={markerClusterData.features.reduce((images, f) => {
              if (f.properties.image) {
                images.push({ src: f.properties.image });
              }
              if (f.properties.data.image) {
                images.push({ src: f.properties.data.image });
              }
              return images;
            }, [])}
            start={start}
            stop={stop}
            step={step}
            radius={100}
            minHeat={0}
            maxHeat={maxUsersPerCell}
            controls={controls}
            showControls
            showLayerPicker
            {...rest}
          />
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    appSettings: getAppSettings(state),
    trajectories: getFilteredTrajectories(state),
    messages: getFilteredMessages(state),
    incidents: getFilteredIncidents(state),
    patients: getFilteredPatients(state),
    bases: getFilteredBases(state) // to look up positions
  };
}

export default connect(mapStateToProps)(Heatmap);
