import {
  FILTER_BY_EVENT,
  FILTER_BY_BASE,
  FILTER_BY_START,
  FILTER_BY_END,
  FILTER_RESET,
  FILTER_SET_TRAJ_TS
} from './ActionTypes';
import { LOGOUT_SUCCESS } from '../../auth/actionTypes';

const INITAL_STATE = {
  eventId: undefined,
  startTs: undefined,
  endTs: undefined,
  trajTSNow: undefined
};

export default (state = INITAL_STATE, { type, payload }) => {
  switch (type) {
    case FILTER_BY_EVENT:
      return { ...state, eventId: payload.id, baseId: undefined };

    case FILTER_BY_BASE:
      return { ...state, baseId: payload.id };

    case FILTER_BY_START:
      return { ...state, startTs: payload.ts };

    case FILTER_BY_END:
      return { ...state, endTs: payload.ts };

    case FILTER_SET_TRAJ_TS:
      return { ...state, trajTSNow: payload.tsInMills };

    case FILTER_RESET:
      return { ...state, startTs: undefined, endTs: undefined };

    case LOGOUT_SUCCESS:
      return INITAL_STATE;
    default:
      return state;
  }
};
