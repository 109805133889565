import React, { useRef, useImperativeHandle } from 'react';
import { Map, LayersControl } from 'react-leaflet';
import { GoogleLayer } from 'react-leaflet-google';
import AutoSizer from 'react-virtualized-auto-sizer';

import { KEY, ROADMAP, SATELLITE, HYBRID } from '../../constants';
import MapStyle from './MapStyle.json';

export default React.forwardRef(
  ({ children, style, renderOverlays, ...props }, forwardRef) => {
    const ref = useRef(null);
    useImperativeHandle(forwardRef, () => ref.current);

    return (
      <AutoSizer
        onResize={() => {
          if (ref.current) {
            ref.current.leafletElement.invalidateSize();
          }
        }}
      >
        {size => (
          <Map ref={ref} {...props} style={{ ...style, ...size }}>
            <LayersControl position="topright">
              <LayersControl.BaseLayer checked name="Google Maps Roads">
                <GoogleLayer
                  googlekey={KEY}
                  maptype={ROADMAP}
                  libraries={['places']}
                  styles={MapStyle}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Maps Satellite">
                <GoogleLayer
                  googlekey={KEY}
                  maptype={SATELLITE}
                  libraries={['places']}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Maps Hydrid">
                <GoogleLayer
                  googlekey={KEY}
                  maptype={HYBRID}
                  libraries={['places']}
                />
              </LayersControl.BaseLayer>
              {renderOverlays && renderOverlays()}
            </LayersControl>

            {children}
          </Map>
        )}
      </AutoSizer>
    );
  }
);
