import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// eslint-disable-next-line import/no-unresolved
import { View, Text, StyleSheet, Platform } from 'react-native';

import DatePicker, {
  registerLocale,
  CalendarContainer
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { de, es, it, fr, pt } from 'date-fns/locale';

registerLocale('de', de);
registerLocale('it', it);
registerLocale('es', es);
registerLocale('pt', pt);
registerLocale('fr', fr);

const styles = StyleSheet.create({
  container: {
    ...Platform.select({ web: { zIndex: 'auto' } })
  },
  text: {
    paddingLeft: 0,
    fontSize: 16,
    fontWeight: '400',
    color: '#8DA624',
    fontFamily: 'Helvetica'
  }
});

const CustomInput = React.forwardRef(
  ({ value, placeholder, onClick, textStyle }, forwardRef) => (
    <Text style={[styles.text, textStyle]} onPress={onClick} ref={forwardRef}>
      {value || placeholder}
    </Text>
  )
);

const Container = ({ className, children }) => (
  <div style={{ width: 'max-content', zIndex: 3 }}>
    <CalendarContainer className={className}>{children}</CalendarContainer>
  </div>
);

const DateTimePicker = ({
  value,
  onChange,
  mode,
  textStyle,
  containerStyle,
  t
}) => {
  let date;

  if (value instanceof Date) {
    date = value;
  } else if (Number.isInteger(value)) {
    date = moment.unix(value).toDate();
  } else if (typeof value === 'string') {
    date = moment(value).toDate();
  }

  let format;
  let placeholder;
  switch (mode) {
    case 'date':
      format = 'PP';
      placeholder = t('Select date');
      break;
    case 'time':
      format = 'pp';
      placeholder = t('Select time');
      break;
    default:
      format = 'PPpp';
      placeholder = t('Select date and time');
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <DatePicker
        selected={date}
        onChange={onChange}
        showTimeSelect={mode !== 'date'}
        showTimeSelectOnly={mode === 'time'}
        timeFormat="HH:mm"
        timeIntervals={1}
        dateFormat={format}
        locale={moment().locale()}
        customInput={<CustomInput textStyle={textStyle} />}
        calendarContainer={Container}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={placeholder}
        timeCaption={t('Time')}
      />
    </View>
  );
};

DateTimePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.instanceOf(undefined)
  ]),
  onChange: PropTypes.func,
  textStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  mode: PropTypes.oneOf(['date', 'time', 'datetime']),
  t: PropTypes.func
};

DateTimePicker.defaultProps = {
  value: undefined, // gets initialized with current date
  onChange: () => {},
  textStyle: {},
  containerStyle: {},
  mode: 'date',
  t: v => v
};

export default DateTimePicker;
