import React from 'react';
import { connect } from 'react-redux';

import { Panel } from '../../../components';
import { DEFAULTLAT, DEFAULTLNG, DEFAULTZOOM } from '../../../constants';
import {
  getFilteredBases,
  getTrajectories,
  getFilteredMaterialOrders,
  getFilteredPatients,
  getFilteredDevices,
  getFilteredDeviceLocations,
  getFilteredCrowd,
  getCurrentEvent,
  getAppSettings,
  getCurrentGis,
  getPermissions
} from '../../../selectors';

import LeafletMap from './LeafletMap';

const BaseMap = ({
  t,
  currentEvent,
  materialOrders,
  patients,
  crowd,
  bases,
  gisDoc,
  trajectories,
  hoverAction,
  mapState,
  checkedLayer,
  baseAction,
  addLocation,
  devices,
  deviceLocations,
  appSettings,
  permissions,
  onClose
}) => (
  <Panel title={t('Karte')} id="map" onClose={onClose}>
    <div style={{ height: 'calc(100% - 40px)' }}>
      <LeafletMap
        t={t}
        lat={DEFAULTLAT}
        lng={DEFAULTLNG}
        zoom={DEFAULTZOOM}
        eventId={currentEvent && currentEvent._id}
        event={currentEvent}
        materialOrders={materialOrders}
        patients={patients}
        crowd={crowd}
        bases={bases}
        gisDoc={gisDoc}
        trajectories={trajectories}
        hoverAction={hoverAction}
        mapState={mapState}
        checkedLayer={checkedLayer}
        baseAction={baseAction}
        addLocation={addLocation}
        /* this would not be necessary if appSettings are required to be there */
        recenterIconUrl={
          appSettings.assets ? appSettings.assets.recenterIconUrl : undefined
        }
        trajTsIconUrl={
          appSettings.assets ? appSettings.assets.trajTsNowIconUrl : undefined
        }
        locateIconUrl={
          appSettings.assets ? appSettings.assets.pushLocate : undefined
        }
        tagsIconUrl={
          appSettings.assets ? appSettings.assets.tagsIconUrl : undefined
        }
        devices={devices}
        deviceLocations={deviceLocations}
        appSettings={appSettings}
        permissions={permissions}
      />
      )}
    </div>
  </Panel>
);

function mapStateToProps(state) {
  const { addLocation, baseAction, hoverAction } = state.dashboard;

  return {
    bases: getFilteredBases(state),
    trajectories: getTrajectories(state),
    materialOrders: getFilteredMaterialOrders(state),
    devices: getFilteredDevices(state),
    deviceLocations: getFilteredDeviceLocations(state),
    patients: getFilteredPatients(state),
    crowd: getFilteredCrowd(state),
    currentEvent: getCurrentEvent(state),
    appSettings: getAppSettings(state),
    gisDoc: getCurrentGis(state),
    permissions: getPermissions(state),
    mapState: state.dashboard.mapState.viewport,
    checkedLayer: state.dashboard.mapState.checkedLayer,
    hoverAction,
    baseAction,
    addLocation
  };
}

export default connect(mapStateToProps)(BaseMap);
