import React, { Component } from 'react';
import { Map, Marker } from 'react-leaflet';
import { icon } from 'leaflet';
import update from 'immutability-helper';
import { GoogleLayer } from 'react-leaflet-google';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';

import { ROADMAP, KEY, DEFAULTZOOM } from '../../../constants';
import { timeConverter } from '../../../utils';

import {
  ListHeader,
  ListItem,
  Dialog,
  DialogHeader,
  DialogContent
} from '../../../components';

class EditMaterialOrderDialog extends Component {
  constructor(props) {
    super(props);
    const { materialOrderDoc, baseDoc, t } = props;
    this.state = {
      materialOrderDoc,
      baseDoc,
      count: 0,
      rowData: [],
      dropdownData: [
        { key: 'new', value: t('Unbearbeitet') },
        { key: 'seen', value: t('Gesehen') }
      ]
    };
  }
  componentDidMount() {
    this.setUpData();
  }
  componentWillReceiveProps({ materialOrderDoc }) {
    this.setState({ materialOrderDoc });
  }

  getDropDownName = status => {
    if (status === 'new') return this.props.t('Unbearbeitet');
    if (status === 'seen') return this.props.t('Gesehen');
    return '';
  };

  setUpData = () => {
    const rowData = [];
    this.props.materialOrderDoc.data.forEach((e, i) => {
      rowData.push({
        title: `${e.units} ${e.name}`,
        details: '',
        _id: i,
        leftIcon: {
          url: this.props.iconUrl
        }
      });
    });
    this.setState({ count: rowData.length, rowData });
  };

  preProcessData = () => {
    this.props.succesHandler(this.state.materialOrderDoc);
  };

  addMarker = () => {
    const iconProps = this.state.baseDoc.icon;
    // iconProps.iconUrl = getIconUrl(this.state.baseDoc, undefined );
    // iconProps.iconRetinaUrl = iconProps.iconUrl;
    const markerIcon = icon(iconProps);
    return (
      <Marker
        position={this.state.baseDoc.geometry.coordinates}
        icon={markerIcon}
      />
    );
  };
  renderDDM = () =>
    this.state.dropdownData.map((e, i) => (
      <MenuItem
        eventKey={i}
        key={e.key}
        onClick={() => {
          const data = this.state.materialOrderDoc;
          this.setState({
            materialOrderDoc: update(data, {
              status: {
                $set: e.key
              }
            })
          });
        }}
      >
        {e.value}
      </MenuItem>
    ));

  render() {
    const {
      rowData,
      count,
      materialOrderDoc: { userName, baseName, ts, status },
      baseDoc
    } = this.state;

    const position = baseDoc && baseDoc.geometry.coordinates;

    const { caption, errorHandler, t } = this.props;
    return (
      <Dialog>
        <DialogHeader title={caption} onClose={errorHandler} />
        <DialogContent>
          <div className="row">
            <div className="col-md-4" style={{ textAlign: 'left' }}>
              {t('Bestellungen von')}
              <b>{` ${userName} `}</b>
              {t('für Posten')}
              <b>{` ${baseName} `}</b>
              {t('für')}
              <b>{` ${timeConverter(ts)}`}</b>
            </div>
            <div className="col-md-4">
              <div className="dropdown" style={{ marginBottom: 5 }}>
                <Dropdown id="dropdown-order">
                  <Dropdown.Toggle bsStyle="default">
                    {this.getDropDownName(status)}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    {this.renderDDM()}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <button
                  className="btn btn-default dropdown-toggle"
                  type="button"
                  id="dropdownMenuStatus"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  style={{ width: '200px' }}
                >
                  {this.getDropDownName(this.state.materialOrderDoc.status)}
                  <span className="caret pull-right" style={{ marginTop: '7px' }} />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                  {this.renderDDM()}
                </ul> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="edit-materialOrder-dialog-map">
                {position && (
                  <Map
                    center={position}
                    zoom={DEFAULTZOOM}
                    zoomControl={false}
                    attributionControl={false}
                    style={{ height: '200px' }}
                  >
                    <GoogleLayer googlekey={KEY} maptype={ROADMAP} />

                    {this.addMarker()}
                  </Map>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ListHeader count={count} />
              {rowData.map(({ title, details }) => (
                <ListItem key={title} value={title} details={details} />
              ))}
            </div>
          </div>
        </DialogContent>
        <div className="edit-materialOrder-dialog-footer">
          <button className="btn btn-default" onClick={errorHandler}>
            {t('Abbrechen')}
          </button>
          <button
            onClick={() => {
              this.preProcessData();
            }}
            className="btn btn-primary pull-right"
          >
            {t('Speichern')}
          </button>
        </div>
      </Dialog>
    );
  }
}

export default translate('translations')(EditMaterialOrderDialog);
