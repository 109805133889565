import React from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';

const withLightbox = Comp => {
  class Wrapper extends React.Component {
    state = {
      currentImage: 0,
      lightboxIsOpen: false
    };

    showImage = currentImage => {
      this.setState({
        currentImage
      });
    };

    previousImage = () => {
      const { currentImage } = this.state;
      if (currentImage > 0) {
        this.setState({ currentImage: currentImage - 1 });
      }
    };

    closeGallery = () => {
      this.setState({ lightboxIsOpen: false });
    };

    nextImage = () => {
      const { images } = this.props;
      const { currentImage } = this.state;

      if (images.length > currentImage) {
        this.setState({ currentImage: currentImage + 1 });
      }
    };

    openGallery = uri => {
      const { images } = this.props;
      const currentImage = images.findIndex(({ src }) => src === uri);

      if (currentImage > -1) {
        this.setState({
          currentImage,
          lightboxIsOpen: true
        });
      }
    };

    render() {
      const { lightboxIsOpen, currentImage } = this.state;
      const { images, ...rest } = this.props;

      return (
        <React.Fragment>
          <ModalGateway>
            {lightboxIsOpen && (
              <Modal onClose={this.closeGallery}>
                <Carousel views={images} currentIndex={currentImage} />
              </Modal>
            )}
          </ModalGateway>

          <Comp
            {...rest}
            openGallery={this.openGallery}
            closeGallery={this.closeGallery}
            nextImage={this.nextImage}
            priviousImage={this.previousImage}
            showImage={this.showImage}
          />
        </React.Fragment>
      );
    }
  }

  Wrapper.propTypes = {
    images: PropTypes.array
  };

  Wrapper.defaultProps = {
    images: []
  };

  return Wrapper;
};

export default withLightbox;
