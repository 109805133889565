/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import _ from 'lodash';
import memoize from 'memoizee';
import { DialogHeader, DialogSection, BasePicker } from './index';

import withEncryption from '../encryption/withEncryption';
import {
  timeConverterDateOnly,
  timeConverterTimeOnly,
  generateId
} from '../utils';

import { getComponentByName } from '../uiblocks/util';

class DynDialog extends Component {
  constructor(props) {
    super(props);

    const doc = props.mainDoc;

    if (Array.isArray(doc.data) && doc.data.length === 0) {
      doc.data = props.form.data.map(({ component, title }) => ({
        [component === 'ButtonGroup' ? 'label' : 'value']: undefined,
        component,
        title
      }));
    }

    // TODO decrypt data on load?
    this.state = {
      mainDoc: doc,
      baseDoc: props.baseDoc,
      changes: {},
      pwDialog: undefined
    };
  }

  getChangeHandler = memoize((i, elem) => v =>
    this.setState(state => {
      const change = { ...state.mainDoc };
      if (elem.component === 'ButtonGroup') {
        change.data[i] = {
          ...change.data[i],
          ...v
        };
      } else {
        change.data[i].value = v;
      }

      const changes = { ...state.changes };
      changes[i] = change.data[i];
      return {
        mainDoc: change,
        changes
      };
    })
  );

  onBaseChange = baseDoc =>
    this.setState((state, { docType, appSettings: { appId } }) => {
      const mainDoc = { ...state.mainDoc };

      // check if new doc
      if (mainDoc._id === '') {
        mainDoc._id = `${appId}:${docType}:${baseDoc.eventId
          .split(':')
          .pop()}:${baseDoc._id.split(':').pop()}:${generateId()}`;
      }

      mainDoc.baseId = baseDoc._id;
      mainDoc.baseName = baseDoc.name;

      return {
        mainDoc,
        baseDoc
      };
    });

  preProcessData = async () => {
    const { mainDoc, changes } = this.state;
    const {
      succesHandler,
      form,
      t,
      encryption,
      currentUser,
      encryptItem
    } = this.props;

    if (!this.checkEncryption()) {
      return;
    }

    const promises = [];
    const missing = [];
    form.data.forEach((item, i) => {
      if (!item.optional) {
        const isMissing = !(mainDoc.data[i].value || mainDoc.data[i].label);

        if (isMissing) {
          missing.push(item.title);
        }
      }
      if (
        item.encrypted &&
        mainDoc.data[i] &&
        !mainDoc.data[i].encrypted &&
        encryption.enabled &&
        encryptItem
      ) {
        promises.push(
          encryptItem(mainDoc.data[i]).then(updated => {
            mainDoc.data[i] = updated;
            changes[i] = updated;
          })
        );
      }
    });

    await Promise.all(promises);

    if (missing.length > 0) {
      // TODO propper dialog
      alert(
        `${t('alertTitleMissings')}\n${t(
          'alertMsgWithMissing'
        )}: ${missing.join(', ')}`
      );

      return;
    }

    // history: [
    //   {ts, user, change: { 1: {}, 2: {}}},
    //   {ts, user, change: { 4: {}, 1: {}}},
    //   {ts, user, change: { 2: {}, 3: {}}},
    // ]
    const step = {
      userName: currentUser.name,
      userId: currentUser._id,
      timestamp: new Date().toISOString(),
      baseName: mainDoc.baseName,
      baseId: mainDoc.baseId,
      ts: mainDoc.ts,
      changes
    };
    if (!mainDoc.history) {
      mainDoc.history = [step];
    } else {
      mainDoc.history.push(step);
    }

    if (mainDoc._id !== '') {
      succesHandler(mainDoc);
    }
  };

  decrypt = async idx => {
    const { mainDoc } = this.state;
    const item = { ...mainDoc.data[idx] };

    const { decryptItem } = this.props;

    if (!decryptItem) {
      throw new Error('decryptItem not in props. Decryption failed.');
    }

    try {
      const decryptedItem = await decryptItem(item, mainDoc.eventId);

      this.setState(state => {
        const data = [...state.mainDoc.data];
        data[idx] = decryptedItem;
        return {
          mainDoc: { ...state.mainDoc, data }
        };
      });
    } catch (error) {
      console.warn(error);
    }
  };

  checkEncryption = () => {
    const { form, encryption, mainDoc } = this.props;
    const encryptionNeeded = _.some(form.data, 'encrypted');

    if (
      encryptionNeeded &&
      !(
        encryption &&
        encryption.enabled &&
        encryption.publicKey &&
        encryption.salt
      )
    ) {
      alert(
        `Encryption is not setup correctly!\nSetup encryption for event "${mainDoc.eventName}"`
      );
      return false;
    }

    return true;
  };

  renderComponent = (elem, i) => {
    const { mainDoc } = this.state;
    const { encryption, t, decryptItem } = this.props;
    const item = mainDoc && mainDoc.data[i];

    if (item && item.encrypted) {
      const disabled =
        !encryption.publicKey || !encryption.salt || !encryption.enabled;
      return (
        <DialogSection
          className="row edit-dialog-section"
          key={`DialogSection-${i}`}
        >
          <h3 key={`h3-${i}`}>{elem.title}</h3>
          <i>{t('Data is Encrypted')}</i>
          <br />
          <button
            type="button"
            className={`btn btn-warning ${disabled ? 'disabled' : ''}`}
            onClick={() => !disabled && this.decrypt(i)}
          >
            Decrypt
          </button>
        </DialogSection>
      );
    }

    const Comp = getComponentByName(elem.component);

    const extraProps = {};

    if (
      elem.component.startsWith('Button') &&
      item &&
      (item.value || item.label)
    ) {
      extraProps.showExtended = true;
    }

    if (Comp) {
      const value =
        elem.component === 'ButtonGroup' ? item : item && item.value;
      return (
        <DialogSection
          className="row edit-dialog-section"
          key={`DialogSection-${i}`}
        >
          <h3 key={`h3-${i}`}>{elem.title}</h3>
          <Comp
            value={value}
            onChange={this.getChangeHandler(i, elem)}
            options={elem.options}
            width={400}
            {...elem.properties}
            {...extraProps}
            t={t}
          />
        </DialogSection>
      );
    }

    return null;
  };

  render() {
    const {
      form: { title, data },
      errorHandler,
      bases,
      t
    } = this.props;

    const { changes } = this.state;
    const { mainDoc, baseDoc, pwDialog } = this.state;
    const daytime = timeConverterTimeOnly(mainDoc.ts);
    const date = timeConverterDateOnly(mainDoc.ts);

    return (
      <div className="edit-dialog">
        <DialogHeader title={title} onClose={errorHandler} />
        <div className="dyn-content">
          <div className="content-scroll-wrap">
            <div className="content-scroll">
              <div className="row" style={{ marginBottom: '12px' }}>
                <div className="col-md-6" style={{ textAlign: 'left' }}>
                  {t('Protokolliert von')}
                  <b>{` ${mainDoc.userName}`}</b>
                  <br />
                  {t('für Posten')}
                  <b>{` ${baseDoc ? baseDoc.name : 'bitte wählen'} `}</b>
                  <br />
                  <b>{` gemeldet um ${daytime} am ${date}`}</b>
                </div>
                <div className="col-md-6">
                  <BasePicker
                    onClick={this.onBaseChange}
                    withImage
                    withTitle
                    active={baseDoc}
                    dropdownData={bases}
                  />
                </div>
              </div>
              {data.map(this.renderComponent)}
            </div>
          </div>
        </div>
        <div className="edit-event-dialog-footer">
          <button
            className="btn btn-default"
            onClick={errorHandler}
            type="button"
          >
            {t('Abbrechen')}
          </button>
          <button
            className="btn btn-primary pull-right"
            disabled={mainDoc._id === '' || _.keys(changes).length === 0}
            type="button"
            onClick={this.preProcessData}
          >
            {t('Speichern')}
          </button>
        </div>
      </div>
    );
  }
}

export default translate('translations')(withEncryption(DynDialog));

// TODO show error in dialog // encrypt decrypt all docs on key change
