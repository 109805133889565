/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import envData from '../../../env.json';
import { getFilteredQrs } from '../../../selectors';
import { getQRCodeUrl } from '../../../config';

class SideMenuFooter extends Component {
  state = {
    showIcon: true
  };

  toggleShowIcon = () =>
    this.setState(state => ({ showIcon: !state.showIcon }));

  render() {
    const { showIcon } = this.state;
    const { qrCodes, iconUrl } = this.props;
    return (
      <div
        className="side-menu-footer"
        title={envData.GIT_COMMIT}
        onClick={this.toggleShowIcon}
      >
        {qrCodes.length === 1 ? (
          <div
            className={`footer-icon-container ${showIcon ? '' : 'is-flipped'}`}
          >
            <img src={iconUrl} alt="footer icon" className="footer-icon" />
            <img
              src={getQRCodeUrl(qrCodes[0]._id)}
              alt="qr code icon"
              className="footer-icon footer-icon--back"
            />
          </div>
        ) : (
          <img src={iconUrl} alt="footer icon" />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    qrCodes: getFilteredQrs(state)
  };
}

export default connect(mapStateToProps)(SideMenuFooter);
