import React from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';

import OrganisationDropdown from './OrganisationDropdown';

const languages = [
  { name: 'English', key: 'en' },
  { name: 'Deutsch', key: 'de' },
  { name: 'Portuguese', key: 'pt' },
  { name: 'Français', key: 'fr' },
  { name: 'Italiano', key: 'it' }
];

const LanguageSelector = ({ i18n, color, iconColor, style }) => {
  const changeLanguage = lng => {
    moment.locale(lng);
    i18n.changeLanguage(lng);
  };
  return (
    <OrganisationDropdown
      active={languages.find(l => l.key === i18n.language)}
      dropdownData={languages}
      onClick={({ key }) => changeLanguage(key)}
      color={color}
      iconColor={iconColor}
      icon="globe"
      style={style}
    />
  );
};

export default translate('translations')(LanguageSelector);
