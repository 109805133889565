/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

const SideMenuHeader = ({
  iconUrl,
  changeSizeHandler,
  customerFirstName,
  customerLastName,
  companyName,
  extended,
  styles
}) => (
  <div
    className="side-menu-header"
    style={{
      background:
        styles && styles.logoBackgroundColor
          ? styles.logoBackgroundColor
          : '#DBFF36',
      color: '#93AB31',
      display: 'flex'
    }}
  >
    <img
      src={iconUrl}
      alt="icon"
      width="36px"
      height="36px"
      onClick={() => {
        changeSizeHandler();
      }}
      className="side-menu-header-icon"
    />

    {extended && (
      <div className="side-menu-header-text">
        <h4>
          {customerFirstName}
          <br />
          {customerLastName}
        </h4>
        <h6>{companyName}</h6>
      </div>
    )}
  </div>
);

export default SideMenuHeader;
