import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { createEpicMiddleware, combineEpics } from 'redux-observable';

import { reducer as formReducer } from 'redux-form';

import { AuthEpic, AuthReducer } from './auth';
import {
  CouchbaseEpic as DbEpic,
  CouchbaseReducer as DbReducer
} from './Couchbase';

import { reducer as OrganisationReducer } from './routes/organisation';

import dashboardReducer from './routes/dashboard/reducers';

// combine reducers
const reducers = combineReducers({
  auth: AuthReducer,
  docs: DbReducer,
  organisation: OrganisationReducer,
  form: formReducer,
  dashboard: dashboardReducer
});

// epics
const rootEpic = combineEpics(AuthEpic, DbEpic);

const epicMiddleware = createEpicMiddleware();

// setup store
let composeEnhancers = compose;
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    name: 'ops',
    actionsBlacklist: ['BATCH_PUT_DOC_SUCCESS']
  });
}
const store = createStore(
  reducers,
  undefined,
  composeEnhancers(applyMiddleware(epicMiddleware))
);

epicMiddleware.run(rootEpic);

export default store;
