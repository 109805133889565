import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getCurrentBriefing,
  getAppSettings,
  getCurrentUser
} from '../../selectors';

import { uploadImage } from '../events/imageUtils';
import { updateDoc } from '../../Couchbase';
import { Panel } from '../../components';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const readImageFromFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onabort = reject;
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

const Briefing = ({ t, briefingDoc }) => {
  const load = useCallback(() => {
    if (!briefingDoc) return;

    const blocksFromHTML = htmlToDraft(briefingDoc.htmlText);

    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditorState(EditorState.createWithContent(content));
  }, [briefingDoc]);

  const uploadImageCallBack = useCallback(
    async file => {
      const image = await readImageFromFile(file);
      const link = await uploadImage(image, `${briefingDoc._id}:${file.name}`);

      return {
        data: {
          link
        }
      };
    },
    [briefingDoc]
  );

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    load();
  }, [load]);

  const handleSave = async () => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const meta =
      '<meta name="viewport" content="width=device-width, initial-scale=1.0">';

    const htmlText = content.startsWith(meta) ? content : `${meta}${content}`;

    setSaving(true);
    try {
      await updateDoc({ ...briefingDoc, htmlText });
      setChanged(false);
    } catch (error) {
      //
    }

    setSaving(false);
  };

  return (
    <Panel title={t('Briefing')} id="briefing">
      <Editor
        wrapperStyle={{
          background: '#eeeeef',
          height: 'calc(100% - 92px)'
        }}
        editorStyle={{
          padding: 6,
          background: '#FFF',
          height: 'calc(100% - 48px)',
          overflowY: 'auto'
        }}
        editorState={editorState}
        onEditorStateChange={editorState => {
          setEditorState(editorState);
          setChanged(true);
        }}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true
          },
          options: [
            'blockType',
            'fontSize',
            'inline',
            'list',
            // 'textAlign',
            'colorPicker',
            'link',
            // 'embedded',
            'emoji',
            'image',
            'remove',
            'history'
          ],
          inline: {
            options: ['bold', 'italic', 'underline']
          },
          list: {
            options: ['unordered', 'ordered']
          },
          textAlign: {
            inDropdown: true
          },
          blockType: {
            options: ['Normal', 'H1', 'H2', 'H3']
          }
        }}
      />
      <div className="edit-dialog-footer">
        <div className="form-group">
          <button
            type="button"
            className={`btn btn-default ${changed ? '' : 'disabled'}`}
            disabled={!changed}
            onClick={load}
          >
            {t('Abbrechen')}
          </button>
          <button
            type="button"
            className={`btn btn-primary ${
              changed ? '' : 'disabled'
            } pull-right`}
            disabled={!changed}
            onClick={handleSave}
          >
            <FontAwesomeIcon
              icon={saving ? 'spinner' : 'save'}
              pulse={saving}
              style={{ marginRight: 3 }}
            />
            {t('Speichern')}
          </button>
        </div>
      </div>
    </Panel>
  );
};

const mapStateToProps = state => ({
  appSettings: getAppSettings(state),
  currentUser: getCurrentUser(state),
  briefingDoc: getCurrentBriefing(state)
});

export default connect(mapStateToProps)(Briefing);
