/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import Control from 'react-leaflet-control';
import { icon as getIcon, divIcon } from 'leaflet';
import update from 'immutability-helper';
import Dropzone from 'react-dropzone';
import Script from 'react-load-script';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

import { polygon } from '@turf/helpers';
import centerOfMass from '@turf/center-of-mass';
import calcArea from '@turf/area';

import memoize from 'memoizee';

import { Tooltip } from 'react-tippy';

import { BaseMap } from '../../components';

import { KEY, DEFAULTLAT, DEFAULTLNG, DEFAULTZOOM } from '../../constants';
import { getIconUrl } from '../../LeafletHelper';
import {
  generateId,
  fliplatlon,
  deepCopyNestedArray,
  prettyArea,
  isActiveTag
} from '../../utils';

import LeafletMapEditInfo from './LeafletMapEditInfo';
import { downloadJSON } from '../dashboard/exportHelper';
import {
  encrypt,
  decrypt,
  fromBase64
} from '../../encryption/encryption_sodium';
import EncryptionDialog from './EncryptionDialog';

import ImageOverlayControl from './ImageOverlayControl';
import { uploadImage, transformImage, getBounds } from './imageUtils';

import TagFilter from '../dashboard/map/TagFilter';

import GeoJsonEdit from './GeoJsonEdit';

const getInitialState = ({
  appId,
  eventId,
  user,
  defaultForms,
  eventDoc,
  gisDoc,
  baseDocs
}) => {
  const ts = Math.floor(Date.now() / 1000).toString();
  const newEventId = `${appId}:Event:${generateId()}`;

  return {
    activeBaseId: undefined,
    activeGISProps: undefined,
    visible: 'notVisible',
    mapClickHandlerActive: false,
    isEncryptionDialogVisible: false,

    eventId,
    deletedBaseDocs: [],
    changed: false,
    baseDocs,
    gisDoc: gisDoc || {
      _id: `${appId}:Gis:${generateId()}`,
      type: 'FeatureCollection',
      className: 'Gis',
      appId,
      eventId: eventDoc ? eventDoc._id : newEventId,
      name: '',
      place: '',
      user,
      features: [],
      ts
    },
    eventDoc: eventDoc || {
      _id: newEventId,
      className: 'Event',
      appId,
      name: '',
      place: '',
      forms: defaultForms || [],
      user,
      location: [], // lat lng array for event (city)
      ts,
      suggestions: [],
      overlays: []
    }
  };
};

class EditEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gmapsPlacesLoaded:
        typeof window.google === 'object' &&
        typeof window.google.maps === 'object' &&
        typeof window.google.maps.places === 'object',

      ...getInitialState(props)
    };

    this.createMarkerClickHandler = memoize(this.createMarkerClickHandler);
  }

  componentDidMount() {
    this.fitMapToBases();
  }

  reset = () => {
    this.setState(getInitialState(this.props));
  };

  componentDidUpdate(prev) {
    const { eventDoc, gisDoc, baseDocs } = this.props;

    if (
      eventDoc !== prev.eventDoc ||
      gisDoc !== prev.gisDoc ||
      baseDocs !== prev.baseDocs
    ) {
      // update docs when revs changed
      this.setState({ eventDoc, gisDoc, baseDocs });
    }
  }

  getMapStyle = () => {
    const { mapClickHandlerActive } = this.state;

    if (mapClickHandlerActive) {
      return { cursor: 'crosshair' };
    }

    return {};
  };

  fitMapToBases = () => {
    if (!this.map || !this.map.leafletElement) {
      return;
    }

    const { bases } = this.props;
    if (bases.length > 1) {
      const bounds = bases.map(b => b.geometry.coordinates);

      this.map.leafletElement.fitBounds(bounds, { padding: [25, 25] });
    } else if (bases.length === 1) {
      // could be a possible fix for: https://trello.com/c/kPYHb3bK
      // If Map has only 1 Base and app has only 1 event, error loading infinite tiles
      // occures only in production
      // possible reason this.map._zoom is undefined => tries to load infinited files
      this.map.leafletElement.setView(
        bases[0].geometry.coordinates,
        this.map._zoom || 15
      );
    }
  };

  addPlace = () => this.setState({ mapClickHandlerActive: true });

  getInitalBase = () => {
    const {
      appId,
      assets: { baseIconUrl }
    } = this.props;
    const { eventDoc } = this.state;

    return {
      _id: `${appId}:Base:${eventDoc._id.split(':').pop()}:${generateId()}`,
      className: 'Base',
      appId,
      eventId: eventDoc._id,
      type: 'Feature',
      selectable: true,
      geometry: {
        type: 'Point',
        coordinates: [0, 0]
      },
      tags: [],
      icon: {
        iconUrl: baseIconUrl,
        iconRetinaUrl: baseIconUrl,
        iconSize: [27, 35],
        iconAnchor: [13, 35],
        popupAnchor: [0, 17]
      },
      deleted: false
    };
  };

  renderBaseMarkers = () => {
    const {
      baseDocs,
      eventDoc: { suggestions }
    } = this.state;

    return baseDocs
      .filter(b => isActiveTag(b.tags, suggestions))
      .map(b => {
        const iconProps = b.icon;
        iconProps.iconUrl = getIconUrl(b, false);
        iconProps.iconRetinaUrl = iconProps.iconUrl;

        return (
          <Marker
            key={b._id}
            _id={b._id}
            icon={getIcon(iconProps)}
            position={b.geometry.coordinates}
            onClick={this.createMarkerClickHandler(b._id)}
            draggable
            onDragend={this.handleMarkerDragged}
          />
        );
      });
  };

  save = async () => {
    const { succesHandler, setSecretKey } = this.props;
    const {
      eventDoc,
      gisDoc,
      baseDocs,
      deletedBaseDocs,
      updatedDocs
    } = this.state;

    this.setState({ saving: true });

    if (eventDoc.encryption && eventDoc.encryption.privateKey) {
      setSecretKey(fromBase64(eventDoc.encryption.privateKey), eventDoc._id);
      delete eventDoc.encryption.privateKey; // HACK remove private key again
    }

    const uploadAndTransformOverlay = async overlay => {
      const {
        original: { url, points, corners },
        filename
      } = overlay;
      const updatedOverlay = _.cloneDeep(overlay);

      if (url.startsWith('data')) {
        updatedOverlay.original.url = await uploadImage(
          url,
          `${eventDoc._id}:${filename}`
        );
      }

      // update transformed image
      updatedOverlay.transformed = {
        bounds: getBounds(corners.map(c => [c.lat, c.lng]))
      };
      updatedOverlay.transformed.url = await transformImage(
        url,
        points
      ).then(data =>
        uploadImage(data, `${eventDoc._id}:transformed:${filename}`)
      );

      return updatedOverlay;
    };

    const overlays = Array.isArray(eventDoc.overlays)
      ? await Promise.all(eventDoc.overlays.map(uploadAndTransformOverlay))
      : [];

    try {
      await succesHandler(
        { ...eventDoc, overlays },
        gisDoc,
        baseDocs,
        deletedBaseDocs,
        updatedDocs
      );
      this.setState({ changed: false, saving: false });
    } catch (error) {
      this.setState({ saving: false });
    }
  };

  renderDivIcons = () => {
    const {
      gisDoc: { features },
      eventDoc: { suggestions }
    } = this.state;
    return features
      .filter(
        f =>
          f.geometry.type === 'Polygon' &&
          isActiveTag(f.properties.tags, suggestions)
      )
      .map(
        (
          { geometry: { coordinates }, properties: { name, area, fill } },
          i
        ) => {
          const CoM = centerOfMass(polygon(coordinates));
          const style = `color:${fill};text-shadow: -1px -1px 0 #fff,1px -1px 0 #fff,-1px 1px 0 #fff,1px 1px 0 #fff;`;
          const html = `<span style='${style}'>${name} <br> (${prettyArea(
            area
          )})</span>`;
          return (
            <Marker
              key={`divMarker-${i}`}
              position={[
                CoM.geometry.coordinates[1],
                CoM.geometry.coordinates[0]
              ]}
              icon={divIcon({ className: 'gis-div-icon-event', html })}
            />
          );
        }
      );
  };

  handleMapClick = event => {
    const { mapClickHandlerActive, baseDocs } = this.state;

    if (mapClickHandlerActive) {
      const newBase = this.getInitalBase();
      newBase.geometry.coordinates = [event.latlng.lat, event.latlng.lng];

      // insert into state
      this.setState({
        baseDocs: [...baseDocs, newBase],
        mapClickHandlerActive: false,
        visible: 'marker',
        activeBaseId: newBase._id,
        changed: true
      });
    }
  };

  createMarkerClickHandler = baseId => () => {
    this.setState({
      visible: 'marker',
      activeBaseId: baseId
    });
  };

  handleMarkerDragged = event => {
    const newPos = [event.target._latlng.lat, event.target._latlng.lng];

    const { baseDocs } = this.state;
    const index = baseDocs.findIndex(b => b._id === event.target.options._id);

    if (index >= 0 && index < baseDocs.length) {
      const data = baseDocs[index];

      baseDocs[index] = update(data, {
        geometry: { coordinates: { $set: newPos } }
      });
      this.setState({ baseDocs, changed: true });
    }
  };

  /**
   * Geo code the choosen place and set the lat lng value to the event
   */
  handleSearchBarSubmit = place => {
    geocodeByAddress(place)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState(state => ({
          eventDoc: update(state.eventDoc, {
            place: { $set: place },
            location: { $set: [latLng.lat, latLng.lng] }
          }),
          changed: true
        }));

        const map = this.eventMap.leafletElement;
        // pan to the found location
        if (map) {
          map.panTo(latLng);
        }
      })
      .catch(error => console.error('Error', error));
  };

  // remove the features from the gis docs
  handleDeleteFeature = ({ id }) => {
    const { gisDoc } = this.state;
    const index = gisDoc.features.findIndex(f => f.properties.id === id);
    if (index > -1) {
      this.setState({
        gisDoc: update(gisDoc, { features: { $splice: [[index, 1]] } }),
        visible: 'notVisible',
        activeGISProps: undefined,
        changed: true
      });
    }
  };

  handleGeoFeatureSelected = feature => {
    this.setState({
      visible: 'polygon',
      activeGISProps: feature.properties
    });
  };

  handleBaseEdit = newBaseDoc => {
    const { baseDocs, eventDoc } = this.state;
    {
      const index = baseDocs.findIndex(b => b._id === newBaseDoc._id);
      const data = baseDocs;
      if (index >= 0 && index < baseDocs.length) {
        data[index] = update(baseDocs[index], { $set: newBaseDoc });

        // update the event doc if new tags were added
        if (newBaseDoc.tags) {
          eventDoc.suggestions = _.unionBy(
            eventDoc.suggestions,
            newBaseDoc.tags,
            'value'
          );
        }

        this.setState({
          eventDoc,
          baseDocs: data,
          changed: true
        });
      }
    }
  };

  handleDeleteBase = () => {
    const { baseDocs, deletedBaseDocs, activeBaseId } = this.state;

    const deleteIndex = baseDocs.findIndex(b => b._id === activeBaseId);
    if (deleteIndex >= 0 && deleteIndex < baseDocs.length) {
      const delBase = baseDocs[deleteIndex];
      this.setState({ deletedBaseDocs: deletedBaseDocs.concat([delBase]) });

      baseDocs.splice(deleteIndex, 1);
      this.setState({ baseDocs, visible: 'notVisible', changed: true });
    }
  };

  handleClose = () => this.setState({ visible: 'notVisible' });

  handleFeaturePropertiesEdit = newProperties => {
    // update the state
    const { gisDoc, eventDoc } = this.state;
    const index = gisDoc.features.findIndex(
      f => f.properties.id === newProperties.id
    );

    // update the properties
    if (index > -1) {
      const suggestions = _.unionBy(
        eventDoc.suggestions || [],
        newProperties.tags || [],
        'value'
      );

      this.setState({
        gisDoc: update(gisDoc, {
          features: { [index]: { properties: { $set: newProperties } } }
        }),
        eventDoc: update(eventDoc, { suggestions: { $set: suggestions } }),
        activeGISProps: newProperties,
        changed: true
      });
    }
  };

  addGeoJson = importedGeoJson => {
    const eventDoc = { ...this.state.eventDoc };
    const baseDocs = [...this.state.baseDocs];

    // import bases seprately
    const importedBases = importedGeoJson.features.filter(
      f => f.geometry.type === 'Point'
    );

    importedBases.forEach(bImport => {
      const {
        properties,
        geometry: { coordinates }
      } = fliplatlon({ ...bImport });

      // check if basis exists and if we are in the same event => update
      const currentBase = baseDocs.find(b => b._id === properties._id);

      if (currentBase) {
        if (coordinates) {
          currentBase.geometry.coordinates = coordinates;
        }
        if (properties.name) {
          currentBase.name = properties.name;
        }
        if (properties.selectable !== undefined) {
          currentBase.selectable = properties.selectable;
        }
        if (properties.icon) {
          currentBase.icon = properties.icon;
        }
        if (properties.tags) {
          currentBase.tags = properties.tags;
        }
      } else {
        // append to base doc list
        const newBase = this.getInitalBase();
        newBase.geometry.coordinates = coordinates;
        newBase.name = properties.name || '';
        if (properties.selectable !== undefined) {
          newBase.selectable = properties.selectable;
        }
        if (properties.icon) {
          newBase.icon = properties.icon;
        }
        if (properties.tags) {
          // replace the event id in tags
          // demoapp:Event:1492695770-4fbf:alex
          const eventIdRegExp = /^\w+:\w+:(\w|-)+/;

          properties.tags.forEach(({ label, value }) => {
            const newTag = {
              label,
              value: value.replace(eventIdRegExp, eventDoc._id)
            };
            newBase.tags.push(newTag);
          });

          eventDoc.suggestions = _.unionBy(
            eventDoc.suggestions,
            [properties.tags],
            'value'
          );
        }
        baseDocs.push(newBase);
      }
    });

    importedGeoJson.features = importedGeoJson.features
      .filter(
        ({ geometry: { type } }) => type === 'Polygon' || type === 'LineString'
      )
      .map(feature => {
        const { type, coordinates } = feature.geometry;

        if (feature.properties.latLng) {
          if (type === 'LineString') {
            coordinates.forEach(pos => {
              pos.reverse();
            });
          } else if (type === 'Polygon') {
            coordinates.forEach(ring => {
              ring.forEach(pos => {
                pos.reverse();
              });
            });
          }

          // eslint-disable-next-line no-param-reassign
          delete feature.properties.latLng;
        }

        // if not set initialize mandatory properties with defaults
        const defaults = {
          name: '',
          id: generateId,
          tags: [],
          fill: '#ED4D70',
          stroke: '#ED4D70',
          'stroke-width': 2,
          'stroke-opacity': 1.0,
          'fill-opacity': 0.6
        };

        _.keys(defaults).forEach(key => {
          if (!feature.properties[key]) {
            const value = defaults[key];
            // eslint-disable-next-line no-param-reassign
            feature.properties[key] = _.isFunction(value) ? value() : value;
          }
        });

        if (type === 'Polygon') {
          // eslint-disable-next-line no-param-reassign
          feature.properties.area = calcArea(polygon(coordinates));
        }

        return feature;
      });

    // update state
    const gisDoc = {
      ...this.state.gisDoc,
      features: _.unionBy(
        importedGeoJson.features,
        this.state.gisDoc.features,
        'properties.id'
      )
    };

    this.setState({ gisDoc, baseDocs, eventDoc, changed: true });
  };

  handleDrop = accepted => {
    if (accepted.length > 0 && accepted[0].name.endsWith('json')) {
      const reader = new FileReader();

      const onload = () => {
        const fileAsBinaryString = reader.result;
        try {
          const importedGeoJson = JSON.parse(fileAsBinaryString);
          this.addGeoJson(importedGeoJson);
        } catch (e) {
          console.error(e); // error in the above string (in this case, yes)!
        }
      };
      reader.onload = onload.bind(this);
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.readAsText(accepted[0]);
    } else {
      const { t } = this.props;
      alert(t('Bitte nur Dateien geojson importieren.'));
    }
  };

  exportGeoJson = () => {
    const {
      gisDoc: { features },
      eventDoc,
      baseDocs
    } = this.state;
    const data = {
      type: 'FeatureCollection',
      features: [...features]
    };
    const filename = `${
      eventDoc.name !== '' ? eventDoc.name : 'unknown'
    }.geojson`;

    // add the basis to the data
    baseDocs.forEach(
      ({
        geometry,
        _id,
        className,
        appId,
        deleted,
        eventId,
        icon,
        name,
        type,
        uploaded,
        selectable,
        tags
      }) => {
        const feature = fliplatlon({
          type: 'Feature',
          geometry: {
            coordinates: deepCopyNestedArray(geometry.coordinates),
            type: geometry.type
          },
          properties: {
            _id,
            className,
            appId,
            deleted,
            eventId,
            icon,
            name,
            type,
            uploaded,
            selectable: !!selectable
          }
        });
        if (tags) {
          feature.properties.tags = tags;
        }

        data.features.push(feature);
      }
    );

    downloadJSON({ filename, data });
  };

  changeGMapsLoadingState = gmapsPlacesLoaded =>
    this.setState({ gmapsPlacesLoaded });

  showEncryptionDialog = () =>
    this.setState({ isEncryptionDialogVisible: true });

  hideEncryptionDialog = () =>
    this.setState({ isEncryptionDialogVisible: false });

  updateOverlays = overlays => {
    this.setState(state => ({
      eventDoc: update(state.eventDoc, {
        overlays: { $set: overlays },
        suggestions: {
          $set: _.unionBy(
            state.eventDoc.suggestions || [],
            overlays.map(o => o.tags).flat() || [],
            'value'
          )
        }
      }),
      changed: true
    }));
  };

  handleTagChange = label => {
    this.setState(state => {
      const updatedTags = [...state.eventDoc.suggestions];
      const idx = updatedTags.findIndex(t => t.label === label);
      updatedTags[idx].active =
        updatedTags[idx].active === undefined
          ? false
          : !updatedTags[idx].active;

      return {
        eventDoc: {
          ...state.eventDoc,
          suggestions: updatedTags
        },
        changed: true
      };
    });
  };

  encrypt = newKeys => {
    const { eventDoc } = this.state;
    const { incidents, patients, encryption } = this.props;

    const { privateKey, publicKey } = encryption[eventDoc._id];
    const docs = []
      .concat(incidents, patients)
      .filter(
        doc => doc.eventId === eventDoc._id && _.some(doc.data, 'encrypted')
      );

    return Promise.all(
      docs.map(async doc => {
        const d = _.cloneDeep(doc);

        for (let i = 0; i < d.data.length; i += 1) {
          if (d.data[i].encrypted) {
            // decrypt old keys
            // eslint-disable-next-line no-await-in-loop
            const plaintext = await decrypt(
              d.data[i].value,
              publicKey,
              privateKey
            );

            // encrypt with new key
            // eslint-disable-next-line no-await-in-loop
            const ciphertext = await encrypt(plaintext, newKeys.publicKey);
            d.data[i].value = ciphertext;
          }
        }

        return d;
      })
    )
      .catch(error => {
        // TODO show to user
        alert(error.message);
        console.error(error, encryption[eventDoc._id], eventDoc._id);
      })
      .then(updatedDocs => {
        // save new key in event
        eventDoc.encryption = newKeys; // HACK privateKey is here for a moment

        this.setState({
          eventDoc,
          isEncryptionDialogVisible: false,
          updatedDocs,
          changed: true
        });
      });
  };

  setSecretKey = sk => this.props.setSecretKey(sk, this.state.eventDoc._id);

  handleGeoJsonChange = geojson => {
    this.setState(state => {
      return {
        gisDoc: {
          ...state.gisDoc,
          features: geojson.features
        },
        changed: true
      };
    });
  };

  render() {
    const {
      eventDoc,
      gisDoc,
      visible,
      activeGISProps,
      gmapsPlacesLoaded,
      baseDocs,
      activeBaseId,
      isEncryptionDialogVisible,
      changed,
      saving
    } = this.state;

    const {
      markerIcons,
      assets,
      gisEnabled,
      encryption,
      encryptionEnabled,
      t
    } = this.props;

    if (!eventDoc || !baseDocs || !gisDoc) {
      return null;
    }

    const position =
      eventDoc.location && eventDoc.location.length === 2
        ? eventDoc.location
        : [DEFAULTLAT, DEFAULTLNG];

    const inputProps = {
      value: eventDoc.place,
      onChange: newText => {
        const newEventDoc = update(eventDoc, { place: { $set: newText } });
        this.setState({ eventDoc: newEventDoc });
      }
    };

    if (!gmapsPlacesLoaded) {
      // THROWS: index.js:2178 You have included the Google Maps API multiple times on this page. This may cause unexpected errors.
      // but at least it does not crash
      // to be improved
      // https://issuetracker.google.com/issues/35820470
      // The problem is that the maps api is loaded but the place library is not included
      // The place library is getting loaded by the leaflet map on the dashboard and if the component is not rendered it is missing.

      return (
        <Script
          url={`https://maps.googleapis.com/maps/api/js?libraries=places&key=${KEY}`}
          onLoad={() => this.changeGMapsLoadingState(true)}
          onError={() => console.error('Could not load the google api')}
        />
      );
    }

    const activeBaseDoc = baseDocs.find(b => b._id === activeBaseId);

    return (
      <React.Fragment>
        {isEncryptionDialogVisible && (
          <EncryptionDialog
            visible
            onClose={this.hideEncryptionDialog}
            onEncrypt={this.encrypt}
            encryption={encryption[eventDoc._id]}
            setSecretKey={this.setSecretKey}
            t={t}
          />
        )}

        <BaseMap
          center={position}
          ref={e => {
            this.eventMap = e;
          }}
          attributionControl={false}
          zoomControl={false}
          zoom={DEFAULTZOOM}
          maxZoom={20}
          onClick={this.handleMapClick}
          style={this.getMapStyle()}
        >
          <Control position="bottomleft">
            <div className="leaflet-control-layers" style={{ padding: 8 }}>
              <div className="form-group">
                <label className="form-control-label" htmlFor="nameInput">
                  {t('Name')}
                </label>
                <input
                  placeholder={t('Name')}
                  id="nameInput"
                  type="text"
                  value={eventDoc.name}
                  onChange={e => {
                    const newText = e.target.value;
                    const newEventDoc = update(eventDoc, {
                      name: { $set: newText }
                    });
                    this.setState({ eventDoc: newEventDoc, changed: true });
                  }}
                  className="form-control"
                  style={{ minWidth: 250 }}
                />
              </div>

              <div className="form-group">
                <label className="form-control-label" htmlFor="placeInput">
                  {t('Ort')}
                </label>

                <PlacesAutocomplete
                  onSelect={this.handleSearchBarSubmit}
                  classNames={{
                    root: 'places-form-group',
                    input: 'places-input',
                    autocompleteContainer: 'my-autocomplete-container'
                  }}
                  inputProps={inputProps}
                />
              </div>

              {encryptionEnabled && (
                <div className="form-group">
                  <button
                    type="button"
                    className={`btn btn-danger`}
                    onClick={this.showEncryptionDialog}
                    style={{ width: '100%' }}
                  >
                    <FontAwesomeIcon icon="key" style={{ marginRight: 8 }} />
                    {t('Verschlüsselung Einrichten')}
                  </button>
                </div>
              )}

              <div className="form-group">
                <button
                  type="button"
                  className={`btn btn-default ${changed ? '' : 'disabled'}`}
                  disabled={!changed}
                  onClick={this.reset}
                >
                  {t('Abbrechen')}
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    changed ? '' : 'disabled'
                  } pull-right`}
                  disabled={!changed}
                  onClick={this.save}
                >
                  <FontAwesomeIcon
                    icon={saving ? 'spinner' : 'save'}
                    pulse={saving}
                    style={{ marginRight: 3 }}
                  />
                  {t('Speichern')}
                </button>
              </div>
            </div>
          </Control>
          <Control position="topleft" className="leaflet-draw">
            <Tooltip title="Place Marker">
              <button
                type="button"
                className="leaflet-draw leaflet-bar btn"
                style={{
                  padding: 2,
                  width: 34,
                  height: 34
                }}
                onClick={this.addPlace}
              >
                <FontAwesomeIcon
                  icon="map-marker-alt"
                  color="#464646"
                  style={{ fontSize: 20 }}
                />
              </button>
            </Tooltip>
          </Control>

          <Control position="topright" className="leaflet-control-layers">
            <Tooltip title={t('Import geoJson')}>
              <Dropzone
                className="leaflet-touch leaflet-control-custom"
                multiple={false}
                onDrop={this.handleDrop}
              >
                <FontAwesomeIcon
                  icon="upload"
                  style={{ fontSize: 22 }}
                  color="#464646"
                />
              </Dropzone>
            </Tooltip>
          </Control>

          <Control position="topright" className="leaflet-control-layers">
            <Tooltip title={t('Export geoJson')}>
              <button
                type="button"
                className="leaflet-touch leaflet-control-custom btn"
                onClick={this.exportGeoJson}
              >
                <FontAwesomeIcon
                  icon="download"
                  style={{ fontSize: 22 }}
                  color="#464646"
                />
              </button>
            </Tooltip>
          </Control>

          <ImageOverlayControl
            overlays={eventDoc.overlays}
            onUpdate={this.updateOverlays}
            mapHeight={300}
            t={t}
            eventId={eventDoc._id}
            tags={eventDoc.suggestions || []}
            onSelected={() => this.setState({ visible: 'image' })}
          />

          <Control position="topright" className="leaflet-control-layers">
            <TagFilter
              visible
              tags={eventDoc.suggestions || []}
              tagChangeHandler={this.handleTagChange}
              tagsIconUrl={assets.tagsIconUrl}
            />
          </Control>

          {gisEnabled && (
            <GeoJsonEdit
              data={gisDoc}
              onChange={this.handleGeoJsonChange}
              onSelected={this.handleGeoFeatureSelected}
              tags={eventDoc.suggestions || []}
            />
          )}

          {this.renderBaseMarkers()}
          {this.renderDivIcons()}

          <Control position="bottomright">
            <div
              ref={container => {
                if (container) {
                  // stop scroll events to go through to map
                  L.DomEvent.disableClickPropagation(
                    container
                  ).disableScrollPropagation(container);
                }
              }}
            >
              <div
                style={{
                  maxHeight: 600, //mapHeight - 90, // TODO get from map component
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
              >
                <LeafletMapEditInfo
                  onChange={this.handleBaseEdit}
                  onClose={this.handleClose}
                  onDelete={this.handleDeleteBase}
                  visible={visible === 'marker'}
                  markerIcons={markerIcons}
                  properties={activeBaseDoc}
                  tags={eventDoc.suggestions ? eventDoc.suggestions : []}
                  eventId={eventDoc._id}
                  t={t}
                />
              </div>

              <LeafletMapEditInfo
                onChange={this.handleFeaturePropertiesEdit}
                onDelete={this.handleDeleteFeature}
                onClose={this.handleClose}
                visible={visible === 'polygon'}
                properties={activeGISProps}
                tags={eventDoc.suggestions ? eventDoc.suggestions : []}
                eventId={eventDoc._id}
                t={t}
              />
            </div>
          </Control>
        </BaseMap>
      </React.Fragment>
    );
  }
}

export default EditEvent;
