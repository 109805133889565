import * as types from '../actionTypes';

const INITALSTATE = {
  type: undefined,
  payload: undefined
};

export default function(state = INITALSTATE, action) {
  switch (action.type) {
    case types.CHAT_LOCATION_INIT:
      return action;
    case types.CHAT_LOCATION_SET:
      return action;
    case types.CHAT_LOCATION_DONE:
      return action;
    default:
      return state;
  }
}
