import { connect } from 'react-redux';

import Layout from '../layout';
import { createLayoutSelector, getAppAssets } from '../../selectors';

const getLayouts = createLayoutSelector('briefings');

const mapStateToProps = state => ({
  title: 'BRIEFING',
  layouts: getLayouts(state),
  backgroundImage: getAppAssets(state).dashboardBackground,
  organisationProps: {
    onlyEvent: true,
    withAllEvents: false,
    withBases: true
  }
});

export default connect(mapStateToProps)(Layout);
