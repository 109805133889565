import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const style = { cursor: 'pointer' };
const DialogHeader = ({ className, title, onClose }) => (
  <div className={className || 'edit-dialog-header'}>
    <span className="edit-dialog-title">
      {title}
      {onClose && (
        <FontAwesomeIcon
          icon="times-circle"
          pull="right"
          onClick={onClose}
          color="#a94442"
          style={style}
        />
      )}
    </span>
  </div>
);

export default DialogHeader;
