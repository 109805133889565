import React, { useState } from 'react';
import Loading from 'react-loading-animation';
import Modal from 'react-modal';
import Dialog from './Dialog';
import DialogHeader from './DialogHeader';
import DialogContent from './DialogContent';

import { cryptoPasswordDialogStyle } from '../ModalStyles';

export default ({ visible, onClose, onPress, t, errorMsg, loading }) => {
  const [password, setPassword] = useState('');
  const [touched, setTouched] = useState(false);

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      style={cryptoPasswordDialogStyle}
    >
      <Dialog className="edit-user-dialog">
        <DialogHeader
          title={t('Please enter passphrase to decrypt data')}
          onClose={onClose}
        />
        <DialogContent className="edit-user-dialog-content">
          <h4>{t('Passphrase')}</h4>
          <input
            type="text"
            value={password}
            onChange={event => {
              setPassword(event.target.value);
              setTouched(true);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                setTouched(false);
                onPress(password);
              }
            }}
            style={{ width: '100%', fontSize: '16px', lineHeight: 1.8 }}
          />
          {!touched && errorMsg && (
            <i style={{ color: '#FF0000' }}>{errorMsg}</i>
          )}
          {loading && <Loading />}
        </DialogContent>
        <div className="edit-user-dialog-footer">
          <button type="button" className="btn btn-default" onClick={onClose}>
            {t('Abbrechen')}
          </button>
          <button
            type="button"
            className="btn btn-primary pull-right"
            onClick={() => {
              setTouched(false);
              onPress(password);
            }}
          >
            {t('Ok')}
          </button>
        </div>
      </Dialog>
    </Modal>
  );
};
