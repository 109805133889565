/* eslint-disable no-underscore-dangle */
import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  ALL_EVENTS,
  DEFAULT_APP_SETTINGS,
  DEFAULT_ASSETS,
  DEFAULT_STYLES,
  DEFAULT_PERMISSIONS,
  MAP_ONLY_LAYOUT,
  MAP_CHAT_LAYOUT,
  MAP_INCIDENTS_LAYOUT,
  NEW_EVENT_LAYOUT,
  NEW_EVENT
} from '../constants';
import { checkRoles, byTimestamp } from '../utils';

export const selectByClassName = className =>
  createSelector(
    state => state.docs && state.docs[className],
    docs => docs || []
  );

const withDefaultAppSettings = settingsDoc => {
  const settings = { ...DEFAULT_APP_SETTINGS, ...settingsDoc };

  // deep merge assets and styles
  settings.assets = { ...DEFAULT_ASSETS, ...settings.assets };
  settings.styles = { ...DEFAULT_STYLES, ...settings.styles };

  return settings;
};

export const getAppSettings = createSelector(
  selectByClassName('AppSettings'),
  state => state.docs.appSettings,
  (allAppSettings, currentAppSettings) => {
    const appSettings =
      currentAppSettings &&
      allAppSettings.find(settings => settings._id === currentAppSettings._id);
    return appSettings && withDefaultAppSettings(appSettings);
  }
);

export const isAppConfigured = state => state.docs.appSettings !== undefined;

export const getCurrentAppId = createSelector(
  getAppSettings,
  appSettings => appSettings && appSettings.appId
);

export const getAppAssets = createSelector(
  getAppSettings,
  appSettings => appSettings.assets || {}
);

export const getAppId = createSelector(
  getAppSettings,
  appSettings => appSettings.appId
);

export const selectSession = state => state.auth.session;

export const getCurrentUser = createSelector(
  selectSession,
  selectByClassName('User'),
  (session, users) => {
    const curUser = users.find(u => u._id.indexOf(session.userCtx.name) >= 0);
    if (!curUser) {
      // console.log(`Could not find profile for %${session.userCtx.name}`);
      return undefined;
    }
    // extend user with roll attribute, in the future a user can has possibly more roles
    curUser.roles = curUser.admin ? ['admin'] : ['user'];
    return curUser;
  }
);

export const getCurrentFilter = state => state.organisation.filter;
export const getCurrentViewMode = state => state.organisation.viewMode.viewMode;
export const getBriefings = selectByClassName('Briefing');
export const getBases = selectByClassName('Base');
export const getTrajectories = selectByClassName('LocationTracklet');
export const getMaterialOrders = selectByClassName('MaterialOrder');
export const getPatients = selectByClassName('PatientTreatment');
export const getDevices = selectByClassName('DeviceStatus');
export const getDeviceLocations = selectByClassName('DeviceLocation');
export const getCrowd = selectByClassName('CrowdStatus');
export const getMessages = selectByClassName('ChatMessage');
export const getEvents = selectByClassName('Event');
export const getGis = selectByClassName('Gis');
export const getIncidents = selectByClassName('Incident');
export const getUsers = selectByClassName('User');
export const getForms = selectByClassName('FormView');
export const getMaterialLists = selectByClassName('MaterialList');
export const getUserSettings = selectByClassName('UserSettings');
export const getQrs = selectByClassName('QRCode');

export const getCurrentEvent = createSelector(
  getEvents,
  getCurrentFilter,
  (events, filter) =>
    filter.eventId !== ALL_EVENTS
      ? events.find(e => e._id === filter.eventId)
      : undefined
);

export const getCurrentEventId = state => state.organisation.filter.eventId;

export const getCurrentGis = createSelector(
  getCurrentEventId,
  getGis,
  (eventId, docs) => docs.find(d => d.eventId === eventId)
);

export const applyFilter = (docs, filter) => {
  const { eventId, startTs, endTs } = filter;
  return docs.filter(d => {
    let { ts } = d;
    const { className } = d;

    if (ts !== undefined && className === 'DeviceLocation') {
      ts = Math.round(ts / 1000);
    }

    const eventOk =
      eventId &&
      (eventId === ALL_EVENTS || (d.eventId ? d.eventId === eventId : true));
    const startOk = Number.isInteger(startTs) && ts ? ts > startTs : true;
    const endOk = Number.isInteger(endTs) && ts ? ts < endTs : true;

    return eventOk && startOk && endOk;
  });
};

export const applyFilterTraj = (docs, filter) => {
  const { eventId, startTs, endTs } = filter;

  return docs.filter(d => {
    const eventOk =
      eventId &&
      (eventId === ALL_EVENTS || (d.eventId ? d.eventId === eventId : true));

    if (!eventOk) return eventOk;

    // filter points of trajectory
    // eslint-disable-next-line no-param-reassign
    d.points = d.points.filter(p => {
      const startOk =
        Number.isInteger(startTs) && p.ts
          ? Math.round(p.ts / 1000) > startTs
          : true;
      const endOk =
        Number.isInteger(endTs) && p.ts
          ? Math.round(p.ts / 1000) < endTs
          : true;

      return startOk && endOk;
    });

    return eventOk;
  });
};

export const applyLogInFilter = docs =>
  docs.filter(d => d.name && (d.selectable === undefined || d.selectable));

export const applyFilterAndSortByTS = (docs, filter) =>
  applyFilter(docs, filter).sort(byTimestamp);

const DUMMY_FORM = {
  data: [],
  title: 'Dummy'
};

const createFormSelector = className =>
  createSelector(
    getForms,
    getMaterialLists,
    getCurrentEvent,
    (forms, materialLists, event) => {
      if (!event) {
        return DUMMY_FORM;
      }

      const formIds =
        Array.isArray(event.forms) && event.forms.length > 0
          ? event.forms
          : [
              `${event.appId}:FormView:PatientTreatment`,
              `${event.appId}:FormView:Incidents`,
              `${event.appId}:FormView:CrowdSpotter`,
              `${event.appId}:MaterialList:zf2016`
            ].filter(f => f.indexOf(className) > 0);

      const form = []
        .concat(forms)
        .concat(materialLists)
        .find(f => f._id.indexOf(className) > 0 && formIds.indexOf(f._id) >= 0);

      if (!form) {
        return DUMMY_FORM;
      }

      return form;
    }
  );

export const getPatientForm = createFormSelector('PatientTreatment');
export const getIncidentForm = createFormSelector('Incident');
export const getCrowdSpotterForm = createFormSelector('CrowdSpotter');
export const getMaterialList = createFormSelector('MaterialList');

export const getUserSettingsCurrentUser = createSelector(
  getUserSettings,
  getCurrentUser,
  (allSettings, user) => {
    if (!user) {
      // this case should only occure if a client manager or super user logs in and we get multiple appsettings and need to pick one
      return {};
    }
    let settingsDoc = allSettings.find(
      s => s.userId === user._id && user.appId === s.appId
    );

    if (!settingsDoc) {
      // create doc if not yet exists
      settingsDoc = {
        _id: user._id.replace('User', 'UserSettings'),
        className: 'UserSettings',
        appId: user.appId,
        userId: user._id,
        userName: user.name,
        layouts: {}
      };
    }

    return settingsDoc;
  }
);

export const getPermissions = createSelector(
  getCurrentUser,
  getAppSettings,
  (user, settings) => {
    const { webview } = settings;
    const userWebView = _.merge(DEFAULT_PERMISSIONS, webview);
    // for every main view (dashboard, settings, ...)
    _.forOwn(userWebView, (val, key) => {
      if (
        userWebView[key].permissionGranted &&
        checkRoles(userWebView[key].requiredRoles, user.roles)
      ) {
        // check all subcomponents
        _.forOwn(userWebView[key], (e, secKey) => {
          if (
            !(
              secKey === 'permissionGranted' ||
              secKey === 'requiredRoles' ||
              secKey === 'features'
            )
          ) {
            if (
              userWebView[key][secKey].permissionGranted &&
              checkRoles(userWebView[key][secKey].requiredRoles, user.roles)
            ) {
              // all good!
            } else {
              userWebView[key][secKey].permissionGranted = false;
            }
          }
        });
      } else {
        userWebView[key].permissionGranted = false; // roles are not matching or permission was not granded anyhow.
      }
    });
    return userWebView;
  }
);

export const getFilteredBriefings = createSelector(
  getBriefings,
  getCurrentFilter,
  applyFilter
);

export const getCurrentBriefing = createSelector(
  getBriefings,
  getBases,
  getCurrentUser,
  getCurrentFilter,
  (briefings, bases, user, { baseId }) => {
    const doc = briefings.find(d => d.baseId === baseId);

    if (doc) {
      return doc;
    }

    const base = bases.find(b => b._id === baseId);

    if (base) {
      return {
        _id: base._id.replace('Base', 'Briefing'),
        appId: base.appId,
        baseId: base._id,
        baseName: base.name,
        className: 'Briefing',
        eventId: base.eventId,
        eventName: base.eventName,
        title: '',
        htmlText: '',
        ts: Math.floor(Date.now() / 1000),
        userId: user._id,
        userName: user.name
      };
    }
  }
);

export const getFilteredBases = createSelector(
  getBases,
  getCurrentFilter,
  applyFilter
);

export const getFilteredBasesCanLogIn = createSelector(
  getFilteredBases,
  applyLogInFilter
);

export const getFilteredGis = createSelector(
  getGis,
  getCurrentEventId,
  (docs, eventId) => applyFilter(docs, { eventId })
);

export const getFilteredTrajectories = createSelector(
  getTrajectories,
  getCurrentFilter,
  applyFilterTraj
);

export const getFilteredMaterialOrders = createSelector(
  getMaterialOrders,
  getCurrentFilter,
  applyFilter
);

export const getFilteredPatients = createSelector(
  getPatients,
  getCurrentFilter,
  applyFilter
);

export const getFilteredDevices = createSelector(
  getDevices,
  getCurrentFilter,
  applyFilter
);

export const getFilteredDeviceLocations = createSelector(
  getDeviceLocations,
  getCurrentFilter,
  getFilteredDevices,
  (locationDocs, currentFilter, statusDocs) => {
    const deviceStatusIds = statusDocs.map(d => d.deviceStatusId);

    return applyFilter(locationDocs, currentFilter).filter(deviceLocation =>
      deviceStatusIds.indexOf(deviceLocation._id)
    );
  }
);

export const getFilteredCrowd = createSelector(
  getCrowd,
  getCurrentFilter,
  applyFilterAndSortByTS
);

export const getFilteredMessages = createSelector(
  getMessages,
  getCurrentFilter,
  applyFilterAndSortByTS
);

export const getFilteredEvents = createSelector(
  getEvents,
  getCurrentFilter,
  applyFilter
);

export const getFilteredIncidents = createSelector(
  getIncidents,
  getCurrentFilter,
  applyFilter
);

export const getFilteredQrs = createSelector(
  getQrs,
  getCurrentFilter,
  (docs, filter) => {
    const { eventId } = filter;

    if (eventId === ALL_EVENTS) {
      return docs;
    }

    return docs.filter(
      qrCodeDoc =>
        !Array.isArray(qrCodeDoc.properties.events) ||
        qrCodeDoc.properties.events.indexOf(eventId) >= 0
    );
  }
);

export const getFilteredUsers = createSelector(
  getUsers,
  getCurrentFilter,
  (docs, filter) => {
    const { eventId } = filter;

    if (!eventId) {
      return [];
    }

    if (eventId === ALL_EVENTS) {
      return docs;
    }

    return docs.filter(
      profile =>
        !Array.isArray(profile.events) || profile.events.indexOf(eventId) >= 0
    );
  }
);

export const getSelectableTags = createSelector(
  getFilteredBasesCanLogIn,
  bases =>
    _.unionBy(...bases.map(b => (Array.isArray(b.tags) ? b.tags : [])), 'value')
);

export const createLayoutSelector = route =>
  createSelector(
    getCurrentViewMode,
    getPermissions,
    state => state.organisation.layouts,
    getCurrentEventId,
    (viewMode, permissions, layouts, eventId) => {
      if (route === 'dashboard') {
        if (viewMode === 'mapOnly') {
          return MAP_ONLY_LAYOUT;
        }
        if (viewMode === 'mapComm') {
          return MAP_CHAT_LAYOUT;
        }
        if (viewMode === 'mapInc') {
          return MAP_INCIDENTS_LAYOUT;
        }
      }

      if (route === 'events' && eventId === NEW_EVENT) {
        return NEW_EVENT_LAYOUT;
      }

      if (!layouts) {
        console.error('Layout is undefined');
        return { lg: [] };
      }

      if (!layouts[route]) {
        console.error(`No layout defined for route ${route}.`);
        return { lg: [] };
      }

      return _.mapValues(layouts[route], layout =>
        layout.filter(item => {
          if (!item || !item.i || !permissions[route][item.i]) {
            console.error(
              `permissions does not contain key ${route}.${item.i}`,
              permissions
            );
            return false;
          }
          return permissions[route][item.i].permissionGranted;
        })
      );
    }
  );

export const getEncryptionDetails = createSelector(
  getEvents,
  state => state.dashboard.encryption,
  getPermissions,
  (events, privateKeys, permissions) =>
    _.mapValues(_.keyBy(events, '_id'), event => ({
      publicKey: _.get(event, 'encryption.publicKey'),
      salt: _.get(event, 'encryption.salt'),
      privateKey: privateKeys[event._id],
      enabled: permissions.dashboard.features.encryption
    }))
);
