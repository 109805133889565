/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class LeafletMapInfo extends Component {
  state = {
    visible: false
  };

  static getDerivedStateFromProps({ visible }) {
    return { visible };
  }

  closeMapInfo = () => this.setState({ visible: false });

  render() {
    const createMarkerUp = html => ({ __html: html });
    const { visible } = this.state;

    if (!visible) {
      return null;
    }

    const {
      iconUrl,
      name,
      details,
      lastSeen,
      onMouseOver,
      onMouseOut
    } = this.props;

    return (
      <div
        className="leaflet-map-info"
        id="leaflet-map-info"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <div className="row">
          <div className="col-md-3 text-center">
            {iconUrl.indexOf('div') === -1 ? (
              <img src={iconUrl} className="leaflet-map-info-icon" alt="icon" />
            ) : (
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={createMarkerUp(iconUrl)}
                style={{ marginLeft: '30px', marginTop: '30px' }}
              />
            )}
          </div>
          <div className="col-md-9">
            <FontAwesomeIcon
              pull="right"
              icon="times"
              onClick={this.closeMapInfo}
            />
            <h5>{name}</h5>
            <p className="leaflet-map-info-details">{details}</p>
            <p className="leaflet-map-info-last-seen">{lastSeen}</p>
          </div>
        </div>
      </div>
    );
  }
}
