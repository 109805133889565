import _ from 'lodash';
import moment from 'moment';
import { stringify } from 'query-string';
import createSvg from '../../uiblocks/HumanBody/createSvg';

import { KEY } from '../../constants';

export const convertArrayOfObjectsToCSV = args => {
  let result;
  let ctr;
  let columnDelimiter;
  const data = args.data || null;

  if (data == null || !data.length) {
    return null;
  }

  let OSName = 'Unknown OS';
  if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
  if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
  if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
  if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';
  columnDelimiter = args.columnDelimiter || ',';
  if (OSName === 'Windows') {
    columnDelimiter = args.columnDelimiter || ';';
  }
  const lineDelimiter = args.lineDelimiter || '\n';

  const mandatoryKeys = [
    'ts',
    'eventId',
    'baseId',
    'dateTime',
    'eventName',
    'baseName',
    'userName'
  ];

  const optionalKeys = _.uniq(_.flatten(_.map(_.values(data), _.keys))).filter(
    v => mandatoryKeys.indexOf(v) < 0
  );
  // _(data)
  // .values()
  // .map(_.keys)
  // .flatten()
  // .uniq()
  // .filter(v => mandatoryKeys.indexOf(v) < 0)
  // // .sort() // keep form sorting, not key sorting
  // .value();

  const keys = mandatoryKeys.concat(optionalKeys);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(item => {
    ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;
      let tmp = item[key];
      if (tmp) {
        tmp = tmp.toString().replace(/(\r\n|\n|\r)/gm, ' ');
        tmp = `"${tmp}"`;
      }
      result += tmp || '';
      ctr += 1;
    });
    result += lineDelimiter;
  });

  return result;
};

export const downloadCSV = args => {
  const filename = args.filename || 'export.csv';
  let csv = convertArrayOfObjectsToCSV({
    data: args.data,
    lineDelimiter: '\r\n'
  });
  if (csv == null) return;
  const BOM = '\uFEFF';
  csv = BOM + csv;
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  // IE11 & Edge
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, filename);
  } else {
    // In FF link must be added to DOM to be clicked
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvData);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const downloadJSON = args => {
  const filename = args.filename || 'export.json';
  const json = JSON.stringify(args.data);
  if (json == null) return;
  // const BOM = '\uFEFF';
  // json = BOM + json;
  const jsonData = new Blob([json], { type: 'text/json;charset=utf-8;' });
  // IE11 & Edge
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(jsonData, filename);
  } else {
    // In FF link must be added to DOM to be clicked
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(jsonData);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const transformItem = (item, option) => {
  if (!item) {
    return undefined;
  }
  const { title, label, value, extra, encrypted, component } = item;
  const v =
    (component === 'DateTimeInput' ? moment(value).format('ll, LT') : value) ||
    label;
  const t = title || (option && option.title);
  if (v && t) {
    if (encrypted) {
      return {
        title: t,
        value: 'Data is encrypted'
      };
    }

    if (typeof v === 'string') {
      if (extra && typeof extra === 'string' && extra.length > 0) {
        return {
          title: t,
          value: v,
          extra
        };
      }
      return {
        title: t,
        value: v
      };
    }

    if (Array.isArray(v)) {
      // TODO handle array of objects not only {label: ''}
      const value = v.length > 0 ? v.map(obj => obj.label).join(' | ') : 'n/a';
      if (_.some(v, 'extra')) {
        return {
          title: t,
          value,
          extra: v.length > 0 ? v[0].extra : '' // Why [0]?
        };
      }

      return {
        title: t,
        value
      };
    }
    // remaining keyed datatype
    const keys = _.keys(v);
    const value = keys
      .map(k => {
        //  array
        if (typeof v[k] === 'string') {
          const opt =
            option &&
            Array.isArray(option.options) &&
            option.options.find(o => o.name === k);
          return `${(opt && opt.label) || k}: ${(opt &&
            opt.properties &&
            opt.properties.mode === 'date' &&
            moment(v[k]).format('ll, LTS')) ||
            v[k]}`;
        }
        if (typeof v[k] === 'object') {
          //  two dimension dicts (e.g. BodyAnnotation)
          const subKeys = _.keys(v[k]);
          const trueKeys = subKeys.filter(sk => v[k][sk] === true);
          return `${k}: ${trueKeys.join(' | ')}`;
        }
        return undefined;
      })
      .filter(o => o !== undefined)
      .join(' | ');
    return {
      title,
      value
    };
  }
  return {
    title,
    value: 'n/a' // empty items non-null to avoid inconsistent exports
  };
};

export const getTitle = (doc, form) => {
  if (!doc || doc.data.length === 0) {
    return '';
  }

  const order = (form && form.previewIndicies) || [0, 1];
  const delimiter = (form && form.delimiter) || ', ';

  return order
    .filter(idx => idx < doc.data.length)
    .map(idx => {
      const ti = transformItem(
        doc.data[idx],
        form && form.data && form.data[idx]
      );
      return ti && ti.value;
    })
    .filter(value => value !== undefined)
    .join(delimiter);
};

export const exportCSV = (docs, form, filename) => {
  let data = [];
  docs.forEach(p => {
    const res = {
      ts: p.ts,
      eventId: p.eventId,
      baseId: p.baseId,
      dateTime: moment(p.ts * 1000).format('DD.MM.YYYY HH:mm'),
      eventName: p.eventName,
      baseName: p.baseName,
      userName: p.userName
    };
    p.data.forEach((item, idx) => {
      const d = transformItem(item, form && form.data && form.data[idx]);
      if (d !== undefined) {
        if (Array.isArray(d)) {
          d.forEach(dd => {
            res[dd.title] = dd.value;
          });
        } else {
          res[d.title] = d.value;
          if (d.extra) {
            res[`${d.title}.extra`] = d.extra;
          }
        }
      }
    });

    if (p.activity || (p.messages && Object.keys(p.messages).length > 0)) {
      let activityData = [];
      if (p.activity) {
        activityData = activityData.concat(
          p.activity.map(a => ({
            ts: a.ts,
            data: {
              ...res,
              activity: `${moment(a.ts * 1000).format('ll, LT')} ${a.comment}`
            }
          }))
        );
      }

      // data the messages
      if (p.messages) {
        // create array from object
        const iMessages = p.messages ? Object.values(p.messages) : [];
        activityData = activityData.concat(
          iMessages.map(m => {
            const ts = parseInt(new Date(m.createdAt).getTime() / 1000, 10);
            let activity = moment(ts * 1000).format('ll, LT');
            activity += `${m.user.name} `;
            activity += `${m.text} `;
            activity += `${
              m.location
                ? `https://www.google.com/maps/?q=${m.location.latitude},${m.location.longitude}`
                : ''
            } `;
            activity += `${m.image ? m.image : ''} `;

            return { ts, data: { ...res, activity } };
          })
        );
      }

      // sort the activity data by ts
      activityData = activityData.sort((a, b) => a.ts - b.ts);

      // add only the data to the csv
      data = data.concat(activityData.map(a => a.data));
    } else {
      data.push(res);
    }
  });

  downloadCSV({
    filename,
    data
  });
};

const decryptDocs = (patients, decryptItem) =>
  Promise.all(
    patients.map(async patient => {
      if (typeof decryptItem === 'function') {
        const decryptedPatient = _.cloneDeep(patient);
        decryptedPatient.data = await Promise.all(
          patient.data.map(item => decryptItem(item, patient.eventId))
        );

        decryptedPatient.history = await Promise.all(
          patient.history.map(async h => {
            const decryptedHistory = { ...h };
            const keys = Object.keys(h.changes);

            await Promise.all(
              keys.map(k =>
                decryptItem(h.changes[k], patient.eventId).then(decrypted => {
                  decryptedHistory.changes[k] = decrypted;
                })
              )
            );

            return decryptedHistory;
          })
        );

        return decryptedPatient;
      }

      return patient;
    })
  );

const loadImage = source =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const data = canvas.toDataURL();
      resolve(data);
    };

    img.onerror = reject;

    img.src = source;
  });

const loadMapImage = marker => {
  const url = `https://maps.googleapis.com/maps/api/staticmap?${stringify({
    size: '300x300',
    zoom: 18,
    markers: `${marker.latitude},${marker.longitude}`,
    key: KEY
  })}`;

  return loadImage(url);
};

export const exportAsPDF = async (
  encryptedDocs,
  {
    decryptItem,
    form,
    filename,
    decryptData,
    withMessages,
    withProtocol,
    translations: customTranslations
  }
) => {
  // try to decrypt data
  const docs = await decryptDocs(encryptedDocs, decryptData && decryptItem);

  const translations = {
    docTitle: 'Export',
    title: '',
    createdAt: 'Created at',
    createdBy: 'Created by',
    lastEditAt: 'Last edit at',
    lastEditBy: 'Last edit by',
    event: 'Event',
    base: 'Base',
    protocol: 'Protocol',
    messages: 'Chat messages',
    ...customTranslations
  };

  docs.sort((p1, p2) => p2.ts - p1.ts);

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 40, 60, 40],
    info: {
      title: translations.docTitle,
      author: 'Antavi GmBH',
      subject: undefined,
      keywords: undefined
    },
    content: [],
    footer(currentPage, pageCount) {
      return {
        text: `Antavi GmbH | info@antavi.ch | ${currentPage.toString()} / ${pageCount}`,
        style: 'footer'
      };
    },
    styles: {
      title: {
        bold: true,
        fontSize: 10
      },
      value: {
        bold: false,
        fontSize: 10
      },
      extraTitle: {
        fontSize: 10,
        italics: true
      },
      extra: {
        fontSize: 10
      },
      footer: {
        alignment: 'center',
        color: '#CCCCCC',
        fontSize: 8
      }
    },
    images: []
  };

  const images = {};

  const addImage = (image, name) => {
    images[name] = image;
  };

  // add patient to content
  docDefinition.content = docs.reduce((content, doc, i) => {
    // add history for backwards compatability
    if (!Array.isArray(doc.history) && Array.isArray(doc.data)) {
      const changes = {};
      doc.data.forEach((item, ii) => {
        changes[ii] = item;
      });

      doc.history = [
        {
          userName: doc.userName,
          baseName: doc.baseName,
          ts: doc.ts,
          timestamp: moment(doc.ts * 1000).toISOString(),
          changes
        }
      ];
    }

    // header
    const createdAt = doc.history[0].timestamp;
    const createdBy = doc.history[0].userName;

    const lastEditAt = doc.history[doc.history.length - 1].timestamp;
    const lastEditBy = doc.history[doc.history.length - 1].userName;

    // const name = `${doc._id}-coordinates`;
    // if (doc.coordinates) {
    //   addImage(loadMapImage(doc.coordinates), name);
    // }
    const res = [
      {
        text: `${translations.title} [${doc.case}]`,
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 10]
      },
      {
        margin: [0, 0, 0, 22],
        columns: [
          [
            {
              text: [
                { text: `${translations.event}`, bold: true },
                ` ${doc.eventName}`
              ]
            },
            {
              text: [
                { text: `${translations.createdAt}`, bold: true },
                ` ${moment(createdAt).format('ll, LTS')}`
              ]
            },
            {
              text: [
                { text: `${translations.lastEditAt}`, bold: true },
                ` ${moment(lastEditAt).format('ll, LTS')}`
              ]
            }
          ],
          [
            {
              text: [
                { text: `${translations.base}`, bold: true },
                ` ${doc.baseName}`
              ]
            },
            {
              text: [
                { text: `${translations.createdBy}`, bold: true },
                ` ${createdBy}`
              ]
            },
            {
              text: [
                { text: `${translations.lastEditBy}`, bold: true },
                ` ${lastEditBy}`
              ]
            }
          ]
        ],
        fontSize: 10
      }
    ];

    // data
    if (Array.isArray(doc.history)) {
      const keys = _.flatten(_.uniq(doc.history.map(h => _.keys(h.changes))));
      // const keys = _(doc.history)
      //   .map(h => _.keys(h.changes))
      //   .flatten()
      //   .uniq()
      //   .value();

      keys.forEach(k => {
        doc.history.forEach(
          ({ changes, timestamp, baseName, userName }, change) => {
            const item = changes[k];

            if (!item) {
              return;
            }

            // if (change === 0) {
            //   res.push({ text: `${item.title}\n`, style: 'title' });
            // }

            const timeCol = {
              width: '*',
              text: [
                change === 0 ? { text: `${item.title}\n`, style: 'title' } : '',
                createdAt !== timestamp
                  ? {
                      text: `${moment(timestamp).format('ll, LTS')}\n`,
                      fontSize: 8
                    }
                  : '',
                doc.userName !== userName ? `${userName}\n` : '',
                doc.baseName !== baseName ? baseName : ''
              ],
              // text: [
              //   { text: `${moment(timestamp).format('ll, LTS')}\n`, fontSize: 8 },
              //   `${userName}\n`,
              //   (patient.baseName !== baseName) ? baseName : '',
              // ],
              style: 'value',
              margin: [0, 0, 0, 5]
            };

            const dataCol = {
              width: '*',
              margin: [0, 0, 0, 5]
            };

            // show each change
            if (item.component === 'BodyAnnotation') {
              dataCol.columns = [
                { svg: createSvg('front', item.value) },
                { svg: createSvg('back', item.value) }
              ];
            } else {
              const d = transformItem(item, form && form.data && form.data[k]);
              dataCol.text = [
                { text: d.value, style: 'value' },
                d.extra && { text: '\nBeschreibung: ', style: 'extraTitle' },
                d.extra && {
                  text: d.extra,
                  style: 'extra',
                  margin: [8, 8, 8, 0]
                }
              ];
            }

            res.push({ columns: [timeCol, dataCol] });
          }
        );
      });
    }

    // activities
    if (
      withProtocol &&
      Array.isArray(doc.activity) &&
      doc.activity.length > 0
    ) {
      res.push({
        text: `${translations.protocol}`,
        fontSize: 14,
        margin: [0, 8, 0, 8]
      });

      doc.activity.sort((a, b) => a.ts - b.ts);
      doc.activity.forEach(({ ts, comment }) => {
        res.push({
          columns: [
            {
              width: '*',
              text: [
                {
                  text: `${moment(ts * 1000).format('ll, LTS')}\n`,
                  fontSize: 8
                }
                // TODO add user
              ],
              margin: [0, 0, 0, 5]
            },
            {
              width: '*',
              margin: [0, 0, 0, 5],
              style: 'value',
              text: comment
            }
          ]
        });
      });
    }

    // messages
    const messages =
      withMessages && doc.messages
        ? _.sortBy(_.values(doc.messages), 'createdAt')
        : [];
    if (Array.isArray(messages) && messages.length > 0) {
      res.push({
        text: `${translations.messages}`,
        fontSize: 14,
        margin: [0, 8, 0, 8]
      });
      messages.forEach(msg => {
        const timeCol = {
          width: '*',
          text: [
            {
              text: `${moment(msg.createdAt).format('ll, LTS')}\n`,
              fontSize: 8
            },
            msg.user.name
          ],
          style: 'value',
          margin: [0, 0, 0, 5]
        };

        const dataCol = {
          width: '*',
          margin: [0, 0, 0, 5],
          stack: msg.text ? [msg.text] : []
        };

        // TODO fix cors issue
        // if (msg.image) {
        //   // load img
        //   const name = `${doc._id}-${msg._id}`;
        //   addImage(loadImage(msg.image), name);
        //   dataCol.stack.push({ image: name, width: 100 });
        // }

        if (msg.location) {
          const name = `${doc._id}-${msg._id}`;
          addImage(loadMapImage(msg.location), name);
          dataCol.stack.push({ image: name, width: 100 });
        }

        res.push({ columns: [timeCol, dataCol] });
      });
    }

    if (i < docs.length - 1) {
      res.push({ text: '', pageBreak: 'after' });
    }

    return content.concat(res);
  }, []);

  // dynamically load pdf module
  const pdfMake = (await import('pdfmake/build/pdfmake')).default;
  const pdfFonts = (await import('pdfmake/build/vfs_fonts')).default;
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  // wait for all images to be generated
  const keys = Object.keys(images);
  const resolved = await Promise.all(keys.map(k => images[k]));
  // map results
  keys.forEach((k, i) => {
    images[k] = resolved[i];
  });
  docDefinition.images = images;

  await new Promise(resolve =>
    pdfMake.createPdf(docDefinition).download(filename, resolve)
  );
};

// TODO
// add map with location of base and report
