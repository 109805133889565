import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import _ from 'lodash';

import SideMenuHeader from './SideMenuHeader';
import SideMenuFooter from './SideMenuFooter';
import SideMenuItem from './SideMenuItem';

import {
  getAppSettings,
  getCurrentUser,
  getPermissions
} from '../../../selectors';

import { toogleSidemenu } from '../../dashboard/actions';

class SideMenu extends Component {
  constructor(props) {
    super(props);

    const activeSideMenuItem = '/dashboard';
    const hoveredSideMenuItem = undefined;
    this.state = {
      activeSideMenuItem,
      hoveredSideMenuItem
    };
  }

  getWrapperStyle = () => {
    let backgroundColor = '#34495E';
    const { styles, extended } = this.props;

    if (styles) {
      backgroundColor = styles.menuBackgroundColor
        ? styles.menuBackgroundColor
        : backgroundColor;
    }

    if (extended) {
      return {
        backgroundColor
      };
    }
    return {
      backgroundColor
    };
  };

  setActive = activeSideMenuItem => {
    this.setState({ activeSideMenuItem });
  };

  setHover = hoveredSideMenuItem => {
    this.setState({ hoveredSideMenuItem });
  };

  changeSizeHandler = () => {
    // this.props.setMainContainerStyle(!this.props.extended);
    this.props.toogleSidemenu();
  };

  addHeader = () => {
    const { currentUser, appSettings, extended } = this.props;
    if (currentUser && currentUser.name) {
      const companyName = currentUser.company;
      let customerFirstName = currentUser.name;
      let customerLastName = '';

      if (currentUser.name.indexOf(' ') !== -1) {
        customerFirstName = currentUser.name.split(' ')[0];
        customerLastName = currentUser.name.split(' ')[1];
      }
      return (
        <SideMenuHeader
          companyName={companyName}
          extended={extended}
          customerFirstName={customerFirstName}
          customerLastName={customerLastName}
          changeSizeHandler={this.changeSizeHandler}
          iconUrl={appSettings.assets.avatarIconUrl}
          styles={appSettings.styles}
        />
      );
    }
    return null;
  };

  addAdminMenuEvents = () => {
    const { permissions, appSettings, extended, t } = this.props;
    if (!_.isEmpty(permissions) && permissions.events.permissionGranted) {
      return (
        <SideMenuItem
          title={t('VERANSTALTUNGEN')}
          to="/events"
          extended={extended}
          setActive={this.setActive}
          setHover={this.setHover}
          isActive={this.isActive('/events')}
          isHovered={this.isHovered('/events')}
          iconUrl={appSettings.assets.eventsIconUrl}
          iconUrlActive={appSettings.assets.eventsActiveIconUrl}
          icon="store-alt"
          styles={appSettings.styles}
        />
      );
    }

    return null;
  };

  addAdminMenuSettings = () => {
    const { permissions, appSettings, extended, t } = this.props;

    if (!_.isEmpty(permissions) && permissions.settings.permissionGranted) {
      return (
        <SideMenuItem
          title={t('EINSTELLUNGEN')}
          to="/settings"
          extended={extended}
          setActive={this.setActive}
          setHover={this.setHover}
          isActive={this.isActive('/settings')}
          isHovered={this.isHovered('/settings')}
          iconUrl={appSettings.assets.settingsIconUrl}
          iconUrlActive={appSettings.assets.settingsActiveIconUrl}
          icon="cog"
          styles={appSettings.styles}
        />
      );
    }

    return null;
  };

  addAdminMenuBriefings = () => {
    const { appSettings, extended, t, permissions } = this.props;
    const {
      assets: { menuBriefingActiveIconUrl, menuBriefingIconUrl }
    } = appSettings;
    if (!_.isEmpty(permissions) && permissions.briefings.permissionGranted) {
      return (
        <SideMenuItem
          title={t('BRIEFING')}
          to="/briefings"
          extended={extended}
          setActive={this.setActive}
          setHover={this.setHover}
          isActive={this.isActive('/briefings')}
          isHovered={this.isHovered('/briefings')}
          iconUrl={menuBriefingIconUrl}
          iconUrlActive={menuBriefingActiveIconUrl}
          icon="book-reader"
          styles={appSettings.styles}
        />
      );
    }

    return null;
  };

  addAdminMenuAnalytics = () => {
    const { appSettings, extended, t, permissions } = this.props;
    const {
      assets: { menuAnalyticsActiveIconUrl, menuAnalyticsIconUrl }
    } = appSettings;
    if (!_.isEmpty(permissions) && permissions.analytics.permissionGranted) {
      return (
        <SideMenuItem
          title={t('ANALYTICS')}
          to="/analytics"
          extended={extended}
          setActive={this.setActive}
          setHover={this.setHover}
          isActive={this.isActive('/analytics')}
          isHovered={this.isHovered('/analytics')}
          iconUrl={menuAnalyticsIconUrl}
          iconUrlActive={menuAnalyticsActiveIconUrl}
          icon="chart-line"
          styles={appSettings.styles}
        />
      );
    }

    return null;
  };

  isActive = title => title === this.state.activeSideMenuItem;

  isHovered = title => title === this.state.hoveredSideMenuItem;

  render() {
    const { appSettings, extended, t } = this.props;

    if (!appSettings) {
      return null;
    }

    return (
      <div id="side-menu" style={this.getWrapperStyle()}>
        {this.addHeader()}

        <SideMenuItem
          title={t('DASHBOARD')}
          to="/dashboard"
          extended={extended}
          setActive={this.setActive}
          setHover={this.setHover}
          isActive={this.isActive('/dashboard')}
          isHovered={this.isHovered('/dashboard')}
          iconUrl={appSettings.assets.dashboardIconUrl}
          iconUrlActive={appSettings.assets.dashboardActiveIconUrl}
          icon="desktop"
          styles={appSettings.styles}
        />

        {this.addAdminMenuEvents()}
        {this.addAdminMenuBriefings()}
        {this.addAdminMenuAnalytics()}
        {this.addAdminMenuSettings()}

        <SideMenuItem
          title={t('LOGOUT')}
          to="/logout"
          extended={extended}
          setActive={this.setActive}
          setHover={this.setHover}
          isActive={this.isActive('/logout')}
          isHovered={this.isHovered('/logout')}
          iconUrl={appSettings.assets.logoutIconUrl}
          iconUrlActive={appSettings.assets.logoutActiveIconUrl}
          icon="sign-out-alt"
          styles={appSettings.styles}
        />

        {extended && <SideMenuFooter iconUrl={appSettings.assets.appIconUrl} />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { sideMenuState } = state.dashboard;
  return {
    appSettings: getAppSettings(state) || {},
    currentUser: getCurrentUser(state),
    permissions: getPermissions(state),
    extended: sideMenuState.extended
  };
};

export default withRouter(
  connect(mapStateToProps, { toogleSidemenu })(
    translate('translations')(SideMenu)
  )
);
