import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { ALL_EVENTS, NEW_EVENT } from '../../constants';
import {
  getEvents,
  getCurrentFilter,
  getFilteredBases,
  getAppSettings,
  getCurrentViewMode,
  getPermissions
} from '../../selectors';

import * as actions from './Actions';

import OrganisationDateFilter from './OrganisationDateFilter';
import OrganisationDropdown from './OrganisationDropdown';
import OrganisationSelector from './OrganisationSelector';
import LanguageSelector from './LanguageSelector';

class Organisation extends Component {
  componentDidUpdate() {
    this.autoSelectFirst();
  }

  componentDidMount() {
    this.autoSelectFirst();
  }

  autoSelectFirst = () => {
    const {
      events,
      bases,
      currentFilter: { eventId, baseId },
      filterByEvent,
      filterByBase
    } = this.props;

    // auto select first event, is current event is not set
    if (!eventId && Array.isArray(events) && events.length > 0) {
      filterByEvent(events[0]._id);
    }

    if (!baseId && Array.isArray(bases) && bases.length > 0) {
      filterByBase(bases[0]._id);
    }
  };

  toggleComponent = key => {
    const { addToLayout, removeFromLayout, layouts, route } = this.props;

    const activeComponents = getActiveComponents(layouts, route);
    if (activeComponents[key]) {
      removeFromLayout(route, key);
    } else {
      addToLayout(route, key);
    }
  };

  handleDropDownChange = id => {
    this.props.filterByEvent(id);
  };

  onClick = e => {
    this.setState({ active: e });
    this.props.filterByEvent(e._id);
  };

  render() {
    const {
      appSettings,
      onlyEvent,
      t,
      permissions,
      withoutEvents,
      withBases,
      events,
      route,
      layouts,
      withAllEvents,
      currentFilter,
      filterByStart,
      filterByEnd,
      filterByBase,
      resetDateFilters,
      bases
    } = this.props;

    const { eventId, baseId } = currentFilter;

    const {
      placeholderColor,
      menuBackgroundColor: background,
      menuTextHighlightColor: color,
      iconColor
    } = appSettings.styles;

    const newEvent = {
      _id: NEW_EVENT,
      name: t('Neue Veranstaltung')
    };

    const allEvents = {
      _id: ALL_EVENTS,
      name: t('Alle Veranstaltungen')
    };

    const active = events.find(e => eventId === e._id) ||
      (eventId === NEW_EVENT && newEvent) ||
      (withAllEvents && allEvents) || {
        _id: undefined,
        name: t('Noch keine Veranstaltung')
      };

    const dropdownData =
      eventId === NEW_EVENT
        ? [newEvent]
        : withAllEvents
        ? [allEvents, ...events]
        : events;

    return (
      <div
        style={{
          backgroundColor: background,
          minHeight: 40,
          paddingTop: 10,
          paddingLeft: 3,
          paddingRight: 3,
          paddingBottom: 3,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {!withoutEvents && (
          <OrganisationDropdown
            active={active}
            dropdownData={dropdownData}
            onClick={this.onClick}
            color={color}
            icon="store-alt"
            iconColor={iconColor}
          />
        )}
        {withBases && (
          <OrganisationDropdown
            active={bases.find(b => b._id === baseId)}
            dropdownData={bases}
            onClick={e => filterByBase(e._id)}
            color={color}
            icon="map-marker-alt"
            iconColor={iconColor}
          />
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 4,
            justifyContent: 'space-between'
          }}
        >
          {!onlyEvent ? (
            <OrganisationDateFilter
              color={color}
              iconColor={iconColor}
              placeholderColor={placeholderColor}
              currentFilter={currentFilter}
              filterByStart={filterByStart}
              filterByEnd={filterByEnd}
              resetDateFilters={resetDateFilters}
              t={t}
            />
          ) : (
            <div />
          )}

          <div style={{ flexDirection: 'row' }}>
            {!onlyEvent && (
              <OrganisationSelector
                route={route}
                handleChangeChk={this.toggleComponent}
                activeComponents={getActiveComponents(layouts, route)}
                permissions={permissions}
                viewMode={this.props.viewMode}
                changeViewMode={this.props.changeViewMode}
                color={color}
                iconColor={iconColor}
                icon="columns"
              />
            )}
            <LanguageSelector color={color} iconColor={iconColor} />
          </div>
        </div>
      </div>
    );
  }
}

const getActiveComponents = (layouts, route) => {
  // if component is in any layout (lg, md, sm) for route consider it active
  const keys = _.union(
    _.flatten(_.values(layouts[route]))
      .filter(e => e !== undefined && e !== null)
      .map(e => e.i)
  );

  const ac = {};
  keys.forEach(k => {
    ac[k] = true;
  });

  return ac;
};

function mapStateToProps(state) {
  return {
    events: getEvents(state),
    bases: getFilteredBases(state),
    currentFilter: getCurrentFilter(state),
    viewMode: getCurrentViewMode(state),
    appSettings: getAppSettings(state),
    permissions: getPermissions(state),
    layouts: state.organisation.layouts,
    extended: state.dashboard.sideMenuState.extended
  };
}

export default connect(
  mapStateToProps,
  actions
)(translate('translations')(Organisation));

// TODO time zone bug
