import React from 'react';
import { translate } from 'react-i18next';

const DialogFooter = ({ className, errorHandler, t }) => (
  <div className={className || 'edit-dialog-footer'}>
    <button onClick={errorHandler} className="btn btn-primary pull-right">
      {t('Ok')}
    </button>
  </div>
);

export default translate('translations')(DialogFooter);
