import React from 'react';
import { connect } from 'react-redux';

import Layout from '../layout';
import { createLayoutSelector, getAppAssets } from '../../selectors';

const getLayouts = createLayoutSelector('events');

const mapStateToProps = state => ({
  title: 'VERANSTALTUNGEN',
  layouts: getLayouts(state),
  backgroundImage: getAppAssets(state).dashboardBackground,
  organisationProps: {
    onlyEvent: true
  }
});

export default connect(mapStateToProps)(Layout);
