import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TintIcon } from '../../../components';

const SideMenuItem = ({
  isActive,
  isHovered,
  to,
  title,
  extended,
  setHover,
  setActive,
  iconUrl,
  icon,
  styles
}) => {
  let backgroundColor = '#34495E';
  let color = '#DBFF36';
  if (styles) {
    backgroundColor = styles.menuBackgroundColor
      ? styles.menuBackgroundColor
      : backgroundColor;
    color = styles.menuTextColor ? styles.menuTextColor : color;
  }
  if (isActive || isHovered) {
    backgroundColor = styles.menuHighlightColor
      ? styles.menuHighlightColor
      : '#DBFF36';
    color = styles.menuTextHighlightColor
      ? styles.menuTextHighlightColor
      : color;
  }

  return (
    <Link to={to} style={{ backgroundColor, textDecoration: 'none' }}>
      <div
        className="side-menu-item"
        style={{ backgroundColor, color }}
        onMouseOver={() => setHover(to)}
        onMouseOut={() => setHover(undefined)}
        onClick={() => setActive(to)}
      >
        {icon ? (
          <FontAwesomeIcon
            icon={icon}
            color={isActive ? styles.iconHighlightColor : styles.iconColor}
          />
        ) : (
          <TintIcon
            url={iconUrl}
            color={isActive ? styles.iconHighlightColor : styles.iconColor}
            className="side-menu-item-icon"
          />
        )}

        {extended && <div className="side-menu-item-text">{title}</div>}
      </div>
    </Link>
  );
};

export default withRouter(SideMenuItem);
