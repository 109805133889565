import React, { memo } from 'react';
import { translate } from 'react-i18next';

import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Responsive as GridLayout } from 'react-grid-layout';
import AutoSizer from 'react-virtualized-auto-sizer';

import Organisation from './organisation';

// dashboard
import BaseMap from './dashboard/map';
import Overview from './dashboard/bases';
import MaterialOrders from './dashboard/orders';
import Patients from './dashboard/patients';
import Incidents from './dashboard/incidents';
import Devices from './dashboard/devices';
import Chat from './dashboard/chat';
import ActivityFeed from './dashboard/activity';

// events
import EventComponent from './events/EventComponent';

// settings
import EmailSettings from './settings/Management';
import Users from './settings/Users';
import QRCodes from './settings/QR';

// briefings
import Briefings from './briefings/Briefing';

// analytics
import PatientStats from './analytics/patientStats';
import IncidentStats from './analytics/incidentStats';
import IncidentProcessingStats from './analytics/incidentStats/processing';
import Heatmap from './analytics/heatmap';

const LayoutComponent = memo(({ name, onClose, ...rest }) => {
  const props = { ...rest };

  if (onClose) {
    props.onClose = () => onClose(name);
  }

  switch (name) {
    case 'bases':
      return <Overview {...props} />;
    case 'devices':
      return <Devices {...props} />;
    case 'map':
      return <BaseMap {...props} />;
    case 'incidents':
      return <Incidents {...props} />;
    case 'patients':
      return <Patients {...props} />;
    case 'materialOrders':
      return <MaterialOrders {...props} />;
    case 'chat':
      return <Chat {...props} />;
    case 'activities':
      return <ActivityFeed {...props} />;
    case 'events':
      return <EventComponent {...props} />;
    case 'users':
      return <Users {...props} />;
    case 'qrCodes':
      return <QRCodes {...props} />;
    case 'email':
      return <EmailSettings {...props} />;
    case 'briefings':
      return <Briefings {...props} />;
    case 'patientStats':
      return <PatientStats {...props} />;
    case 'incidentStats':
      return <IncidentStats {...props} />;
    case 'incidentProcessingStats':
      return <IncidentProcessingStats {...props} />;
    case 'heatmap':
      return <Heatmap {...props} />;

    default:
      console.warn(`No component for key ${name} registered.`);
      return null;
  }
});

const Layout = ({
  t,
  layouts,
  title,
  backgroundImage,
  organisationProps,
  onLayoutChange,
  onClose,
  viewMode
}) => {
  const style = backgroundImage
    ? {
        background: `url('${backgroundImage}') no-repeat center center fixed`,
        backgroundSize: 'cover'
      }
    : {};

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Ops - ${t(title)}`}</title>
      </Helmet>
      <Organisation {...organisationProps} />

      <div className="grid-layout-container" style={style}>
        <AutoSizer>
          {({ width, height }) => (
            <GridLayout
              draggableHandle=".panel-header"
              rowHeight={(height - 50 - 4 * 10) / 4}
              width={width}
              onLayoutChange={
                viewMode === 'custom' ? onLayoutChange : undefined
              }
              layouts={layouts}
              breakpoints={{
                lg: 1400,
                md: 900,
                sm: 600,
                xs: 500
              }}
              cols={{
                lg: 13,
                md: 9,
                sm: 6,
                xs: 3
              }}
            >
              {layouts.lg.map(panel => (
                <div key={panel.i}>
                  <LayoutComponent
                    name={panel.i}
                    onClose={!panel.static && onClose}
                    t={t}
                  />
                </div>
              ))}
            </GridLayout>
          )}
        </AutoSizer>
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  t: PropTypes.func.isRequired,
  layouts: PropTypes.object.isRequired,
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
  organisationProps: PropTypes.object,
  onLayoutChange: PropTypes.func,
  onClose: PropTypes.func,
  viewMode: PropTypes.string
};

Layout.defaultProps = {
  backgroundImage: undefined,
  title: '',
  organisationProps: {
    withAllEvents: false,
    route: undefined
  },
  viewMode: 'custom',
  onLayoutChange: () => {},
  onClose: () => {}
};

export default translate('translations')(Layout);
