import i18n from 'i18next';
import { en, de, fr, it, es, nl, zh_CN, ru, pt } from './translations';

i18n.init({
  lng: 'de',
  // we init with resources
  resources: {
    en,
    de,
    fr,
    it,
    es,
    nl,
    zh_CN,
    ru,
    pt
  },
  fallbackLng: false, // use key as fallback
  debug: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },

  react: {
    wait: true
  }
});

export default i18n;
