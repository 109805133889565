import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  View,
  StyleSheet
  // eslint-disable-next-line import/no-unresolved
} from 'react-native';

import Button from '../Button/Button';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'hidden'
  }
});

const ButtonSelect = ({
  options,
  onChange,
  value,
  noRows,
  showExtended,
  t,
  isMulti
}) => {
  const [moreAvailable, setMoreAvailable] = useState(false);
  const [extended, showMore] = useState(false);

  const height = noRows * (40 + 6);
  const maxHeight = showExtended || extended ? '100%' : height;

  const handleOnLayout = ({
    nativeEvent: {
      layout: { y }
    }
  }) => {
    if (y > height) {
      setMoreAvailable(true);
    }
  };

  return (
    <View style={styles.container}>
      <View style={[styles.buttonContainer, { maxHeight }]}>
        {options.map((opt, idx) => {
          const key = opt.key || opt.name || opt.label || `${idx}`;

          const active =
            (isMulti &&
              Array.isArray(value) &&
              value.find(({ label }) => label === opt.label)) ||
            (value && value.label === opt.label);

          const handleChange = () => {
            if (isMulti) {
              if (active) {
                onChange(
                  Array.isArray(value)
                    ? value.filter(({ label }) => label !== opt.label)
                    : []
                );
              } else {
                onChange(value ? [...value, opt] : [opt]);
              }
            } else if (active) {
              onChange(undefined);
            } else {
              onChange(opt);
            }
          };

          return (
            <Button
              key={key}
              caption={opt.label}
              onLayout={handleOnLayout}
              buttonStyle={{
                backgroundColor: active ? '#DBFF49' : '#FFFFFF',
                borderColor: active ? '#33495D' : '#C4C4C4'
              }}
              textStyle={{
                color: '#33495D'
              }}
              onPress={handleChange}
            />
          );
        })}
      </View>

      {!showExtended && moreAvailable && (
        <Button
          caption={extended ? t('Less') : t('More')}
          onPress={() => showMore(!extended)}
          buttonStyle={{
            paddingLeft: 6,
            paddingRight: 6,
            backgroundColor: '#34475B',
            marginTop: 8,
            alignSelf: 'flex-end'
          }}
          textStyle={{ color: '#DBFF49' }}
        />
      )}
    </View>
  );
};

ButtonSelect.propTypes = {
  noRows: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object)
  ]),
  showExtended: PropTypes.bool,
  t: PropTypes.func,
  isMulti: PropTypes.bool
};

// options: [
//  { label: 'Hallo', value: {} },
//     { label: 'Test', value: {} },
//     { label: 'Ein langer Button' },
//     { label: 'Ok' },
//     { label: 'Cancel' },
//     { label: 'Buuhhha' },
//     { label: 'Muhaha' },
//     { label: 'Noch mehr' }
// ]

// value = {} | [{}, {}]

ButtonSelect.defaultProps = {
  noRows: 2,
  options: [],
  value: undefined,
  onChange: () => {},
  showExtended: false,
  t: v => v,
  isMulti: true
};

export default ButtonSelect;

// TODO add animation
