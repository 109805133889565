import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

// eslint-disable-next-line import/no-unresolved
// import { Text, StyleSheet } from 'react-native';

// const styles = StyleSheet.create({
//   text: {
//     fontSize: 14,
//     fontWeight: '400',
//     color: '#8DA624',
//     fontFamily: 'Helvetica'
//   }
// });

// const CustomControl = ({ getValue, ...props }) => {
//   console.log(props);
//   console.log(getValue()[0].name);
//   return <Text style={styles.text}>{getValue()[0].name}</Text>;
// };

const styles = {
  // valueContainer: base => ({ ...base, flex: 1, d }),
  menuPortal: base => ({ ...base, zIndex: 3 }),
  singleValue: base => ({
    ...base,
    color: '#8DA624'
  })
};

const Picker = ({ value, ...rest }) => (
  <Select
    {...rest}
    defaultValue={value}
    // menuPortalTarget={document.body}
    // components={{ Control: CustomControl }}
    isMulti={false}
    styles={styles}
  />
);

Picker.propTypes = {
  options: PropTypes.array, // TODO
  onChange: PropTypes.func,
  value: PropTypes.object // TODO,
};

Picker.defaultProps = {
  options: [],
  onChange: () => {},
  value: undefined
};

export default Picker;

// TODO adapt styles to fit the rest of the input controls
